import { AxiosError } from 'axios';
import { NewPedigreeFormController } from 'common/features/newPedigreeForm/controllers/NewPedigreeFormController';
import { LoadingBackdrop } from 'components/common';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { PedigreeSaveProgress } from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import { PedigreeCaseConfirmationView } from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeCaseConfirmation/views/PedigreeCaseConfirmationView';
import PedigreeStage from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeRenderer/controllers/PedigreeStage';
import { usePedigreeSubmissionFailedDialog } from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeRenderer/hooks/usePedigreeSubmissionFailedDialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from './Layout';

export type SubmissionError = 'invalidNodeData' | 'questionnaireNotCreated';

interface PedigreeEditorProps {
  setHideFooter: (hide: boolean) => void;
}

function PedigreeEditor(props: PedigreeEditorProps) {
  const service = useMyFamilyTreeSubmissionService();
  const { pedigreeCaseId } = useParams();
  const [pedigreeSaveProgress, setPedigreeSaveProgress] =
    useState<PedigreeSaveProgress>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation('myFamilyTree');

  const { data: pedigreeCase, isLoading: isPedigreeCaseLoading } = useQuery(
    ['pedigreeCase', pedigreeCaseId],
    () => service?.getPedigreeCase(pedigreeCaseId || ''),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('../404');
          return;
        }
        if (error.response?.status === 403) {
          navigate('../');
          return;
        }
      },
    },
  );

  const { data: pedigree, isLoading: isPedigreeLoading } = useQuery(
    ['pedigree', pedigreeCase?.pedigreeId],
    () => service?.getPedigree(pedigreeCase?.pedigreeId || ''),
    {
      enabled: !!pedigreeCase?.pedigreeId,
    },
  );

  const { data: pedigreeCaseInfo, isLoading: isPedigreeCaseInfoLoading } =
    useQuery(['pedigreeCaseInfo', pedigreeCaseId], () =>
      service.getPedigreeCaseInformation(pedigreeCaseId as string),
    );

  const { pedigreeSubmissionFailedDialog, showPedigreeSubmissionFailedDialog } =
    usePedigreeSubmissionFailedDialog();

  const submitMutation = useMutation(service?.submitPedigree, {
    onSuccess: () => {
      queryClient.invalidateQueries('pedigreeCaseInfo');
      queryClient.invalidateQueries('pedigreeCase');
    },
    onError: ({ response }) => {
      const { data } = response || {};

      const errors: {
        [key: string]: SubmissionError;
      } = {
        'Invalid pedigree node data': 'invalidNodeData',
        'Questionnaire not created': 'questionnaireNotCreated',
      };

      const submissionError = errors[data.message];

      if (submissionError && pedigreeCaseId) {
        showPedigreeSubmissionFailedDialog(submissionError, pedigreeCaseId);
      }
    },
  });

  const savePedigreeMutation = useMutation(service.savePedigreeProgress, {
    onSuccess: ({ pedigreeCaseUrl, emailSent }) => {
      setPedigreeSaveProgress({ pedigreeCaseUrl, emailSent });
    },
  });

  const handleSubmit = () => {
    submitMutation.mutate(pedigreeCaseId || '');
  };

  const handleContinue = () => {
    savePedigreeMutation.mutate(pedigreeCaseId || '');
  };

  const isCaseConfirmation =
    pedigreeCaseInfo?.pedigreeCaseStatus === 'submitted' ||
    !!pedigreeSaveProgress?.pedigreeCaseUrl;

  useEffect(() => {
    if (!isCaseConfirmation) {
      props.setHideFooter(true);
      return () => {
        props.setHideFooter(false);
      };
    }
  }, [isCaseConfirmation, props]);

  const isLoading =
    isPedigreeCaseLoading || isPedigreeLoading || isPedigreeCaseInfoLoading;

  if (isLoading) {
    return <LoadingBackdrop open={isLoading} />;
  }

  if (isCaseConfirmation) {
    return (
      <PedigreeCaseConfirmationView
        pedigreeSaveProgress={pedigreeSaveProgress}
      />
    );
  }

  const hasPedigree = pedigreeCase?.pedigreeId || pedigree;

  return (
    <Layout
      {...(hasPedigree
        ? {
            handleSubmit,
            handleContinue,
            isSubmitting: submitMutation.isLoading,
            isSaving: savePedigreeMutation.isLoading,
          }
        : undefined)}
    >
      {!hasPedigree ? (
        <NewPedigreeFormController
          heading={{
            title: t('my-family-tree-pedigree-editing.new-pedigree-form.title'),
            description: t(
              'my-family-tree-pedigree-editing.new-pedigree-form.relatives',
            ),
            info: t(
              'my-family-tree-pedigree-editing.new-pedigree-form.description',
            ),
            showIcon: true,
          }}
          showRelativeDiseaseField
          createNewPedigree={(formData) =>
            service.createNewPedigree(pedigreeCaseId || '', formData)
          }
        />
      ) : (
        <>
          {pedigree && <PedigreeStage pedigree={pedigree} />}
          {pedigreeSubmissionFailedDialog}
        </>
      )}
    </Layout>
  );
}

export default PedigreeEditor;
