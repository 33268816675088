import { Box, ContainerProps } from '@mui/material';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
  signOut?: () => void;
  onLogoClick?: () => void;
  maxWidth?: ContainerProps['maxWidth'];
}

function Layout({ children, signOut, onLogoClick, maxWidth }: LayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Header
        signOut={signOut}
        onLogoClick={onLogoClick}
        containerMaxWidth={maxWidth}
      />
      {children}
    </Box>
  );
}

export default Layout;
