import { Button, Stack, Typography } from '@mui/material';
import { ButtonAction } from './types';

interface ErrorMessageViewProps {
  message: string;
  actions: ButtonAction[];
}

const ErrorMessageView = (props: ErrorMessageViewProps) => {
  return (
    <Stack gap={3} alignItems="center">
      <Typography pt={3} align="center">
        {props.message}
      </Typography>
      {props.actions.length && (
        <Stack gap={3} direction="row" justifyContent="center">
          {props.actions.map((action, index) => (
            <Button
              key={index}
              size="large"
              sx={{ width: '300px' }}
              variant={action.primary ? 'contained' : 'outlined'}
              color="primary"
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default ErrorMessageView;
