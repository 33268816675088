import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function FamilyTreeHeader({ tab }: { tab: string }) {
  const { t } = useTranslation(['myFamilyTree']);
  const theme = useTheme();
  const navigate = useNavigate();

  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Stack
      direction={isNotMobile ? 'row' : 'column'}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h1">{t('overview.header')}</Typography>
      <Button
        sx={{ marginTop: isNotMobile ? '0px' : '16px' }}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          navigate('/new-patient', {
            state: tab,
          });
        }}
      >
        {t('overview.addNewPatient')}
      </Button>
    </Stack>
  );
}

export default FamilyTreeHeader;
