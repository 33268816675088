import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { List, useTheme } from '@mui/material';
import { OptionButton } from 'myfamilytree/components/common/OptionButton';
import SimplePopper from 'myfamilytree/components/common/SimplePopper';
import { useTranslation } from 'react-i18next';
import { NodeMemberMenuValue } from '../controllers/PedigreeNodeMenuController';

interface PedigreeNodeMemberMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleMemberMenuOptionClick: (value: NodeMemberMenuValue) => void;
}

const PedigreeNodeMemberMenuView = (props: PedigreeNodeMemberMenuProps) => {
  const theme = useTheme();
  const { t } = useTranslation('myFamilyTree');

  const options: { label: string; value: NodeMemberMenuValue }[] = [
    { label: t('pedigree.node.member.option.label.parents'), value: 'parents' },
    { label: t('pedigree.node.member.option.label.partner'), value: 'partner' },
    { label: t('pedigree.node.member.option.label.brother'), value: 'brother' },
    { label: t('pedigree.node.member.option.label.sister'), value: 'sister' },
    {
      label: t('pedigree.node.member.option.label.daughter'),
      value: 'daughter',
    },
    { label: t('pedigree.node.member.option.label.son'), value: 'son' },
    { label: t('pedigree.node.member.option.label.delete'), value: 'delete' },
  ];

  return (
    <SimplePopper
      open={props.open}
      anchorEl={props.anchorEl}
      styleProps={{ width: 165 }}
    >
      <List sx={{ padding: `${theme.spacing(0.75)} 0` }}>
        {options.map((option, index) => {
          const icon = option.value === 'delete' ? faTrashCan : faPlus;
          return (
            <OptionButton
              key={index}
              option={option}
              buttonStyle={{
                padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
                ':hover': {
                  backgroundColor: theme.palette.common.brand.lightPurple,
                },
              }}
              iconStyle={{
                fontSize: theme.spacing(1.5),
                lineHeight: theme.spacing(1.5),
              }}
              listItemStyle={{
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
              onOptionClick={props.handleMemberMenuOptionClick}
              icon={icon}
              useDivider={option.value === 'delete'}
            />
          );
        })}
      </List>
    </SimplePopper>
  );
};

export default PedigreeNodeMemberMenuView;
