import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import SectionBox from 'components/common/SectionBox';
import { useTranslation } from 'react-i18next';
import ButtonRowView from '../../addRelative/views/ButtonRowView';

interface MessageVerificationViewProps {
  title: string;
  onBackButtonClick: () => void;
  onContinueClick?: () => void;
  onContinueClickLoading?: boolean;
  message: string;
}

function MessageVerificationView(props: MessageVerificationViewProps) {
  const { t } = useTranslation('all');
  return (
    <Container>
      <Stack spacing={2}>
        <HeaderWithBackButton
          onBackButtonClick={props.onBackButtonClick}
          headerVariant="h1"
        >
          {props.title}
        </HeaderWithBackButton>
        <SectionBox>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            {props.message}
          </Typography>
        </SectionBox>
        <Box>
          <ButtonRowView
            ButtonOne={
              <Button
                variant="outlined"
                onClick={props.onBackButtonClick}
                fullWidth
              >
                {t('ui-generic.back')}
              </Button>
            }
            ButtonTwo={
              props.onContinueClick ? (
                <LoadingButton
                  variant="contained"
                  onClick={props.onContinueClick}
                  loading={props.onContinueClickLoading}
                  fullWidth
                >
                  {t('consent.actions.send-message')}
                </LoadingButton>
              ) : null
            }
          />
        </Box>
      </Stack>
    </Container>
  );
}

export default MessageVerificationView;
