import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import {
  NewPatientFormData,
  NewPatientPayload,
  RelativeFormData,
} from 'features/consentManagement/common/types';
import { getNewConsentNotification } from 'features/consentManagement/common/utils/notification-template';
import ConsentSentConfirmationView from 'features/consentManagement/common/views/ConsentSentConfirmationView';
import MessageVerificationView from 'features/consentManagement/common/views/MessageVerificationView';
import NewPatientFormController from 'features/consentManagement/newPatient/controllers/NewPatientFormController';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

function NewPatient() {
  const service = useConsentManagementService();
  const { t } = useTranslation('all');
  const [newPatientData, setNewPatientData] = React.useState<
    NewPatientFormData | undefined
  >(undefined);

  const [pageState, setPageState] = React.useState<
    'form' | 'review' | 'confirmation'
  >('form');

  const createNewConsentMutation = useMutation(
    service.createNewConsentRequest,
    {
      onSuccess: () => setPageState('confirmation'),
    },
  );

  const handleContinueClick = (data: NewPatientFormData) => {
    setNewPatientData(data);
    setPageState('review');
  };

  const toNewPatientPayload = (
    newPatientFormData: NewPatientFormData,
  ): NewPatientPayload => {
    const relatives = newPatientFormData.relatives.map((relative) => {
      const { specificProxySigner, ...rest } = relative;
      return rest as Exclude<RelativeFormData, 'specificProxySigner'>;
    });
    return {
      patientData: newPatientFormData.patientData,
      relatives: relatives,
      consentFor: newPatientFormData.consentFor,
    };
  };

  const handleOnSubmitClick = () => {
    if (!newPatientData) {
      return;
    }
    createNewConsentMutation.mutate(toNewPatientPayload(newPatientData));
  };

  if (pageState === 'confirmation') {
    return (
      <ConsentSentConfirmationView
        patientName={newPatientData?.patientData.name || ''}
        relatives={newPatientData?.relatives || []}
      />
    );
  }

  if (pageState === 'review') {
    return (
      <MessageVerificationView
        title={t('consent-management.new-patient.title')}
        onContinueClick={handleOnSubmitClick}
        onBackButtonClick={() => setPageState('form')}
        message={getNewConsentNotification(
          t,
          newPatientData?.patientData.name || '',
          newPatientData?.patientData.personalNumber || '',
          newPatientData?.relatives || [],
        )}
      />
    );
  }

  return (
    <NewPatientFormController
      onContinueClick={handleContinueClick}
      initialData={newPatientData}
    />
  );
}

export default NewPatient;
