import { PedigreeNode } from 'pedigree/features/common/types';
import { useMemo } from 'react';
import { Html } from 'react-konva-utils';
import { ThemeProvider, useTheme } from 'styled-components';
import { usePedigreeContext } from '../../common/pedigreeContext';
import { NodePatientView } from '../views/NodePatientView';

interface NodePatientControllerProps {
  position?: {
    x: number;
    y: number;
  };
  pedigreeNode: PedigreeNode;
  isSelected: boolean;
}

export const NodePatientController = (props: NodePatientControllerProps) => {
  const { pedigreeNode, position, isSelected } = props;

  const { nodeSize, handleNodeAction, getLocalizedLabelOf } =
    usePedigreeContext();

  const relativeRelationLabel = useMemo(
    () => getLocalizedLabelOf(pedigreeNode.id),
    [pedigreeNode.id, getLocalizedLabelOf],
  );

  const theme = useTheme();

  const nodeInfoLabel = useMemo(() => {
    const mock = {
      addDiagnosisLabel: 'Add diagnosis',
      cancerHistoryLabel: 'Cancer history',
      deceasedLabel: 'Deceased',
    };
    return mock;
  }, []);

  const { x, y } = position || {
    x: 0,
    y: 0,
  };

  return (
    <>
      <Html
        groupProps={{
          x: x,
          y: y,
          offsetX: nodeSize.width / 2,
          offsetY: nodeSize.height / 2,
        }}
      >
        <ThemeProvider theme={theme}>
          <NodePatientView
            {...{
              sex: pedigreeNode.sex,
              name: pedigreeNode.name,
              isIndex: pedigreeNode.isIndex,
              isSelected: isSelected,
              relativeRelationLabel,
              nodeSize: nodeSize,
              onClick: () =>
                handleNodeAction({
                  action: 'edit',
                  pedigreeNodeId: pedigreeNode.id,
                }),
              hasCancerHistory: pedigreeNode.hasDiagnoseHistory || false,
              hasError: !!nodeInfoLabel.addDiagnosisLabel && !isSelected,
              cancerHistoryLabel: nodeInfoLabel.cancerHistoryLabel,
              addDiagnosisLabel: nodeInfoLabel.addDiagnosisLabel,
              deceasedLabel: nodeInfoLabel.deceasedLabel,
            }}
          />
        </ThemeProvider>
      </Html>
    </>
  );
};
