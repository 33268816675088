import { PatientData } from 'api/consentManagement/consent-management-service';
import { formatPhoneNumberInput } from 'features/common/utils';
import { PatientDataErrors } from 'features/consentManagement/common/types';
import React from 'react';
import PatientFormView from '../views/PatientFormView';

interface PatientFormControllerProps {
  data: PatientData;
  setData: (data: PatientData) => void;
  errors: PatientDataErrors;
  setErrors: (errors: PatientDataErrors) => void;
  consentFor: string;
  disabled?: boolean;
}

function PatientFormController(props: PatientFormControllerProps) {
  const handleTextInputChangeFactory =
    (key: keyof PatientData) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (key === 'phoneNumber') {
        value = formatPhoneNumberInput(value);
      }
      props.setData({ ...props.data, [key]: value });
      props.setErrors({ ...props.errors, [key]: '' });
    };

  return (
    <PatientFormView
      data={props.data}
      errors={props.errors}
      handleTextInputChangeFactory={handleTextInputChangeFactory}
      consentFor={props.consentFor}
      disabled={props.disabled}
    />
  );
}

export default PatientFormController;
