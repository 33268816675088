import { Box, Container, Stack } from '@mui/material';
import { ReactComponent as LogoArkus } from 'assets/images/arkus-logo.svg';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = (props) => {
  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="column" spacing={3} alignItems="center">
          <LogoArkus style={{ maxWidth: 300 }} />
          <Box
            sx={{
              backgroundColor: 'common.brand.lightPurple',
              p: 2,
              borderRadius: 1,
            }}
          >
            {props.children}
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default AuthLayout;
