import {
  Button,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { PatientData } from 'api/consentManagement/consent-management-service';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import { defaultRelativeData } from 'features/consentManagement/common/consts';
import {
  PatientDataErrors,
  RelativeDataErrors,
  RelativeFormData,
} from 'features/consentManagement/common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCancelConfirmationDialog from '../../common/hooks/useCancelConfirmationDialog';
import { NewPatientFormData } from '../../common/types';
import {
  validatePatientData,
  validateRelativeData,
} from '../../common/utils/form-validation';
import PatientFormController from './PatientFormController';
import PersonalNumberFormController from './PersonalNumberFormController';
import RelativesFormController from './RelativesFormController';

interface NewPatientFormControllerProps {
  onContinueClick: (data: NewPatientFormData) => void;
  initialData?: NewPatientFormData;
}

const NewPatientFormController: React.FC<NewPatientFormControllerProps> = (
  props,
) => {
  const navigate = useNavigate();
  const { t } = useTranslation('all');
  const consentFor: string = t(
    'consent-management.new-patient.section2.consentFor.defaultValue',
  );

  const [patientData, setPatientData] = React.useState<PatientData>({
    personalNumber: '',
    name: '',
    phoneNumber: '',
    email: '',
  });

  const [patientDataErrorMessages, setPatientDataErrorMessages] =
    React.useState<PatientDataErrors>({
      personalNumber: '',
      name: '',
      phoneNumber: '',
    });

  const [relatives, setRelatives] = React.useState<RelativeFormData[]>([
    defaultRelativeData,
  ]);

  const [relativesErrorMessages, setRelativesErrorMessages] = React.useState<
    RelativeDataErrors[]
  >([
    {
      personalNumber: '',
      name: '',
      relationshipToPatient: '',
      proxySignerName: '',
      deceased: '',
      proxySignerPersonalNumber: '',
      proxySignerToConsenteeRelationship: '',
    },
  ]);

  const theme = useTheme();

  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const setPersonalNumber = (personalNumber: string) => {
    setPatientData((prevState) => ({ ...prevState, personalNumber }));
  };

  React.useEffect(() => {
    if (props.initialData) {
      setPatientData(props.initialData.patientData);
      setRelatives(props.initialData.relatives);
    }
  }, [props.initialData]);

  const handleOnContinueClick = () => {
    const { valid: patientDataValid, errorMessages: patientDataErrorMessages } =
      validatePatientData(patientData);
    const { valid: relativesValid, errorMessages: relativesErrorMessages } =
      validateRelativeData(relatives);

    if (patientDataValid && relativesValid) {
      props.onContinueClick({ patientData, relatives, consentFor });
    } else {
      if (patientDataErrorMessages !== undefined)
        setPatientDataErrorMessages(patientDataErrorMessages);

      if (relativesErrorMessages !== undefined)
        setRelativesErrorMessages(
          relativesErrorMessages.filter((error) => error !== undefined),
        );
    }
  };

  const { dialog: cancelConfirmationDialog, openDialog: onCancelClick } =
    useCancelConfirmationDialog(() => navigate(-1));

  const handleCancelClick = () => {
    const contentHasChanged =
      patientData.personalNumber !== '' ||
      patientData.name !== '' ||
      patientData.phoneNumber !== '' ||
      patientData.email !== '' ||
      relatives.some(
        (relative) =>
          relative.personalNumber !== '' ||
          relative.name !== '' ||
          relative.relationshipToPatient !== '' ||
          relative.proxySignerName !== '' ||
          relative.proxySignerPersonalNumber !== '' ||
          relative.proxySignerToConsenteeRelationship !== '',
      );
    onCancelClick(contentHasChanged);
  };

  const service = useConsentManagementService();

  return (
    <>
      <Container>
        <Stack direction="column" spacing={3}>
          <HeaderWithBackButton
            headerVariant="h1"
            onBackButtonClick={handleCancelClick}
          >
            {t('consent-management.new-patient.title')}
          </HeaderWithBackButton>
          <PersonalNumberFormController
            setPersonalNumber={setPersonalNumber}
            initialData={patientData.personalNumber}
            validatePersonalNumber={service.validatePersonalNumber}
          />
          {patientData.personalNumber && (
            <>
              <PatientFormController
                data={patientData}
                setData={setPatientData}
                errors={patientDataErrorMessages}
                setErrors={setPatientDataErrorMessages}
                consentFor={consentFor}
              />
              <RelativesFormController
                data={relatives}
                setData={setRelatives}
                errors={relativesErrorMessages}
                setRelativesErrorMessages={setRelativesErrorMessages}
              />
              <Stack
                direction={isNotMobile ? 'row' : 'column'}
                justifyContent="center"
                spacing={2}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancelClick}
                  sx={{ minWidth: 300 }}
                >
                  {t('consent-management.new-patient.cancel')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleOnContinueClick}
                  sx={{ minWidth: 300 }}
                >
                  {t('consent-management.new-patient.continue')}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Container>
      {cancelConfirmationDialog}
    </>
  );
};

export default NewPatientFormController;
