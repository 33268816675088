import { useConsentSubmissionService } from 'api/consentSubmission/consentSubmissionServiceProvider';
import {
  formatPersonalNumberInput,
  formatPhoneNumberInput,
} from 'features/common/utils';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  defaultErrorMessages,
  validateRelativeFormData,
} from '../utils/form-validation';
import RelativeInformationFormView from '../views/RelativeInformationFormView';

interface RelativeInformationFormControllerProps {
  onSubmitSuccess: () => void;
  consentId: string;
}

function RelativeInformationFormController(
  props: RelativeInformationFormControllerProps,
) {
  const queryClient = useQueryClient();
  const { consentId } = props;
  const service = useConsentSubmissionService();
  const [formData, setFormData] = useState<ConsentSubmissionFormData>(
    defaultConsentSubmissionFormData,
  );
  const [errorMessages, setErrorMessages] = React.useState<{
    [key in keyof ConsentSubmissionFormData]?: string | undefined;
  }>(defaultErrorMessages);

  const { data } = useQuery(
    ['consent', 'formData'],
    () => service.getConsent(consentId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const updatedFormData: ConsentSubmissionFormData = {
          ...formData,
          name: data.name,
          personalNumber: data.personalNumber,
          relationshipToPatient: data.relationshipToPatient || '',
          phoneNumber: data.phoneNumber || '',
          geneticInvestigationInfo: data.additionalInfo || '',
        };
        if ('proxySigner' in data) {
          updatedFormData.deceased = true;
          updatedFormData.personalNumber = data.proxySigner.personalNumber;
          updatedFormData.name = data.proxySigner.name;
          updatedFormData.phoneNumber = data.proxySigner.phoneNumber || '';
          updatedFormData.signerRelationshipToRelative =
            data.proxySigner.signerToConsenteeRelationship || '';
          updatedFormData.deceasedPersonalNumber = data.personalNumber;
        }
        setFormData(updatedFormData);
      },
    },
  );

  const submitMutation = useMutation(
    () => service.submitConsent({ consentId, formData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('consent');
        props.onSubmitSuccess();
      },
    },
  );

  const handleContinueClick = () => {
    const { valid, errorMessages } = validateRelativeFormData(formData);
    if (valid) {
      submitMutation.mutate();
      return;
    }
    if (errorMessages !== undefined) {
      setErrorMessages(errorMessages);
    }
  };

  const createHandleTextInputChange =
    (key: keyof ConsentSubmissionFormData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (key.toLocaleLowerCase().includes('personalnumber')) {
        value = formatPersonalNumberInput(value);
      }
      if (key === 'phoneNumber') {
        value = formatPhoneNumberInput(value);
      }
      setErrorMessages({ ...errorMessages, [key]: '' });
      setFormData({ ...formData, [key]: value });
    };

  const createAutoCompleteInputChange =
    (key: keyof ConsentSubmissionFormData) => (value: string | null) => {
      setErrorMessages({ ...errorMessages, [key]: '' });
      setFormData({ ...formData, [key]: value || '' });
    };

  return (
    <>
      <RelativeInformationFormView
        {...formData}
        patientName={data?.patient.name || ''}
        deceasedName={data?.name || null}
        onPhoneNumberChange={createHandleTextInputChange('phoneNumber')}
        phoneNumberError={errorMessages.phoneNumber || ''}
        onRelationshipToPatientChange={createAutoCompleteInputChange(
          'relationshipToPatient',
        )}
        relationshipToPatientError={errorMessages.relationshipToPatient || ''}
        onSignerRelationshipToRelativeChange={createAutoCompleteInputChange(
          'signerRelationshipToRelative',
        )}
        onDeceasedPersonalNumberChange={createHandleTextInputChange(
          'deceasedPersonalNumber',
        )}
        deceasedPersonalNumberError={errorMessages.deceasedPersonalNumber || ''}
        signerRelationshipToRelativeError={
          errorMessages.signerRelationshipToRelative || ''
        }
        onGeneticInvestigationInformationChange={createHandleTextInputChange(
          'geneticInvestigationInfo',
        )}
        geneticInvestigationInformationError=""
        onContinueClick={handleContinueClick}
        disabled={submitMutation.isLoading}
        continueButtonLoading={submitMutation.isLoading}
        geneticCounselorName={data?.staff.name || ''}
        geneticCounselorPhoneNumber={data?.staff.phoneNumber || ''}
      />
    </>
  );
}

export default RelativeInformationFormController;
export type ConsentSubmissionFormData = {
  deceased: boolean;
  name: string;
  phoneNumber: string;
  relationshipToPatient: string;
  personalNumber: string;
  geneticInvestigationInfo: string;
  signerRelationshipToRelative: string;
  deceasedPersonalNumber: string;
};

export const defaultConsentSubmissionFormData: ConsentSubmissionFormData = {
  deceased: false,
  name: '',
  phoneNumber: '',
  relationshipToPatient: '',
  personalNumber: '',
  geneticInvestigationInfo: '',
  signerRelationshipToRelative: '',
  deceasedPersonalNumber: '',
};
