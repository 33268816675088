import axios, { AxiosInstance } from 'axios';
import { i18n } from 'i18next';
import { QueryClient } from 'react-query';

export class BaseService {
  axiosInstance: AxiosInstance;

  constructor(
    apiBaseUrl: string | undefined,
    accessToken: string | null,
    queryClient: QueryClient | null,
    i18n: i18n,
    logout?: () => void,
  ) {
    if (!apiBaseUrl) {
      throw new Error('API base URL not available');
    }
    this.axiosInstance = axios.create({ baseURL: apiBaseUrl });
    if (accessToken) {
      this.axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
    }

    this.axiosInstance.defaults.headers.common['Accept-Language'] =
      i18nLangToLocale(i18n.language);

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          logout?.();
        }
        return Promise.reject(error);
      },
    );

    i18n.on('languageChanged', (lng) => {
      this.axiosInstance.defaults.headers.common['Accept-Language'] =
        i18nLangToLocale(lng);
      if (queryClient) {
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, 500);
      }
    });
  }
}

function i18nLangToLocale(lng: string): string {
  return lng === 'en' ? 'en_GB' : 'sv_SE';
}
