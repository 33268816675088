import { Button, Container, Stack, Typography } from '@mui/material';
import { BankidClientAction } from 'api/bankid/bankid-service';
import bankidLogo from 'assets/images/bankid-logo.svg';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import LoadingIndicator from './LoadingIndicator';

interface BankidViewProps {
  action: BankidClientAction;
  message: string;
  qrCode?: string;
  onCancelClick?: () => void;
}

function BankidView({
  action,
  message,
  qrCode,
  onCancelClick,
}: BankidViewProps) {
  const { t } = useTranslation(['all']);
  return (
    <Container maxWidth="sm">
      <Stack spacing={4} alignItems="center">
        <img src={bankidLogo} alt="BankID logo" />
        {action === 'showQR' && qrCode && (
          <QRCode value={qrCode} data-testid="qr-code" />
        )}
        {action === 'showLoading' && <LoadingIndicator />}
        {message && <Typography textAlign="center">{message}</Typography>}
        {['showLoading', 'showQR'].includes(action) && (
          <Button variant="outlined" onClick={onCancelClick} fullWidth>
            {t('ui-generic.cancel')}
          </Button>
        )}
      </Stack>
    </Container>
  );
}

export default BankidView;
