import {
  faCheck,
  faCircleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from 'components/common/Dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PedigreeReviewPanelProps {
  onAcceptClick: () => void;
  onRejectClick: () => void;
}

function PedigreeReviewPanel(props: PedigreeReviewPanelProps) {
  const { t } = useTranslation('iPedigree');
  const [confirmationDialogType, setConfirmationDialogType] = useState<
    'accept' | 'reject' | null
  >(null);

  const confirmationDialogProps = useMemo<DialogProps>(() => {
    if (confirmationDialogType === 'accept') {
      return {
        open: true,
        title: t('pedigree-review-panel.confirmation-dialog.accept.title'),
        content: t('pedigree-review-panel.confirmation-dialog.accept.content'),
        actions: [
          <Button
            key="cancel"
            variant="outlined"
            onClick={() => setConfirmationDialogType(null)}
          >
            {t(
              'pedigree-review-panel.confirmation-dialog.action.accept.cancel',
            )}
          </Button>,
          <Button
            key="confirm"
            variant="contained"
            onClick={() => {
              props.onAcceptClick();
              setConfirmationDialogType(null);
            }}
          >
            {t(
              'pedigree-review-panel.confirmation-dialog.action.accept.confirm',
            )}
          </Button>,
        ],
      };
    }

    if (confirmationDialogType === 'reject') {
      return {
        open: true,
        title: t('pedigree-review-panel.confirmation-dialog.reject.title'),
        content: t('pedigree-review-panel.confirmation-dialog.reject.content'),
        actions: [
          <Button
            key="confirm"
            variant="outlined"
            onClick={() => {
              props.onRejectClick();
              setConfirmationDialogType(null);
            }}
          >
            {t(
              'pedigree-review-panel.confirmation-dialog.action.reject.confirm',
            )}
          </Button>,
          <Button
            key="cancel"
            variant="contained"
            onClick={() => setConfirmationDialogType(null)}
          >
            {t(
              'pedigree-review-panel.confirmation-dialog.action.reject.cancel',
            )}
          </Button>,
        ],
      };
    }

    return {
      open: false,
      title: '',
      actions: [],
    };
  }, [confirmationDialogType, t, props]);

  return (
    <>
      <Box sx={{ backgroundColor: 'warning.light' }}>
        <Container sx={{ py: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="column" spacing={1}>
              <Typography variant="h4">
                <Box display="inline" sx={{ mr: 2, color: 'warning.main' }}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </Box>
                {t('pedigree-review-panel.title')}
              </Typography>
              <Box>
                <Typography variant="body2">
                  {t('pedigree-review-panel.description.line1')}
                </Typography>
                <Typography variant="body2">
                  {t('pedigree-review-panel.description.line2')}
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Button
                variant="outlined"
                onClick={() => setConfirmationDialogType('reject')}
                startIcon={<FontAwesomeIcon icon={faXmark} />}
              >
                {t('pedigree-review-panel.action.reject')}
              </Button>
              <Button
                variant="contained"
                onClick={() => setConfirmationDialogType('accept')}
                startIcon={<FontAwesomeIcon icon={faCheck} />}
              >
                {t('pedigree-review-panel.action.accept')}
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Dialog {...confirmationDialogProps} />
    </>
  );
}

export default PedigreeReviewPanel;
