import { Typography, Box } from '@mui/material';

interface ListConsentEmptyStateProps {
  title: string;
  description: string;
}

function ListConsentEmptyState(props: ListConsentEmptyStateProps) {
  return (
    <Box>
      <Typography variant="h4">{props.title}</Typography>
      <Typography variant="body1">{props.description}</Typography>
    </Box>
  );
}

export default ListConsentEmptyState;
