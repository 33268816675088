import { styled } from '@mui/material';
import { forwardRef } from 'react';

export const arrowStyle = {
  '&[data-popper-placement*="right"] .arrow': {
    left: -15,
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: -15,
  },
};

const Arrow = styled('div')({
  position: 'absolute',
  width: 50,
  height: 25,
  boxSizing: 'border-box',
  '&::before': {
    content: '" "',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
});

export const NodeActionMenuArrow = forwardRef<HTMLDivElement>(
  function NodeActionMenuArrow(_, ref) {
    return <Arrow className="arrow" ref={ref}></Arrow>;
  },
);
