import { Button, Container, ContainerProps, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EconsentLogo from './EconsentLogo';

interface HeaderProps {
  signOut?: () => void;
  onLogoClick?: () => void;
  containerMaxWidth: ContainerProps['maxWidth'];
}

function Header({ signOut, onLogoClick, containerMaxWidth }: HeaderProps) {
  const { t } = useTranslation('all');
  return (
    <Container sx={{ py: 2 }} maxWidth={containerMaxWidth}>
      <Stack direction="row" justifyContent="space-between">
        <EconsentLogo onClick={onLogoClick} />
        {signOut && (
          <Button variant="text" onClick={signOut}>
            {t('sign-out')}
          </Button>
        )}
      </Stack>
    </Container>
  );
}

export default Header;
