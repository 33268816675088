import { Typography } from '@mui/material';
import { UiSchema } from '@rjsf/utils';
import { DynamicForm } from 'common/features/dynamicForm';
import { usePedigreeContext } from 'pedigree/features/common/pedigreeContext';
import Drawer from 'pedigree/features/common/views/Drawer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const NodeInformationController = () => {
  const {
    selectedNodeId,
    setSelectedNodeId,
    selectedNode,
    service,
    pedigree,
    getLocalizedLabelOf,
  } = usePedigreeContext();
  const { t, i18n } = useTranslation('pedigree');

  const selectedRelativeRelationLabel = useMemo(() => {
    if (selectedNode?.isIndex) {
      return selectedNode.name || '';
    }
    return selectedNodeId ? getLocalizedLabelOf(selectedNodeId) : '';
  }, [selectedNodeId, getLocalizedLabelOf, selectedNode]);

  const { data: relativeForm } = useQuery(
    ['relativeForm', selectedNodeId, i18n.language],
    () => service.getRelativeForm(pedigree.id, selectedNodeId || ''),
    {
      enabled: !!selectedNodeId && !selectedNode?.isIndex,
    },
  );

  const { data: patientForm } = useQuery(
    ['patientForm', selectedNodeId, i18n.language],
    () => service.getPatientForm(),
    {
      enabled: !!selectedNode?.isIndex,
    },
  );

  const onClose = () => {
    setSelectedNodeId(null);
  };

  const data = selectedNode?.isIndex ? patientForm : relativeForm;

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: true,
      },
      norender: true,
    },
    'ui:accordionsGap': 0.5,
  };

  return (
    <Drawer
      title={selectedRelativeRelationLabel}
      isOpen={!!selectedNodeId}
      onClose={onClose}
    >
      {data?.formData ? (
        <DynamicForm
          schema={data?.jsonSchema}
          uiSchema={{ ...uiSchema, ...data?.uiSchema }}
          formData={data?.formData}
          formContext={{ narrowForm: true }}
          readonly
        />
      ) : (
        <Typography p={2} pr={3} pl={3}>
          {t('node-details.no-info-provided')}
        </Typography>
      )}
    </Drawer>
  );
};
