import { Box, Container, Typography } from '@mui/material';
import { ContainerProps } from '@mui/material/Container';

interface SubHeaderBannerProps {
  title: string;
  maxWidth?: ContainerProps['maxWidth'];
}
function SubHeaderBanner(props: SubHeaderBannerProps) {
  return (
    <Box sx={{ backgroundColor: 'common.brand.lightPurple' }}>
      <Container sx={{ py: 3, px: 2 }} maxWidth={props.maxWidth}>
        <Typography variant="h3">{props.title}</Typography>
      </Container>
    </Box>
  );
}

export default SubHeaderBanner;
