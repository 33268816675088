import { Button, Stack, Typography } from '@mui/material';
interface PanelHeaderProps {
  title: string;
  buttonText: string;
  onAddNewPatientClick: () => void;
}

function PanelHeader({
  title,
  buttonText,
  onAddNewPatientClick,
}: PanelHeaderProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <Typography variant="h1">{title}</Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onAddNewPatientClick}
      >
        {buttonText}
      </Button>
    </Stack>
  );
}

export default PanelHeader;
