import { Button } from '@mui/material';
import Dialog from 'components/common/Dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function useCancelConfirmationDialog(onConfirm: () => void) {
  const { t } = useTranslation('all');
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = (contentHasChanged: boolean) => {
    if (!contentHasChanged) {
      onConfirm();
      return;
    }
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return {
    dialog: (
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        title={t('consent-management.cancel-confirmation.title')}
        content={t('consent-management.cancel-confirmation.text')}
        actions={[
          <Button onClick={closeDialog} variant="outlined" key="close-dialog">
            {t('consent-management.cancel-confirmation.continue-editing')}
          </Button>,
          <Button
            onClick={onConfirm}
            autoFocus
            variant="contained"
            key="confirm-dialog"
          >
            {t('consent-management.cancel-confirmation.exit-editing')}
          </Button>,
        ]}
      />
    ),
    openDialog,
  };
}

export default useCancelConfirmationDialog;
