import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { SvgIcon } from '@mui/material';
import { ReactComponent as BankidLogoDark } from 'assets/images/bankid-logo-dark.svg';
import { ReactComponent as BankidLogoWhite } from 'assets/images/bankid-logo-white.svg';

interface BankidSignButtonProps extends LoadingButtonProps {}

export function BankidSignButton(props: BankidSignButtonProps) {
  let buttonSx: LoadingButtonProps['sx'] = {
    backgroundColor: 'common.brand.bankid',
    ':hover': {
      backgroundColor: 'common.brand.bankidHover',
    },
    color: 'white',
  };

  if (props.variant === 'outlined') {
    buttonSx = {
      borderColor: 'common.brand.bankid',
      color: 'common.brand.bankid',
      ':hover': {
        borderColor: 'common.brand.bankidHover',
        color: 'common.brand.bankidHover',
        backgroundColor: 'white',
      },
    };
  }

  return (
    <LoadingButton
      {...props}
      sx={buttonSx}
      startIcon={
        <SvgIcon viewBox="0 0 41 40" style={{ fontSize: 50 }}>
          {props.variant === 'outlined' ? (
            <BankidLogoDark />
          ) : (
            <BankidLogoWhite />
          )}
        </SvgIcon>
      }
    >
      {props.children}
    </LoadingButton>
  );
}
