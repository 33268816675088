import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography } from '@mui/material';
import SectionBox from 'components/common/SectionBox';
import { RadioInput, TextInput } from 'components/inputs';
import { useTranslation } from 'react-i18next';
import { RelativeDiseaseDataError } from '../types';

export type NumberOfRelativeField = {
  label: string;
  value: string;
  setValue: (value: string) => void;
};

export type RelativeDiseaseField = {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  setValue: (value: string) => void;
};

interface NewPedigreeFormViewProps extends NumberOfRelativeFormProps {
  showRelativeDiseaseField?: boolean;
  onSubmit: () => void;
  relativeDiseaseField: RelativeDiseaseField;
  isSubmitting: boolean;
  errors: RelativeDiseaseDataError;
}

export const NewPedigreeFormView = (props: NewPedigreeFormViewProps) => {
  const { t } = useTranslation('pedigree');

  return (
    <Container maxWidth="md">
      <SectionBox>
        <Stack spacing={2}>
          <NumberOfRelativeFormView {...props} />
          {props.showRelativeDiseaseField && (
            <RadioInput
              id="relatives-disease"
              value={props.relativeDiseaseField.value}
              options={props.relativeDiseaseField.options}
              label={t('new-pedigree-form.relative-disease')}
              onChange={(e) =>
                props.relativeDiseaseField.setValue(e.target.value)
              }
              error={
                props.errors.relativeHasDisease &&
                t(
                  `new-pedigree-form.input-validation.${props.errors.relativeHasDisease}`,
                )
              }
              rowDirection
              required
            ></RadioInput>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={props.onSubmit}
            loading={props.isSubmitting}
          >
            {t('common.button.continue')}
          </LoadingButton>
        </Stack>
      </SectionBox>
    </Container>
  );
};

interface NumberOfRelativeFormProps {
  heading: {
    title: string;
    description: string;
    info?: string;
    showIcon: boolean;
  };
  fields: NumberOfRelativeField[];
}

export const NumberOfRelativeFormView = (props: NumberOfRelativeFormProps) => {
  return (
    <>
      <Typography variant="h2">
        {props.heading.showIcon && (
          <Box display="inline" mr={2} color="primary.main">
            <FontAwesomeIcon icon={faUsers} />
          </Box>
        )}
        {props.heading.title}
      </Typography>
      <Stack spacing={1}>
        <Typography variant="body1">{props.heading.description}</Typography>
        {props.heading.info && (
          <Typography variant="body2" color="common.neutral.600">
            {props.heading.info}
          </Typography>
        )}
        {props.fields.map((field) => (
          <TextInput
            key={`relative-${field.label}`}
            id={`relative-${field.label}`}
            label={field.label}
            value={field.value}
            onChange={(event) => {
              field.setValue(event.target.value);
            }}
            inlineLabel
            textFieldProps={{
              sx: { maxWidth: 50 },
              type: 'number',
              inputMode: 'numeric',
              inputProps: { min: 0 },
            }}
          />
        ))}
      </Stack>
    </>
  );
};
