import { SnackbarProps, Snackbar, Alert } from '@mui/material';

interface ErrorSnackbarProps {
  errorMessage: string;
  onClose: () => void;
  snackbarProps?: SnackbarProps;
}

function ErrorSnackbar(props: ErrorSnackbarProps) {
  return (
    <Snackbar
      open={props.errorMessage !== ''}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={10000}
      {...props.snackbarProps}
    >
      <Alert severity="error" variant="filled" onClose={props.onClose}>
        {props.errorMessage}
      </Alert>
    </Snackbar>
  );
}

export default ErrorSnackbar;
