import SendReminder from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendReminder';
import { useParams } from 'react-router-dom';

const SendReminderPage = () => {
  const { pedigreeCaseId } = useParams();
  if (!pedigreeCaseId) {
    throw new Error('Pedigree case id is undefined');
  }

  return <SendReminder pedigreeCaseId={pedigreeCaseId} />;
};

export default SendReminderPage;
