import React from 'react';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from '../serviceProviderFactory';
import { BankidService } from './bankid-service';

const Context = React.createContext<{
  service: BankidService | null;
}>({ service: null });

export const BankidServiceProvider = serviceProviderFactory(
  BankidService,
  Context,
);

export const useBankidService = serviceHookFactory(BankidService, Context);
