export const formatPersonalNumberInput = (personalNumber: string) => {
  return personalNumber.replace(/[^0-9]/g, '');
};

export const formatPhoneNumberInput = (phoneNumber: string) => {
  if (phoneNumber.length > 0) {
    const onlyDigits = phoneNumber.replace(/[^0-9]/g, '');
    return onlyDigits;
  }
  return '';
};
