import React from 'react';
import SortAndSearchInputView from '../views/SortAndSearchInputView';

type SortField = 'createdAt' | 'patientName';
type SortOrder = 'ASC' | 'DESC';

export type SortAndSearchParameters = {
  sort: {
    field: SortField;
    order: SortOrder;
  };
  search: string;
};


function SortAndSearchInputController(props: {
  sortAndSearchParameters: SortAndSearchParameters;
  setSortAndSearchParameters: React.Dispatch<
    React.SetStateAction<SortAndSearchParameters>
  >;
}) {
  const handleSetSortValue = (value: string) => {
    const [field, order] = value.split(':') as [SortField, SortOrder];
    props.setSortAndSearchParameters((prev) => ({
      ...prev,
      sort: {
        field,
        order,
      },
    }));
  };

  const handleSetSearchValue = (value: string) => {
    props.setSortAndSearchParameters((prev) => ({
      ...prev,
      search: value,
    }));
  };

  const searchValue = props.sortAndSearchParameters.search;
  const sortValue = `${props.sortAndSearchParameters.sort.field}:${props.sortAndSearchParameters.sort.order}`;

  return (
    <SortAndSearchInputView
      searchValue={searchValue}
      setSearchValue={handleSetSearchValue}
      sortValue={sortValue}
      setSortValue={handleSetSortValue}
    />
  );
}

export default SortAndSearchInputController;
