import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
export interface TabProps {
  title: string;
  icon: IconDefinition;
  onClick?: () => void;
  data: { value: number; label: string }[];
  selected?: boolean;
}

function Tab(props: TabProps) {
  const theme = useTheme();
  const disabled = props.onClick === undefined;
  const { t } = useTranslation('iPedigree');

  const cardBGColor = disabled
    ? theme.palette.action.disabledBackground
    : props.selected
    ? 'inherit'
    : theme.palette.primary.main;
  const textColor = disabled
    ? theme.palette.text.disabled
    : props.selected
    ? theme.palette.common.brand.darkBlue
    : theme.palette.common.white;

  return (
    <Box
      sx={{
        position: 'relative',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={props.onClick}
    >
      {props.selected && (
        <Box
          sx={{
            position: 'absolute',
            right: '-30px',
            border: 'solid 15px transparent',
            borderLeftColor: '#233749',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      )}
      <Paper
        elevation={disabled ? 0 : 3}
        sx={{
          padding: 2,
          backgroundColor: cardBGColor,
          color: textColor,
        }}
      >
        <Stack direction="column" spacing={1}>
          {disabled ? (
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" sx={{ color: textColor }}>
                <Box component="span" mr={2}>
                  <FontAwesomeIcon icon={props.icon} />
                </Box>
              </Typography>
              <Stack>
                <Typography variant="h4" sx={{ color: textColor }}>
                  {props.title}
                </Typography>
                <Typography variant="body2" sx={{ color: textColor }}>
                  {t('page-dashboard.tab.notAvailable')}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" sx={{ color: textColor }}>
                <Box component="span" mr={2}>
                  <FontAwesomeIcon icon={props.icon} />
                </Box>
                {props.title}
              </Typography>
            </Stack>
          )}

          <Stack>
            {props.data.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h5" sx={{ color: textColor }}>
                  {item.value}
                </Typography>
                <Typography variant="body1" sx={{ color: textColor }}>
                  {item.label}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Tab;
