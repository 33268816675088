import { Container, Typography } from '@mui/material';
import {
  ConsentSubmissionServiceProvider,
  useConsentSubmissionService,
} from 'api/consentSubmission/consentSubmissionServiceProvider';
import PdfServiceProvider from 'api/pdf/PdfServiceProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import Footer from 'components/layout/Footer';
import {
  useBankidAuth,
  withBankidAuthProvider,
} from 'features/auth/bankid/AuthProvider';
import ConsentSubmissionController from 'features/consentSubmission/controllers/ConsentSubmissionController';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { SUBMISSION_PATH } from 'routes/EConsentRouter';
import { formatFooterText } from './utils';

function ConsentSubmission() {
  const navigate = useNavigate();
  const service = useConsentSubmissionService();
  const { consentRequestId, consentId } = useParams<{
    consentRequestId: string;
    consentId: string;
  }>();

  const { data: consentRequest } = useQuery('consentRequest', () =>
    service.getConsentRequest(consentRequestId || ''),
  );

  const { isLoading } = useQuery(
    'consentValidation',
    () =>
      service.validateConsentRequestAndConsentIds({
        consentRequestId: consentRequestId || '',
        consentId: consentId || '',
      }),
    {
      onError() {
        navigate(`/${SUBMISSION_PATH}/${consentRequestId}`);
      },
      retry: false,
    },
  );
  if (isLoading) return <LoadingBackdrop />;

  return (
    <>
      <Container sx={{ py: 3, px: 2, flex: '1 0 auto' }} maxWidth="sm">
        <ConsentSubmissionController consentId={consentId || ''} />
      </Container>
      <Footer maxWidth="sm">
        {formatFooterText(consentRequest?.requestingEntity)}{' '}
      </Footer>
    </>
  );
}

function withConsentSubmissionServiceProvider<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  return function (props: P) {
    const { accessToken, logout } = useBankidAuth();

    return (
      <ConsentSubmissionServiceProvider
        accessToken={accessToken}
        logout={logout}
      >
        <PdfServiceProvider accessToken={accessToken} logout={logout}>
          <Component {...props} />
        </PdfServiceProvider>
      </ConsentSubmissionServiceProvider>
    );
  };
}

const ComponentBeforeOnLaunchPicked = (
  <Typography>Logga in med BankID för att se och ge ditt samtycke.</Typography>
);

export default withBankidAuthProvider(
  ComponentBeforeOnLaunchPicked,
  withConsentSubmissionServiceProvider(ConsentSubmission),
);
