import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import completedImage from 'assets/images/consent-completed-check.png';
import { useTranslation } from 'react-i18next';

interface ConsentAcceptedConfirmationViewProps {
  onDownloadConsentClick?: () => Promise<void>;
}

function ConsentAcceptedConfirmationView(
  props: ConsentAcceptedConfirmationViewProps,
) {
  const { t } = useTranslation(['all']);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <img src={completedImage} alt="completed checked" />
      <Stack spacing={2} mt={3}>
        <Typography variant="body1">
          {t('consent-submission-success.message')}
        </Typography>
        <LoadingButton
          loading={props.onDownloadConsentClick === undefined}
          variant="contained"
          color="primary"
          onClick={props.onDownloadConsentClick}
        >
          {t('consent-submission-success.download-consent.button')}
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default ConsentAcceptedConfirmationView;
