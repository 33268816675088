import { Container } from '@mui/material';
import {
  ConsentSubmissionServiceProvider,
  useConsentSubmissionService,
} from 'api/consentSubmission/consentSubmissionServiceProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import Footer from 'components/layout/Footer';
import PageNotFoundView from 'features/common/views/PageNotFoundView';
import ConsentLookupController from 'features/consentSubmission/controllers/ConsentLookupController';
import ConsentNotActiveView from 'features/consentSubmission/views/ConsentNotActiveView';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { formatFooterText } from './utils';

function ConsentLookup() {
  const service = useConsentSubmissionService();
  const { consentRequestId } = useParams<{ consentRequestId: string }>();

  const {
    data: consentRequest,
    isLoading,
    error,
  } = useQuery('consentRequest', () =>
    service.getConsentRequest(consentRequestId || ''),
  );

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (error) return <PageNotFoundView />;

  if (consentRequest && consentRequest.completedAt !== null) {
    return <ConsentNotActiveView />;
  }

  return (
    <>
      <Container sx={{ py: 3, px: 2, flex: '1 0 auto' }} maxWidth="sm">
        <ConsentLookupController consentRequestId={consentRequestId} />
      </Container>
      <Footer maxWidth="sm">
        {formatFooterText(consentRequest?.requestingEntity)}{' '}
      </Footer>
    </>
  );
}

function withConsentSubmissionServiceProvider<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  return function (props: P) {
    return (
      <ConsentSubmissionServiceProvider accessToken={null}>
        <Component {...props} />
      </ConsentSubmissionServiceProvider>
    );
  };
}

export default withConsentSubmissionServiceProvider(ConsentLookup);
