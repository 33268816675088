import { Skeleton, Stack, Typography } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { SectionBox } from 'components/common';
import ExplanationSection from 'components/common/ExplanationSection';
import GenericErrorFallback from 'components/errorFallback/GenericErrorFallback';
import { AutoCompleteInput } from 'components/inputs';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export type Purpose = {
  id: number;
};

interface ConcerningCancerSelectionFormProps {
  purpose: Purpose | null;
  setPurpose: (purpose: Purpose | null) => void;
  explanationText: string[];
  titleIndex?: number;
}

function ConcerningCancerSelectionForm(
  props: ConcerningCancerSelectionFormProps,
) {
  const { purpose, setPurpose } = props;

  const { t, i18n } = useTranslation('iPedigree');
  const pedigreeCaseService = useStaffPedigreeCaseService();

  const { data: diseases, isLoading } = useQuery(
    ['diseases', i18n.language],
    pedigreeCaseService.getDiseaseOptionsForPurpose,
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  if (!diseases) {
    throw new Error('Diseases not found');
  }

  const purposeValue = purpose ? purpose.id.toString() : null;
  const options = diseases.map((disease) => ({
    label: disease.localizedName,
    value: disease.id.toString(),
  }));
  const handleOnChange = (value: string | null) => {
    if (!value) {
      setPurpose(null);
      return;
    }
    setPurpose({ id: parseInt(value) });
  };

  const title = props.titleIndex
    ? `${props.titleIndex}. ${t('concerning-cancer-selection-form.title')}`
    : t('concerning-cancer-selection-form.title');

  return (
    <SectionBox>
      <Stack spacing={3}>
        <Typography variant="h2">{title}</Typography>
        <AutoCompleteInput
          label={t('concerning-cancer-selection-form.label')}
          id="purpose"
          value={purposeValue}
          options={options}
          onChange={handleOnChange}
          autocompleteProps={{ disableClearable: false }}
        />
        <ExplanationSection
          buttonText={t('concerning-cancer-selection-form.explanation.button')}
          explanationText={props.explanationText}
        />
      </Stack>
    </SectionBox>
  );
}

export default withErrorBoundary(ConcerningCancerSelectionForm, {
  FallbackComponent: GenericErrorFallback,
});
