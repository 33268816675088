import { LoadingButton } from '@mui/lab';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { HeaderWithBackButton, SectionBox } from 'components/common';
import { ButtonAction } from './types';

interface NotificationViewProps {
  title: string;
  onBackButtonClick: () => void;
  children: React.ReactNode;
  actions: ButtonAction[];
}

function NotificationView(props: NotificationViewProps) {
  return (
    <Container maxWidth="sm">
      <Stack gap={3} pt={4}>
        <HeaderWithBackButton
          headerVariant="h1"
          onBackButtonClick={props.onBackButtonClick}
        >
          {props.title}
        </HeaderWithBackButton>
        <SectionBox>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} component="div">
            {props.children}
          </Typography>
        </SectionBox>

        <Box>
          <Grid container spacing={2}>
            {props.actions?.map((action, index) => (
              <Grid item key={index} sm={6}>
                <LoadingButton
                  key={index}
                  size="large"
                  sx={{ width: '100%' }}
                  variant={action.primary ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={action.onClick}
                  loading={action.isLoading}
                  disabled={action.disabled}
                >
                  {action.label}
                </LoadingButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
}

export default NotificationView;
