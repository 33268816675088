import React from 'react';
import { useSearchParams } from 'react-router-dom';

export function useTabNavigation<TabValue extends string>(
  initialTab: TabValue,
  tabNames: TabValue[],
) {
  const [currentTab, setCurrentTab] = React.useState<TabValue>(initialTab);

  const [params, setParams] = useSearchParams();

  React.useEffect(() => {
    const tabSearchParamsValue = params.get('tab');
    const isValidTabName =
      tabSearchParamsValue !== currentTab &&
      tabNames.includes(tabSearchParamsValue as TabValue);

    if (isValidTabName) {
      setCurrentTab(tabSearchParamsValue as TabValue);
    } else {
      setParams({ tab: currentTab });
    }
  }, [currentTab, params, setParams, tabNames]);

  const handleChange = (newValue: TabValue) => {
    setCurrentTab(newValue);
    setParams({ tab: newValue });
  };

  return { currentTab, handleChange };
}
