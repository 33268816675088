import { Chip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NewTagView = () => {
  const { t } = useTranslation('iPedigree');
  const theme = useTheme();
  return (
    <Chip
      sx={{
        backgroundColor: theme.palette.common.brand.lightGreen,
      }}
      label={t('page-dashboard.table.cell.status.new')}
    />
  );
};

export default NewTagView;
