import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DiagnosisFormHeaderProps {
  onDeleteDiagnosisClick: () => void;
  title: string;
  subtitle?: string;
  showDeleteButton?: boolean;
}

export const DiagnosisFormHeader = (props: DiagnosisFormHeaderProps) => {
  const { onDeleteDiagnosisClick, title, subtitle, showDeleteButton } = props;

  const theme = useTheme();
  const { t } = useTranslation(['diagnosisForm']);

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        {showDeleteButton && (
          <Tooltip
            title={t('tooltip-delete.title')}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: theme.palette.info.light,
                  color: theme.palette.common.brand.darkBlue,
                  '& .MuiTooltip-arrow': {
                    color: theme.palette.info.light,
                  },
                },
              },
            }}
          >
            <IconButton
              size="small"
              onClick={onDeleteDiagnosisClick}
              sx={{
                color: theme.palette.common.brand.darkBlue,
                width: 24,
                height: 24,
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Typography variant="caption">{subtitle}</Typography>
    </Stack>
  );
};
