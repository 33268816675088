import { Box } from '@mui/material';
import React from 'react';

interface TabSectionPanelProps<T> {
  children?: React.ReactNode;
  value: T;
  currentValue: T;
}

export function TabSectionPanel<T>(props: TabSectionPanelProps<T>) {
  const { value, currentValue, children } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== currentValue}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
    >
      {value !== currentValue ? null : children}
    </Box>
  );
}
