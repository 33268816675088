import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';

interface TitleWithIconProps {
  title: string;
  icon: IconDefinition;
  iconColor?: string;
}
export function TitleWithIcon(props: TitleWithIconProps) {
  return (
    <Typography variant="h4">
      <Box
        mr={2}
        sx={{ display: 'inline', color: props.iconColor || 'info.main' }}
      >
        <FontAwesomeIcon icon={props.icon} />
      </Box>
      {props.title}
    </Typography>
  );
}
