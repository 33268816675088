import { Typography } from '@mui/material';

interface EmptyStateProps {
  title: string;
  message: string;
}

function EmptyState(props: EmptyStateProps) {
  return (
    <>
      <Typography variant="h4" sx={{ mt: 2 }}>{props.title}</Typography>
      <Typography variant="body1">{props.message}</Typography>
    </>
  );
}

export default EmptyState;
