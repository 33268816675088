import { Stack, Typography } from '@mui/material';

interface PatientInfoItemProps {
  label: string;
  value: Array<String> | string;
  direction?: 'row' | 'column';
}

const PatientInfoItem = ({
  label,
  value,
  direction = 'row',
}: PatientInfoItemProps) => {
  return (
    <Stack
      direction={direction === 'row' ? 'row' : 'column'}
      gap={direction === 'row' ? 1 : 0.5}
    >
      <Typography color="common.neutral.600" variant="body1">
        {label}
      </Typography>

      {Array.isArray(value) && value.length > 1 ? (
        value.map((item, index) => {
          return (
            <Stack gap={10} direction="row" key={index}>
              <Typography color="common.brand.darkBlue" variant="body1">
                {item}
              </Typography>
            </Stack>
          );
        })
      ) : (
        <Typography color="common.brand.darkBlue" variant="body1">
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default PatientInfoItem;
