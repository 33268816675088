import { Box, Card, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DiseaseLegendProps {
  diagnoseToColorMap: Record<string, string>;
}

export const DiseaseLegend = (props: DiseaseLegendProps) => {
  const { t } = useTranslation('pedigree');
  const diseaseRef = useRef<HTMLDivElement | null>(null);

  const diagnoseToColorMap = Object.keys(props.diagnoseToColorMap);

  const container = document.getElementById('pedigree-professional-container');
  const containerHeight = container?.clientHeight || 0;

  const nodeDisplayFilter = document.getElementById('node-display-filter');
  const nodeDisplayFilterHeight = nodeDisplayFilter?.clientHeight || 0;
  const nodeGeneticTestResult = document.getElementById('genetic-test-result');
  const nodeGeneticTestResultHeight = nodeGeneticTestResult?.clientHeight || 0;

  const sidebarPadding = 32;
  const sidebarItemsGap = 24;

  const totalHeightOfSidebarItems =
    nodeDisplayFilterHeight +
    nodeGeneticTestResultHeight +
    sidebarPadding +
    sidebarItemsGap;

  const CARD_PADDING = 32;

  const HEIGHT = containerHeight - totalHeightOfSidebarItems - CARD_PADDING;

  const hasScroll =
    !!diseaseRef?.current?.clientHeight &&
    diseaseRef.current.clientHeight > HEIGHT;

  return (
    <Card
      elevation={3}
      sx={{
        p: 2,
        pr: 0,
      }}
    >
      <Stack
        spacing={1.5}
        sx={{
          maxHeight: HEIGHT,
          position: 'relative',
        }}
      >
        <Typography variant="h6">{t('pedigree-legend-label')}:</Typography>
        <Stack
          sx={{
            overflowY: 'auto',
            pr: 2,
          }}
        >
          <Stack gap={2} pb={hasScroll ? 3 : 0} ref={diseaseRef}>
            {diagnoseToColorMap.map((disease: string, index: number) => (
              <DiseaseItem
                key={index}
                disease={disease}
                color={props.diagnoseToColorMap[disease]}
              />
            ))}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: hasScroll ? 'block' : 'none',
            position: 'absolute',
            maxWidth: 198,
            height: 30,
            bottom: 0,
            left: 0,
            right: 0,
            background:
              'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
            pointerEvents: 'none',
          }}
        />
      </Stack>
    </Card>
  );
};

function DiseaseItem({ disease, color }: { disease: string; color: string }) {
  const textRef = useRef<HTMLElement | null>(null);
  const [isMultiLine, setIsMultiLine] = useState(false);

  const textDiseaseLineHeight = 16;

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsMultiLine(element.clientHeight > textDiseaseLineHeight);
    }
  }, [disease]);

  return (
    <Stack
      gap={1}
      flexDirection="row"
      alignItems={isMultiLine ? 'flex-start' : 'center'}
    >
      <Box minWidth={14} height={14} bgcolor={color} />
      <Typography
        fontSize={12}
        ref={textRef}
        sx={{ lineHeight: `${textDiseaseLineHeight}px` }}
      >
        {disease}
      </Typography>
    </Stack>
  );
}
