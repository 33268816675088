import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';

function Root() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {[
            { to: '/', label: 'Home' },
            { to: '/pedigree', label: 'Pedigree' },
            { to: '/dynamic-form', label: 'Dynamic Form' },
          ].map(({ to, label }) => (
            <Link key={to} to={to} style={{ textDecoration: 'none' }}>
              <Typography variant="h4" color="white" p={3}>
                {label}
              </Typography>
            </Link>
          ))}
        </Toolbar>
      </AppBar>
      <Container sx={{ py: 3 }}>
        <Outlet />
      </Container>
    </>
  );
}

export default Root;
