import { Sex } from 'pedigree/features/common/types';
import { CSSProperties } from 'react';
import usePedigreeNodePatientStyles from '../hooks/usePedigreeNodePatientStyles';

interface NodePatientViewProps {
  sex: Sex;
  name?: string;
  isIndex: boolean;
  isSelected: boolean;
  hasCancerHistory: boolean;
  hasError?: boolean;
  relativeRelationLabel?: string;
  addDiagnosisLabel?: string;
  cancerHistoryLabel?: string;
  deceasedLabel?: string;
  styleProps?: CSSProperties;
  onClick: () => void;
  nodeSize: {
    width: number;
    height: number;
  };
}

export const NodePatientView = (props: NodePatientViewProps) => {
  const { isIndex, isSelected, hasCancerHistory, hasError = false } = props;

  usePedigreeNodePatientStyles({
    isIndex,
    isSelected,
    hasCancerHistory,
    hasError,
  });
  return <div>NodePatient</div>;
};
