import { Card, Stack, Typography } from '@mui/material';
import { GeneticTestResult } from 'pedigree/features/common/types';
import { useTranslation } from 'react-i18next';
import { GeneticTestMarker } from './GeneticTestMarker';

export const GeneticTextResult = () => {
  const { t } = useTranslation('pedigree');

  const geneticTestOptions: { result: GeneticTestResult; text: string }[] = [
    {
      result: 'positive',
      text: t('pedigree-genetic-test-positive-label'),
    },
    {
      result: 'negative',
      text: t('pedigree-genetic-test-negative-label'),
    },
    {
      result: 'unknown',
      text: t('pedigree-genetic-test-unknown-label'),
    },
  ];

  return (
    <Card
      id="genetic-test-result"
      elevation={3}
      sx={{
        p: 2,
      }}
    >
      <Stack spacing={1.5}>
        <Typography variant="h6">
          {t('pedigree-genetic-test-label')}:
        </Typography>
        {geneticTestOptions.map((item, index) => (
          <Stack key={index} direction="row" gap={1}>
            <GeneticTestMarker result={item.result} />
            <Typography fontSize={12}>{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
