import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@mui/material';
import { CSSProperties } from 'react';

interface DrawerProps extends MuiDrawerProps {
  isOpen: boolean;
  styleProps?: CSSProperties;
}

export const Drawer = (props: DrawerProps) => {
  const { isOpen, styleProps, ...rest } = props;
  return (
    <MuiDrawer
      sx={{
        width: 0,
        '& .MuiDrawer-paper': {
          padding: 2,
          boxSizing: 'border-box',
          ...styleProps,
        },
      }}
      hideBackdrop={true}
      anchor="right"
      open={isOpen}
      {...rest}
    >
      {props.children}
    </MuiDrawer>
  );
};
