import { BaseService } from 'api/base-service';
import { PersonalNumberValidationResponse } from 'api/types';
import {
  AddRelativesPayload,
  NewPatientPayload,
} from 'features/consentManagement/common/types';
import { ConsentStatus } from 'types';

export type Relative = {
  name: string;
  consentStatus: ConsentStatus;
  updatedAt: string;
  createdAt: string;
  signedAt?: string;
  pdfLocationUrl?: string;
  new: boolean;
  consentId: string;
};

type ListConsentUpdatesItem = {
  consentRequestId: string;
  new: boolean;
  patientName: string;
  patientPersonalNumber: string;
  relatives: Relative[];
};

type ListConsentUpdates = {
  items: ListConsentUpdatesItem[];
  totalCount: number;
};

type ListQueryParams = {
  sortQuery: string;
  searchQuery: string;
  showCompleted?: boolean;
};

export type ConsentListItem = {
  relativeName: string;
  relativePersonalNumber: string;
  consentStatus: ConsentStatus;
  relationshipToPatient: string;
  pdfLocationUrl?: string;
  deceased: boolean;
  proxySignerName?: string;
  proxySignerRelationshipToConsentee?: string;
  new: boolean;
  consentId: string;
  updatedAt: string;
  createdAt: string;
  signedAt: string | undefined;
};

export type ListedConsentRequest = {
  consentRequestId: string;
  patientName: string;
  patientPersonalNumber: string;
  patientEmail: string;
  patientPhoneNumber: string;
  consents: ConsentListItem[];
  consentFor: string;
  consentRequestCreatedAt: string;
  completedAt: string | null;
};

export type RelativeData = {
  consentStatus: ConsentStatus;
  name: string;
  personalNumber: string;
  relationshipToPatient: string | null;
  deceased: boolean;
  proxySignerName?: string;
  proxySignerPersonalNumber?: string;
  proxySignerToConsenteeRelationship?: string;
};

export type PatientData = {
  personalNumber: string;
  name: string;
  phoneNumber: string;
  email?: string;
};

export type ConsentRequest = {
  patientData: PatientData;
  relatives: RelativeData[];
  consentFor: string;
};

export class ConsentManagementService extends BaseService {
  getConsentRequest = async (
    consentRequestId: string,
  ): Promise<ConsentRequest | null> =>
    this.axiosInstance
      .get(`/staff/consent-requests/${consentRequestId}`, {})
      .then((response) => response.data);

  listConsentUpdates = async (): Promise<ListConsentUpdates> =>
    this.axiosInstance
      .get('/staff/consent-updates')
      .then((response) => response.data);

  listConsentRequests = async ({
    sortQuery,
    searchQuery,
    showCompleted,
  }: ListQueryParams): Promise<ListedConsentRequest[]> => {
    const params = new URLSearchParams({
      sortBy: sortQuery,
      search: searchQuery,
      completed: showCompleted ? 'true' : 'false',
    });
    return this.axiosInstance
      .get(`/staff/consent-requests?${params}`)
      .then((response) => response.data);
  };

  createNewConsentRequest = async (
    consent: NewPatientPayload,
  ): Promise<ListedConsentRequest> => {
    if (consent.patientData.email === '') {
      delete consent.patientData.email;
    }

    return this.axiosInstance
      .post('/staff/consent-requests', consent)
      .then((response) => response.data);
  };

  addRelativesToConsentRequest = async ({
    consentRequestId,
    relatives,
  }: {
    consentRequestId: string;
    relatives: AddRelativesPayload;
  }): Promise<void> =>
    this.axiosInstance
      .post(`/staff/consent-requests/${consentRequestId}/relatives`, relatives)
      .then((response) => response.data);

  completeConsentRequest = async (consentRequestId: string): Promise<void> =>
    this.axiosInstance
      .put(`/staff/consent-requests/${consentRequestId}/complete`, {
        complete: true,
      })
      .then((response) => response.data);

  validatePersonalNumber = async (
    personalNumber: string,
  ): Promise<PersonalNumberValidationResponse> =>
    this.axiosInstance
      .get(`/personal-number-validation?personalNumber=${personalNumber}`)
      .then((response) => response.data);

  setConsentViewed = async (consentId: string): Promise<void> =>
    this.axiosInstance
      .put(`/staff/consents/${consentId}/viewed`, { viewed: true })
      .then((response) => response.data);

  sendReminder = async (
    consentRequestId: string,
  ): Promise<ListedConsentRequest> =>
    this.axiosInstance
      .post(`/staff/consent-requests/${consentRequestId}/reminders`, {})
      .then((response) => response.data);
}
