import { Container, Stack } from '@mui/material';
import ConsentManagementHeader from 'features/consentManagement/header/ConsentManagementHeader';
import TabNavBar from 'features/consentManagement/header/TabNavbar';
import TabSectionPanel from 'features/consentManagement/header/TabSectionPanel';
import ConsentRequestsListController from 'features/consentManagement/listPatients/controllers/ConsentRequestsListController';
import ConsentUpdatesListController from 'features/consentManagement/listPatients/controllers/ConsentUpdatesListController';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function Dashboard() {
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [tabName, setTabName] = React.useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const listNameTabs = useMemo(() => {
    return ['updates', 'ongoing', 'resolved']
  },[]);

  const setTabNameParams = (value: number) => {
    const tabName = listNameTabs[value];
    setTabName(tabName);
    setSearchParams({ 'tab': tabName });
  };

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setTabNameParams(value);
  };

  useEffect(() => {
    const tabSearchParamsValue= searchParams.get('tab');
    const isValidTabName = tabSearchParamsValue && tabSearchParamsValue !== tabName;

    if (isValidTabName) {
      const tabValue = listNameTabs.findIndex((name) => tabSearchParamsValue === name);
      setTabValue(tabValue);
    };
  },[searchParams, tabName, listNameTabs]);

  return (
    <Container>
      <Stack direction="column" spacing={3}>
        <ConsentManagementHeader navigate={navigate} />
        <TabNavBar value={tabValue} handleChange={handleChange} />

        <TabSectionPanel value={tabValue} index={0}>
          <ConsentUpdatesListController />
        </TabSectionPanel>
        <TabSectionPanel value={tabValue} index={1}>
          <ConsentRequestsListController />
        </TabSectionPanel>
        <TabSectionPanel value={tabValue} index={2}>
          <ConsentRequestsListController showCompletedConsents={true} />
        </TabSectionPanel>
      </Stack>
    </Container>
  );
}

export default Dashboard;
