import {
  serviceHookFactory,
  serviceProviderFactory,
} from 'myfamilytree/common/index';
import React from 'react';
import { MyFamilyTreeSubmissionService } from './my-family-tree-submission-service';

const MyFamilySubmissionServiceContext = React.createContext<{
  service: MyFamilyTreeSubmissionService | null;
}>({ service: null });

export const MyFamilyTreeSubmissionServiceProvider = serviceProviderFactory(
  MyFamilyTreeSubmissionService,
  MyFamilySubmissionServiceContext,
);

export const useMyFamilyTreeSubmissionService = serviceHookFactory(
  MyFamilyTreeSubmissionService,
  MyFamilySubmissionServiceContext,
);
