import { Button, Stack } from '@mui/material';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import { DiagnosisFormTemplate } from './DiagnosisFormTemplate';

const ARRAY_FIELD_TEMPLATE_MAP: {
  [key: string]: (props: ArrayFieldTemplateProps) => JSX.Element;
} = {
  DiagnosisForm: DiagnosisFormTemplate,
};

export const ListFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const addButtonText = props.uiSchema?.['ui:buttonText'] || undefined;

  const arrayFieldTemplate = props.uiSchema?.['ui:arrayFieldTemplate'];

  if (!!arrayFieldTemplate) {
    return ARRAY_FIELD_TEMPLATE_MAP[arrayFieldTemplate](props);
  }

  const showAddButton = props.canAdd && !props.readonly;

  return (
    <Stack spacing={3}>
      {props.items.map((element, index) => (
        <Stack key={index}>
          <Stack>{element.children}</Stack>
        </Stack>
      ))}
      {showAddButton && (
        <Button variant="outlined" onClick={props.onAddClick}>
          {addButtonText}
        </Button>
      )}
    </Stack>
  );
};
