import useDialog, { DialogContent } from 'common/hooks/useDialog';
import { SubmissionError } from 'myfamilytree/pages/myFamilyTreePatient/PedigreeEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PedigreeSubmitDialogElementView } from '../views/PedigreeSubmitDialogElementView';

export function usePedigreeSubmissionFailedDialog() {
  const { t } = useTranslation(['myFamilyTree']);

  const [dialogContent, setDialogContent] = useState<
    DialogContent | undefined
  >();

  const { dialog, openDialog, closeDialog } = useDialog({
    dialogContent,
    onDialogConfirmClick: () => {
      closeDialog();
    },
  });

  const showPedigreeSubmissionFailedDialog = (
    submissionError: SubmissionError,
    pedigreeCaseId: string,
  ) => {
    const url = window.location.origin;

    const hrefQuestionnaire = `${url}/my-family-tree/patient/${pedigreeCaseId}/questionnaire`;
    const isInvalidNodeDataError = submissionError === 'invalidNodeData';

    setDialogContent({
      title: t('my-family-tree-pedigree-submitted.dialog.error.title'),
      content: isInvalidNodeDataError
        ? t(
            `my-family-tree-pedigree-submitted.dialog.error.${submissionError}.content`,
          )
        : undefined,
      element: isInvalidNodeDataError ? (
        <PedigreeSubmitDialogElementView showNodeCard={true} />
      ) : (
        <PedigreeSubmitDialogElementView
          content={`my-family-tree-pedigree-submitted.dialog.error.${submissionError}.content`}
          href={hrefQuestionnaire}
        />
      ),
      textConfirm: t('my-family-tree-pedigree-submitted.dialog.error.button'),
      textCancel: undefined,
    });
    openDialog();
  };

  return {
    pedigreeSubmissionFailedDialog: dialog,
    showPedigreeSubmissionFailedDialog,
  };
}
