import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  IconDefinition,
  faChevronUp,
  faPencil,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HandleNodeAction,
  PedigreeNodeAction,
} from '../hooks/useHandleNodeAction';
import NodeActionsMenuPopper from './NodeActionsMenuPopper';

type PedigreeNodeActionWithSubMenu =
  | 'addChild'
  | 'addHalfSibling'
  | 'addSibling';

export type Action = PedigreeNodeAction | PedigreeNodeActionWithSubMenu;

export type BaseOption = {
  value: Action;
  label: string;
  icon: IconDefinition;
};

export type Option = {
  value: Action;
  label: string;
  icon: IconDefinition;
  subMenuOptions?: Option[];
  divider?: boolean;
};

interface ListOption {
  value: Action;
  subMenuOptions?: ListOption[];
  divider?: boolean;
}

interface NodeActionsMenuViewProps {
  handleNodeMenuOptionClick: (action: HandleNodeAction) => void;
  anchorElement: HTMLElement | null;
  pedigreeNodeId: string | null;
  hasEditAction?: boolean;
}

const iconMap: Record<string, IconDefinition> = {
  addDaughter: faCircle,
  addHalfSister: faCircle,
  addHalfBrother: faSquare,
  addSon: faSquare,
  addBrother: faSquare,
  addSister: faCircle,
  addTwin: faChevronUp,
  delete: faTrashCan,
  edit: faPencil,
};

export const NodeActionsMenu = (props: NodeActionsMenuViewProps) => {
  const { t } = useTranslation('pedigree');

  const [subMenuAnchorElement, setSubMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const menuOptions: Option[] = useMemo(() => {
    const listOptions: ListOption[] = [
      { value: 'addParents' },
      { value: 'addPartner' },
      {
        value: 'addSibling',
        subMenuOptions: [
          { value: 'addBrother' },
          { value: 'addSister' },
          { value: 'addTwin' },
        ],
      },
      {
        value: 'addHalfSibling',
        subMenuOptions: [
          { value: 'addHalfBrother' },
          { value: 'addHalfSister' },
        ],
      },
      {
        value: 'addChild',
        subMenuOptions: [{ value: 'addSon' }, { value: 'addDaughter' }],
        divider: true,
      },
      { value: 'delete' },
    ];

    const options: Option[] = listOptions.map(
      ({ value, subMenuOptions, divider }) => {
        const icon = iconMap[value] || faPlus;
        return {
          value: value,
          label: t(`node-menu-member-option.${value}`),
          icon,
          divider,
          subMenuOptions: subMenuOptions?.map(({ value }) => {
            const icon = iconMap[value];
            return {
              value: value,
              label: t(`node-menu-member-option.${value}`),
              icon,
            };
          }),
        };
      },
    );

    if (props.hasEditAction) {
      options.splice(options.length - 1, 0, {
        label: t('node-menu-member-option.edit'),
        value: 'edit',
        icon: faPencil,
      });
    }

    return options;
  }, [props.hasEditAction, t]);

  const subMenuOptions = useMemo(() => {
    const options = menuOptions
      .filter(
        (option) => `option-menu-${option?.value}` === subMenuAnchorElement?.id,
      )
      .flatMap((option) => option.subMenuOptions || [])
      .filter((option): option is Option => Boolean(option));
    return options;
  }, [subMenuAnchorElement?.id, menuOptions]);

  const onMenuOptionClick = (action: Action) => {
    const { handleNodeMenuOptionClick, pedigreeNodeId } = props;

    if (!pedigreeNodeId) {
      return;
    }

    const actionHasSubMenu = menuOptions.some(
      (option) => option.value === action && !!option.subMenuOptions,
    );

    if (actionHasSubMenu) {
      return;
    }

    handleNodeMenuOptionClick({
      action: action as PedigreeNodeAction,
      pedigreeNodeId,
    });
    setSubMenuAnchorElement(null);
  };

  const onMenuOptionMouseOver = useCallback(
    (event: React.MouseEvent<HTMLElement>, action: Action) => {
      const { target, currentTarget } = event;
      const targetElement = target as HTMLElement;

      const actionHasSubMenu = menuOptions.some(
        (option) => option.value === action && !!option.subMenuOptions,
      );

      if (!actionHasSubMenu) {
        setSubMenuAnchorElement(null);
        return;
      }

      if (!!targetElement.id) {
        setSubMenuAnchorElement(currentTarget as HTMLElement);
      }
    },
    [menuOptions],
  );

  useEffect(() => {
    if (!props.anchorElement && subMenuAnchorElement) {
      setSubMenuAnchorElement(null);
    }
  }, [subMenuAnchorElement, props.anchorElement]);

  return (
    <>
      <NodeActionsMenuPopper
        id="menu"
        showArrow
        anchorElement={props.anchorElement}
        options={menuOptions}
        onMenuOptionClick={onMenuOptionClick}
        onMenuOptionMouseOver={onMenuOptionMouseOver}
      ></NodeActionsMenuPopper>
      <NodeActionsMenuPopper
        id="sub-menu"
        offset={4}
        placement="right-start"
        anchorElement={subMenuAnchorElement}
        options={subMenuOptions}
        onMenuOptionClick={onMenuOptionClick}
      ></NodeActionsMenuPopper>
    </>
  );
};
