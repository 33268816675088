import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import SectionBox from 'components/common/SectionBox';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonRowView from '../../addRelative/views/ButtonRowView';

interface ConsentSentConfirmationViewProps {
  patientName: string;
  relatives: {
    name: string;
    relationshipToPatient: string | null;
    personalNumber?: string;
  }[];
}

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

function ConsentSentConfirmationView(props: ConsentSentConfirmationViewProps) {
  const { t } = useTranslation('all');
  const { translateRelationship } = useRelationshipOptions();
  const navigate = useNavigate();

  return (
    <Container>
      <Stack spacing={4}>
        <Typography variant="h1">{t('consent.new-edit.sent-title')}</Typography>
        <Typography variant="body1">
          {t('consent.new-edit.sent-text', { patientName: props.patientName })}
        </Typography>
        <SectionBox>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  {t('consent.table-labels.relative')}
                </CustomTableCell>
                <CustomTableCell>
                  {t('consent.table-labels.relationship')}
                </CustomTableCell>
                <CustomTableCell>
                  {t('ui-generic.personal-number')}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.relatives.map((relative, idx) => (
                <TableRow key={`relative-${idx}`}>
                  <CustomTableCell>{relative.name}</CustomTableCell>
                  <CustomTableCell>
                    {translateRelationship(
                      relative.relationshipToPatient || '',
                    )}
                  </CustomTableCell>
                  <CustomTableCell>
                    {relative.personalNumber
                      ? formatPersonalNumber(relative.personalNumber)
                      : '-'}
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SectionBox>
        <ButtonRowView
          ButtonOne={
            <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>
              {t('consent.actions.to-consents')}
            </Button>
          }
          ButtonTwo={
            <Button variant="contained" fullWidth onClick={() => navigate(0)}>
              {t('consent.actions.create-new')}
            </Button>
          }
        />
      </Stack>
    </Container>
  );
}

export default ConsentSentConfirmationView;
