import useDialog from 'common/hooks/useDialog';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

interface Diagnose {
  type: string;
}

interface PedigreeIds {
  pedigreeId: string | undefined;
  nodeId: string | undefined;
}
interface usePedigreeSubmissionRelativeDiagnosisProps {
  pedigreeIds: PedigreeIds;
  relativeFormData: any;
  relativeLabel: string;
  handleCloseDrawer: () => void;
  setRelativeFormData: (data: any) => void;
  setPreviousNodeSelected: (nodeId: string) => void;
}

type PreviousSubmitRelativeForm = {
  relativeFormData: any;
  previousPedigreeIds: PedigreeIds;
  relativeLabel: string;
};

export const usePedigreeSubmissionRelativeDiagnosis = (
  props: usePedigreeSubmissionRelativeDiagnosisProps,
) => {
  const service = useMyFamilyTreeSubmissionService();
  const queryClient = useQueryClient();
  const { t } = useTranslation('myFamilyTree');
  const isButtonSubmitRef = useRef(false);

  const [previousSubmitRelativeForm, setPreviousSubmitRelativeForm] =
    useState<PreviousSubmitRelativeForm>();
  const [isRetrySubmit, setIsRetrySubmit] = useState<Boolean>(false);

  const {
    pedigreeIds,
    setRelativeFormData,
    handleCloseDrawer,
    setPreviousNodeSelected,
  } = props;

  const resetInfoRetrySubmitRelative = () => {
    setIsRetrySubmit(false);
    setPreviousSubmitRelativeForm({
      relativeFormData: undefined,
      previousPedigreeIds: {
        pedigreeId: undefined,
        nodeId: undefined,
      },
      relativeLabel: '',
    });
  };

  const submittedRelativeForm = () => {
    if (!!isRetrySubmit) {
      resetInfoRetrySubmitRelative();
    }
    if (!!isButtonSubmitRef.current) {
      pedigreeIds.nodeId && setPreviousNodeSelected(pedigreeIds.nodeId);
      handleCloseDrawer();
    }
    setRelativeFormData({});
  };

  const submitMutation = useMutation(
    (data: any) => {
      const { previousPedigreeIds } = previousSubmitRelativeForm || {};

      const pedigreeId =
        isRetrySubmit && previousPedigreeIds?.pedigreeId
          ? previousPedigreeIds?.pedigreeId
          : pedigreeIds.pedigreeId || '';

      const nodeId =
        isRetrySubmit && previousPedigreeIds?.nodeId
          ? previousPedigreeIds?.nodeId
          : pedigreeIds.nodeId || '';

      return service?.submitRelativeForm(pedigreeId, nodeId, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pedigree');
        submittedRelativeForm();
      },
      onError: () => {
        if (!isRetrySubmit) {
          setIsRetrySubmit(true);
          setPreviousSubmitRelativeForm({
            relativeFormData: getRelativeFormDataWithNonEmptyDiagnoses(
              props.relativeFormData,
            ),
            previousPedigreeIds: props.pedigreeIds,
            relativeLabel: props.relativeLabel,
          });
        }
        openDialog();
      },
    },
  );

  const getRelativeFormDataWithNonEmptyDiagnoses = (relativeFormData: any) => {
    const { medicalHistory } = relativeFormData;

    const hasListDiagnoses =
      medicalHistory?.diagnosedWithCancer?.diagnoses?.length >= 1;

    if (hasListDiagnoses) {
      const filteredNonEmptyDiagnoses =
        medicalHistory.diagnosedWithCancer.diagnoses.filter(
          (diagnose: Diagnose) => !!diagnose?.type,
        );
      return {
        ...relativeFormData,
        medicalHistory: {
          ...medicalHistory,
          diagnosedWithCancer: {
            ...medicalHistory.diagnosedWithCancer,
            diagnoses: !!filteredNonEmptyDiagnoses.length
              ? filteredNonEmptyDiagnoses
              : [{}],
          },
        },
      };
    } else {
      return relativeFormData;
    }
  };

  const submitNodeRelativeDiagnosis = (isButtonSubmit: boolean) => {
    const formDataIsEmpty = Object.keys(props.relativeFormData).length === 0;

    isButtonSubmitRef.current = isButtonSubmit;

    if (isRetrySubmit) {
      submitMutation.mutate(previousSubmitRelativeForm?.relativeFormData);
      return;
    }

    if (!formDataIsEmpty) {
      const formData = getRelativeFormDataWithNonEmptyDiagnoses(
        props.relativeFormData,
      );
      submitMutation.mutate(formData);
    } else if (isButtonSubmit) {
      handleCloseDrawer();
      pedigreeIds.nodeId && setPreviousNodeSelected(pedigreeIds.nodeId);
    }
  };

  const { dialog, openDialog } = useDialog({
    dialogContent: {
      title: t('pedigree.onboarding-drawer.dialog-relativeDiagnosisForm.title'),
      content: t(
        'pedigree.onboarding-drawer.dialog-relativeDiagnosisForm.description',
        {
          relative: previousSubmitRelativeForm?.relativeLabel,
          email: 'email@email.com', // will be replace
        },
      ),
      textConfirm: t(
        'pedigree.onboarding-drawer.dialog-relativeDiagnosisForm.textConfirm',
      ),
      textCancel: t(
        'pedigree.onboarding-drawer.dialog-relativeDiagnosisForm.textCancel',
      ),
    },
    disableBackdropClick: true,
    isLoading: submitMutation.isLoading,
    preventCloseDialogOnConfirm: true,
    onDialogConfirmClick: () => {
      submitNodeRelativeDiagnosis(isButtonSubmitRef.current);
    },
    onDialogCloseClick: () => {
      submittedRelativeForm();
    },
  });

  return {
    isButtonSubmit: isButtonSubmitRef.current,
    submissionRelativeFailedDialog: dialog,
    submitNodeRelativeDiagnosis,
  };
};
