import { Stack, Divider } from '@mui/material';
import { ReactComponent as LogoArkus } from 'assets/images/arkus-logo.svg';
import { ReactComponent as LogoEconsent } from 'assets/images/econsent-logo.svg';

interface LogoProps {
  onClick?: () => void;
  logoWidth?: number;
}

function Logo({ onClick, logoWidth }: LogoProps) {
  logoWidth = logoWidth || 110;
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': {
          opacity: onClick ? 0.8 : 1,
        },
      }}
    >
      <LogoArkus style={{ maxWidth: logoWidth }} />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          height: '19px',
          alignSelf: 'center',
          backgroundColor: 'common.neutral.400',
        }}
      />
      <LogoEconsent
        style={{ maxWidth: logoWidth * 0.9, transform: 'translateY(-0.5px)' }}
      />
    </Stack>
  );
}

export default Logo;
