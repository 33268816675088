import { Box, SxProps } from '@mui/material';

interface SectionBoxProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const SectionBox = ({ children, sx }: SectionBoxProps) => {
  return (
    <Box
      sx={{
        p: 3,
        border: '1px solid',
        borderColor: 'common.neutral.300',
        borderRadius: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SectionBox;
