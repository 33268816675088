import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import useDialog from 'common/hooks/useDialog';
import { LoadingBackdrop } from 'components/common';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ErrorMessageView from './ErrorMessageView';
import NotificationIsSentConfirmationView from './NotificationIsSentConfirmationView';
import NotificationView from './NotificationView';

interface SendRequestProps {
  pedigreeCaseId: string;
  onBackButtonClick?: () => void;
  sendRequest?: () => Promise<void>;
}

const SendRequest = (props: SendRequestProps) => {
  const service = useStaffPedigreeCaseService();
  const { pedigreeCaseId } = props;
  const navigate = useNavigate();

  const createRequest = useCallback(async () => {
    if (props.sendRequest) {
      return props.sendRequest();
    }
    await service.createRequest(pedigreeCaseId);
  }, [props, pedigreeCaseId, service]);

  const onBackButtonClick = useCallback(() => {
    if (props.onBackButtonClick) {
      return props.onBackButtonClick();
    }
    return navigate(-1);
  }, [props, navigate]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation(['pedigreeCaseSendRequestReminder', 'common']);

  const { data: requestPreview, isLoading: requestPreviewLoading } = useQuery(
    ['requestPreview', pedigreeCaseId],
    () => service.getPedigreeRequestPreview(pedigreeCaseId),
  );

  const {
    data: pedigreeCase,
    isLoading: pedigreeCaseLoading,
    error: pedigreeCaseError,
    refetch: pedigreeCaseRefetch,
  } = useQuery('pedigreeCase', () => service.getPedigreeCase(pedigreeCaseId));

  const { dialog, openDialog, closeDialog } = useDialog({
    dialogContent: {
      title: t('send-request.failed-to-send.title'),
      content: t('send-request.failed-to-send.message'),
      textConfirm: t('common:button.try-again'),
      textCancel: t('common:button.cancel'),
    },
    onDialogConfirmClick: () => handleSendRequest(),
  });

  const createRequestMutation = useMutation(createRequest, {
    onSuccess: () => setShowConfirmation(true),
    onError: openDialog,
  });

  const handleSendRequest = () => {
    closeDialog();
    createRequestMutation.mutate();
  };

  if (requestPreviewLoading || pedigreeCaseLoading) {
    return <LoadingBackdrop />;
  }

  if (!pedigreeCase || pedigreeCaseError) {
    return (
      <ErrorMessageView
        message={t('common:error-message')}
        actions={[
          { label: t('common:button.back'), onClick: onBackButtonClick },
          {
            label: t('common:button.try-again'),
            onClick: () => pedigreeCaseRefetch(),
            primary: true,
          },
        ]}
      />
    );
  }

  if (showConfirmation) {
    return (
      <NotificationIsSentConfirmationView
        title={t('send-request.confirmation.title')}
        description={t('send-request.confirmation.message', {
          patientName: pedigreeCase.patientDetails.name,
        })}
        actions={[
          {
            label: t('button.to-homepage'),
            onClick: () => navigate('/'),
            primary: false,
          },
          {
            label: t('button.to-patient'),
            onClick: () => navigate('./..'),
            primary: false,
          },
        ]}
      />
    );
  }

  return (
    <>
      {dialog}
      <NotificationView
        title={t('send-request.header')}
        onBackButtonClick={onBackButtonClick}
        actions={[
          {
            label: t('common:button.back'),
            onClick: onBackButtonClick,
            primary: false,
            disabled: createRequestMutation.isLoading,
          },
          {
            label: t('button.send'),
            onClick: handleSendRequest,
            primary: true,
            isLoading: createRequestMutation.isLoading,
          },
        ]}
      >
        {requestPreview}
      </NotificationView>
    </>
  );
};

export default SendRequest;
