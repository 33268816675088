import React from 'react';
import { Tabs, Tab, Toolbar, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TabNavBarProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function TabNavBar({ value, handleChange }: TabNavBarProps) {
  const theme = useTheme();
  const { t } = useTranslation(['all']);
  return (
    <Toolbar
      sx={{
        [theme.breakpoints.up('sm')]: {
          paddingLeft: '0px',
        },
      }}
    >
      <Tabs variant="scrollable" value={value} onChange={handleChange}>
        <Tab
          label={t('tab-nav-bar-consent-update')}
          {...tabAriaAttributes(0)}
        />
        <Tab
          label={t('tab-nav-bar-consent-ongoing')}
          {...tabAriaAttributes(1)}
        />
        <Tab
          label={t('tab-nav-bar-consent-resolved')}
          {...tabAriaAttributes(2)}
        />
      </Tabs>
    </Toolbar>
  );
}

function tabAriaAttributes(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default TabNavBar;
