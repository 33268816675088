import {
  Button,
  CardContent,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { Sex } from 'pedigree/features/common/types';
import { CardWithBorder } from 'myfamilytree/common';
import { Trans, useTranslation } from 'react-i18next';
import PedigreeDrawerNodeCardView from './PedigreeDrawerNodeCardView';

interface PedigreeEditingInfoDrawerContentViewProps {
  onConfirmClick: () => void;
}

export const PedigreeEditingInfoDrawerContentView = (
  props: PedigreeEditingInfoDrawerContentViewProps,
) => {
  const { t } = useTranslation('myFamilyTree');

  return (
    <Stack gap={3} p={3}>
      <Typography variant="h3">
        {t('pedigree.onboarding-drawer.infoEditing.title')}
      </Typography>
      <Typography>
        <Trans t={t} i18nKey="onBoarding-drawer.description.paragraph1" />
      </Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 4,
          '& .MuiListItem-root': {
            display: 'list-item',
            padding: '0 8px 0 16px',
          },
        }}
        component="ul"
        disablePadding
        dense
      >
        <ListItem>{t('onBoarding-drawer.list.item1')}</ListItem>
        <ListItem>{t('onBoarding-drawer.list.item2')}</ListItem>
        <ListItem>{t('onBoarding-drawer.list.item3')}</ListItem>
      </List>
      <Typography>{t('onBoarding-drawer.description.paragraph2')}</Typography>
      <Typography>{t('onBoarding-drawer.description.paragraph3')}</Typography>
      <CardWithBorder variant="outlined">
        <CardContent>
          <PedigreeDrawerNodeCardView
            sex={Sex.FEMALE}
            relativeRelationLabel={t(
              'onBoarding-drawer.description.relative-relation-label',
            )}
          />
        </CardContent>
      </CardWithBorder>
      <Typography>{t('onBoarding-drawer.description.paragraph4')}</Typography>
      <Button variant="contained" onClick={props.onConfirmClick}>
        {t('pedigree.onboarding-drawer.infoEditing.textButton')}
      </Button>
    </Stack>
  );
};
