import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateField as MuiDateField } from '@mui/x-date-pickers/DateField';
import { enUS, svSE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { useId, useMemo } from 'react';
import InputWrapper from './InputWrapper';

const LANGUAGE_TO_LOCALE_MAP = {
  se: svSE,
  'en-GB': enUS,
};

interface DateFieldProps {
  id: string;
  label: string;
  value?: string; // ISO date string 2024-01-01
  onChange: (value: string) => void;
  error?: string;
  required?: boolean;
  labelBefore?: boolean;
  fullWidth?: boolean;
  labelInfo?: string;
  inlineLabel?: boolean;
  format?: 'yyyy.MM.dd' | 'MM/dd/yyyy';
  language: 'se' | 'en-GB';
}

export const DateField = ({
  id,
  label,
  value,
  onChange,
  error,
  required,
  labelBefore,
  fullWidth,
  labelInfo,
  inlineLabel,
  format,
  language,
}: DateFieldProps) => {
  const randomId = useId();
  const childId = `${id}-${randomId}`;

  const dateValue = useMemo(() => {
    return value ? DateTime.fromISO(value) : DateTime.fromISO('');
  }, [value]);

  const handleOnChange = (value: DateTime<true> | DateTime<false> | null) => {
    onChange(value?.toISODate() || '');
  };

  const locale = LANGUAGE_TO_LOCALE_MAP[language];

  const localeText =
    locale.components.MuiLocalizationProvider.defaultProps.localeText;

  return (
    <InputWrapper
      label={label}
      childId={childId}
      labelInfo={labelInfo}
      required={required}
      error={error}
      inlineLabel={inlineLabel}
      labelBefore={labelBefore}
      fullWidth={fullWidth}
    >
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={language}
        localeText={localeText}
      >
        <MuiDateField
          value={dateValue}
          onChange={handleOnChange}
          format={format}
          size="small"
          slotProps={{
            textField: {
              error: !!error,
            },
          }}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
};
