import { RJSFValidationError } from '@rjsf/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function useCustomTransformErrors() {
  const { t } = useTranslation('dynamicForm');

  return {
    transformErrors: useCallback(
      (errors: RJSFValidationError[]) => {
        return errors.map((error: any) => {
          if (
            error.name === 'required' ||
            (error.name === 'enum' &&
              // to remove the validation error caused by oneOf and allowed values
              error?.params?.allowedValues?.length < 0) ||
            error.name === 'minLength'
          ) {
            error.message = t('input-validation.array.min');
          } else if (error.name === 'pattern') {
            error.message = t('input-validation.string.pattern.base');
          } else if (error.name === 'minimum') {
            error.message = t('input-validation.number.value.minimum', {
              value: error.params.limit,
            });
          } else if (error.name === 'maximum') {
            error.message = t('input-validation.number.value.maximum', {
              value: error.params.limit,
            });
          }
          if (
            error.property === '.children.hasChildren' &&
            error.name === 'enum'
          ) {
            error.message = undefined;
          }
          if (error.property === '.children' && error.name === 'oneOf') {
            error.message = undefined;
          }
          // to remove the validation error caused by oneOf and allowed values
          if (
            error.name === 'oneOf' ||
            error.name === 'const' ||
            error?.params?.allowedValues?.length > 0
          ) {
            error.message = undefined;
          }
          if (error.message === 'must match format "email"') {
            error.message = t('input-validation.string.pattern.email');
          }
          return error;
        });
      },
      [t],
    ),
  };
}

export default useCustomTransformErrors;