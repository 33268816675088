import './LoadingIndicator.css';

const BASE_DELAY_SECONDS = 0.2;
const NUMBER_OF_DOTS = 4;

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator" data-testid="loading-indicator">
      {[...Array(NUMBER_OF_DOTS)].map((_, index) => (
        <div
          key={index}
          className="dot"
          style={{
            animationDelay: `${
              -BASE_DELAY_SECONDS * (NUMBER_OF_DOTS - index)
            }s`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default LoadingIndicator;
