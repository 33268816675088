import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Question } from 'api/pedigreeCase/staff-pedigree-case-service';
import QuestionnaireQuestionView from './QuestionnaireQuestionView';

interface QuestionnaireGroupViewProps {
  groupTitle: string;
  defaultExpanded?: boolean;
  questions: Question[];
  isInDrawer?: boolean;
}

const QuestionnaireGroupView = ({
  groupTitle,
  defaultExpanded,
  questions,
  isInDrawer,
}: QuestionnaireGroupViewProps) => {
  const accordionProps: Omit<AccordionProps, 'children'> = {
    disableGutters: true,
    defaultExpanded,
    variant: 'outlined',
    sx: { borderRadius: 1 },
  };

  if (isInDrawer) {
    accordionProps.elevation = 0;
    accordionProps.variant = undefined;
    accordionProps.sx = undefined;
  }

  return (
    <Accordion {...accordionProps}>
      <AccordionSummary
        sx={isInDrawer ? { backgroundColor: 'common.neutral.100' } : undefined}
        expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
      >
        <Typography variant="h4">{groupTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={3}>
          {questions.map((question: Question, index: number) => (
            <QuestionnaireQuestionView
              key={index}
              question={question.title}
              answer={question.answer}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionnaireGroupView;
