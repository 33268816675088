import { Container, Stack } from '@mui/material';
import PatientOverviewPanel from 'common/features/pedigreeCase/patientOverview/PatientOverviewPanel';
import PedigreeOverviewPanel from 'iPedigree/features/patientPage/PedigreeOverviewPanel';
import { useParams } from 'react-router-dom';

function PatientOverviewPage() {
  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();

  if (!pedigreeCaseId) {
    console.error(
      'No pedigree case id provided. Please provide a valid pedigree case id.',
    );
    return (
      <div>
        <h1>
          Error: No pedigree case id provided. Please provide a valid pedigree
          case id.
        </h1>
      </div>
    );
  }

  return (
    <Container>
      <Stack direction="row" spacing={3} mt={3} alignItems="flex-start">
        <PedigreeOverviewPanel pedigreeCaseId={pedigreeCaseId} />
        <PatientOverviewPanel pedigreeCaseId={pedigreeCaseId} />
      </Stack>
    </Container>
  );
}

export default PatientOverviewPage;
