import { formatPersonalNumber } from 'common/utils';

interface PersonalNumberViewProps {
  personalNumber: string;
}

function PersonalNumberView(props: PersonalNumberViewProps) {
  return <>{formatPersonalNumber(props.personalNumber)}</>;
}

export default PersonalNumberView;
