import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Stack } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { ObjectFieldTemplateProps } from '@rjsf/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { DynamicFormContext } from '..';
import { ListFieldContentsView } from '../views/ListFieldContentsView';

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const formContext = useContext(DynamicFormContext);
  const { isSubmitError, setIsSubmitError } = formContext;

  const showCollapseBox = props.uiSchema?.['ui:type'] === 'box';
  const title = props.uiSchema?.['ui:title'];
  const border = props.formContext.border ? 'outlined' : undefined;
  const elevation = props.formContext.border ? undefined : 0;
  const accordionRef = useRef<HTMLDivElement>(null);

  const handleChange = () => {
    if (!!isSubmitError && !!expanded) {
      setIsSubmitError(false);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded && accordionRef.current) {
      setTimeout(() => {
        accordionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 300);
    }
  }, [expanded]);

  useEffect(() => {
    const { errorSchema } = props;
    const hasCollapseBoxAndError =
      showCollapseBox &&
      isSubmitError &&
      Object.keys(errorSchema || {}).length > 0;

    if (hasCollapseBoxAndError) {
      setExpanded(true);
    }
  }, [isSubmitError, props, showCollapseBox]);

  return (
    <>
      {showCollapseBox ? (
        <Accordion
          expanded={!!expanded}
          onChange={handleChange}
          variant={border}
          elevation={elevation}
          disableGutters
          ref={accordionRef}
        >
          <AccordionSummary
            sx={{
              backgroundColor: 'common.neutral.100',
              paddingLeft: 3,
              paddingRight: 3,
            }}
            expandIcon={<FontAwesomeIcon color="#233749" icon={faCaretDown} />}
          >
            <Typography variant="h4" fontWeight="400">
              {props.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <ListFieldContentsView {...props} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Stack spacing={1}>
          {title && <Typography variant="body1">{title}</Typography>}
          <ListFieldContentsView {...props} />
        </Stack>
      )}
    </>
  );
};
