import { useBankidService } from 'api/bankid/BankidServiceProvider';
import {
  BankidAuthCollectCompleteResponse,
  BankidCompleteResponse,
  BankidLaunchMethod,
} from 'api/bankid/bankid-service';
import { BankidController } from 'features/bankid/controllers/BankidController';
import BankidLaunchMethodPickerView from './BankidLaunchMethodPickerView';

interface BankidSigninControllerProps {
  beforeOnLaunchPicker?: React.ReactNode;
  onAuthComplete: (token: string) => void;
  preferredBankidLaunchMethod: BankidLaunchMethod | undefined;
  setPreferredBankidLaunchMethod: (
    method: BankidLaunchMethod | undefined,
  ) => void;
}

function BankidSigninController(props: BankidSigninControllerProps) {
  const service = useBankidService();
  const handleBankidComplete = (
    response: BankidCompleteResponse | BankidAuthCollectCompleteResponse,
  ) => {
    if ('jwt' in response) {
      props.onAuthComplete(response.jwt);
    }
  };

  const handleCancelClick = () => {
    props.setPreferredBankidLaunchMethod(undefined);
  };

  if (!props.preferredBankidLaunchMethod) {
    return (
      <BankidLaunchMethodPickerView
        before={props.beforeOnLaunchPicker}
        setLaunchMethod={props.setPreferredBankidLaunchMethod}
      />
    );
  }

  return (
    <>
      <BankidController
        onBankidInit={service.initAuthBankid}
        onBankidCollect={service.authCollectBankid}
        launchMethod={props.preferredBankidLaunchMethod}
        onCompleted={handleBankidComplete}
        onCancel={handleCancelClick}
      />
    </>
  );
}
export default BankidSigninController;
