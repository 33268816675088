import { Container } from '@mui/material';
import { BankidServiceProvider } from 'api/bankid/BankidServiceProvider';
import { BankidLaunchMethod } from 'api/bankid/bankid-service';
import React from 'react';
import BankidSigninController from './BankidSigninController';

const TOKEN_KEY = 'arkus-bankid-auth-token';

interface AuthContextInterface {
  logout: () => void;
  preferredBankidLaunchMethod: BankidLaunchMethod | undefined;
  accessToken: string | null;
}

const AuthContext = React.createContext<AuthContextInterface>({
  logout: () => {},
  preferredBankidLaunchMethod: undefined,
  accessToken: null,
});

interface BankidAuthProviderProps {
  beforeOnLaunchPicker?: React.ReactNode;
  containerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: React.ReactNode;
}

export function BankidAuthProvider({
  beforeOnLaunchPicker,
  containerMaxWidth = 'sm',
  children,
}: BankidAuthProviderProps): React.ReactElement {
  const [accessToken, setAccessToken] = React.useState<string | null>(null);
  const [preferredBankidLaunchMethod, setPreferredBankidLaunchMethod] =
    React.useState<BankidLaunchMethod | undefined>(undefined);

  React.useEffect(() => {
    const token = getRelativeAuthToken();
    if (token) {
      setAccessToken(token);
    }
  }, []);

  const logout = () => {
    removeRelativeAuthToken();
    setAccessToken(null);
    setPreferredBankidLaunchMethod(undefined);
  };

  const authenticate = (token: string) => {
    saveRelativeAuthToken(token);
    setAccessToken(token);
  };

  const isAuthenticated = Boolean(accessToken);
  if (!isAuthenticated) {
    return (
      <BankidServiceProvider accessToken={null}>
        <Container maxWidth={containerMaxWidth} sx={{ my: 4 }}>
          <BankidSigninController
            beforeOnLaunchPicker={beforeOnLaunchPicker}
            onAuthComplete={authenticate}
            preferredBankidLaunchMethod={preferredBankidLaunchMethod}
            setPreferredBankidLaunchMethod={setPreferredBankidLaunchMethod}
          />
        </Container>
      </BankidServiceProvider>
    );
  }

  return (
    <AuthContext.Provider
      value={{ logout, preferredBankidLaunchMethod, accessToken }}
    >
      <BankidServiceProvider accessToken={null}>
        {children}
      </BankidServiceProvider>
    </AuthContext.Provider>
  );
}

export const getRelativeAuthToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

const saveRelativeAuthToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};

const removeRelativeAuthToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

export function useBankidAuth(): AuthContextInterface {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useBankidAuth must be used within an BankidAuthProvider');
  }
  return context;
}

export function withBankidAuthProvider<P extends object>(
  ComponentBeforeOnLaunchPicked: React.ReactNode,
  Component: React.ComponentType<P>,
): React.FC<P> {
  return (props) => (
    <BankidAuthProvider beforeOnLaunchPicker={ComponentBeforeOnLaunchPicked}>
      <Component {...props} />
    </BankidAuthProvider>
  );
}
