import IPedigreeCasePagesTabNavBar from 'iPedigree/features/patientPage/TabNavBar';
import PatientOverviewPage from 'iPedigree/pages/PatientOverviewPage';
import { PatientPedigreePage } from 'iPedigree/pages/PatientPedigreePage';
import SendReminderPage from 'iPedigree/pages/SendReminderPage';
import SendRequestPage from 'iPedigree/pages/SendRequestPage';
import { Outlet, Route, Routes } from 'react-router-dom';

function PageWithNavBar() {
  return (
    <>
      <IPedigreeCasePagesTabNavBar />
      <Outlet />
    </>
  );
}

function PatientPageRouter() {
  return (
    <Routes>
      <Route path="/" element={<PageWithNavBar />}>
        <Route path="/" element={<PatientOverviewPage />} />
        <Route path="/pedigree" element={<PatientPedigreePage />} />
      </Route>
      <Route path="/send-request" element={<SendRequestPage />} />
      <Route path="/send-reminder" element={<SendReminderPage />} />
    </Routes>
  );
}

export default PatientPageRouter;
