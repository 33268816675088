import { Button, CardContent, Stack, Typography } from '@mui/material';
import { CardWithBorder } from 'myfamilytree/common';
import { Sex } from 'pedigree/features/common/types';
import { useTranslation } from 'react-i18next';
import PedigreeDrawerNodeCardView from './PedigreeDrawerNodeCardView';

interface PedigreeCreatedDrawerContentViewProps {
  onConfirmClick: () => void;
}

export const PedigreeCreatedDrawerContentView = (
  props: PedigreeCreatedDrawerContentViewProps,
) => {
  const { t } = useTranslation('myFamilyTree');
  return (
    <Stack gap={3} p={3}>
      <Typography variant="h3">
        {t('pedigree.onboarding-drawer.created.title')}
      </Typography>
      <Typography>
        {t('pedigree.onboarding-drawer.created.description.paragraph1')}
      </Typography>
      <CardWithBorder variant="outlined">
        <CardContent>
          <PedigreeDrawerNodeCardView
            sex={Sex.FEMALE}
            relativeRelationLabel={t(
              'onBoarding-drawer.description.relative-relation-label',
            )}
          />
        </CardContent>
      </CardWithBorder>
      <Button variant="contained" onClick={props.onConfirmClick}>
        {t('pedigree.onboarding-drawer.created.textButton')}
      </Button>
    </Stack>
  );
};
