import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Dialog from 'components/common/Dialog';
import SpinnerIcon from 'components/icons/SpinnerIcon';
import { ReactNode, useState } from 'react';

export type DialogContent = {
  title: string;
  content?: string;
  element?: ReactNode;
  textConfirm: string;
  textCancel?: string;
};

interface useDialogProps {
  onDialogConfirmClick: () => void;
  onDialogCloseClick?: () => void;
  preventCloseDialogOnConfirm?: boolean;
  dialogContent?: DialogContent;
  disableBackdropClick?: boolean;
  isLoading?: boolean;
}

const useDialog = ({
  onDialogConfirmClick,
  onDialogCloseClick,
  dialogContent,
  disableBackdropClick = false,
  isLoading = false,
  preventCloseDialogOnConfirm = false,
}: useDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const onConfirm = () => {
    if (!preventCloseDialogOnConfirm) {
      closeDialog();
    }
    onDialogConfirmClick();
  };

  const onClose = () => {
    closeDialog();
    onDialogCloseClick && onDialogCloseClick();
  };

  let DialogInstance = null;

  if (dialogContent) {
    const { title, content, textConfirm, textCancel, element } = dialogContent;
    DialogInstance = (
      <Dialog
        open={isOpen}
        {...(!disableBackdropClick && {
          onClose,
        })}
        title={title}
        content={content}
        element={element}
        actions={[
          textCancel && (
            <Button
              onClick={onClose}
              variant="outlined"
              key="close-dialog"
              disabled={isLoading}
            >
              {textCancel}
            </Button>
          ),
          <LoadingButton
            onClick={onConfirm}
            autoFocus
            variant="contained"
            key="confirm-dialog"
            loadingIndicator={
              <SpinnerIcon className="loader" spinnerColor="#23374959" />
            }
            loading={isLoading}
          >
            {textConfirm}
          </LoadingButton>,
        ]}
      />
    );
  }

  return {
    dialog: DialogInstance,
    openDialog,
    closeDialog,
  };
};

export default useDialog;
