import { Typography } from '@mui/material';
import { ListConsentEmptyState } from 'myfamilytree/common/index';
import BasicTable, { Row } from 'myfamilytree/components/tables/BasicTable';
import { ReactNode } from 'react';

interface PatientListViewProps {
  rows: Row[];
  headings: string[];
  casesEmptyStateTitle: string;
  casesEmptyStateDescription: string;
  casesInfo?: string;
  paginationComponent?: ReactNode;
}

export const PatientListView = (props: PatientListViewProps) => {
  return (
    <>
      {props.rows && props.rows.length ? (
        <>
          <BasicTable headings={props.headings} rows={props.rows} />
          {props.paginationComponent}
          {props.casesInfo && (
            <Typography variant="body1" mt={2}>
              {props.casesInfo}
            </Typography>
          )}
        </>
      ) : (
        <ListConsentEmptyState
          title={props.casesEmptyStateTitle}
          description={props.casesEmptyStateDescription}
        />
      )}
    </>
  );
};
