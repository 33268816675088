import { AxiosError } from 'axios';
import { LoadingBackdrop } from 'components/common';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { NotAuthorized } from 'myfamilytree/features/myFamilyTreeSubmission/common/views/NotAuthorized';
import { PATIENT_PATHS } from 'myfamilytree/routes/MyFamilyTreePatientRouter';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export function PatientHome() {
  const { pedigreeCaseId } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation('myFamilyTree');

  const service = useMyFamilyTreeSubmissionService();
  const { isLoading, error } = useQuery(
    ['myFamilyTreeForm', pedigreeCaseId, i18n.language],
    () => service?.getQuestionnaireForm(pedigreeCaseId || ''),
    {
      onSuccess: () => {
        navigate(PATIENT_PATHS.QUESTIONNAIRE);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('../404');
          return;
        }
      },
      retry: false,
    },
  );

  if (isLoading) return <LoadingBackdrop open={isLoading} />;
  if (error && error.response?.status === 403) return <NotAuthorized />;
}
