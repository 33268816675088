import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography } from '@mui/material';
import React from 'react';

interface HeaderWithBackButtonProps {
  children: React.ReactNode;
  headerVariant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onBackButtonClick: () => void;
}

function HeaderWithBackButton(props: HeaderWithBackButtonProps) {
  return (
    <Typography variant={props.headerVariant}>
      <IconButton
        aria-label="back"
        onClick={props.onBackButtonClick}
        sx={{ mr: 4, mb: 0.5, color: 'text.primary' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>
      {props.children}
    </Typography>
  );
}

export default HeaderWithBackButton;
