import { useNavigate } from 'react-router-dom';

const useSendReminderClickHandler = () => {
  const navigate = useNavigate();

  const getSendReminderClickHandler = (consentRequest: {
    consentRequestId: string;
    consents: { consentStatus: string }[];
    completedAt: string | null;
  }) => {
    const canSendReminder =
      consentRequest.completedAt === null &&
      consentRequest.consents.some(
        (consent) => consent.consentStatus === 'pending',
      );

    const handleSendReminderClick = () => {
      navigate(`${consentRequest.consentRequestId}/send-reminder/`);
    };
    return canSendReminder ? handleSendReminderClick : undefined;
  };

  return { getSendReminderClickHandler };
};

export default useSendReminderClickHandler;
