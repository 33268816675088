import {
  constructDataValidator,
  validatePhoneNumber,
} from 'features/common/form-validation-utils';
import Joi from 'joi';
import { ConsentSubmissionFormData } from '../controllers/RelativeInformationFormController';

const validationSchema = Joi.object({
  deceased: Joi.boolean().required(),
  name: Joi.string().required(),
  phoneNumber: Joi.string()
    .required()
    .custom(validatePhoneNumber)
    .pattern(/^[0-9]+$/),
  relationshipToPatient: Joi.string().required(),
  personalNumber: Joi.string()
    .required()
    .length(12)
    .pattern(/^[0-9]+$/),
  geneticInvestigationInfo: Joi.string().allow(''),
  signerRelationshipToRelative: Joi.string().when('deceased', {
    is: true,
    then: Joi.required(),
    otherwise: Joi.allow(''),
  }),
  deceasedPersonalNumber: Joi.when('deceased', {
    is: true,
    then: Joi.string()
      .required()
      .length(12)
      .pattern(/^[0-9]+$/),
    otherwise: Joi.allow(''),
  }),
});

export const validateRelativeFormData =
  constructDataValidator<ConsentSubmissionFormData>(validationSchema);

export const defaultErrorMessages: {
  [key in keyof ConsentSubmissionFormData]: string;
} = {
  deceased: '',
  name: '',
  phoneNumber: '',
  relationshipToPatient: '',
  personalNumber: '',
  geneticInvestigationInfo: '',
  signerRelationshipToRelative: '',
  deceasedPersonalNumber: '',
};
