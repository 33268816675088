import { Paper, Popper, PopperPlacementType } from '@mui/material';
import { CSSProperties } from 'react';

interface SimplePopperProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  children: React.ReactNode;
  styleProps?: CSSProperties;
  placement?: PopperPlacementType;
  modifiers?: any;
}

const SimplePopper = (props: SimplePopperProps) => {
  const placement = props.placement || 'right';
  const modifiers = props.modifiers || {
    name: 'offset',
    options: {
      offset: [0, 4],
    },
  };
  return (
    <Popper
      id={props.id || 'simple-popper'}
      open={props.open}
      anchorEl={props.anchorEl}
      style={{ zIndex: 10, ...props.styleProps }}
      placement={placement}
      modifiers={[modifiers]}
    >
      <Paper elevation={8} data-testid="paper">
        {props.children}
      </Paper>
    </Popper>
  );
};

export default SimplePopper;
