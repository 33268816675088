import { useTheme } from '@mui/material';
import { Diagnose, GeneticTest, Sex } from 'pedigree/features/common/types';
import {
  getNodeInfoText,
  getNodeInfoTextBoxOptions,
} from 'pedigree/features/common/utils/node-info-utils';
import { useEffect, useMemo, useState } from 'react';
import { Arrow, Circle, Group, Line, Rect, Text, Wedge } from 'react-konva';
import { VisibleNodeProperties } from '../hooks/usePedigreeFilter';

interface NodeProfessionalViewProps {
  isIndex: boolean;
  nodeWidth: number;
  sex: Sex;
  name?: string;
  diagnoses?: Diagnose[];
  geneticTest?: GeneticTest;
  yearOfBirth?: number;
  ageAtDeath?: number;
  deceased?: boolean;
  colors: string[];
  hasError: boolean;
  visibleNodeProperties: VisibleNodeProperties;
  isSelected?: boolean;
  isCreated?: boolean;
  isUpdated?: boolean;
}

export function NodeProfessionalView(props: NodeProfessionalViewProps) {
  const [highlighted, setHighlighted] = useState(false);

  const {
    isIndex,
    deceased,
    hasError,
    sex,
    nodeWidth,
    visibleNodeProperties,
    colors,
  } = props;

  const theme = useTheme();
  const text = useMemo(() => {
    return getNodeInfoText(props, visibleNodeProperties);
  }, [props, visibleNodeProperties]);

  const textBoxOptions = getNodeInfoTextBoxOptions(nodeWidth);
  const defaultColor = theme.palette.common.brand.darkBlue;
  const strokeWidth = 3;

  useEffect(() => {
    if (props.isCreated || props.isUpdated) {
      setHighlighted(true);
      setTimeout(() => {
        setHighlighted(false);
      }, 3000);
    }
  }, [props.isCreated, props.isUpdated]);

  const showBorder =
    colors.length === 0 || highlighted || props.isSelected || hasError;
  const isHighlighted = highlighted || props.isSelected;
  const borderColor = isHighlighted
    ? theme.palette.common.brand.blue
    : hasError
    ? theme.palette.error.main
    : defaultColor;

  return (
    <>
      <NodeBackground sex={sex} width={nodeWidth} highlighted={isHighlighted} />
      {colors.length > 0 && (
        <Group {...getClip(nodeWidth, sex)}>
          <Rect
            x={-nodeWidth / 2}
            y={-nodeWidth / 2}
            width={nodeWidth}
            height={nodeWidth}
            fill="white"
          />
          <DiagnosesColoringGroup colors={colors} />
        </Group>
      )}
      {showBorder && (
        <NodeBorder
          sex={sex}
          width={nodeWidth}
          strokeWidth={strokeWidth}
          color={borderColor}
        />
      )}
      <Text
        text={text}
        x={-nodeWidth / 2}
        y={nodeWidth / 2}
        fill={defaultColor}
        {...textBoxOptions}
      />
      {isIndex && <IndexArrow nodeWidth={nodeWidth} color={defaultColor} />}
      {deceased && (
        <DeceasedIndicator nodeWidth={nodeWidth} color={borderColor} />
      )}
    </>
  );
}

const getClip = (width: number, sex: Sex) => {
  width = width - 2;
  if (sex === Sex.FEMALE)
    return {
      clipFunc: (ctx: any) => {
        ctx.arc(0, 0, width / 2, 0, Math.PI * 2, false);
      },
    };
  return {
    clip: {
      x: -width / 2,
      y: -width / 2,
      width: width,
      height: width,
    },
  };
};

interface DiagnosesColoringGroupProps {
  colors: string[];
}

function DiagnosesColoringGroup(props: DiagnosesColoringGroupProps) {
  const { colors } = props;
  const wedgeRadius = 100;

  if (colors.length === 0) {
    return (
      <Rect
        x={-wedgeRadius / 2}
        y={-wedgeRadius / 2}
        width={wedgeRadius}
        height={wedgeRadius}
        fill="white"
      />
    );
  }

  const wedgeAngle = Math.ceil(360 / colors.length);

  return (
    <Group>
      {colors.map((color, index) => (
        <Wedge
          key={index}
          radius={wedgeRadius}
          angle={wedgeAngle}
          fill={color}
          rotation={wedgeAngle * index + 90}
        />
      ))}
    </Group>
  );
}

interface NodeBackgroundProps {
  sex: Sex;
  width: number;
  highlighted?: boolean;
}

function NodeBackground(props: NodeBackgroundProps) {
  const shadowProps = props.highlighted
    ? {
        shadowColor: 'black',
        shadowBlur: 3,
        shadowOffset: { x: 1, y: 1 },
        shadowOpacity: 0.9,
      }
    : {};

  const adjustedWidth = props.width - 2;

  if (props.sex === Sex.FEMALE) {
    return <Circle radius={adjustedWidth / 2} fill="white" {...shadowProps} />;
  }
  return (
    <Rect
      x={-adjustedWidth / 2}
      y={-adjustedWidth / 2}
      width={adjustedWidth}
      height={adjustedWidth}
      fill="white"
      {...shadowProps}
    />
  );
}

interface NodeBorderProps {
  sex: Sex;
  width: number;
  strokeWidth: number;
  color: string;
}

function NodeBorder(props: NodeBorderProps) {
  const { width, sex, strokeWidth, color } = props;
  const adjustedWidth = width - strokeWidth;

  const strokeProps = {
    stroke: color,
    strokeWidth,
  };

  if (sex === Sex.FEMALE) {
    return <Circle radius={adjustedWidth / 2} {...strokeProps} />;
  }
  return (
    <Rect
      x={-adjustedWidth / 2}
      y={-adjustedWidth / 2}
      width={adjustedWidth}
      height={adjustedWidth}
      {...strokeProps}
    />
  );
}

interface IndexArrowProps {
  nodeWidth: number;
  color: string;
}

function IndexArrow({ nodeWidth, color }: IndexArrowProps) {
  const arrowLength = nodeWidth;
  const dx = arrowLength / 2;
  const dy = arrowLength / 3;

  return (
    <Arrow
      x={-nodeWidth - 5}
      y={nodeWidth - dy}
      points={[0, 0, dx, -dy]}
      fill={color}
      stroke={color}
      strokeWidth={2}
    />
  );
}

interface DeceasedIndicatorProps {
  nodeWidth: number;
  color: string;
}

function DeceasedIndicator({ nodeWidth, color }: DeceasedIndicatorProps) {
  const offset = 5;
  return (
    <Line
      points={[
        -nodeWidth / 2 - offset,
        nodeWidth / 2 - offset,
        nodeWidth / 2 + offset,
        -nodeWidth / 2 + offset,
      ]}
      stroke={color}
      strokeWidth={2}
    />
  );
}
