import { Button, Stack } from '@mui/material';
import { RadioInput } from 'components/inputs';
import { useTranslation } from 'react-i18next';

interface SelectPartnerFormProps {
  partnerValue: string;
  parentLabel: string;
  handleParentValue: (value: string) => void;
  handleConfirmPartnerValue: () => void;
  formError: boolean;
  handleCloseForm: () => void;
}

const SelectPartnerForm = ({
  partnerValue,
  parentLabel,
  handleParentValue,
  handleConfirmPartnerValue,
  formError,
  handleCloseForm,
}: SelectPartnerFormProps) => {
  const { t } = useTranslation('pedigree');

  return (
    <Stack gap={3}>
      <RadioInput
        id="select-partner"
        label={t('node-select-parent-form.subtitle.add-child', {
          parent: t(`node-select-parent-form.parent.label.${parentLabel}`),
        })}
        options={[
          {
            label: t('node-select-parent-form.label.existing-partner'),
            value: 'existingPartner',
          },
          {
            label: t('node-select-parent-form.label.new-partner'),
            value: 'newPartner',
          },
        ]}
        radioGroupProps={{
          sx: { paddingLeft: '10px' },
        }}
        value={partnerValue}
        onChange={(e) => handleParentValue(e.target.value)}
        error={formError ? t('node-select-parent-form.required') : ''}
        required
      ></RadioInput>
      <Stack direction="row" gap={3}>
        <Button variant="outlined" fullWidth onClick={handleCloseForm}>
          {t('node-select-parent-form.button.cancel')}
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleConfirmPartnerValue}
        >
          {t('node-select-parent-form.button.continue')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectPartnerForm;
