import { Stack, Typography, useTheme } from '@mui/material';
import { LocalizedDisease } from 'api/pedigreeCase/staff-pedigree-case-service';
import { DiseaseOption } from 'common/features/diagnosisForm/DiagnosisFormFields';
import { SectionBox } from 'components/common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { DiagnosisForm } from '../../../diagnosisForm/DiagnosisForm';
import {
  DiagnosisFormData,
  DiagnosisItemErrors,
} from '../../../diagnosisForm/types';

interface PatientDiagnosisFormControllerProps {
  diagnoses: DiagnosisFormData[];
  errors: DiagnosisItemErrors[];
  setDiagnoses: (data: DiagnosisFormData[]) => void;
  setErrors: (errors: DiagnosisItemErrors[]) => void;
  getDiseaseOptions: () => Promise<LocalizedDisease[]>;
}

function PatientDiagnosisFormController(
  props: PatientDiagnosisFormControllerProps,
) {
  const { diagnoses, errors, setDiagnoses, setErrors, getDiseaseOptions } =
    props || {};

  const { t } = useTranslation(['pedigreeCaseNewPatient']);
  const theme = useTheme();

  const { data: diseases } = useQuery('diseaseOptions', getDiseaseOptions);

  const diseaseOptions: DiseaseOption[] = useMemo(() => {
    if (diseases) {
      const options = diseases.map(({ id, localizedName }) => {
        return {
          label: localizedName,
          value: `${id}`,
        };
      });
      return options;
    }
    return [{ label: '', value: '' }];
  }, [diseases]);

  const onDiagnoseUpdate = (diagnose: DiagnosisFormData, index: number) => {
    let newData = [...diagnoses];
    newData[index] = diagnose;
    setDiagnoses(newData);
  };

  const resetError = (index: number, key: keyof DiagnosisItemErrors) => {
    const newErrors = [...errors];
    newErrors[index] = { ...newErrors[index], [key]: '' };
    setErrors(newErrors);
  };

  return (
    <SectionBox
      sx={{
        p: 4,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h2">{t(`section3.title`)}</Typography>
        <Typography
          variant="body2"
          color={theme.palette.common.opacity.darkBlue[70]}
        >
          {t('section3.description')}
        </Typography>
        <DiagnosisForm
          diseaseOptions={diseaseOptions}
          diagnoses={diagnoses}
          errors={errors}
          resetError={resetError}
          onDiagnoseUpdate={onDiagnoseUpdate}
          onDiagnoseAdd={setDiagnoses}
          onDiagnoseDelete={setDiagnoses}
          requiredFields={{
            disease: true,
          }}
          fieldStyles={{
            ageAtDiagnosis: { width: 133 },
          }}
        />
      </Stack>
    </SectionBox>
  );
}

export default PatientDiagnosisFormController;
