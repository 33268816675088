import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  QuestionsGroup,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import QuestionnaireSectionView from 'common/features/pedigreeCase/questionnaire/views/QuestionnaireGroupView';
import { Drawer } from 'myfamilytree/components/common/Drawer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

interface NodeInformationDrawerProps {
  pedigreeCaseId: string;
  selectedNodeId: string | null;
  selectedNodeRelationLabel: string;
  onClose: () => void;
}

const NodeInformationDrawer = ({
  pedigreeCaseId,
  selectedNodeId,
  selectedNodeRelationLabel,
  onClose,
}: NodeInformationDrawerProps) => {
  const { t } = useTranslation('myFamilyTree');
  const isOpen = useMemo(() => selectedNodeId !== null, [selectedNodeId]);

  const service = useStaffPedigreeCaseService();

  const { data: pedigreeNodeDetails } = useQuery(
    ['node', selectedNodeId],
    () =>
      service.getPedigreeNodeDetails(
        pedigreeCaseId,

        selectedNodeId || '',
      ),
    {
      enabled: selectedNodeId !== null,
    },
  );

  return (
    <Drawer
      onClose={onClose}
      anchor="right"
      isOpen={isOpen}
      hideBackdrop={false}
      styleProps={{ maxWidth: 400 }}
    >
      <Stack justifyContent="space-between" mb={2} direction="row">
        <Typography variant="h3">{selectedNodeRelationLabel}</Typography>
        <IconButton onClick={onClose} sx={{ width: 40 }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
      </Stack>
      {pedigreeNodeDetails && pedigreeNodeDetails.groups.length > 0 ? (
        <Stack gap={3}>
          {pedigreeNodeDetails.groups.map(
            (group: QuestionsGroup, index: number) => (
              <QuestionnaireSectionView
                key={index}
                groupTitle={group.title}
                defaultExpanded={index === 0}
                questions={group.questions}
              />
            ),
          )}
        </Stack>
      ) : (
        <Typography>
          {t(
            'my-family-tree-management.pedigree-overview.node-details.no-info-provided',
          )}
        </Typography>
      )}
    </Drawer>
  );
};

export default NodeInformationDrawer;
