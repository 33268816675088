import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/material';

interface ToastMessageProps {
  message: string;
}
export function ToastMessage({ message }: ToastMessageProps) {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <FontAwesomeIcon icon={faCheck} style={{ fontSize: '1.5rem' }} />
      {message}
    </Stack>
  );
}
