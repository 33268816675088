import { RelativeFormData } from './types';

export const defaultRelativeData: RelativeFormData = {
  personalNumber: '',
  name: '',
  relationshipToPatient: '',
  deceased: false,
  specificProxySigner: false,
  proxySignerName: '',
  proxySignerPersonalNumber: '',
  proxySignerToConsenteeRelationship: '',
};
