import { FormHelperText, FormHelperTextProps } from '@mui/material';
import React from 'react';

interface FormHelperProps {
  children: React.ReactNode;
  error?: boolean;
  formHelperTextProps?: FormHelperTextProps;
}

const FormHelper = ({
  children,
  error,
  formHelperTextProps,
}: FormHelperProps) => {
  return (
    <FormHelperText
      error={error}
      sx={{
        minWidth: '100%',
        width: 0,
      }}
      {...formHelperTextProps}
    >
      {children}
    </FormHelperText>
  );
};

export default FormHelper;
