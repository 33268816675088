import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHelperTextProps, useTheme } from '@mui/material';
import { FormHelper } from 'components/forms';

const FormError = ({
  error,
  formHelperTextProps,
}: {
  error: string;
  formHelperTextProps?: FormHelperTextProps;
}) => {
  const theme = useTheme();

  return (
    <FormHelper error formHelperTextProps={formHelperTextProps}>
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        style={{
          paddingRight: theme.spacing(1),
        }}
      />
      {error}
    </FormHelper>
  );
};

export default FormError;
