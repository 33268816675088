import { Button } from '@mui/material';
import Dialog from 'components/common/Dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function useDeleteRelativeConfirmationDialog(
  deleteRelative: (idx: number) => void,
) {
  const { t } = useTranslation('all');
  const [isOpen, setIsOpen] = useState(false);
  const [relativeIdx, setRelativeIdx] = useState<number | undefined>(undefined);

  const openDialog = (idx: number, hasChanged: boolean) => {
    if (!hasChanged) {
      deleteRelative(idx);
      return;
    }
    setRelativeIdx(idx);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setRelativeIdx(undefined);
    setIsOpen(false);
  };

  const onConfirmClick = () => {
    if (relativeIdx !== undefined) {
      deleteRelative(relativeIdx);
    }
    closeDialog();
  };

  return {
    dialog: (
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        title={t('consent-management.delete-relative-confirmation.title')}
        actions={[
          <Button
            onClick={closeDialog}
            color="primary"
            variant="outlined"
            key="close-dialog"
          >
            {t('consent-management.delete-relative-confirmation.cancel')}
          </Button>,
          <Button
            onClick={onConfirmClick}
            color="primary"
            autoFocus
            variant="contained"
            key="confirm-dialog"
          >
            {t('consent-management.delete-relative-confirmation.confirm')}
          </Button>,
        ]}
      />
    ),
    openDialog,
  };
}

export default useDeleteRelativeConfirmationDialog;
