import { Button, Stack } from '@mui/material';
import {
  BankidInitResponse,
  BankidLaunchMethod,
  BankidResponse,
} from 'api/bankid/bankid-service';
import { BankidController } from 'features/bankid/controllers/BankidController';
import { useTranslation } from 'react-i18next';

interface ConsentSubmissionSigningViewProps {
  onBankidInit: () => Promise<BankidInitResponse>;
  onBankidCollect: (bankidSessionId: string) => Promise<BankidResponse>;
  onSignCompleted: () => void;
  onCancelSignClick: () => void;
  onTryAgainClick: () => void;
  signHasFailed: boolean;
  bankidLaunchMethod: BankidLaunchMethod;
  onSignFailed: () => void;
}

function ConsentSubmissionSigningView(
  props: ConsentSubmissionSigningViewProps,
) {
  const { t } = useTranslation(['all']);

  return (
    <Stack spacing={3} alignItems="center">
      <BankidController
        onBankidInit={props.onBankidInit}
        onBankidCollect={props.onBankidCollect}
        launchMethod={props.bankidLaunchMethod}
        onCompleted={props.onSignCompleted}
        onCancel={props.onCancelSignClick}
        onError={props.onSignFailed}
      />
      {props.signHasFailed && (
        <Button variant="outlined" fullWidth onClick={props.onTryAgainClick}>
          {t('consent-submission-signin-await.failed.button')}
        </Button>
      )}
    </Stack>
  );
}

export default ConsentSubmissionSigningView;
