import { AuthServiceProvider } from 'api/auth/AuthServicesProvider';
import React from 'react';
import useAuth from '../useAuth';
import StaffAuthSigninController from './controllers/StaffAuthSigninController';

const TOKEN_KEY = 'arkus-staff-auth-token';

interface StaffAuthContextInterface {
  logout: () => void;
  accessToken: string | null;
}

const StaffAuthContext = React.createContext<StaffAuthContextInterface>({
  logout: () => {},
  accessToken: '',
});

interface StaffAuthProviderProps {
  children: React.ReactNode;
}

function StaffAuthProvider({ children }: StaffAuthProviderProps) {
  const { logout, authenticate, accessToken, tokenIsLoading } =
    useAuth(TOKEN_KEY);
  const isAuthenticated = Boolean(accessToken);

  if (tokenIsLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <AuthServiceProvider accessToken={accessToken}>
        <StaffAuthSigninController authenticate={authenticate} />
      </AuthServiceProvider>
    );
  }
  return (
    <AuthServiceProvider accessToken={accessToken}>
      <StaffAuthContext.Provider value={{ logout, accessToken }}>
        {children}
      </StaffAuthContext.Provider>
    </AuthServiceProvider>
  );
}

export function useStaffAuth(): StaffAuthContextInterface {
  const context = React.useContext(StaffAuthContext);
  if (context === undefined) {
    throw new Error('useStaffAuth must be used within a StaffAuthProvider');
  }
  return context;
}

export function withStaffAuthProvider<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  return function WithStaffAuthProvider(props: P) {
    return (
      <StaffAuthProvider>
        <Component {...props} />
      </StaffAuthProvider>
    );
  };
}
