import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PatientDetails } from 'api/pedigreeCase/staff-pedigree-case-service';
import { RadioInput, TextInput } from 'components/inputs';
import { DateField } from 'components/inputs/DateField';
import { useTranslation } from 'react-i18next';

interface PatientDetailsFormViewProps {
  patientData: PatientDetails;
  disabled?: boolean;
  errors: PatientDataErrors;
  handleOnchange: (key: keyof PatientDetails, value: string) => void;
  isRequired?: boolean;
}

function PatientDetailsFormView(props: PatientDetailsFormViewProps) {
  const { patientData, disabled, errors, handleOnchange } = props || {};
  const { t, i18n } = useTranslation(['pedigreeCaseNewPatient']);

  return (
    <Box>
      <Grid container justifyContent="center" alignItems="stretch" spacing={3}>
        <Grid xs={12} md={6}>
          <TextInput
            label={t('section2.name')}
            id="name"
            value={patientData.name || ''}
            onChange={(e) => handleOnchange('name', e.target.value)}
            required
            error={errors.name && t(`input-validation.${errors.name}`)}
            textFieldProps={{ disabled: disabled }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <DateField
            id="dateOfBirth"
            label={t('section2.dateOfBirth')}
            value={patientData.dateOfBirth}
            onChange={(value) => handleOnchange('dateOfBirth', value)}
            required
            error={
              errors.dateOfBirth && t(`input-validation.${errors.dateOfBirth}`)
            }
            format="yyyy.MM.dd"
            language={i18n.language === 'en' ? 'en-GB' : 'se'}
          />
        </Grid>
        <Grid xs={12}>
          <RadioInput
            label={t(`section2.sex`)}
            value={patientData.sex}
            rowDirection
            required
            error={errors.sex && t(`input-validation.${errors.sex}`)}
            onChange={(e) => handleOnchange('sex', e.target.value)}
            options={[
              {
                label: t(`section2.female`),
                value: 'female',
              },
              {
                label: t(`section2.male`),
                value: 'male',
              },
            ]}
            id="sex"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientDetailsFormView;

export type PatientDataErrors = {
  [key in keyof PatientDetails]?: string | undefined;
};
