import { BaseService } from 'api/base-service';
import { ConsentSubmissionFormData } from 'features/consentSubmission/controllers/RelativeInformationFormController';
import { ConsentStatus } from 'types';

export type RequestingEntity = {
  name: string;
  city: string;
  phone: string;
};
type getConsentRequestResponse = {
  completedAt: string | null;
  requestingEntity: RequestingEntity;
};

type validateConsentResponse = {
  id: string;
  status: ConsentStatus;
};

type validateConsentRequestPinCodeProps = {
  consentRequestId: string;
  pinCode: string;
};

export type GetConsentResponse =
  | BaseGetConsentResponse
  | DeceasedGetConsentResponse;

type BaseGetConsentResponse = {
  id: string;
  status: ConsentStatus;
  name: string;
  personalNumber: string;
  patient: {
    name: string;
    personalNumber: string;
  };
  consentFor: string;
  staff: {
    name: string;
    phoneNumber: string;
  };
  phoneNumber?: string;
  relationshipToPatient: string;
  additionalInfo?: string;
  pdfLocationUrl?: string;
};

type DeceasedGetConsentResponse = BaseGetConsentResponse & {
  personalNumber?: string;
  proxySigner: {
    name: string;
    personalNumber: string;
    phoneNumber?: string;
    signerToConsenteeRelationship?: string;
  };
  yearOfDeath?: string;
};

type SubmitConsentProps = {
  consentId: string;
};

type DeclineConsentProps = SubmitConsentProps;
type SignConsentProps = SubmitConsentProps;

type SubmitConsentPayload = {
  signerPhoneNumber: string;
  relativeRelationshipToPatient: string;
  consentForDeceased?: boolean;
  deceasedRelativePersonalNumber?: string;
  signerToDeceasedRelationship?: string;
  additionalInfo?: string;
};

export class ConsentSubmissionService extends BaseService {
  getConsentRequest = (
    consentRequestId: string,
  ): Promise<getConsentRequestResponse> =>
    this.axiosInstance
      .get(`/relatives/consent-requests/${consentRequestId}`)
      .then((response) => response.data);

  validateConsentRequestPinCode = ({
    consentRequestId,
    pinCode,
  }: validateConsentRequestPinCodeProps): Promise<validateConsentResponse> =>
    this.axiosInstance
      .get(
        `/relatives/consent-requests/${consentRequestId}/consents/codes/${pinCode}`,
      )
      .then((response) => response.data);

  validateConsentRequestAndConsentIds = ({
    consentRequestId,
    consentId,
  }: {
    consentRequestId: string;
    consentId: string;
  }): Promise<validateConsentResponse> =>
    this.axiosInstance
      .get(
        `/relatives/consent-requests/${consentRequestId}/consents/${consentId}`,
        {},
      )
      .then((response) => response.data);

  getConsent = (consentId: string): Promise<GetConsentResponse> =>
    this.axiosInstance
      .get(`/relatives/consents/${consentId}`)
      .then((response) => response.data);

  submitConsent = async ({
    consentId,
    formData,
  }: {
    consentId: string;
    formData: ConsentSubmissionFormData;
  }) => {
    let payload: SubmitConsentPayload = {
      relativeRelationshipToPatient: formData.relationshipToPatient,
      additionalInfo: formData.geneticInvestigationInfo,
      signerPhoneNumber: formData.phoneNumber,
      consentForDeceased: false,
    };
    if (formData.deceased) {
      payload = {
        ...payload,
        consentForDeceased: true,
        deceasedRelativePersonalNumber: formData.deceasedPersonalNumber,
        signerToDeceasedRelationship: formData.signerRelationshipToRelative,
      };
    }
    return this.axiosInstance
      .put(`/relatives/consents/${consentId}`, payload, {})
      .then((response) => response.data);
  };

  declineConsent = async ({ consentId }: DeclineConsentProps) =>
    this.axiosInstance
      .put(`/relatives/consents/${consentId}/decline`, undefined, {})
      .then((response) => response.data);

  initSignConsent = async ({ consentId }: SignConsentProps) =>
    this.axiosInstance
      .post(`/relatives/consents/${consentId}/signatures`, undefined, {})
      .then((response) => response.data);

  verifyConsentSign = async ({
    consentId,
    signSessionId,
  }: {
    consentId: string;
    signSessionId: string;
  }) =>
    this.axiosInstance
      .get(`/relatives/consents/${consentId}/signatures/${signSessionId}`, {})
      .then((response) => response.data);
}
