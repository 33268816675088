import { Stack, Typography } from '@mui/material';
import { Footer } from 'myfamilytree/common';

interface FamilyTreePatientFooterProps {
  phone: string;
  name: string;
}

export const FamilyTreePatientFooter = (
  props: FamilyTreePatientFooterProps,
) => {
  return (
    <Footer>
      <Stack direction="row">
        <Typography variant="body2">
          {`${props.phone}, ${props.name}`}
        </Typography>
      </Stack>
    </Footer>
  );
};
