import { Button, Stack, Typography } from '@mui/material';
import { FormError } from 'components/forms';
import { useTranslation } from 'react-i18next';
import { PinInput } from 'react-input-pin-code';

interface ConsentLookupViewProps {
  onPinCodeSubmit: () => void;
  errorMessage: string;
  pinCode: string[];
  onPinCodeChange: (pinCode: string[]) => void;
}

function ConsentLookupView(props: ConsentLookupViewProps): JSX.Element {
  const { errorMessage } = props;
  const { t } = useTranslation('all');
  return (
    <Stack direction="column" spacing={4}>
      <Stack spacing={2}>
        <Typography>{t('consent-submission.pincode-input.title')}</Typography>
        <Typography>
          {t('consent-submission.pincode-input.description')}
        </Typography>
      </Stack>
      <Stack justifyContent="center" direction="row">
        <Stack justifyContent="center" direction="column">
          <PinInput
            values={props.pinCode}
            onChange={(_value, _index, values) => props.onPinCodeChange(values)}
            placeholder=""
            autoFocus
            type="text"
          />
          {errorMessage && <FormError error={errorMessage} />}
        </Stack>
      </Stack>
      <Button onClick={() => props.onPinCodeSubmit()} variant="contained">
        {t('ui-common.buttons.continue')}
      </Button>
    </Stack>
  );
}

export default ConsentLookupView;
