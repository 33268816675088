import { Stack } from '@mui/material';
import { Diagnose } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PatientInfoItem from './PatientInfoItem';

type PatientOverviewDiagnosesProps = {
  diagnoses: Diagnose[];
};

type DiseasesGroupedByClinic = {
  [key: string]: {
    name: string;
    age: number | null;
    ageIsApproximate: boolean;
  }[];
};

const PatientOverviewDiagnoses = ({
  diagnoses,
}: PatientOverviewDiagnosesProps) => {
  const { t } = useTranslation('pedigreeCasePatientOverview');

  const diseasesGroupedByClinic = useMemo<DiseasesGroupedByClinic>(() => {
    return diagnoses.reduce(
      (
        grouped: {
          [key: string]: {
            name: string;
            age: number | null;
            ageIsApproximate: boolean;
          }[];
        },
        diagnosis,
      ) => {
        const treatedBy = diagnosis.treatedBy || ''; // Set clinic to empty string if it is undefined
        (grouped[treatedBy] = grouped[treatedBy] || []).push({
          name: diagnosis.disease,
          age: diagnosis.ageAtDiagnosis,
          ageIsApproximate: diagnosis.ageIsApproximate,
        });
        return grouped;
      },
      {},
    );
  }, [diagnoses]);

  const clinicsArray = useMemo(() => {
    return Object.keys(diseasesGroupedByClinic).map((clinic) => ({
      clinic: clinic || '',
      diseases: diseasesGroupedByClinic[clinic],
      hasDiseaseWithAge: diseasesGroupedByClinic[clinic].some(
        (disease) => !!disease.age,
      ),
    }));
  }, [diseasesGroupedByClinic]);

  const diagnosisLabel = (hasDiseaseWithAge: boolean) => {
    return hasDiseaseWithAge
      ? t('diagnosis-label.diagnosis') + ' ' + t('diagnosis-label.age') + ':'
      : t('diagnosis-label.diagnosis') + ':';
  };

  return (
    <>
      {clinicsArray.map(({ clinic, diseases, hasDiseaseWithAge }, index) => (
        <Stack spacing={3} key={index}>
          <PatientInfoItem
            label={diagnosisLabel(hasDiseaseWithAge)}
            value={diseases.map((disease) =>
              disease.age
                ? `${disease.name} (${disease.age}${
                    disease.ageIsApproximate
                      ? `, ${t('diagnosis-label.approximate')}`
                      : ''
                  })`
                : disease.name,
            )}
            direction="column"
          />
          {clinic && (
            <PatientInfoItem
              label={t('treatedAt') + ':'}
              value={clinic}
              direction="column"
            />
          )}
        </Stack>
      ))}
    </>
  );
};

export default PatientOverviewDiagnoses;
