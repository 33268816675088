import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PatientData } from 'api/consentManagement/consent-management-service';
import SectionBox from 'components/common/SectionBox';
import { TextInput } from 'components/inputs';
import { PatientDataErrors } from 'features/consentManagement/common/types';
import { useTranslation } from 'react-i18next';

interface PatientFormViewProps {
  data: PatientData;
  errors: PatientDataErrors;
  handleTextInputChangeFactory: (
    key: keyof PatientData,
  ) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  disabled?: boolean;
  consentFor: string;
}

function PatientFormView(props: PatientFormViewProps) {
  const { t } = useTranslation(['all']);

  return (
    <SectionBox>
      <Stack direction="column" spacing={3}>
        <Typography variant="h2">
          {t('consent-management.new-patient.section2.title')}
        </Typography>
        <Box>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid xs={12}>
              <TextInput
                label={t('consent-management.new-patient.section2.name')}
                id="name"
                value={props.data.name}
                onChange={props.handleTextInputChangeFactory('name')}
                required
                error={
                  props.errors.name &&
                  t(`input-validation.${props.errors.name}`)
                }
                textFieldProps={{ disabled: props.disabled }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextInput
                label={t('consent-management.new-patient.section2.phoneNumber')}
                id="phoneNumber"
                value={props.data.phoneNumber}
                onChange={props.handleTextInputChangeFactory('phoneNumber')}
                required
                error={
                  props.errors.phoneNumber &&
                  t(`input-validation.${props.errors.phoneNumber}`)
                }
                textFieldProps={{
                  disabled: props.disabled,
                  placeholder: '07XXXXXXXX',
                  inputProps: {
                    inputMode: 'numeric',
                  },
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextInput
                label={t('consent-management.new-patient.section2.email')}
                id="email"
                value={props.data.email}
                onChange={props.handleTextInputChangeFactory('email')}
                textFieldProps={{ disabled: props.disabled }}
                error={
                  props.errors.email &&
                  t(`input-validation.${props.errors.email}`)
                }
              />
            </Grid>
            <Grid xs={12}>
              <Stack direction="column" spacing={0.5}>
                <Typography variant="body1">
                  {t('consent-management.new-patient.section2.consentFor')}
                </Typography>

                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                  {props.consentFor}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </SectionBox>
  );
}

export default PatientFormView;
