import { Box, TableCell, TableCellProps, TableRow } from '@mui/material';
import * as React from 'react';

export type HiddenRow = {
  cells: TableCellWithCollapsedBoxType[];
};

export type CollapsibleRowType = {
  cells: React.ReactNode[];
  hiddenRows?: HiddenRow[];
  actionButtons?: React.ReactNode;
  isOpen: boolean;
};

type CollapsibleRowProps = CollapsibleRowType & {
  isEvenRow?: boolean;
};

function CollapsibleRow(props: CollapsibleRowProps) {
  const { cells, hiddenRows, actionButtons, isOpen } = props;
  const backgroundColor = !props.isEvenRow ? 'common.neutral.100' : 'white';

  return (
    <>
      <TableRow sx={{ backgroundColor: backgroundColor }}>
        {cells.map((cell, index) => (
          <TableCell
            key={`cell-${index}`}
            sx={{
              pl: cells.length > 6 && index === 1 ? 0 : '',
              width: cells.length > 6 && index === 0 ? '4%' : 'auto',
            }}
          >
            {cell}
          </TableCell>
        ))}
      </TableRow>

      {hiddenRows &&
        hiddenRows.map((hiddenRow, index) => {
          return (
            <TableRow
              key={`hidden-row-${index}`}
              sx={{
                visibility: isOpen ? 'visible' : 'collapse',
                td: {
                  py: isOpen ? 0.7 : 0,
                  borderBottom: 'none',
                  backgroundColor: backgroundColor,
                  transition: 'padding 0.5s ease',
                },
              }}
            >
              {hiddenRow.cells}
            </TableRow>
          );
        })}

      {isOpen && actionButtons && (
        <TableRow>
          <TableCell
            colSpan={cells.length}
            sx={{ backgroundColor: backgroundColor }}
          >
            <Box sx={{ py: 2 }}>{actionButtons}</Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default CollapsibleRow;

type TableCellWithCollapsedBoxType = React.ReactNode;

export function TableCellWithCollapsedBox(
  props: TableCellProps,
): TableCellWithCollapsedBoxType {
  return (
    <TableCell {...props}>
      <Box className="collapsed">{props.children}</Box>
    </TableCell>
  );
}
