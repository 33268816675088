import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CloseConsentRequestModalProps {
  showCompleteConfirmation: boolean;
  isSubmitting: boolean;
  onCloseClick: () => void;
  onCompleteClick: () => void;
}

function CompleteConsentRequestModal(props: CloseConsentRequestModalProps) {
  const { t } = useTranslation(['all']);
  return (
    <Dialog open={props.showCompleteConfirmation}>
      <DialogTitle variant="h4">{t('modal.close-case.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('modal.close-case.body')}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          pb: 3,
          px: 3,
        }}
      >
        <LoadingButton
          onClick={props.onCloseClick}
          variant="outlined"
          loading={props.isSubmitting}
        >
          {t('modal.close-case.cancel-button')}
        </LoadingButton>
        <Button onClick={props.onCompleteClick} variant="contained">
          {t('modal.close-case.confirm-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompleteConsentRequestModal;
