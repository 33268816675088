import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Fade, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NodeFormActionsButtonsProps {
  onClickSave: () => void;
  onClickCancel: () => void;
  disabled?: boolean;
  nodeChangesSaved?: boolean;
}

export const NodeFormActionsButtons = (props: NodeFormActionsButtonsProps) => {
  const { t } = useTranslation('pedigree');
  const theme = useTheme();

  return (
    <Stack
      p={2}
      pl={3}
      pr={3}
      direction="row"
      sx={{
        position: 'absolute',
        justifyContent: 'space-between',
        alignContent: 'center',
        boxSizing: 'border-box',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: '0px -3px 6px rgba(35, 55, 73, 0.06)',
        borderTop: `1px solid ${theme.palette.common.opacity.darkBlue[25]}`,
      }}
    >
      <Fade in={props.nodeChangesSaved} timeout={1000}>
        <Stack justifyContent="center">
          {props.nodeChangesSaved && (
            <Stack
              direction="row"
              sx={{
                gap: 1.3,
                alignItems: 'center',
                opacity: 0.7,
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: 24,
                  width: 24,
                }}
              />
              <Typography>{t('node-form-status-saved')}</Typography>
            </Stack>
          )}
        </Stack>
      </Fade>
      <Stack direction="row" gap={3} justifyContent="end">
        <Button onClick={props.onClickCancel} variant="outlined">
          {t('node-form-button-cancel')}
        </Button>
        <Button
          onClick={props.onClickSave}
          variant="contained"
          disabled={props.disabled}
        >
          {t('node-form-button-save')}
        </Button>
      </Stack>
    </Stack>
  );
};
