import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack } from '@mui/material';

interface TableRowCellWithToggleButtonViewProps {
  isOpenRow: boolean;
  idx: number;
  setOpenRow: (idx: number | null) => void;
  name: string;
}

function TableRowCellWithToggleButtonView(
  props: TableRowCellWithToggleButtonViewProps,
) {
  return (
    <Stack display="flex" direction="row" alignItems="center">
      <IconButton
        aria-label="expand row"
        color="primary"
        sx={{
          px: 1,
        }}
        size="small"
        onClick={() => props.setOpenRow(props.isOpenRow ? null : props.idx)}
      >
        {props.isOpenRow ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </IconButton>
      {props.name}
    </Stack>
  );
}

export default TableRowCellWithToggleButtonView;
