import { Stack, Typography } from '@mui/material';

interface QuestionnaireQuestionViewProps {
  question: string;
  answer?: string;
  direction?: 'row' | 'column';
}

const QuestionnaireQuestionView = ({
  question,
  answer,
}: QuestionnaireQuestionViewProps) => {
  return (
    <Stack direction="row" gap={1}>
      <Typography color="common.neutral.600" variant="body1">
        {question}
      </Typography>
      <Typography>{answer}</Typography>
    </Stack>
  );
};

export default QuestionnaireQuestionView;
