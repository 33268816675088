import {
    MenuItem,
    Select as MuiSelect,
    SelectChangeEvent,
    Stack,
    Typography,
  } from '@mui/material';
  
  function Select({ options, currentValue, handleChange }: selectProps) {
    return (
        <MuiSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentValue}
          label={currentValue}
          onChange={handleChange}
          defaultValue={currentValue}
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
          }}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value} disabled={option?.disabled}>
                <Stack direction="row" alignItems="center" gap={1}>
                  {option.icon && <img alt={'icon'} src={option.icon} />}
                  <Typography variant="body1">{option.label}</Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </MuiSelect>
    );
  }
  
  export default Select;
  
  type selectProps = {
    options: { label: string; value: string; icon?: string, disabled?: boolean }[];
    currentValue: string;
    handleChange: (event: SelectChangeEvent) => void;
  };
  