import { TablePagination, TablePaginationProps } from '@mui/material';
import { useState } from 'react';

interface useTablePaginationProps {
  count: number;
  tablePaginationProps?: TablePaginationProps;
}

const useTablePagination = (props: useTablePaginationProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const paginationComponent = (
    <TablePagination
      {...props.tablePaginationProps}
      component="div"
      count={props.count}
      page={page}
      onPageChange={(_, newPage) => setPage(newPage)}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      }}
    />
  );

  return {
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    paginationComponent,
  };
};

export default useTablePagination;
