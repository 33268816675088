import { Alert, Snackbar } from '@mui/material';
import { ToastMessage } from 'components/common/ToastMessage';
import { usePedigreeContext } from '../pedigreeContext';

type Severity = 'success' | 'error' | 'warning' | 'info';

export type SnackbarMessageType = {
  message: string;
  type: Severity | undefined;
};

export const SnackbarMessage = () => {
  const { snackbarMessage, setSnackbarMessage } = usePedigreeContext();
  const { message, type } = snackbarMessage || {};

  const onClose = () => {
    setSnackbarMessage(null);
  };

  return type === 'success' ? (
    <Snackbar
      open={!!message}
      onClose={onClose}
      message={<ToastMessage message={message || ''} />}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
      sx={{
        '& .MuiSnackbarContent-root': {
          minWidth: 'fit-content',
        },
      }}
    ></Snackbar>
  ) : (
    <Snackbar
      open={!!message}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={10000}
    >
      <Alert severity={type} variant="filled" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
