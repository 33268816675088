import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PedigreeCaseInactive() {
  const { t } = useTranslation('myFamilyTree');
  return (
    <Stack
      direction="column"
      spacing={4}
      alignItems="center"
      justifyContent="center"
      sx={{
        flexGrow: 1,
      }}
    >
      <Typography variant="h1">{t('pedigreeCaseInactive.title')}</Typography>
      <Typography variant="body1">
        {t('pedigreeCaseInactive.content')}
      </Typography>
    </Stack>
  );
}

export default PedigreeCaseInactive;
