import {
  faCircleCheck,
  faExclamationCircle,
  faInfoCircle,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PedigreeCaseEvent,
  PedigreeCaseEventName,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { PedigreeRequestReminderEventsView } from 'common/features/pedigreeCase/PedigreeRequestReminderEventsViewProps';
import { isoToShortDate } from 'common/utils';
import { SectionBox } from 'components/common';
import Dialog from 'components/common/Dialog';
import { TitleWithIcon } from 'components/common/TitleWithIcon';
import { ToastMessage } from 'components/common/ToastMessage';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  NavigateFunction,
  NavigateOptions,
  useNavigate,
} from 'react-router-dom';
import { useLocation } from 'react-use';
import { LocationSensorState } from 'react-use/lib/useLocation';

interface PedigreeOverviewPanelProps {
  pedigreeCaseId: string;
}

function PedigreeOverviewPanel(props: PedigreeOverviewPanelProps) {
  const service = useStaffPedigreeCaseService();

  const [cancelRequestDialogOpen, setCancelRequestDialogOpen] =
    useState<boolean>(false);

  const { data: pedigreeCase, isLoading: isPedigreeCase } = useQuery(
    ['pedigreeCase', props.pedigreeCaseId],
    () => service.getPedigreeCase(props.pedigreeCaseId),
  );

  const { data: pedigreeCaseEvents, isLoading: isLoadingEvents } = useQuery(
    ['pedigreeCaseEvents', props.pedigreeCaseId],
    () => service.getPedigreeCaseEvents(props.pedigreeCaseId),
  );

  const isLoading = isPedigreeCase || isLoadingEvents;
  const receivedAt = useMemo(() => {
    if (!pedigreeCaseEvents) {
      return null;
    }
    const receivedEvent = pedigreeCaseEvents.items.find(
      (event) => event.name === 'pedigreeSubmittedByPatient',
    );
    return receivedEvent?.createdAt;
  }, [pedigreeCaseEvents]);

  const viewToRender: React.ReactNode = useMemo(() => {
    if (isLoading) {
      return null;
    }
    if (pedigreeCase?.status === 'caseCreated') {
      const hasContactDetails = Boolean(
        pedigreeCase.patientDetails.email ||
          pedigreeCase.patientDetails.phoneNumber,
      );
      return <NoPedigreeView hasContactDetails={hasContactDetails} />;
    }
    if (
      pedigreeCase?.status === 'pedigreeRequested' ||
      pedigreeCase?.status === 'pedigreeCreatedByPatient'
    ) {
      return (
        <PedigreeRequestedView
          pedigreeCaseEvents={pedigreeCaseEvents?.items || []}
          openCancelRequestDialog={() => setCancelRequestDialogOpen(true)}
        />
      );
    }
    if (
      (pedigreeCase?.status === 'pedigreeReceived' ||
        pedigreeCase?.status === 'caseViewed') &&
      receivedAt
    ) {
      return <PedigreeReceivedView receivedAtDate={receivedAt} />;
    }
    if (pedigreeCase?.status === 'pedigreeProvided') {
      return (
        <PedigreeProvidedView
          pedigreeCaseEvents={pedigreeCaseEvents?.items || []}
        />
      );
    }
  }, [pedigreeCase, isLoading, pedigreeCaseEvents?.items, receivedAt]);

  return (
    <>
      <SectionBox sx={{ flexGrow: 1, maxWidth: 675 }}>
        {viewToRender}
      </SectionBox>
      <PedigreeRequestCancelingDialog
        pedigreeCaseId={props.pedigreeCaseId}
        isOpen={cancelRequestDialogOpen}
        onClose={() => setCancelRequestDialogOpen(false)}
      />
    </>
  );
}

interface NoPedigreeViewProps {
  hasContactDetails: boolean;
}

function NoPedigreeView(props: NoPedigreeViewProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('iPedigree');

  return (
    <Stack direction="column" spacing={3}>
      <TitleWithIcon
        icon={faInfoCircle}
        title={t('pedigree-overview-panel.no-pedigree.title')}
      />
      <Typography variant="body1">
        {t('pedigree-overview-panel.no-pedigree.description')}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() =>
            handleNavigate(location, navigate, 'pedigree', {
              state: {
                showCreatePedigreeForm: true,
              },
            })
          }
          variant="outlined"
        >
          {t('pedigree-overview-panel.create-pedigree.button-text')}
        </Button>
        <Button
          onClick={() => handleNavigate(location, navigate, 'send-request')}
          variant="outlined"
        >
          {t('pedigree-overview-panel.send-request.button-text')}
        </Button>
      </Stack>
    </Stack>
  );
}

interface PedigreeRequestedViewProps {
  pedigreeCaseEvents: PedigreeCaseEvent[];
  openCancelRequestDialog: () => void;
}

function PedigreeRequestedView(props: PedigreeRequestedViewProps) {
  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={3}>
      <TitleWithIcon
        icon={faInfoCircle}
        title={t('pedigree-overview-panel.requested.title')}
      />
      <Alert
        severity="info"
        icon={false}
        sx={{ backgroundColor: theme.palette.common.brand.infoBackground }}
      >
        <Typography variant="body1">
          {t('pedigree-overview-panel.requested.alert-message')}
        </Typography>
      </Alert>

      <PedigreeRequestReminderEventsView
        pedigreeCaseEvents={props.pedigreeCaseEvents}
      />

      <Stack direction="row" spacing={2}>
        <Button
          key="cancel-request-button"
          onClick={() => props.openCancelRequestDialog()}
          variant="outlined"
        >
          {t('pedigree-overview-panel.cancel-request.button-text')}
        </Button>
        <Button
          key="send-reminder-button"
          onClick={() => handleNavigate(location, navigate, 'send-reminder')}
          variant="outlined"
        >
          {t('pedigree-overview-panel.send-reminder.button-text')}
        </Button>
      </Stack>
    </Stack>
  );
}

interface PedigreeRequestCancelingDialogProps {
  pedigreeCaseId: string;
  isOpen: boolean;
  onClose: () => void;
}

function PedigreeRequestCancelingDialog(
  props: PedigreeRequestCancelingDialogProps,
) {
  const { t } = useTranslation('iPedigree');
  const [showCancelToast, setShowCancelToast] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const service = useStaffPedigreeCaseService();

  const cancelMutation = useMutation(
    () => service.cancelRequest(props.pedigreeCaseId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pedigreeCase', props.pedigreeCaseId]);
        queryClient.invalidateQueries([
          'pedigreeCaseEvents',
          props.pedigreeCaseId,
        ]);
        setShowCancelToast(true);
        props.onClose();
      },
    },
  );

  return (
    <>
      <Dialog
        open={props.isOpen}
        title={t('pedigree-overview-panel.cancel-request.dialog.title')}
        content={t('pedigree-overview-panel.cancel-request.dialog.content')}
        actions={[
          <LoadingButton
            key="confirm-cancel-request-button"
            onClick={() => cancelMutation.mutate()}
            variant="outlined"
            loading={cancelMutation.isLoading}
          >
            {t('pedigree-overview-panel.cancel-request.dialog.confirm')}
          </LoadingButton>,
          <Button
            key="cancel-cancel-request-button"
            onClick={() => props.onClose()}
            variant="contained"
            disabled={cancelMutation.isLoading}
          >
            {t('pedigree-overview-panel.cancel-request.dialog.cancel')}
          </Button>,
        ]}
      />
      <Snackbar
        open={showCancelToast}
        onClose={() => setShowCancelToast(false)}
        message={
          <ToastMessage
            message={t(
              'pedigree-overview-panel.cancel-request.confirmation-toast',
            )}
          />
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1800}
      />
    </>
  );
}

interface PedigreeReceivedViewProps {
  receivedAtDate: string;
}

function PedigreeReceivedView(props: PedigreeReceivedViewProps) {
  const { t, i18n } = useTranslation('iPedigree');
  const navigate = useNavigate();

  return (
    <Stack direction="column" spacing={3}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <TitleWithIcon
          icon={faExclamationCircle}
          title={t('pedigree-overview-panel.received.title')}
          iconColor="warning.main"
        />
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faSitemap} />}
          onClick={() => navigate('./pedigree')}
        >
          {t('pedigree-overview-panel.received.action.review')}
        </Button>
      </Stack>
      <Box>
        <Typography variant="body2">
          {t('pedigree-overview-panel.received.description.line1', {
            receivedAt: isoToShortDate(props.receivedAtDate, i18n),
          })}
        </Typography>
        <Typography variant="body2">
          {t('pedigree-overview-panel.received.description.line2')}
        </Typography>
      </Box>
    </Stack>
  );
}

interface PedigreeProvidedViewProps {
  pedigreeCaseEvents: PedigreeCaseEvent[];
}

function PedigreeProvidedView(props: PedigreeProvidedViewProps) {
  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();

  return (
    <Stack direction="column" spacing={3}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        <TitleWithIcon
          icon={faCircleCheck}
          iconColor="success.main"
          title={t('pedigree-overview.panel.created.title')}
        />
        <Button
          onClick={() => navigate('./pedigree')}
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faSitemap} />}
        >
          {t('pedigree-overview.panel.created.button')}
        </Button>
      </Stack>
      <PedigreeProvidedViewDescription
        pedigreeCaseEvents={props.pedigreeCaseEvents}
      />
    </Stack>
  );
}

interface PedigreeProvidedViewDescriptionProps {
  pedigreeCaseEvents: PedigreeCaseEvent[];
}

function PedigreeProvidedViewDescription(
  props: PedigreeProvidedViewDescriptionProps,
) {
  const { t, i18n } = useTranslation('iPedigree');

  const getEvent = useCallback(
    (eventName: PedigreeCaseEventName) => {
      return props.pedigreeCaseEvents.find((event) => event.name === eventName);
    },
    [props.pedigreeCaseEvents],
  );

  const pedigreeAcceptedEvent = useMemo(
    () => getEvent('pedigreeAccepted'),
    [getEvent],
  );
  const pedigreeSubmittedByPatientEvent = useMemo(
    () => getEvent('pedigreeSubmittedByPatient'),
    [getEvent],
  );
  const pedigreeCreatedByStaffEvent = useMemo(
    () => getEvent('pedigreeCreatedByStaff'),
    [getEvent],
  );

  if (pedigreeAcceptedEvent) {
    const pedigreeAcceptedDate = isoToShortDate(
      pedigreeAcceptedEvent.createdAt,
      i18n,
    );
    const pedigreeAcceptedBy = pedigreeAcceptedEvent.createdBy;
    const receivedFromPatientDate = pedigreeSubmittedByPatientEvent?.createdAt
      ? isoToShortDate(pedigreeSubmittedByPatientEvent?.createdAt, i18n)
      : null;

    return (
      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2">
          {t('pedigree-overview.panel.acceptedByStaff.description', {
            name: pedigreeAcceptedBy,
            date: pedigreeAcceptedDate,
          })}
        </Typography>
        <Typography variant="body2">
          {t('pedigree-overview.panel.createdByPatient.description', {
            date: receivedFromPatientDate,
          })}
        </Typography>
      </Stack>
    );
  }

  const createdByStaffDate = isoToShortDate(
    pedigreeCreatedByStaffEvent?.createdAt || '',
    i18n,
  );
  const createdByStaffName = pedigreeCreatedByStaffEvent?.createdBy;
  return (
    <Typography variant="body1">
      {t('pedigree-overview.panel.created.description', {
        name: createdByStaffName,
        date: createdByStaffDate,
      })}
    </Typography>
  );
}

export const handleNavigate = (
  location: LocationSensorState,
  navigate: NavigateFunction,
  pathname: 'send-reminder' | 'send-request' | 'pedigree',
  state?: NavigateOptions,
) => {
  if (location.pathname?.includes('pedigree')) {
    const newPathname = location.pathname.replace('pedigree', pathname);
    return navigate(newPathname, state);
  }
  navigate(pathname, state);
};

export default PedigreeOverviewPanel;
