import {
  BankidInitResponse,
  BankidLaunchMethod,
} from 'api/bankid/bankid-service';
import { useConsentSubmissionService } from 'api/consentSubmission/consentSubmissionServiceProvider';
import { useState } from 'react';
import ConsentSubmissionSigningView from '../views/ConsentSubmissionSigningView';

interface ConsentSubmissionSigningControllerProps {
  consentId: string;
  onSignCompleted: () => void;
  backToOverview: () => void;
  bankidLaunchMethod: BankidLaunchMethod;
}

function ConsentSubmissionSigningController(
  props: ConsentSubmissionSigningControllerProps,
) {
  const { consentId, bankidLaunchMethod, backToOverview, onSignCompleted } =
    props;
  const service = useConsentSubmissionService();
  const [signHasFailed, setSignHasFailed] = useState(false);

  const onBankidInit = async (): Promise<BankidInitResponse> => {
    return service.initSignConsent({ consentId });
  };

  const onBankidCollect = async (bankidSessionId: string) => {
    const response = await service.verifyConsentSign({
      consentId,
      signSessionId: bankidSessionId,
    });
    return response;
  };

  return (
    <ConsentSubmissionSigningView
      onBankidInit={onBankidInit}
      onBankidCollect={onBankidCollect}
      signHasFailed={signHasFailed}
      onCancelSignClick={() => backToOverview()}
      onTryAgainClick={() => backToOverview()}
      onSignFailed={() => setSignHasFailed(true)}
      bankidLaunchMethod={bankidLaunchMethod}
      onSignCompleted={onSignCompleted}
    />
  );
}

export default ConsentSubmissionSigningController;
