import { Box, Grid } from '@mui/material';

interface ButtonRowViewProps {
  ButtonOne: React.ReactNode;
  ButtonTwo: React.ReactNode;
}

function ButtonRowView(props: ButtonRowViewProps) {
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          {props.ButtonOne}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {props.ButtonTwo}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ButtonRowView;
