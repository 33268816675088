import { PatientData } from 'api/consentManagement/consent-management-service';
import {
  constructDataValidator,
  validatePhoneNumber,
} from 'features/common/form-validation-utils';
import Joi from 'joi';
import { RelativeFormData } from '../types';

const personalNumberSchema = Joi.string()
  .length(12)
  .pattern(/^[0-9]+$/);

const patientInformationSchema = Joi.object({
  name: Joi.string().required(),
  phoneNumber: Joi.string()
    .required()
    .custom(validatePhoneNumber)
    .pattern(/^[0-9]+$/),
  email: Joi.string().optional().allow('').email({ tlds: false }),
  consentFor: Joi.string().optional().allow(''),
  personalNumber: personalNumberSchema.optional().allow(''),
});

const relativeSchema = Joi.object({
  name: Joi.string().required(),
  personalNumber: personalNumberSchema.optional().allow(''),
  relationshipToPatient: Joi.string().required(),
  proxySignerName: Joi.string().when('specificProxySigner', {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow(''),
  }),
  proxySignerPersonalNumber: Joi.string().when('specificProxySigner', {
    is: true,
    then: personalNumberSchema.optional().allow(''),
    otherwise: Joi.string().optional().allow(''),
  }),
  proxySignerToConsenteeRelationship: Joi.string().when('specificProxySigner', {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow(''),
  }),
  deceased: Joi.boolean().required(),
  specificProxySigner: Joi.boolean().required(),
});

export const validatePatientData = constructDataValidator<PatientData>(
  patientInformationSchema,
);

const validateSingleRelativeData =
  constructDataValidator<RelativeFormData>(relativeSchema);

export const validateRelativeData = (data: RelativeFormData[]) => {
  const result = data.map((relativeData) =>
    validateSingleRelativeData(relativeData),
  );
  let valid = result.every((validationResult) => validationResult.valid);
  const errorMessages = result.map(
    (validationResult) => validationResult.errorMessages || {},
  );
  if (data.length === 0) {
    return {
      valid: false,
      errorMessages: [],
    };
  }
  return { valid, errorMessages };
};
