import { Stack } from '@mui/material';
import { useNumberOfRelativeFields } from 'common/features/newPedigreeForm/controllers/NewPedigreeFormController';
import { NumberOfRelativesFormData } from 'common/features/newPedigreeForm/types';
import { NumberOfRelativeFormView } from 'common/features/newPedigreeForm/views/NewPedigreeFormView';
import { SectionBox } from 'components/common';
import ExplanationSection from 'components/common/ExplanationSection';
import CancelAndContinueButtons from 'iPedigree/common/CancelAndContinueButtons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConcerningCancerSelectionForm, {
  Purpose,
} from './ConcerningCancerSelectionForm';

interface NewPedigreeFormProps {
  submitFormCallback: (params: {
    numberOfRelatives: NumberOfRelativesFormData;
    purpose?: Purpose;
  }) => Promise<void>;
  onCancelClick: () => void;
  askForConcerningCancer: boolean;
}

function NewPedigreeForm(props: NewPedigreeFormProps) {
  const { t } = useTranslation(['iPedigree', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { numberOfRelatives, numberOfRelativesFields } =
    useNumberOfRelativeFields();
  const [purpose, setPurpose] = useState<Purpose | null>(null);

  const numberOfRelativesFormTitle = props.askForConcerningCancer
    ? `1. ${t('new-pedigree-form.title')}`
    : t('new-pedigree-form.title');

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    await props.submitFormCallback({
      numberOfRelatives,
      purpose: purpose || undefined,
    });
    setIsSubmitting(false);
  };

  const concerningCancerSelectionExplanationText: string[] = t(
    'new-pedigree-form.concerning-cancer-selection-form.explanation.text',
    { returnObjects: true },
  );

  const relativeAddExplanationText: string[] = t(
    'pedigree-add-relatives.explanation.text',
    { returnObjects: true },
  );

  return (
    <Stack spacing={3} maxWidth={610} sx={{ my: 3, mx: 'auto' }}>
      <SectionBox>
        <Stack spacing={3}>
          <NumberOfRelativeFormView
            heading={{
              title: numberOfRelativesFormTitle,
              description: t('new-pedigree-form.description'),
              showIcon: false,
            }}
            fields={numberOfRelativesFields}
          />
          <ExplanationSection
            buttonText={t('pedigree-add-relatives.explanation.button')}
            explanationText={relativeAddExplanationText}
          />
        </Stack>
      </SectionBox>
      {props.askForConcerningCancer && (
        <ConcerningCancerSelectionForm
          purpose={purpose}
          setPurpose={setPurpose}
          explanationText={concerningCancerSelectionExplanationText}
          titleIndex={2}
        />
      )}
      <CancelAndContinueButtons
        onCancelClick={props.onCancelClick}
        onContinueClick={handleContinueClick}
        isLoading={isSubmitting}
      />
    </Stack>
  );
}

export default NewPedigreeForm;
