import React from 'react';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from '../serviceProviderFactory';
import { ConsentManagementService } from './consent-management-service';

const ConsentManagementServiceContext = React.createContext<{
  service: ConsentManagementService | null;
}>({ service: null });

export const ConsentManagementServiceProvider = serviceProviderFactory(
  ConsentManagementService,
  ConsentManagementServiceContext,
);

export const useConsentManagementService = serviceHookFactory(
  ConsentManagementService,
  ConsentManagementServiceContext,
);
