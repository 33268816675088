import { FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React from 'react';

type RadioOptions = {
  label: string;
  value: string | boolean;
}[];

interface RadioInputProps {
  label?: string;
  options: RadioOptions;
  radioGroupProps?: any;
  radioProps?: any;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  labelInfo?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  rowDirection?: boolean;
}

const RadioInput = ({
  label,
  options,
  radioGroupProps,
  radioProps,
  id,
  value,
  onChange,
  labelInfo,
  disabled = false,
  error,
  required = false,
  rowDirection = false,
}: RadioInputProps) => {
  const labelId = id + '-label';
  const theme = useTheme();

  return (
    <InputWrapper
      label={label}
      labelId={labelId}
      labelInfo={labelInfo}
      disabled={disabled}
      error={error}
      required={required}
    >
      <RadioGroup
        sx={{ flexDirection: rowDirection ? 'row' : 'column' }}
        aria-labelledby={labelId}
        value={value}
        onChange={onChange}
        {...radioGroupProps}
      >
        {options.map((option) => (
          <FormControlLabel
            disabled={disabled}
            value={option.value.toString()}
            control={
              <Radio sx={error ? { color: theme.palette.error.main } : {}} />
            }
            label={option.label}
            key={`${id}-option-${option.value.toString()}`}
            {...radioProps}
          />
        ))}
      </RadioGroup>
    </InputWrapper>
  );
};

export default RadioInput;
