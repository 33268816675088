import {
  faDownload,
  faPrint,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { usePdfService } from './PdfServiceProvider';

interface Props {
  pdfLocationUrl: string | null;
  title: string;
  onCloseClick: () => void;
  onDownloadClick: () => void;
  onPrintClick: () => void;
}

function PdfViewModal({
  pdfLocationUrl,
  title,
  onCloseClick,
  onDownloadClick,
  onPrintClick,
}: Props) {
  const { pdfApiService } = usePdfService();
  const [isLoading, setIsLoading] = React.useState(false);
  const [pdfDataUrl, setConsentPdfUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!pdfLocationUrl) {
      return;
    }
    setIsLoading(true);
    try {
      pdfApiService?.getPdfData(pdfLocationUrl).then((data: string) => {
        setConsentPdfUrl(data);
      });
    } catch (error) {
      // Handle error appropriately
      console.error('Error fetching consent PDF URL:', error);
    } finally {
      setIsLoading(false);
    }
  }, [pdfLocationUrl, pdfApiService]);

  const isOpen = pdfLocationUrl !== null;
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen || isLoading}
      onClose={onCloseClick}
      fullWidth
      maxWidth="md"
      sx={{ '.MuiDialog-paper': { overflowY: 'hidden' } }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.common.brand.lightPurple,
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="h4">{title}</Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5">Actions</Typography>

              <IconButton
                color="primary"
                size="small"
                onClick={onDownloadClick}
              >
                <FontAwesomeIcon icon={faDownload} />
              </IconButton>
              <IconButton color="primary" size="small" onClick={onPrintClick}>
                <FontAwesomeIcon icon={faPrint} />
              </IconButton>
              <IconButton
                onClick={onCloseClick}
                aria-label="close"
                color="primary"
              >
                <FontAwesomeIcon icon={faXmark} size="sm" />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          height: '80vh',
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={`${pdfDataUrl}#toolbar=0&navpanes=0&scrollbar=0`}
            title="Consent PDF"
            width="100%"
            height="100%"
            allowFullScreen
            style={{ border: 'none' }}
          ></iframe>
        )}
      </Box>
    </Dialog>
  );
}

export default PdfViewModal;
