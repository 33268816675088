import { Drawer } from '@mui/material';
import { useState } from 'react';

interface PatientOnboardingControllerProps {
  pedigreeId: string;
  pedigreeNodesWithCancerHistory: string[];
  setPedigreeNodesWithCancerHistory: (value: string[]) => void;
}

export const PatientOnboardingController = (
  props: PatientOnboardingControllerProps,
) => {
  const [drawerStatus, setDrawerStatus] = useState<
    'cancerHistory' | 'created' | 'info' | null
  >(null);
  // const { service } = usePedigreeContext();

  // if cancerHistory service.sendRelativesWithCancerHistory
  return <Drawer />;
};
