import { constructDataValidator } from 'features/common/form-validation-utils';
import Joi from 'joi';
import { RelativeDisease } from './types';

const pedigreeFormSchema = Joi.object({
  relativeHasDisease: Joi.string().required(),
});

export const validateRelativeData =
  constructDataValidator<RelativeDisease>(pedigreeFormSchema);
