import { Grid, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { DiagnosisFieldsAttributes, DiagnosisFormData } from './types';

type DiagnoseDataField = {
  title: string;
  value: string | number | boolean;
};

interface DiagnosisReadonlyProps {
  diagnosisData: DiagnosisFormData;
  fieldTexts: DiagnosisFieldsAttributes;
}

const DiagnosisReadonly = (props: DiagnosisReadonlyProps) => {
  const { diagnosisData, fieldTexts } = props;
  const theme = useTheme();

  const diagnosisFields = useMemo(() => {
    const noRenderKeys = new Set([
      'id',
      'readonly',
      'ageIsApproximate',
      'status',
    ]);
    const diagnosisItem: Record<string, string | number | boolean | null> =
      diagnosisData;

    const texts: Record<string, { label: string }> = fieldTexts || {};

    return Object.keys(diagnosisData).reduce<DiagnoseDataField[]>(
      (acc, key) => {
        if (!noRenderKeys.has(key)) {
          const title =
            key !== 'disease' && texts?.[key]?.label ? texts[key].label : '';
          const value = diagnosisItem[key] ?? '';
          acc.push({ title, value });
        }
        return acc;
      },
      [],
    );
  }, [diagnosisData, fieldTexts]);

  return (
    <Grid
      container
      borderLeft={`1px solid ${theme.palette.common.opacity.darkBlue[25]}`}
      marginLeft="1px"
      spacing={2}
    >
      {diagnosisFields.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Typography
            variant="body1"
            color={theme.palette.common.opacity.darkBlue[70]}
          >
            {item.title}
          </Typography>
          <Typography variant="body1">{item.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default DiagnosisReadonly;
