import { Button, Container, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

function GenericErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation('common');
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t('error-boundary.title')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('error-boundary.message')}
      </Typography>
      <Button onClick={resetErrorBoundary} variant="contained">
        {t('error-boundary.button.retry')}
      </Button>
    </Container>
  );
}
export default GenericErrorFallback;
