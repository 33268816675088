type NodeState = {
  isIndex: boolean;
  hasCancerHistory: boolean;
  hasError: boolean;
  isSelected: boolean;
};

type usePedigreeNodePatientStylesReturnType = {
  iconColor: string;
  textColor?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  borderColor?: string;
  hoverBorderColor?: string;
  borderWidth: number;
};

const usePedigreeNodePatientStyles = (
  state: NodeState,
): usePedigreeNodePatientStylesReturnType => {
  const mock = {
    iconColor: 'gray',
    textColor: 'gray',
    backgroundColor: 'blue',
    hoverBackgroundColor: 'blue',
    borderColor: 'blue',
    hoverBorderColor: 'blue',
    borderWidth: 1,
  };
  return mock;
};

export default usePedigreeNodePatientStyles;
