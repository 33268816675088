import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack } from '@mui/material';
import { Diagnose } from 'api/pedigreeCase/staff-pedigree-case-service';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { DiseaseOption } from './DiagnosisFormFields';
import { DiagnosisFormItem } from './DiagnosisFormItem';
import {
  DiagnoseValue,
  DiagnosisFormData,
  DiagnosisFormTexts,
  DiagnosisItemErrors,
  FieldStyles,
} from './types';

interface DiagnosisFormProps {
  diseaseOptions: DiseaseOption[];
  textsProvided?: DiagnosisFormTexts;
  fieldStyles?: {
    [key in keyof Diagnose]?: FieldStyles;
  };
  requiredFields?: {
    [key in keyof Diagnose]?: boolean;
  };
  addButtonWidth?: CSSProperties['maxWidth'];
  readonly?: boolean;
  isAllowDeleteRemainingItem?: boolean;
  errors: DiagnosisItemErrors[];
  resetError?: (index: number, key: keyof DiagnosisItemErrors) => void;
  diagnoses: DiagnosisFormData[];
  onDiagnoseAdd: (diagnoses: DiagnosisFormData[]) => void;
  onDiagnoseDelete: (diagnoses: DiagnosisFormData[], index: number) => void;
  onDiagnoseUpdate: (diagnose: DiagnosisFormData, index: number) => void;
}

export const DiagnosisForm = (props: DiagnosisFormProps) => {
  const {
    diagnoses,
    onDiagnoseAdd,
    onDiagnoseDelete,
    onDiagnoseUpdate,
    diseaseOptions,
    errors,
    resetError,
    textsProvided,
    addButtonWidth,
    readonly = false,
    fieldStyles,
    requiredFields,
    isAllowDeleteRemainingItem = true,
  } = props;

  const { t } = useTranslation(['diagnosisForm']);

  const addDiagnosis = () => {
    const newItemId =
      diagnoses.length > 0 ? Math.max(...diagnoses.map((d) => d.id)) + 1 : 1;

    const item = { ...emptyDiagnosis, id: newItemId };
    const newItems = [...diagnoses, item];
    onDiagnoseAdd(newItems);
  };

  const deleteDiagnosis = (index: number) => {
    const newItems = diagnoses.filter((_, i) => i !== index);
    onDiagnoseDelete(newItems, index);
  };

  const handleOnchange = <K extends keyof Diagnose>(
    key: K,
    value: DiagnoseValue[K],
    index: number,
  ) => {
    let inputValue = value;

    let newDiagnose = { ...diagnoses[index] };
    newDiagnose = { ...newDiagnose, [key]: inputValue };
    onDiagnoseUpdate(newDiagnose, index);

    if (resetError) {
      resetError(index, key);
    }
  };

  const buttonAddText =
    diagnoses.length >= 1
      ? t('diagnosis-form.button.add-another')
      : t('diagnosis-form.button.add');

  return (
    <>
      <Box>
        <Stack gap={3}>
          {diagnoses.map((item, index) => (
            <DiagnosisFormItem
              key={item.id}
              showDeleteButton={isAllowDeleteRemainingItem || index !== 0}
              diseaseOptions={diseaseOptions}
              diagnoses={item}
              titleItemNumber={index + 1}
              textsProvided={textsProvided}
              fieldStyles={fieldStyles}
              requiredFields={requiredFields}
              errors={errors && errors[index]}
              readonly={readonly}
              onDiagnoseFieldChange={(key, value) =>
                handleOnchange(key, value, index)
              }
              deleteDiagnosis={() => deleteDiagnosis(index)}
            />
          ))}
          {!readonly && (
            <Button
              onClick={addDiagnosis}
              variant="outlined"
              sx={{ maxWidth: addButtonWidth || 'fit-content' }}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {buttonAddText}
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export const emptyDiagnosis = {
  id: 1,
  disease: '',
  ageAtDiagnosis: null,
  ageIsApproximate: false,
  treatedBy: '',
  notes: '',
};
