interface MaleIconProps {
  color: string;
}

const MaleIcon: React.FC<MaleIconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.8203 7.82812H10.3438C9.05078 7.82812 8 8.8789 8 10.1719V14.3828C8 14.9648 8.42188 15.4453 8.97656 15.5391V20.8281C8.97656 21.4766 9.5 22 10.1484 22H13.0195C13.668 22 14.1914 21.4766 14.1914 20.8281V15.5391C14.7461 15.4453 15.168 14.9648 15.168 14.3828V10.1719C15.1641 8.875 14.1172 7.82812 12.8203 7.82812Z" />
      <path d="M11.5857 7.21094C13.0247 7.21094 14.1912 6.04443 14.1912 4.60547C14.1912 3.16651 13.0247 2 11.5857 2C10.1468 2 8.98026 3.16651 8.98026 4.60547C8.98026 6.04443 10.1468 7.21094 11.5857 7.21094Z" />
    </svg>
  );
};

MaleIcon.defaultProps = {
  color: 'white',
};

export default MaleIcon;
