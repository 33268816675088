import { Button } from '@mui/material';
import axios from 'axios';
import { DrawerState } from 'pedigree/features';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  NodesParams,
  PedigreeRelationshipType,
  generateCreateNodeParams,
} from '../generate-create-node-params';
import { AddNewPedigreeNode, CreateNodeParams, Pedigree } from '../types';
import { DialogContentType } from '../views/Dialog';
import { SnackbarMessageType } from '../views/SnackbarMessage';
import { PedigreeNodeAction } from './useHandleNodeAction';

export type PedigreeNodeAddAction =
  | 'addParents'
  | 'addPartner'
  | 'addBrother'
  | 'addSister'
  | 'addSon'
  | 'addDaughter'
  | 'addHalfBrother'
  | 'addHalfSister'
  | ('addTwin' | 'addIdenticalTwins' | 'addFraternalTwins');

type CreateNewNodeMemberParams = {
  value: PedigreeRelationshipType;
  pedigreeNodeId: string;
  isSequentialRequest?: boolean;
} & NodesParams;

export type HandleCreateNewNodeMember = {
  action: Partial<PedigreeNodeAction>;
  pedigreeNodeId: string;
} & NodesParams;

interface useAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  setSnackbarMessage: (content: SnackbarMessageType | null) => void;
  addNewPedigreeNode: AddNewPedigreeNode;
  setDrawerState: (info: DrawerState) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  setPedigree: (Pedigree: Pedigree | null) => void;
}

const RELATIONSHIP_TYPE_MAP: Record<string, PedigreeRelationshipType> = {
  addParents: 'parents',
  addPartner: 'partner',
  addBrother: 'brother',
  addSister: 'sister',
  addSon: 'son',
  addDaughter: 'daughter',
  addHalfBrother: 'half-brother',
  addHalfSister: 'half-sister',
  addIdenticalTwins: 'identical-twins',
  addFraternalTwins: 'fraternal-twins',
};

export const useAddNode = (props: useAddNodeProps) => {
  const { t } = useTranslation('pedigree');

  const createMemberMutation = useMutation(
    (params: { nodeParams: CreateNodeParams; isSequentialRequest: boolean }) =>
      props.addNewPedigreeNode(props.pedigree?.id || '', params.nodeParams),
    {
      onSuccess: (data, { isSequentialRequest }) => {
        if (!isSequentialRequest) {
          props.setPedigree(data);
          props.setSnackbarMessage({
            message: t('node-member-snackbar-message.add.success'),
            type: 'success',
          });
        }
      },
    },
  );

  const createNewNodeMember = async (params: CreateNewNodeMemberParams) => {
    try {
      await callAddNewNodeApi(params.value, params.isSequentialRequest);
    } catch (error) {
      let errorMessage;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      //when add sibling to a node without parents, we first add parents and then add sibling
      if (errorMessage === 'Target node must have at least one parent') {
        await createNewNodeMember({
          value: 'parents',
          pedigreeNodeId: params.pedigreeNodeId,
          isSequentialRequest: true,
        });
        await createNewNodeMember({
          value: params.value,
          pedigreeNodeId: params.pedigreeNodeId,
        });
      } else {
        handleActionError(errorMessage);
      }
    }

    async function callAddNewNodeApi(
      value: any, //FIXME: value type shoud be aligned with generateCreateNodeParams relationshipType
      isSequentialRequest?: boolean,
    ) {
      if (props.pedigree) {
        const nodeParams = generateCreateNodeParams({
          pedigree: props.pedigree,
          targetNodeId: params.pedigreeNodeId,
          relationshipType: value,
          ...params,
        });
        await createMemberMutation.mutateAsync({
          nodeParams,
          isSequentialRequest: isSequentialRequest || false,
        });
      }
    }
  };

  const closeDialog = () => {
    props.setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const openAddErrorDialog = (errorType: string) => {
    props.setDialogContent({
      open: true,
      title: t(`node-member-dialog.add.title.${errorType}`),
      content: t(`node-member-dialog.add.content.${errorType}`),
      actions: [
        <Button
          key="close-delete-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.add.button.isNotAllow')}
        </Button>,
      ],
    });
  };

  const handleActionError = (errorMessage: string) => {
    let errorType = null;
    if (errorMessage === 'Node already has a partner') {
      errorType = 'hasPartnerError';
    }
    if (errorMessage === 'Child already has both parents') {
      errorType = 'hasParentsError';
    }

    if (errorType !== null) {
      openAddErrorDialog(errorType);
    } else {
      props.setSnackbarMessage({
        message: t('common-snackBar-message.error'),
        type: 'error',
      });
    }
  };

  const handleCreateNewNodeMember = (params: HandleCreateNewNodeMember) => {
    const pedigreeRelationshipValue = RELATIONSHIP_TYPE_MAP[params.action];
    createNewNodeMember({
      value: pedigreeRelationshipValue,
      ...params,
    });
  };

  return {
    handleCreateNewNodeMember,
  };
};
