import { Button, Stack, Typography } from '@mui/material';
import { FormError } from 'components/forms';
import { useTranslation } from 'react-i18next';

interface SelectParentFormProps {
  label: string;
  errorMessage: string;
  formError: boolean;
  onConfirmClick: () => void;
  onBackButtonClick: () => void;
}

const SelectParentForm = ({
  label,
  errorMessage,
  formError,
  onConfirmClick,
  onBackButtonClick,
}: SelectParentFormProps) => {
  const { t } = useTranslation('pedigree');

  return (
    <Stack gap={3}>
      <Typography>{label}</Typography>
      <Stack direction="row" gap={3}>
        <Button variant="outlined" fullWidth onClick={onBackButtonClick}>
          {t('node-select-parent-form.button.back')}
        </Button>
        <Button variant="contained" fullWidth onClick={onConfirmClick}>
          {t('node-select-parent-form.button.confirm')}
        </Button>
      </Stack>
      {formError && <FormError error={errorMessage} />}
    </Stack>
  );
};

export default SelectParentForm;
