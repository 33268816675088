import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import { SectionBox } from 'components/common';
import CancelAndContinueButtons from 'iPedigree/common/CancelAndContinueButtons';
import { formatPhoneNumberInput, TextInput } from 'myfamilytree/common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmail } from 'validator';
import ConcerningCancerSelectionForm, {
  Purpose,
} from './ConcerningCancerSelectionForm';

type ContactDetails = {
  email: string;
  phoneNumber: string;
};

type PatientDetails = {
  name: string;
  personalNumber: string;
};

export type RequestPedigreeFormData = ContactDetails & {
  purpose: Purpose | null;
};

type ContactDetailsError = 'badEmail' | 'oneOfRequired' | undefined;

interface RequestPedigreeFormProps {
  patient: PatientDetails;
  formData: RequestPedigreeFormData;
  setFormData: (formData: RequestPedigreeFormData) => void;
  onContinueClick: () => void;
  onCancelClick: () => void;
  askForContactDetails: boolean;
  askForConcerningCancer: boolean;
}

function RequestPedigreeForm(props: RequestPedigreeFormProps) {
  const { t } = useTranslation(['iPedigree']);
  const [contactDetailsError, setContactDetailsError] =
    useState<ContactDetailsError>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    const contactDetailsError = validateContactDetails({
      email: props.formData.email,
      phoneNumber: props.formData.phoneNumber,
    });
    if (props.askForContactDetails && contactDetailsError) {
      setContactDetailsError(contactDetailsError);
      setIsSubmitting(false);
      return;
    }
    props.onContinueClick();
    setIsSubmitting(false);
  };

  const useIndexInSubFormTitles =
    props.askForContactDetails && props.askForConcerningCancer;

  const concerningCancerSelectionExplanationText: string[] = t(
    'requesting-pedigree.concerning-cancer-selection-form.explanation.text',
    { returnObjects: true },
  );

  return (
    <>
      {props.askForContactDetails && (
        <ContactDetailsForm
          patient={props.patient}
          contactDetails={{
            email: props.formData.email,
            phoneNumber: props.formData.phoneNumber,
          }}
          setContactDetails={(contactDetails) =>
            props.setFormData({
              ...props.formData,
              email: contactDetails.email,
              phoneNumber: contactDetails.phoneNumber,
            })
          }
          titleIndex={useIndexInSubFormTitles ? 1 : undefined}
          error={contactDetailsError}
        />
      )}
      {props.askForConcerningCancer && (
        <ConcerningCancerSelectionForm
          purpose={props.formData.purpose}
          setPurpose={(purpose) =>
            props.setFormData({ ...props.formData, purpose })
          }
          explanationText={concerningCancerSelectionExplanationText}
          titleIndex={useIndexInSubFormTitles ? 2 : undefined}
        />
      )}
      <CancelAndContinueButtons
        onCancelClick={props.onCancelClick}
        onContinueClick={handleContinueClick}
        isLoading={isSubmitting}
      />
    </>
  );
}

export default RequestPedigreeForm;

interface ContactDetailsFormProps {
  patient: PatientDetails;
  contactDetails: ContactDetails;
  setContactDetails: (contactDetails: ContactDetails) => void;
  titleIndex?: number;
  error: ContactDetailsError;
}

function ContactDetailsForm(props: ContactDetailsFormProps) {
  const { t } = useTranslation(['iPedigree', 'all']);

  const title = props.titleIndex
    ? `${props.titleIndex}. ${t('contact-details-form.title')}`
    : t('contact-details-form.title');

  const handleMobilePhoneChange = (phone: string) => {
    props.setContactDetails({ ...props.contactDetails, phoneNumber: phone });
  };

  const handleEmailChange = (email: string) => {
    props.setContactDetails({ ...props.contactDetails, email });
  };

  const hintText = t('contact-details-form.hint');
  const errorText = useMemo(() => {
    if (props.error === 'badEmail') {
      return t(`contact-details-form.error.bad-email`);
    }
    if (props.error === 'oneOfRequired') {
      return t(`contact-details-form.error.one-of-required`);
    }
    return null;
  }, [props.error, t]);

  const hasError = !!props.error;

  return (
    <SectionBox>
      <Stack spacing={3}>
        <Typography variant="h2">{title}</Typography>
        <Stack spacing={1}>
          <Typography variant="h4">{props.patient.name}</Typography>
          <Typography variant="body1">
            {`${t('all:personal-number')}: ${formatPersonalNumber(
              props.patient.personalNumber,
            )}`}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Stack spacing={3} direction="row" alignItems="end">
            <MobilePhoneInput
              value={props.contactDetails.phoneNumber}
              onChange={handleMobilePhoneChange}
              hasError={hasError}
            />
            <Typography variant="h5" pb={1}>
              {t('contact-details-form.or')}
            </Typography>
            <EmailInput
              value={props.contactDetails.email}
              onChange={handleEmailChange}
              hasError={hasError}
            />
          </Stack>
          {errorText ? (
            <Typography variant="caption" color="error">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: 4 }}
              />
              {errorText}
            </Typography>
          ) : (
            <Typography variant="caption">{hintText}</Typography>
          )}
        </Stack>
      </Stack>
    </SectionBox>
  );
}

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  hasError: boolean;
}

function MobilePhoneInput(props: InputProps) {
  const { t } = useTranslation(['iPedigree']);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = formatPhoneNumberInput(event.target.value);
    props.onChange(newPhoneNumber);
  };

  return (
    <TextInput
      label={t('contact-details-form.phone')}
      id="phoneNumber"
      value={props.value}
      onChange={handleChange}
      textFieldProps={{
        error: props.hasError,
        placeholder: '07XXXXXXXX',
        inputProps: { inputMode: 'numeric' },
      }}
    />
  );
}

function EmailInput(props: InputProps) {
  const { t } = useTranslation(['iPedigree']);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <TextInput
      label={t('contact-details-form.email')}
      id="email"
      value={props.value}
      onChange={handleChange}
      textFieldProps={{ type: 'email', error: props.hasError }}
    />
  );
}

function validateContactDetails(
  contactDetails: ContactDetails,
): ContactDetailsError {
  if (!contactDetails.email && !contactDetails.phoneNumber) {
    return 'oneOfRequired';
  }
  if (contactDetails.email && !isEmail(contactDetails.email)) {
    return 'badEmail';
  }
  return;
}
