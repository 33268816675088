import axios from 'axios';
import useDialog, { DialogContent } from 'common/hooks/useDialog';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import {
  CreateNodeParams,
  Pedigree,
} from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import { ErrorSnackbar } from 'myfamilytree/common';
import {
  generateCreateNodeParams,
  PedigreeRelationshipType,
} from 'pedigree/features/common/generate-create-node-params';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

interface usePedigreeAddNodeActionProps {
  pedigree: Pedigree;
}

export const usePedigreeAddNodeAction = (
  props: usePedigreeAddNodeActionProps,
) => {
  const [dialogContent, setDialogContent] = useState<
    DialogContent | undefined
  >();
  const [snackbarErrorMessage, setSnackbarError] = useState<string>('');

  const service = useMyFamilyTreeSubmissionService();
  const { t } = useTranslation('myFamilyTree');
  const queryClient = useQueryClient();

  const createMember = useMutation(
    (nodeParams: CreateNodeParams) =>
      service?.addNewPedigreeNode(props.pedigree.id, nodeParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pedigree');
      },
    },
  );

  const createNewNodeMember = async (
    value: PedigreeRelationshipType,
    pedigreeNodeId: string,
  ) => {
    try {
      await callAddNewNodeApi(value);
    } catch (error) {
      let errorMessage;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      //when add sibling to a node without parents, we first add parents and then add sibling
      if (errorMessage === 'Target node must have at least one parent') {
        await createNewNodeMember('parents', pedigreeNodeId);
        await createNewNodeMember(value, pedigreeNodeId);
      } else {
        handleActionError(errorMessage);
      }
    }

    async function callAddNewNodeApi(value: any) {
      //FIXME: value type shoud be aligned with generateCreateNodeParams relationshipType
      const nodeParams = generateCreateNodeParams({
        pedigree: props.pedigree,
        targetNodeId: pedigreeNodeId,
        relationshipType: value,
      });
      await createMember.mutateAsync(nodeParams);
    }
  };

  const { dialog, openDialog, closeDialog } = useDialog({
    dialogContent,
    onDialogConfirmClick: () => {
      closeDialog();
    },
  });

  const handleActionError = (message: string) => {
    const { label, content } = dialogErrorHandle(message);
    if (label && content) {
      setDialogContent({
        title: t(`pedigree.node.member.dialog.title.${label}.add`),
        content: t(`pedigree.node.member.dialog.content.${content}.add`),
        textConfirm: t(`pedigree.node.member.dialog.button.isNotAllow.add`),
        textCancel: undefined,
      });
      openDialog();
    } else {
      setSnackbarError(t('common-snackBar-message.error'));
    }
  };

  const handleAddNodeAction = (
    value: PedigreeRelationshipType,
    pedigreeNodeId: string,
  ) => {
    createNewNodeMember(value, pedigreeNodeId);
  };

  return {
    addNodeDialog: dialog,
    addNodeSnackbar: (
      <ErrorSnackbar
        onClose={() => setSnackbarError('')}
        errorMessage={snackbarErrorMessage}
      ></ErrorSnackbar>
    ),
    handleAddNodeAction,
  };
};

const dialogErrorHandle = (message: string) => {
  const hasPartnerError = message === 'Node already has a partner';
  const hasParentsError = message === 'Child already has both parents';

  let label;
  let content;

  if (hasPartnerError) {
    label = 'hasPartnerError';
    content = 'hasPartnerError';
  }

  if (hasParentsError) {
    label = 'hasParentsError';
    content = 'hasParentsError';
  }

  return {
    label,
    content,
  };
};
