import { Button, Container, ContainerProps, Stack } from '@mui/material';
import ArkusLogo from 'assets/images/arkus-logo.svg';
import { useStaffAuth } from 'features/auth/staff/StaffAuthProvider';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from './LanguageSelector';

interface TopNavProps {
  containerMaxWidth?: ContainerProps['maxWidth'];
}

function TopNav({ containerMaxWidth }: TopNavProps) {
  const { logout } = useStaffAuth();

  const { t } = useTranslation(['common']);

  return (
    <Container sx={{ py: 2, pt: 1, pb: 1 }} maxWidth={containerMaxWidth}>
      <Stack direction="row" justifyContent="space-between">
        <img alt={'logo'} src={ArkusLogo} />
        <Stack direction="row">
          <LanguageSelector />
          <Button variant="text" onClick={logout}>
            {t('button.sign-out')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default TopNav;
