import {
  faBell,
  faDownload,
  faEye,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, styled } from '@mui/material';

interface ActionButtonsProps {
  onPrintClick?: () => void;
  onDownloadClick?: () => void;
  onViewClick?: () => void;
  onReminderClick?: () => void;
}

const StyledIconButton = styled(IconButton)(() => ({
  width: 40,
  height: 40,
}));

function ActionButtons(props: ActionButtonsProps) {
  return (
    <Stack direction="row">
      {props.onViewClick && (
        <StyledIconButton
          color="primary"
          onClick={props.onViewClick}
          size="small"
        >
          <FontAwesomeIcon icon={faEye} />
        </StyledIconButton>
      )}
      {props.onDownloadClick && (
        <StyledIconButton
          color="primary"
          onClick={props.onDownloadClick}
          size="small"
        >
          <FontAwesomeIcon icon={faDownload} />
        </StyledIconButton>
      )}
      {props.onPrintClick && (
        <StyledIconButton
          color="primary"
          onClick={props.onPrintClick}
          size="small"
        >
          <FontAwesomeIcon icon={faPrint} />
        </StyledIconButton>
      )}
      {props.onReminderClick && (
        <StyledIconButton
          color="primary"
          onClick={props.onReminderClick}
          size="small"
        >
          <FontAwesomeIcon icon={faBell} />
        </StyledIconButton>
      )}
    </Stack>
  );
}

export default ActionButtons;
