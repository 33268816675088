import { WidgetProps } from '@rjsf/utils';
import { ReadonlyView } from '../views/ReadonlyView';

export const ReadonlyWidget = (props: WidgetProps) => {
  const title = props.uiSchema?.['ui:title'] || '';
  const format = props.uiSchema?.['ui:format'];
  const padding = props.uiSchema?.['ui:padding'] || 0;

  return (
    <ReadonlyView
      title={title}
      value={props.value}
      padding={padding}
      format={format}
    />
  );
};
