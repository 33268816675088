import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import useSortAndSearchInput from 'myfamilytree/features/common/hooks/useSortAndSearchInput';
import SortAndSearchInputView from 'myfamilytree/features/common/views/SortAndSearchInputView';
import useTablePagination from 'myfamilytree/features/myFamilyTreeManagement/common/hooks/useTablePagination';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useCaseInfoTable } from '../hooks/useCaseInfoTable';
import { PatientListView } from '../views/PatientListView';

type SortField = 'patientName';
type SortOrder = 'ASC' | 'DESC';

export type SortAndSearchParameters = {
  sort: {
    field: SortField;
    order: SortOrder;
  };
  search: string;
};

const initialSortAndSearchParameters: SortAndSearchParameters = {
  sort: {
    field: 'patientName',
    order: 'ASC',
  },
  search: '',
};

const PatientListController = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));
  const service = useStaffPedigreeCaseService();
  const { t } = useTranslation(['myFamilyTree']);

  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    searchValue,
    setSearchValue,
    sortValue,
    setSortValue,
    searchQuery,
    sortQuery,
  } = useSortAndSearchInput<SortAndSearchParameters>({
    initialSortAndSearchParameters,
  });

  const { paginationComponent, limit, offset } = useTablePagination({
    count: totalCount,
  });

  const patientQuantityInfoLabel = t(
    'my-family-tree-management.page-dashboard.table.all-number-patients.info',
    { limit: limit <= totalCount ? limit : totalCount, total: totalCount },
  );

  let casesEmptyStateTitle = t(
    'my-family-tree-management.page-dashboard.table.all-empty-state.title',
  );
  let casesEmptyStateDescription = t(
    'my-family-tree-management.page-dashboard.table.all-empty-state.description',
  );

  if (searchQuery) {
    casesEmptyStateTitle = t(
      'my-family-tree-management.page-dashboard.table.no-results.title',
      {
        searchQuery,
      },
    );
    casesEmptyStateDescription = t(
      'my-family-tree-management.page-dashboard.table.no-results.description',
    );
  }

  const { data, isLoading } = useQuery(
    ['pedigreeCases', sortQuery, searchQuery, limit, offset],
    () =>
      service.getPedigreeCases({
        sortQuery,
        searchQuery,
        limit: `${limit}`,
        offset: `${offset}`,
      }),
    {
      onSuccess: (data) => {
        const { count } = data || {};
        setTotalCount(count);
      },
    },
  );

  const { rows, headings } = useCaseInfoTable({
    items: data?.items,
    tabSection: 'allPatient',
  });

  const showSortAndSearchInput = !!data?.items.length || searchQuery;

  return (
    <>
      {isLoading ? (
        <LoadingBackdrop />
      ) : (
        <Stack spacing={4} mb={4}>
          {showSortAndSearchInput && (
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <SortAndSearchInputView
                searchValue={searchValue}
                sortValue={sortValue}
                setSearchValue={setSearchValue}
                setSortValue={setSortValue}
                sortLabel={t('sort.sort-menu-label')}
                searchLabel={t('search.search')}
                sortOptions={[
                  {
                    label: t('sort.patients-surname.a-z-ascending'),
                    value: 'patientName:ASC',
                  },
                  {
                    label: t('sort.patients-surname.z-a-descending'),
                    value: 'patientName:DESC',
                  },
                ]}
                sortFieldProps={{ sx: { minWidth: 300 } }}
                searchFieldProps={{ sx: { minWidth: 380 } }}
                searchPlaceholder={t('search.placeholder-text')}
                inlineLabel={isNotMobile}
              />
              <Typography variant="body1">
                {patientQuantityInfoLabel}
              </Typography>
            </Stack>
          )}
          <PatientListView
            paginationComponent={paginationComponent}
            rows={rows}
            headings={headings}
            casesEmptyStateTitle={casesEmptyStateTitle}
            casesEmptyStateDescription={casesEmptyStateDescription}
          ></PatientListView>
        </Stack>
      )}
    </>
  );
};
export default PatientListController;
