import { useConsentSubmissionService } from 'api/consentSubmission/consentSubmissionServiceProvider';
import { usePdfService } from 'api/pdf/PdfServiceProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ConsentAcceptedConfirmationView from '../views/ConsentAcceptedConfirmationView';
import ConsentDeclinedConfirmationView from '../views/ConsentDeclinedConfirmationView';

interface ConsentSubmittedConfirmationControllerProps {
  consentId: string;
}

function ConsentSubmittedConfirmationController(
  props: ConsentSubmittedConfirmationControllerProps,
) {
  const service = useConsentSubmissionService();
  const { downloadPdf } = usePdfService();
  const queryClient = useQueryClient();

  const { data: consent, isLoading } = useQuery(
    ['consent', 'submittedConsentConfirmation'],
    () => service.getConsent(props.consentId),
  );

  const consentPdfUrl = consent?.pdfLocationUrl;

  React.useEffect(() => {
    if (consent?.status === 'accepted' && !consentPdfUrl) {
      queryClient.invalidateQueries([
        'consent',
        'submittedConsentConfirmation',
      ]);
    }
  }, [consent?.status, consentPdfUrl, queryClient]);

  if (isLoading && !consent) return <LoadingBackdrop />;

  const handleDownloadConsentClick = consentPdfUrl
    ? () => downloadPdf(consentPdfUrl, 'consent.pdf')
    : undefined;

  if (consent?.status === 'accepted') {
    return (
      <ConsentAcceptedConfirmationView
        onDownloadConsentClick={handleDownloadConsentClick}
      />
    );
  }
  return <ConsentDeclinedConfirmationView />;
}

export default ConsentSubmittedConfirmationController;
