import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface DialogProps extends MuiDialogProps {
  title: string;
  content?: string;
  element?: ReactNode;
  actions: React.ReactNode[];
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { title, content, element, actions, ...rest } = props;

  return (
    <MuiDialog
      {...rest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {(content || element) && (
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {content}
          </DialogContentText>
          {element}
        </DialogContent>
      )}
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
