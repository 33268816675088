import { isoToRelativeDate, isoToShortDate } from 'common/utils';
import { i18n } from 'i18next';
import { ConsentStatus } from 'types';

export function getConsentDate(
  consent: {
    consentStatus: ConsentStatus;
    createdAt: string;
    updatedAt: string;
    signedAt?: string;
  },
  useShortDateOnly: boolean,
  i18n: i18n,
) {
  if (consent.consentStatus === 'pending') {
    return isoToShortDate(consent.createdAt, i18n);
  }

  const signedAt = consent.signedAt || consent.updatedAt;
  if (useShortDateOnly) {
    return isoToShortDate(signedAt, i18n);
  }
  return isoToRelativeDate(signedAt, i18n);
}
