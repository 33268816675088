import { faBell, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography } from '@mui/material';
import { formatPersonalNumber, isoToShortDate } from 'common/utils';
import { useTranslation } from 'react-i18next';

interface ConsentRequestListItemCardHeaderViewProps {
  patientName: string;
  patientPersonalNumber: string;
  createdAt: string;
  completedAt?: string;
  onCompleteConsentClick?: () => void;
  onSendReminderClick?: () => void;
}
function ConsentRequestListItemHeaderView(
  props: ConsentRequestListItemCardHeaderViewProps,
) {
  const { t, i18n } = useTranslation(['all']);

  const patientName = props.patientName;
  return (
    <Stack
      direction="column"
      sx={{
        p: 3,
        backgroundColor: 'common.brand.lightPurple',
        overflowX: 'auto',
        scrollbarWidth: 'none',
      }}
    >
      <Stack direction="row" justifyContent="space-between" rowGap={0.5}>
        <Typography variant="h4">{patientName}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {props.onSendReminderClick && (
            <Button
              endIcon={<FontAwesomeIcon icon={faBell} />}
              onClick={props.onSendReminderClick}
            >
              {t('consent-request.action-buttons.send-reminder')}
            </Button>
          )}
          {props.onCompleteConsentClick && (
            <Button
              endIcon={<FontAwesomeIcon icon={faCheck} />}
              onClick={props.onCompleteConsentClick}
            >
              {t('consent-request-list-item.header.button.close')}
            </Button>
          )}
        </Box>
      </Stack>
      <Stack direction="row" flexWrap="wrap" useFlexGap columnGap={7}>
        <Typography variant="body1" noWrap>
          {t('consent-request-list-item.personal-number')}:{' '}
          {formatPersonalNumber(props.patientPersonalNumber)}
        </Typography>
        {props.createdAt && (
          <Typography variant="body1">
            {t('consent-request-list-item.createdAt')}:{' '}
            {isoToShortDate(props.createdAt, i18n)}
          </Typography>
        )}
        {props.completedAt && (
          <Typography variant="body1">
            {t('consent-request-list-item.completedAt')}:{' '}
            {isoToShortDate(props.completedAt, i18n)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default ConsentRequestListItemHeaderView;
