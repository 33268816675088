import {
  Diagnose,
  PatientDetails,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { calculateAgeFromDateOfBirth } from 'common/utils';
import { constructDataValidator } from 'features/common/form-validation-utils';
import { TFunction } from 'i18next';
import Joi from 'joi';

const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

const personalNumberSchema = Joi.string()
  .length(12)
  .pattern(/^[0-9]+$/);

const patientDetailsSchema = Joi.object({
  name: Joi.string().required(),
  dateOfBirth: Joi.string().required().pattern(dateRegex),
  sex: Joi.string().required(),
  personalNumber: personalNumberSchema.optional().allow(''),
});

const patientDiagnosesSchema = Joi.object({
  id: Joi.number(),
  disease: Joi.string().required(),
  ageAtDiagnosis: Joi.number().allow(null),
  ageIsApproximate: Joi.boolean(),
  treatedBy: Joi.string().allow(''),
  notes: Joi.string().allow(''),
});

export const validatePatientData =
  constructDataValidator<PatientDetails>(patientDetailsSchema);

const validateSingleDiagnosesData = constructDataValidator<Diagnose>(
  patientDiagnosesSchema,
);

export const validateDiagnoseData = (
  data: Diagnose[],
  dateOfBirth: string,
  t: TFunction,
) => {
  const result = data.map((diagnose) => {
    let { errorMessages = {}, valid } = validateSingleDiagnosesData(diagnose);

    const ageAtDiagnosis = Number(diagnose.ageAtDiagnosis);
    if (ageAtDiagnosis) {
      const patientAge = calculateAgeFromDateOfBirth(dateOfBirth);

      if (ageAtDiagnosis > patientAge) {
        valid = false;
        errorMessages.ageAtDiagnosis = t(
          'input-validation.number.value.maximum',
          {
            value: patientAge,
          },
        );
      }
    }

    return {
      errorMessages,
      valid,
    };
  });
  const valid = result.every((validationResult) => validationResult.valid);
  const errorMessages = result.map(
    (validationResult) => validationResult.errorMessages || {},
  );
  if (data.length === 0) {
    return {
      valid: false,
      errorMessages: [],
    };
  }
  return { valid, errorMessages };
};
