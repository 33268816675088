import { Button, Stack } from '@mui/material';
import { RadioInput } from 'components/inputs';
import { DrawerFormConfirmationData } from 'pedigree/features';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePedigreeContext } from '../pedigreeContext';
import Drawer from '../views/Drawer';
import { SelectionSiblingsForm } from '../views/SelectionSiblingsForm';

type option = 'yes' | 'no' | 'notSure';

export type NumberOfSiblings = {
  numberOfSiblings: number;
  numberOfMaleSiblings: number;
  numberOfFemaleSiblings: number;
};

type FormValue = {
  isIdenticalTwins: option | null;
} & NumberOfSiblings;

export type IdenticalOption = {
  label: string;
  value: option;
};

export const TwinSelectionFormController = () => {
  const {
    pedigree,
    selectedNodeId,
    setSelectedNodeId,
    setDrawerState,
    drawerState,
  } = usePedigreeContext();
  const { t } = useTranslation('pedigree');
  const { pedigreeNodeId, onClickConfirm } = drawerState || {};

  const [formValue, setFormValue] = useState<FormValue>({
    isIdenticalTwins: null,
    numberOfSiblings: 1,
    numberOfMaleSiblings: 0,
    numberOfFemaleSiblings: 0,
  });

  const [formError, setFormError] = useState({
    isIdenticalTwins: '',
    numberOfSiblings: '',
    numberOfFraternalSiblings: '',
  });

  const [hasExistingTwins, setHasExistingTwins] = useState<boolean>(false);

  const handleCloseForm = () => {
    setSelectedNodeId(null);
    setDrawerState(null);
  };

  const showFormError = ({
    fieldError,
  }: {
    fieldError: keyof typeof formError;
  }) => {
    setFormError((prevState) => ({
      ...prevState,
      [fieldError]: t('node-form-add-twin.error-message.required'),
    }));
  };

  const validateForm = (): boolean => {
    const {
      isIdenticalTwins,
      numberOfMaleSiblings,
      numberOfFemaleSiblings,
      numberOfSiblings,
    } = formValue;

    if (isIdenticalTwins === null) {
      showFormError({ fieldError: 'isIdenticalTwins' });
      return false;
    }

    if (isIdenticalTwins === 'yes' && numberOfSiblings === 0) {
      showFormError({ fieldError: 'numberOfSiblings' });
      return false;
    }

    const isFraternalTwins =
      isIdenticalTwins === 'no' || isIdenticalTwins === 'notSure';

    if (
      isFraternalTwins &&
      numberOfMaleSiblings === 0 &&
      numberOfFemaleSiblings === 0
    ) {
      showFormError({ fieldError: 'numberOfFraternalSiblings' });
      return false;
    }

    return true;
  };

  const onConfirmTwin = () => {
    const formIsInvalid = validateForm();
    if (!formIsInvalid) return;

    const {
      isIdenticalTwins,
      numberOfMaleSiblings,
      numberOfFemaleSiblings,
      numberOfSiblings,
    } = formValue;

    const action =
      isIdenticalTwins === 'yes' ? 'addIdenticalTwins' : 'addFraternalTwins';

    const nodeId = pedigreeNodeId || '';

    const params: DrawerFormConfirmationData =
      action === 'addIdenticalTwins'
        ? { action, pedigreeNodeId: nodeId, numberOfSiblings }
        : {
            action,
            pedigreeNodeId: nodeId,
            numberOfMaleSiblings,
            numberOfFemaleSiblings,
          };

    if (onClickConfirm) {
      onClickConfirm(params);
    }
    return handleCloseForm();
  };

  const handleIdenticalSiblingsChange = (value: option) => {
    setFormValue({
      isIdenticalTwins: value,
      numberOfSiblings: 1,
      numberOfMaleSiblings: 0,
      numberOfFemaleSiblings: 0,
    });

    setFormError({
      isIdenticalTwins: '',
      numberOfSiblings: '',
      numberOfFraternalSiblings: '',
    });
  };

  const handleNumberSiblings =
    (key: keyof NumberOfSiblings) => (value: string) => {
      const errorKey =
        key === 'numberOfMaleSiblings' || key === 'numberOfFemaleSiblings'
          ? 'numberOfFraternalSiblings'
          : key;

      setFormValue((prevState) => ({
        ...prevState,
        [key]: parseInt(value) || 0,
      }));

      setFormError((prevState) => ({
        ...prevState,
        [errorKey]: '',
      }));
    };

  const identicalOptions: IdenticalOption[] = [
    { label: t('node-form-add-twin.identical.options.yes'), value: 'yes' },
    { label: t('node-form-add-twin.identical.options.no'), value: 'no' },
    {
      label: t('node-form-add-twin.identical.options.not-sure'),
      value: 'notSure',
    },
  ];

  const siblingsFields = useMemo(
    () => [
      {
        label: t('node-form-add-twin.sibling-quantity.options.brothers'),
        value: formValue.numberOfMaleSiblings.toString(),
        setValue: handleNumberSiblings('numberOfMaleSiblings'),
      },
      {
        label: t('node-form-add-twin.sibling-quantity.options.sisters'),
        value: formValue.numberOfFemaleSiblings.toString(),
        setValue: handleNumberSiblings('numberOfFemaleSiblings'),
      },
    ],
    [formValue.numberOfMaleSiblings, formValue.numberOfFemaleSiblings, t],
  );

  const existingTwins = useCallback(() => {
    const selectedNode = pedigree.nodes.find(
      (node) => node.id === selectedNodeId,
    );

    if (selectedNode?.twinRelation?.type) {
      setHasExistingTwins(true);

      const isIdenticalTwins = selectedNode.twinRelation.type === 'identical';

      setFormValue((prevState) => ({
        ...prevState,
        isIdenticalTwins: isIdenticalTwins ? 'yes' : 'no',
      }));
    }
  }, [pedigree.nodes, selectedNodeId]);

  useEffect(() => {
    existingTwins();
  }, [existingTwins]);

  return (
    <Drawer
      title={t('node-form-add-twin.title')}
      isOpen={!!selectedNodeId}
      onClose={handleCloseForm}
      showCloseButton={false}
    >
      <Stack paddingLeft={3} paddingRight={3} paddingTop={2} spacing={3}>
        {!hasExistingTwins && (
          <RadioInput
            id="isIdenticalTwins"
            label={t('node-form-add-twin.identical.label')}
            options={identicalOptions}
            value={formValue.isIdenticalTwins || ''}
            onChange={(e) =>
              handleIdenticalSiblingsChange(e.target.value as option)
            }
            rowDirection={true}
            radioProps={{ sx: { marginRight: 6 } }}
            required
            error={formError.isIdenticalTwins}
          />
        )}
        {!!formValue.isIdenticalTwins && (
          <SelectionSiblingsForm
            isIdenticalTwins={formValue.isIdenticalTwins}
            numberOfSiblings={formValue.numberOfSiblings?.toString() || ''}
            fields={siblingsFields}
            handleNumberSiblings={handleNumberSiblings}
            error={formError}
          />
        )}
        <Stack direction="row" gap={3}>
          <Button variant="outlined" fullWidth onClick={handleCloseForm}>
            {t('node-form-add-twin.button.cancel')}
          </Button>
          <Button variant="contained" fullWidth onClick={onConfirmTwin}>
            {t('node-form-add-twin.button.add')}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
