import { useCallback, useEffect, useState } from 'react';

function useAuth(tokenKey: string) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [tokenIsLoading, setTokenIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    if (token) {
      setAccessToken(token);
    }
    setTokenIsLoading(false);
  }, [tokenKey]);

  const logout = useCallback((): void => {
    localStorage.removeItem(tokenKey);
    setAccessToken(null);
  }, [tokenKey]);

  const authenticate = useCallback(
    (token: string) => {
      try {
        localStorage.setItem(tokenKey, token);
        setAccessToken(token);
      } catch (error) {
        console.error('Failed to store token in localStorage:', error);
      }
    },
    [tokenKey],
  );

  return { logout, authenticate, accessToken, tokenIsLoading };
}

export default useAuth;
