import { CustomValidator, RJSFSchema, UiSchema } from '@rjsf/utils';
import { DynamicForm } from 'common/features/dynamicForm';
import { FieldErrorTemplate } from 'common/features/dynamicForm/templates/FieldErrorTemplate';

interface RelativeDiagnosisFormViewProps {
  onSubmit: (data: any) => void;
  onChange: (data: any) => void;
  customValidate: CustomValidator<any, RJSFSchema, any>;
  schema: RJSFSchema;
  uiSchema: UiSchema;
  formData: any;
}

const RelativeDiagnosisFormView = (props: RelativeDiagnosisFormViewProps) => {
  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: true,
      },
      norender: true,
    },
    ...props.uiSchema,
  };

  return (
    <DynamicForm
      schema={props.schema}
      uiSchema={uiSchema}
      onSubmit={props.onSubmit}
      onChange={props.onChange}
      templates={{ FieldErrorTemplate }}
      customValidate={props.customValidate}
      formData={props.formData}
    />
  );
};
export default RelativeDiagnosisFormView;
