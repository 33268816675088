import { Stack, Typography } from '@mui/material';
import { PatientDetails } from 'api/pedigreeCase/staff-pedigree-case-service';
import { SectionBox } from 'components/common';
import { useTranslation } from 'react-i18next';
import PatientDetailsFormView, {
  PatientDataErrors,
} from '../views/PatientDetailsFormView';

interface PatientFormControllerProps {
  patientData: PatientDetails;
  disabled?: boolean;
  errors: PatientDataErrors;
  setPatientData: (data: PatientDetails) => void;
  setErrors: (errors: PatientDataErrors) => void;
}

function PatientFormController(props: PatientFormControllerProps) {
  const { patientData, disabled, errors, setPatientData, setErrors } =
    props || {};
  const { t } = useTranslation(['pedigreeCaseNewPatient']);

  const handleOnchange = (key: keyof PatientDetails, value: string) => {
    setPatientData({ ...patientData, [key]: value });
    setErrors({ ...errors, [key]: '' });
  };

  return (
    <SectionBox
      sx={{
        p: 4,
      }}
    >
      <Stack direction="column" spacing={3}>
        <Typography variant="h2">{t(`section2.title`)}</Typography>
        <PatientDetailsFormView
          patientData={patientData}
          errors={errors}
          handleOnchange={handleOnchange}
          disabled={disabled}
        />
      </Stack>
    </SectionBox>
  );
}

export default PatientFormController;
