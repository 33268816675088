import { Button, Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface ConsentRequestNotFoundProps {
  consentRequestId: string;
}

function ConsentRequestNotFound(props: ConsentRequestNotFoundProps) {
  const { t } = useTranslation('all');
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        textAlign: 'center',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h4" gutterBottom>
          {t('consent.not-found')}
        </Typography>
        <Typography variant="body1">
          {t('consent.not-found.request', {
            consentRequestId: props.consentRequestId,
          })}
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          {t('consent-management.new-patient.confirmation.go-to-dashboard')}
        </Button>
      </Stack>
    </Container>
  );
}

export default ConsentRequestNotFound;
