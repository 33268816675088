import { BaseService } from '../base-service';

export class PdfService extends BaseService {
  getPdfData = async (pdfLocationUrl: string): Promise<string> => {
    const response = await this.axiosInstance.get(pdfLocationUrl);

    const pdfData = base64ToBlob(response.data);
    const pdfUrl = URL.createObjectURL(pdfData);
    return pdfUrl;
  };
}

function base64ToBlob(base64: string) {
  const byteString = atob(base64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
}
