import React from 'react';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from '../serviceProviderFactory';
import { AuthService } from './auth-service';

const AuthServiceContext = React.createContext<{
  service: AuthService | null;
}>({ service: null });

export const AuthServiceProvider = serviceProviderFactory(
  AuthService,
  AuthServiceContext,
);

export const useAuthService = serviceHookFactory(
  AuthService,
  AuthServiceContext,
);
