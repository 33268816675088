import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { validateRelativeData } from '../form-validation';
import {
  NumberOfRelativesFormData,
  RelativeDisease,
  RelativeDiseaseDataError,
} from '../types';
import {
  NewPedigreeFormView,
  RelativeDiseaseField,
} from '../views/NewPedigreeFormView';

interface NewPedigreeFormControllerProps {
  heading: {
    title: string;
    description: string;
    info?: string;
    showIcon: boolean;
  };
  showRelativeDiseaseField?: boolean;
  createNewPedigree: (formData: NumberOfRelativesFormData) => Promise<void>;
}

export const NewPedigreeFormController = (
  props: NewPedigreeFormControllerProps,
) => {
  const { t } = useTranslation(['relationship', 'pedigree']);
  const navigate = useNavigate();

  const submitMutation = useMutation(
    (formData: NumberOfRelativesFormData) => props.createNewPedigree(formData),
    {
      onSuccess: () => navigate(0),
    },
  );

  const { numberOfRelatives, numberOfRelativesFields } =
    useNumberOfRelativeFields();

  const [relativeDisease, setRelativeDisease] = useState<RelativeDisease>({
    relativeHasDisease: '',
  });

  const [errorMessages, setErrorMessages] = useState<RelativeDiseaseDataError>({
    relativeHasDisease: '',
  });

  const relativeDiseaseField = useMemo<RelativeDiseaseField>(() => {
    const options = [
      {
        label: t('pedigree:common.options.yes'),
        value: 'yes',
      },
      {
        label: t('pedigree:common.options.no'),
        value: 'no',
      },
      {
        label: t('pedigree:common.options.notSure'),
        value: 'notSure',
      },
    ];
    return {
      options,
      value: relativeDisease.relativeHasDisease,
      setValue: (value: string) =>
        setRelativeDisease({ relativeHasDisease: value }),
    };
  }, [relativeDisease, t]);

  const handleSubmit = () => {
    if (props.showRelativeDiseaseField) {
      const { valid: relativeDiseaseDataIsValid, errorMessages } =
        validateRelativeData(relativeDisease);
      if (relativeDiseaseDataIsValid && errorMessages === undefined) {
        localStorage.setItem(
          'relativeDisease',
          relativeDisease.relativeHasDisease,
        );
        return submitMutation.mutate(numberOfRelatives);
      }

      setErrorMessages(errorMessages || {});
      return;
    }
    submitMutation.mutate(numberOfRelatives);
  };

  return (
    <NewPedigreeFormView
      heading={props.heading}
      fields={numberOfRelativesFields}
      relativeDiseaseField={relativeDiseaseField}
      showRelativeDiseaseField={props.showRelativeDiseaseField}
      errors={errorMessages}
      onSubmit={handleSubmit}
      isSubmitting={submitMutation.isLoading}
    />
  );
};

export const useNumberOfRelativeFields = () => {
  const { t } = useTranslation(['relationship', 'pedigree']);

  const [numberOfRelatives, setNumberOfRelatives] =
    useState<NumberOfRelativesFormData>({
      brothers: 0,
      sisters: 0,
      sons: 0,
      daughters: 0,
      paternalUncles: 0,
      paternalAunts: 0,
      maternalUncles: 0,
      maternalAunts: 0,
    });

  const handleNumberOfRelativeFieldChangeFactory =
    (key: keyof NumberOfRelativesFormData) => (value: string) => {
      const parsedValue = parseInt(value);
      if (isNaN(parsedValue) && value !== '') {
        return;
      }

      setNumberOfRelatives((prev) => ({
        ...prev,
        [key]: parsedValue,
      }));
    };

  const fields = useMemo<
    {
      label: string;
      value: string;
      setValue: (value: string) => void;
    }[]
  >(() => {
    return [
      {
        label: t('brothers'),
        value: numberOfRelatives.brothers.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('brothers'),
      },
      {
        label: t('sisters'),
        value: numberOfRelatives.sisters.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('sisters'),
      },
      {
        label: t('sons'),
        value: numberOfRelatives.sons.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('sons'),
      },
      {
        label: t('daughters'),
        value: numberOfRelatives.daughters.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('daughters'),
      },
      {
        label: t('paternal-uncles'),
        value: numberOfRelatives.paternalUncles.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('paternalUncles'),
      },
      {
        label: t('paternal-aunts'),
        value: numberOfRelatives.paternalAunts.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('paternalAunts'),
      },
      {
        label: t('maternal-uncles'),
        value: numberOfRelatives.maternalUncles.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('maternalUncles'),
      },
      {
        label: t('maternal-aunts'),
        value: numberOfRelatives.maternalAunts.toString(),
        setValue: handleNumberOfRelativeFieldChangeFactory('maternalAunts'),
      },
    ];
  }, [numberOfRelatives, t]);

  return {
    numberOfRelatives,
    numberOfRelativesFields: fields,
  };
};
