import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { BaseService } from 'api/base-service';
import { PedigreeCaseStatus } from 'api/pedigreeCase/staff-pedigree-case-service';
import { NumberOfRelativesFormData } from 'common/features/newPedigreeForm/types';
import {
  CreateChildNodeParams,
  CreateHalfSiblingNodeParams,
  CreateParentsNodeParams,
  CreatePartnerNodeParams,
  CreateSiblingNodeParams,
  CreateTwinNodeParams,
} from 'pedigree/features/common/generate-create-node-params';
import { PedigreeNode } from 'pedigree/features/common/types';

export type Pedigree = {
  id: string;
  nodes: PedigreeNode[];
};

export type PedigreeSaveProgress = {
  pedigreeCaseUrl: string;
  emailSent: boolean;
};

type CustomizableFormData = {
  [key: string]: string | number | boolean | CustomizableFormData;
};

type QuestionnaireForm = {
  jsonSchema: RJSFSchema;
  uiSchema: UiSchema;
  formData: CustomizableFormData;
};

export type CreateNodeParams =
  | CreateParentsNodeParams
  | CreatePartnerNodeParams
  | CreateSiblingNodeParams
  | CreateChildNodeParams
  | CreateHalfSiblingNodeParams
  | CreateTwinNodeParams;

type PatientPedigreeCase = {
  id: string;
  status: PedigreeCaseStatus;
  pedigreeId?: string;
};

type PatientPedigreeCaseStatus = 'active' | 'inactive' | 'submitted';

type RequestingEntity = {
  name: string;
  city: string;
  phone: string;
};

type PedigreeCaseInformation = {
  patientName: string;
  pedigreeCaseStatus: PatientPedigreeCaseStatus;
  requestingEntity: RequestingEntity;
};

export class MyFamilyTreeSubmissionService extends BaseService {
  getPedigreeCase = async (
    pedigreeCaseId: string,
  ): Promise<PatientPedigreeCase> => {
    const response = await this.axiosInstance.get(
      `/patients/pedigree-cases/${pedigreeCaseId}`,
    );
    return response.data;
  };

  getPedigreeCaseInformation = async (
    pedigreeCaseId: string,
  ): Promise<PedigreeCaseInformation> => {
    const response = await this.axiosInstance.get(
      '/patients/pedigree-cases/' + pedigreeCaseId + '/case-information',
    );
    return response.data;
  };

  createNewPedigree = async (
    pedigreeCaseId: string,
    formData: NumberOfRelativesFormData,
  ) => {
    return this.axiosInstance
      .put(`/patients/pedigree-cases/${pedigreeCaseId}/pedigree`, formData)
      .then((response) => response.data);
  };

  getPedigree = async (pedigreeId: string): Promise<Pedigree> => {
    const response = await this.axiosInstance.get(
      `/patients/pedigrees/${pedigreeId}`,
    );
    return response.data;
  };

  deletePedigreeNode = async (
    pedigreeId: string,
    nodeId: number,
  ): Promise<Pedigree> => {
    const response = await this.axiosInstance.delete(
      `/patients/pedigrees/${pedigreeId}/nodes/${nodeId}`,
    );
    return response.data;
  };

  getQuestionnaireForm = async (
    pedigreeCaseId: string,
  ): Promise<QuestionnaireForm> => {
    const response = await this.axiosInstance.get(
      `/patients/pedigree-cases/${pedigreeCaseId}/questionnaire-form`,
    );
    return response.data;
  };

  submitQuestionnaireForm = async (
    pedigreeCaseId: string,
    formData: CustomizableFormData,
  ) => {
    const response = await this.axiosInstance.put(
      `/patients/pedigree-cases/${pedigreeCaseId}/questionnaire-form`,
      formData,
    );
    return response.data;
  };

  addNewPedigreeNode = async (
    pedigreeId: string,
    nodeParams: CreateNodeParams,
  ): Promise<Pedigree> => {
    return this.axiosInstance
      .post(`/patients/pedigrees/${pedigreeId}/nodes/`, nodeParams)
      .then((response) => response.data);
  };

  submitPedigree = async (pedigreeCaseId: string) => {
    return this.axiosInstance
      .put(`/patients/pedigree-cases/${pedigreeCaseId}/submit`)
      .then((response) => response.data);
  };

  savePedigreeProgress = async (
    pedigreeCaseId: string,
  ): Promise<PedigreeSaveProgress> => {
    return this.axiosInstance
      .put(`/patients/pedigree-cases/${pedigreeCaseId}/progress`)
      .then((response) => response.data);
  };

  sendRelativesWithCancerHistory = async (
    pedigreeId: string,
    nodeList: string[],
  ) => {
    return this.axiosInstance
      .put(`/patients/pedigrees/${pedigreeId}/cancer-history`, nodeList)
      .then((response) => response.data);
  };

  getRelativeForm = async (
    pedigreeId: string,
    pedigreeNodeId: string,
  ): Promise<QuestionnaireForm> => {
    const response = await this.axiosInstance.get(
      `/patients/pedigrees/${pedigreeId}/nodes/${pedigreeNodeId}/details-form`,
    );
    return response.data;
  };

  submitRelativeForm = async (
    pedigreeId: string,
    pedigreeNodeId: string,
    formData: CustomizableFormData,
  ) => {
    const response = await this.axiosInstance.put(
      `/patients/pedigrees/${pedigreeId}/nodes/${pedigreeNodeId}/details-form`,
      formData,
    );
    return response.data;
  };
}
