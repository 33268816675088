import { PedigreeRendererType } from 'pedigree/features';
import { VisibleNodeProperties } from 'pedigree/features/pedigreeProfessional/hooks/usePedigreeFilter';
import { useMemo } from 'react';
import { PedigreeNode } from '../types';
import {
  getNodeInfoHeight,
  getNodeInfoTextBoxOptions,
} from '../utils/node-info-utils';
import { createPedigreeLayout } from '../utils/pedigree-layout';

const NODE_SIZE_MAP: Record<
  PedigreeRendererType,
  { width: number; height: number }
> = {
  patient: { width: 160, height: 152 },
  professionalReadonly: { width: 60, height: 60 },
  professionalEditable: { width: 60, height: 60 },
};

const SPACE_BETWEEN_NODES_MAP: Record<
  PedigreeRendererType,
  { horizontal: number; vertical: number }
> = {
  patient: { horizontal: 200, vertical: 100 },
  professionalReadonly: { horizontal: 92, vertical: 120 },
  professionalEditable: { horizontal: 92, vertical: 120 },
};

export const getNodeSize = (pedigreeType: PedigreeRendererType) =>
  NODE_SIZE_MAP[pedigreeType];

interface useLayoutProps {
  pedigreeNodes: PedigreeNode[];
  pedigreeType: PedigreeRendererType;
  visibleNodeInfoProperties?: VisibleNodeProperties;
}

export const usePedigreeLayout = (props: useLayoutProps) => {
  const nodeSize = getNodeSize(props.pedigreeType);
  const spaceBetweenNodes = SPACE_BETWEEN_NODES_MAP[props.pedigreeType];

  const layout = useMemo(() => {
    if (!props.pedigreeNodes?.length) return;
    return createPedigreeLayout(
      props.pedigreeNodes.map((node) => {
        const nodeTextHeight = getNodeInfoHeight(
          node,
          getNodeInfoTextBoxOptions(nodeSize.width),
          props.visibleNodeInfoProperties,
        );
        return {
          ...node,
          label: {
            width: nodeSize.width,
            height: nodeTextHeight,
          },
        };
      }),
      {
        node: { width: nodeSize.width, height: nodeSize.height },
        minGap: spaceBetweenNodes,
        sibshipGap: 26,
        sibshipGapWithPartnershipArcs: 30,
        overlappingSibshipGapOffset: 10,
        partnershipArc: { startOffset: 18, endOffset: 18 },
        nodeLabel: { marginBottom: 24 },
      },
    );
  }, [
    props.pedigreeNodes,
    props.visibleNodeInfoProperties,
    nodeSize,
    spaceBetweenNodes,
  ]);

  const layoutSize = useMemo(
    () => ({
      width: layout?.width || 0,
      height: layout?.height || 0,
    }),
    [layout],
  );

  return {
    layout,
    layoutSize,
    nodeSize,
    spaceBetweenNodes,
  };
};
