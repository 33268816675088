import { Stack, Typography } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import {
  calculateAgeFromDateOfBirth,
  formatPersonalNumber,
  isoToShortDate,
} from 'common/utils';
import { SectionBox } from 'components/common';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import PatientOverviewContactDetails from './PatientOverviewContactDetails';
import PatientOverviewDiagnoses from './PatientOverviewDiagnoses';

interface PatientOverviewPanelProps {
  pedigreeCaseId: string;
}

const PatientOverviewPanel = (props: PatientOverviewPanelProps) => {
  const { t, i18n } = useTranslation('pedigreeCasePatientOverview');
  const { pedigreeCaseId } = props;

  const service = useStaffPedigreeCaseService();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', pedigreeCaseId],
    () => service.getPedigreeCase(pedigreeCaseId),
  );

  if (isLoading) {
    return null;
  }

  if (!pedigreeCase) {
    return (
      <>{t('error.no-pedigree-case-data-found-for-id', { pedigreeCaseId })}</>
    );
  }

  const localizedSex =
    pedigreeCase.patientDetails.sex === 'male'
      ? t('sex.male')
      : t('sex.female');

  const localizedDateOfBirth = isoToShortDate(
    pedigreeCase.patientDetails.dateOfBirth,
    i18n,
  );

  const age = calculateAgeFromDateOfBirth(
    pedigreeCase.patientDetails.dateOfBirth,
  ).toString();

  return (
    <SectionBox>
      <Stack gap={3} width={400}>
        <Typography variant="h4">{pedigreeCase.patientDetails.name}</Typography>
        <PatientOverviewItem label={t('sex.label')} value={localizedSex} />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <PatientOverviewItem
            label={t('personal-number')}
            value={formatPersonalNumber(
              pedigreeCase.patientDetails.personalNumber,
            )}
            direction="column"
          />
          <PatientOverviewItem
            label={t('date-of-birth')}
            value={localizedDateOfBirth}
            direction="column"
          />
          <PatientOverviewItem
            label={t('age')}
            value={age}
            direction="column"
          />
        </Stack>
        <PatientOverviewDiagnoses diagnoses={pedigreeCase.diagnoses} />
        {pedigreeCase.purpose.length > 0 && (
          <PatientOverviewItem
            label={t('purpose-of-investigation')}
            value={pedigreeCase.purpose
              .map((purpose) => purpose.localizedName)
              .join(', ')}
            direction="column"
          />
        )}
        <PatientOverviewContactDetails pedigreeCaseId={pedigreeCaseId} />
      </Stack>
    </SectionBox>
  );
};

interface PatientOverviewItemProps {
  label: string;
  value: string;
  direction?: 'row' | 'column';
}

export function PatientOverviewItem(props: PatientOverviewItemProps) {
  const labelColor = 'common.neutral.600';
  if (props.direction === 'column') {
    return (
      <Stack gap={0.5}>
        <Typography
          color={labelColor}
          variant="body1"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {props.label}
        </Typography>
        <Typography variant="body1">{props.value}</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="row" gap={1}>
      <Typography color={labelColor} variant="body1">
        {props.label}
      </Typography>
      <Typography variant="body1">{props.value}</Typography>
    </Stack>
  );
}

export default PatientOverviewPanel;
