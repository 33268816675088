import { Box, Chip } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { Relative } from 'api/consentManagement/consent-management-service';
import { formatPersonalNumber } from 'common/utils';
import ListConsentEmptyState from 'components/common/ListConsentEmptyState';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import {
  CollapsibleRowType,
  TableCellWithCollapsedBox,
} from 'components/tables/CollapsibleRow';
import ConsentStatusCell from 'components/tables/ConsentStatusCell';
import TableWithCollapsibleRows from 'components/tables/TableWithCollapsibleRows';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useConsentActions from '../hooks/useConsentActions';
import useSendReminderClickHandler from '../hooks/useSendReminderHandle';
import { getConsentDate } from '../utils';
import ConsentActionButtonsView from '../views/ConsentActionButtonsView';
import TableRowCellWithToggleButtonView from '../views/TableRowCellWithToggleButtonView';
import CompleteConsentRequestController from './CompleteConsentRequestController';

function ConsentUpdatesListController() {
  const service = useConsentManagementService();
  const { t } = useTranslation(['all']);
  const navigate = useNavigate();
  const { getSendReminderClickHandler } = useSendReminderClickHandler();
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const [consentRequestIdToComplete, setConsentRequestIdToComplete] =
    React.useState<string | null>(null);
  const { data, isLoading: isListConsentsLoading } = useQuery(
    'getUpdateListConsents',
    service.listConsentUpdates,
  );
  const { mapConsentTableRow, isMutating } = useConsentTableMapper();
  const headings = [
    '',
    t('consent-request-list-item.table.heading.patient-name'),
    t('consent-request-list-item.table.heading.personal-number'),
    t('consent-request-list-item.content.table-head.relative-name'),
    t('consent-request-list-item.content.table-head.consent-status'),
    t('consent-request-list-item.table.heading.date'),
    t('consent-request-list-item.content.table-head.actions'),
  ];

  const handleCompleteClick = (consentRequestId: string) => {
    setConsentRequestIdToComplete(consentRequestId);
  };

  const rows: CollapsibleRowType[] =
    data?.items.map((consentRequest, idx) => {
      const isOpen = openRow === idx;

      const handleSendReminderClick = getSendReminderClickHandler({
        consentRequestId: consentRequest.consentRequestId,
        completedAt: null,
        consents: consentRequest.relatives.map((relative) => ({
          consentStatus: relative.consentStatus,
        })),
      });

      const consentRelativeItems = consentRequest.relatives.map((consent) =>
        mapConsentTableRow(consent),
      );
      const firstConsentRelativeItem = consentRelativeItems[0];
      const restConsentRelativeItems = consentRelativeItems.slice(1);
      return {
        cells: [
          <>
            {consentRequest.new && (
              <Chip
                label={t('consent-list-table-row-cell-chip.new-label')}
                sx={{
                  backgroundColor: 'common.brand.lightGreen',
                }}
              />
            )}
          </>,
          <TableRowCellWithToggleButtonView
            isOpenRow={isOpen}
            idx={idx}
            setOpenRow={setOpenRow}
            name={consentRequest.patientName}
          />,
          formatPersonalNumber(consentRequest.patientPersonalNumber),
          firstConsentRelativeItem.name,
          firstConsentRelativeItem.consentStatus,
          firstConsentRelativeItem.date,
          firstConsentRelativeItem.consentActions,
        ],
        hiddenRows: restConsentRelativeItems.map((relative) => {
          return {
            cells: [
              <TableCellWithCollapsedBox colSpan={3} key={1} />,
              <TableCellWithCollapsedBox key={2}>
                {relative.name}
              </TableCellWithCollapsedBox>,
              <TableCellWithCollapsedBox key={3}>
                {relative.consentStatus}
              </TableCellWithCollapsedBox>,
              <TableCellWithCollapsedBox key={4}>
                {relative.date}
              </TableCellWithCollapsedBox>,
              <TableCellWithCollapsedBox key={5}>
                {relative.consentActions}
              </TableCellWithCollapsedBox>,
            ],
          };
        }),
        actionButtons: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ConsentActionButtonsView
              onAddRelativeClick={() =>
                navigate(`${consentRequest.consentRequestId}/add-relative/`)
              }
              onCompleteClick={() =>
                handleCompleteClick(consentRequest.consentRequestId)
              }
              onSendReminderClick={handleSendReminderClick}
            />
          </Box>
        ),
        isOpen: openRow === idx,
      };
    }) || [];

  const isLoading = isListConsentsLoading || isMutating;

  return (
    <>
      {isLoading ? (
        <LoadingBackdrop />
      ) : data?.items.length ? (
        <TableWithCollapsibleRows headings={headings} rows={rows} />
      ) : (
        <ListConsentEmptyState
          title={t('consent-update-empty-state.title')}
          description={t('consent-update-empty-state.description')}
        />
      )}
      <CompleteConsentRequestController
        consentRequestIdToComplete={consentRequestIdToComplete}
        setConsentRequestIdToComplete={setConsentRequestIdToComplete}
      />
    </>
  );
}

export default ConsentUpdatesListController;

function useConsentTableMapper() {
  const { getConsentActions, isMutating } = useConsentActions();
  const { i18n } = useTranslation();

  const mapConsentTableRow = (consent: Relative) => {
    return {
      name: consent.name,
      consentStatus: (
        <ConsentStatusCell consentStatus={consent.consentStatus} />
      ),
      date: getConsentDate(consent, false, i18n),
      consentActions: getConsentActions(consent),
    };
  };

  return { mapConsentTableRow, isMutating };
}
