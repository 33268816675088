import Konva from 'konva';
import { DateTime } from 'luxon';
import { VisibleNodeProperties } from 'pedigree/features/pedigreeProfessional/hooks/usePedigreeFilter';
import { Diagnose, GeneticTest, PedigreeNode } from '../types';

export const getNodeInfoTextBoxOptions = (nodeWidth: number) => ({
  width: 1.7 * nodeWidth,
  fontSize: 12,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 1.33,
});

export function getNodeInfoHeight(
  pedigreeNode: PedigreeNode,
  textBoxOptions: {
    width: number;
    fontSize: number;
    fontFamily: string;
    lineHeight: number;
  },
  visibleNodeInfoProperties?: VisibleNodeProperties,
) {
  if (!visibleNodeInfoProperties) return 0;
  const text = getNodeInfoText(pedigreeNode, visibleNodeInfoProperties);
  if (!text) return 0;
  const textElement = new Konva.Text({
    text,
    ...textBoxOptions,
  });

  const textHeight = textElement.getClientRect().height;
  return textHeight;
}

export function getNodeInfoText(
  pedigreeNode: {
    name?: string;
    diagnoses?: Diagnose[];
    geneticTest?: GeneticTest;
    yearOfBirth?: number;
    ageAtDeath?: number;
    deceased?: boolean;
  },
  visibleNodeInfoProperties: VisibleNodeProperties,
) {
  const { name, diagnoses, yearOfBirth, ageAtDeath, deceased, geneticTest } =
    pedigreeNode;
  const ageAndYearOrAgeAtDeath = getAgeAndYearOrAgeAtDeath(
    deceased || false,
    yearOfBirth,
    ageAtDeath,
  );
  const nameIsVisible = name && visibleNodeInfoProperties.name;
  const ageIsVisible = ageAndYearOrAgeAtDeath && visibleNodeInfoProperties.age;
  const diagnosesAreVisible = diagnoses && visibleNodeInfoProperties.diagnosis;
  const variantsAreVisible =
    geneticTest &&
    geneticTest.variants.length > 0 &&
    visibleNodeInfoProperties.variants;

  const rows: string[] = [];

  let nameAgeRow: string[] = [];
  if (nameIsVisible) nameAgeRow.push(name);
  if (ageIsVisible && deceased) nameAgeRow.push(ageAndYearOrAgeAtDeath);
  if (nameIsVisible && ageIsVisible && !deceased) {
    nameAgeRow[0] = `${name},`;
  }
  if (nameAgeRow.length > 0) rows.push(nameAgeRow.join(', '));
  if (ageIsVisible && !deceased) rows.push(ageAndYearOrAgeAtDeath);
  if (variantsAreVisible) rows.push(geneticTest.variants.join(', '));
  if (diagnosesAreVisible) rows.push(createDiagnosesText(diagnoses));

  return rows.join('\n');
}

function createDiagnosesText(diagnoses: Diagnose[]) {
  return diagnoses
    .map((diagnose) => {
      const ageOfOnsetString = diagnose.ageOfOnset
        ? `${diagnose.ageOfOnset}`
        : null;
      if (ageOfOnsetString) {
        return `${diagnose.diseaseName}, ${ageOfOnsetString}`;
      }
      return diagnose.diseaseName;
    })
    .join('\n');
}

function getAgeAndYearOrAgeAtDeath(
  deceased: boolean,
  yearOfBirth?: number,
  ageAtDeath?: number,
) {
  if (!deceased && yearOfBirth) {
    const age = (DateTime.now().year - yearOfBirth).toString();
    return `${age} (${yearOfBirth})`;
  }
  if (ageAtDeath) {
    return `d.${ageAtDeath}`;
  }
}
