import SubHeaderBanner from 'components/layout/SubHeaderBanner';
import Layout from 'components/layout/index';
import ConsentLookup from 'pages/consentSubmission/ConsentLookup';
import ConsentSubmission from 'pages/consentSubmission/ConsentSubmission';
import { Outlet, Route, Routes } from 'react-router-dom';

function ConsentSubmissionRouter() {
  return (
    <Routes>
      <Route
        element={
          <Layout maxWidth="sm">
            <SubHeaderBanner title="eSamtycke" maxWidth="sm" />
            <Outlet />
          </Layout>
        }
      >
        <Route path="/:consentRequestId" element={<ConsentLookup />} />
        <Route
          path="/:consentRequestId/consents/:consentId"
          element={<ConsentSubmission />}
        />
      </Route>
    </Routes>
  );
}

export default ConsentSubmissionRouter;
