import { DrawerFormConfirmationData, DrawerState } from 'pedigree/features';
import { AddNewPedigreeNode, Pedigree, PedigreeNode } from '../types';
import { findNodeById } from '../utils/helpers';
import { DialogContentType } from '../views/Dialog';
import { SnackbarMessageType } from '../views/SnackbarMessage';
import { PedigreeNodeAddAction, useAddNode } from './useAddNode';
import { PedigreeNodeAction } from './useHandleNodeAction';

interface useHandleAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  setSnackbarMessage: (content: SnackbarMessageType | null) => void;
  addNewPedigreeNode: AddNewPedigreeNode;
  setDrawerState: (info: DrawerState) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  setPedigree: (Pedigree: Pedigree | null) => void;
}

const useHandleAddNode = (props: useHandleAddNodeProps) => {
  const {
    pedigree,
    setDialogContent,
    setSnackbarMessage,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
    addNewPedigreeNode,
  } = props;

  const { handleCreateNewNodeMember } = useAddNode({
    pedigree,
    setDialogContent,
    setSnackbarMessage,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
    addNewPedigreeNode,
  });

  const openDrawerSelectionForm = ({
    action,
    pedigreeNodeId,
    type,
  }: {
    action: PedigreeNodeAction;
    pedigreeNodeId: string;
    type: 'parentSelection' | 'twinSelection';
  }) => {
    props.setDrawerState({
      type,
      pedigreeNodeId,
      action,
      onClickConfirm: (data: DrawerFormConfirmationData) => {
        handleCreateNewNodeMember(data);
      },
    });
    props.setSelectedNodeId(pedigreeNodeId);
  };

  const handleAddHalfSibling = (
    action: PedigreeNodeAddAction,
    pedigreeNode: PedigreeNode,
    nodes: PedigreeNode[],
  ) => {
    const hasBothParents = Boolean(
      pedigreeNode.fatherId && pedigreeNode.motherId,
    );
    const parentId = pedigreeNode.fatherId || pedigreeNode.motherId;

    if (parentId && !hasBothParents) {
      const parentNode = findNodeById(nodes, parentId);
      const parentHasPartner = Boolean(parentNode?.partnerIds?.length);

      if (parentHasPartner) {
        return openDrawerSelectionForm({
          action,
          pedigreeNodeId: pedigreeNode.id,
          type: 'parentSelection',
        });
      }

      return handleCreateNewNodeMember({
        action,
        pedigreeNodeId: pedigreeNode.id,
        sharedRelationshipType: pedigreeNode.fatherId ? 'father' : 'mother',
      });
    }

    return openDrawerSelectionForm({
      action,
      pedigreeNodeId: pedigreeNode.id,
      type: 'parentSelection',
    });
  };

  const handleAddNodeAction = (
    action: Partial<PedigreeNodeAction>,
    pedigreeNodeId: string,
  ) => {
    if (!props.pedigree) return;

    const pedigreeNode = findNodeById(props.pedigree.nodes, pedigreeNodeId);
    if (!pedigreeNode) return;

    const isChildAction = action === 'addSon' || action === 'addDaughter';
    const isHalfSiblingAction =
      action === 'addHalfBrother' || action === 'addHalfSister';
    const isAddChildToNodeWithMultiplePartners =
      isChildAction && pedigreeNode.partnerIds.length > 1;

    const openDrawerForSelection = (
      type: 'parentSelection' | 'twinSelection',
    ) => {
      return openDrawerSelectionForm({ action, pedigreeNodeId, type });
    };

    if (isAddChildToNodeWithMultiplePartners) {
      return openDrawerForSelection('parentSelection');
    }

    if (isHalfSiblingAction) {
      return handleAddHalfSibling(action, pedigreeNode, props.pedigree.nodes);
    }

    if (action === 'addTwin') {
      return openDrawerForSelection('twinSelection');
    }

    handleCreateNewNodeMember({ action, pedigreeNodeId });
  };

  return { handleAddNodeAction };
};

export default useHandleAddNode;
