import {
  Button,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import {
  Diagnose,
  LocalizedDisease,
  PatientDetails,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { PersonalNumberValidationResponse } from 'api/types';
import {
  validateDiagnoseData,
  validatePatientData,
} from 'common/features/pedigreeCase/newPatient/utils/form-validation';
import useDialog from 'common/hooks/useDialog';
import { CircularLoading } from 'components/common/CircularLoading';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import PersonalNumberFormController from 'features/consentManagement/newPatient/controllers/PersonalNumberFormController';
import { Sex } from 'pedigree/features/common/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DiagnosisFormData,
  DiagnosisItemErrors,
} from '../../../diagnosisForm/types';
import { NewPatientFormData } from '../../types';
import { PatientDataErrors } from '../views/PatientDetailsFormView';
import PatientDiagnosisFormController from './PatientDiagnosisFormController';
import PatientFormController from './PatientFormController';

export interface NewPatientFormService {
  getDiseaseOptionsForDiagnose: () => Promise<LocalizedDisease[]>;
  validatePersonalNumber: (
    personalNumber: string,
  ) => Promise<PersonalNumberValidationResponse>;
}

interface NewPatientFormControllerProps {
  onContinueClick: (data: NewPatientFormData) => void;
  onCancel: () => void;
  initialData?: NewPatientFormData;
  service: NewPatientFormService;
}

function NewPatientFormController(props: NewPatientFormControllerProps) {
  const { initialData, onContinueClick, service } = props || {};

  const theme = useTheme();
  const { t } = useTranslation(['pedigreeCaseNewPatient']);
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const [isLoading, setIsLoading] = useState(false);

  const [patientDetails, setPatientData] =
    useState<PatientDetails>(initialPatientState);
  const [patientDataErrorMessages, setPatientDataErrorMessages] =
    useState<PatientDataErrors>(initialPatientState);

  const [diagnoses, setDiagnosesData] = useState<DiagnosisFormData[]>([]);
  const [diagnosesDataErrorMessages, setDiagnosesDataErrorMessages] = useState<
    DiagnosisItemErrors[]
  >([initialDiagnosesErrorState]);

  const dialogContent = {
    title: t('cancel-confirmation.title'),
    content: t('cancel-confirmation.text'),
    textConfirm: t('cancel-confirmation.exit-editing'),
    textCancel: t('cancel-confirmation.continue-editing'),
  };

  const { dialog: cancelConfirmationDialog, openDialog } = useDialog({
    dialogContent,
    onDialogConfirmClick: props.onCancel,
  });

  const handleCancelClick = () => {
    const isDirty = (value: string) => value !== '';
    const patientDetailsData = Object.values(patientDetails).some(isDirty);
    const diagnosesData = diagnoses.some(
      (diagnose) =>
        diagnose.disease !== '' ||
        diagnose.ageAtDiagnosis !== null ||
        diagnose.treatedBy !== '' ||
        diagnose.ageIsApproximate ||
        diagnose.notes !== '',
    );
    const contentHasChanged = patientDetailsData || diagnosesData;
    const onCancelClick = contentHasChanged ? openDialog() : props.onCancel();
    return onCancelClick;
  };

  const handleFormErrors = (
    patientErrorMessages?: PatientDataErrors,
    diagnosesErrorMessages?: DiagnosisItemErrors[],
  ) => {
    if (patientErrorMessages) {
      setPatientDataErrorMessages(patientErrorMessages);
    }
    if (diagnosesErrorMessages) {
      const errorMessages = diagnosesErrorMessages.filter(
        (error) => error !== undefined,
      );
      setDiagnosesDataErrorMessages(errorMessages);
    }
  };

  const handleOnContinueClick = () => {
    const { valid: patientDataValid, errorMessages: patientDataErrorMessages } =
      validatePatientData(patientDetails);

    const {
      valid: diagnosesDataValid,
      errorMessages: diagnosesDataErrorMessages,
    } = validateDiagnoseData(diagnoses, patientDetails.dateOfBirth, t);

    if (patientDataValid && (diagnosesDataValid || !diagnoses.length)) {
      const newPatientFormData = {
        patientDetails,
        diagnoses: diagnoses
          ?.filter(({ disease }) => Boolean(disease))
          ?.map(({ id, ...diagnoses }) => diagnoses),
      };

      onContinueClick(newPatientFormData);
      return;
    }
    handleFormErrors(patientDataErrorMessages, diagnosesDataErrorMessages);
    return;
  };

  const setPersonalNumber = (personalNumber: string) => {
    setPatientData((prevState) => ({
      ...prevState,
      personalNumber,
    }));
  };

  const handlePersonalNumberValidationSuccess = (data: {
    name?: string;
    dateOfBirth: string;
    sex: Sex;
  }) => {
    setPatientData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const setInitialDiagnosesData = (diagnoses: Diagnose[]) => {
    const diagnosesData = diagnoses
      .filter(({ disease }) => Boolean(disease))
      .map((diagnose, index) => ({
        ...diagnose,
        id: index,
      }));
    setDiagnosesData(diagnosesData);
    return;
  };

  useEffect(() => {
    if (!initialData) {
      return;
    }

    setPatientData(initialData.patientDetails);

    if (initialData.diagnoses.length) {
      setInitialDiagnosesData(initialData.diagnoses);
      return;
    }

    return;
  }, [initialData]);

  return (
    <>
      <Container maxWidth="sm">
        <Stack direction="column" spacing={3} pt={4} pb={4}>
          <HeaderWithBackButton
            headerVariant="h1"
            onBackButtonClick={handleCancelClick}
          >
            {t('title')}
          </HeaderWithBackButton>
          <PersonalNumberFormController
            setPersonalNumber={setPersonalNumber}
            onValidationSuccess={handlePersonalNumberValidationSuccess}
            setIsLoading={setIsLoading}
            initialData={patientDetails.personalNumber}
            validatePersonalNumber={service.validatePersonalNumber}
          />
          {isLoading && (
            <Stack alignSelf="center" p={2.5}>
              <CircularLoading label={t('loading-label')} />
            </Stack>
          )}
          {!!patientDetails.personalNumber && (
            <Fade in={!isLoading} timeout={1000}>
              <Stack spacing={3}>
                <PatientFormController
                  patientData={patientDetails}
                  setPatientData={setPatientData}
                  errors={patientDataErrorMessages}
                  setErrors={setPatientDataErrorMessages}
                />
                <PatientDiagnosisFormController
                  diagnoses={diagnoses}
                  getDiseaseOptions={service.getDiseaseOptionsForDiagnose}
                  setDiagnoses={setDiagnosesData}
                  errors={diagnosesDataErrorMessages}
                  setErrors={setDiagnosesDataErrorMessages}
                />
                <Stack
                  direction={isNotMobile ? 'row' : 'column'}
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    onClick={handleCancelClick}
                    sx={{ minWidth: 300 }}
                    fullWidth={true}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleOnContinueClick}
                    sx={{ minWidth: 300 }}
                    fullWidth={true}
                    disabled={!patientDetails.personalNumber}
                  >
                    {t('continue')}
                  </Button>
                </Stack>
              </Stack>
            </Fade>
          )}
        </Stack>
      </Container>
      {cancelConfirmationDialog}
    </>
  );
}

export default NewPatientFormController;

export const initialPatientState: PatientDetails = {
  name: '',
  dateOfBirth: '',
  personalNumber: '',
  sex: '',
};

export const initialDiagnosesErrorState = {
  disease: '',
  ageAtDiagnosis: '',
  ageIsApproximate: '',
  treatedBy: '',
  notes: '',
};
