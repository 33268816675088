import { Container } from '@mui/material';
import { DynamicForm } from 'common/features/dynamicForm';
import { FieldErrorTemplate } from 'common/features/dynamicForm/templates/FieldErrorTemplate';
import { LoadingBackdrop } from 'components/common';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { PATIENT_PATHS } from 'myfamilytree/routes/MyFamilyTreePatientRouter';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export const PersonalInfoFormController = () => {
  const navigate = useNavigate();

  const { pedigreeCaseId } = useParams();
  const { i18n } = useTranslation('myFamilyTree');

  const service = useMyFamilyTreeSubmissionService();
  const { data, isLoading } = useQuery(
    ['myFamilyTreeForm', pedigreeCaseId, i18n.language],
    () => service?.getQuestionnaireForm(pedigreeCaseId || ''),
  );

  const queryClient = useQueryClient();
  const submitMutation = useMutation(
    (data: any) => {
      return service?.submitQuestionnaireForm(pedigreeCaseId || '', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'myFamilyTreeForm',
          pedigreeCaseId,
          i18n.language,
        ]);
        navigate(`../${PATIENT_PATHS.PEDIGREE}`);
      },
    },
  );

  const onSubmit = (data: any) => {
    submitMutation.mutate(data.formData);
  };

  return (
    <>
      {data && (
        <Container maxWidth="sm" disableGutters>
          <DynamicForm
            key={i18n.language}
            schema={data.jsonSchema}
            uiSchema={data.uiSchema}
            formData={data.formData}
            formContext={{ border: true }}
            onSubmit={onSubmit}
            templates={{ FieldErrorTemplate }}
            disabled={submitMutation.isLoading}
          />
        </Container>
      )}
      {!data && !isLoading && <div>Form not found</div>}
      {<LoadingBackdrop open={isLoading} />}
    </>
  );
};
