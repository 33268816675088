import { useTranslation } from 'react-i18next';
import { Expand } from 'types';
import { PedigreeNode, Sex } from '../types';
import {
  getAllBloodRelativesOf,
  getAllChildrenOf,
  getAllHalfSiblingsOf,
  getAllSiblingsOf,
} from '../utils/helpers';

function usePedigreeNodeRelationshipLabels(nodes: PedigreeNode[]): {
  pedigreeNodeRelationshipLabelKeys: { [id: string]: string };
  getLocalizedLabelOf(nodeId: string): string;
  getLabelKeyOf(nodeId: string): RelativeKey | null;
} {
  const { t, i18n } = useTranslation('relationship');

  const labelKeyArray = generateLabelKeys(nodes);
  const labelKeyMap: { [id: string]: RelativeKey } = labelKeyArray.reduce(
    (acc, { id, relativeKey }) => {
      acc[id] = relativeKey;
      return acc;
    },
    {} as { [id: string]: RelativeKey },
  );

  const getLabelKeyOf = (nodeId: string): RelativeKey | null => {
    return labelKeyMap[nodeId] || null;
  };

  const getLocalizedLabelOf = (nodeId: string): string => {
    const labelKey = labelKeyMap[nodeId];
    if (i18n.exists('relationship:' + labelKey)) {
      return t(labelKey);
    }
    return labelKey || '';
  };

  return {
    pedigreeNodeRelationshipLabelKeys: labelKeyMap,
    getLocalizedLabelOf,
    getLabelKeyOf,
  };
}

export default usePedigreeNodeRelationshipLabels;

function generateLabelKeys(
  nodes: PedigreeNode[],
): { id: string; relativeKey: RelativeKey }[] {
  const indexNode = nodes.find((n) => n.isIndex);
  if (!indexNode) return [];
  const childrenLabelKeys = getNodesWithChildrenLabelKey(indexNode, nodes);
  const siblingLabelKeys = getNodesWithSiblingLabelKey(indexNode, nodes);
  const parentalLabelKeys = getNodesWithParentalLabelKey(indexNode, nodes);
  const piblingLabelKeys = getNodesWithPiblingLabelKey(indexNode, nodes);
  const cousinLabelKeys = getNodesWithCousinLabelKey(indexNode, nodes);

  const labelKeys: { id: string; relativeKey: RelativeKey }[] = [
    { id: indexNode.id, relativeKey: 'index' },
    ...childrenLabelKeys,
    ...siblingLabelKeys,
    ...parentalLabelKeys,
    ...piblingLabelKeys,
    ...cousinLabelKeys,
  ];

  const nodesWithRelativeLabelKey = getNodesWithRelativeLabelKey(
    indexNode,
    nodes,
    labelKeys,
  );
  labelKeys.push(...nodesWithRelativeLabelKey);

  const nodesWithPartnerLabelKey = getNodesWithPartnerLabelKey(
    indexNode,
    nodes,
    labelKeys,
  );
  labelKeys.push(...nodesWithPartnerLabelKey);

  return labelKeys;
}

function getNodesWithChildrenLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
): { id: string; relativeKey: ChildRelation }[] {
  const children = nodes.filter(
    (n) => n.fatherId === indexNode.id || n.motherId === indexNode.id,
  );
  return children.map((child) => ({
    id: child.id,
    relativeKey: isMale(child) ? 'son' : 'daughter',
  }));
}

function getNodesWithParentalLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
): { id: string; relativeKey: ParentalRelation | GrandParentalRelation }[] {
  const father = nodes.find((n) => n.id === indexNode.fatherId);
  const mother = nodes.find((n) => n.id === indexNode.motherId);
  const labels: {
    id: string;
    relativeKey: ParentalRelation | GrandParentalRelation;
  }[] = [];
  if (father) {
    labels.push({ id: father.id, relativeKey: 'father' });
    labels.push(...getGrandParentalLabelKeys(father, 'paternal', nodes));
  }
  if (mother) {
    labels.push({ id: mother.id, relativeKey: 'mother' });
    labels.push(...getGrandParentalLabelKeys(mother, 'maternal', nodes));
  }
  return labels;
}

function getNodesWithSiblingLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
): { id: string; relativeKey: SiblingRelation | HalfSiblingRelation }[] {
  const siblingsLabelKeys = getAllSiblingsOf(indexNode, nodes).map(
    (sibling): { id: string; relativeKey: SiblingRelation } => ({
      id: sibling.id,
      relativeKey: isMale(sibling) ? 'brother' : 'sister',
    }),
  );
  const halfSiblingLabelKeys = getAllHalfSiblingsOf(indexNode, nodes).map(
    (sibling): { id: string; relativeKey: HalfSiblingRelation } => ({
      id: sibling.id,
      relativeKey: `half-${isMale(sibling) ? 'brother' : 'sister'}`,
    }),
  );
  return [...siblingsLabelKeys, ...halfSiblingLabelKeys];
}

function getGrandParentalLabelKeys(
  parentNode: PedigreeNode,
  parentalSide: PaternalMaternalRelation,
  nodes: PedigreeNode[],
): { id: string; relativeKey: GrandParentalRelation }[] {
  const parentsFather = nodes.find((n) => n.id === parentNode.fatherId);
  const parentsMother = nodes.find((n) => n.id === parentNode.motherId);
  const labels: {
    id: string;
    relativeKey: GrandParentalRelation;
  }[] = [];
  if (parentsFather) {
    labels.push({
      id: parentsFather.id,
      relativeKey: `${parentalSide}-grandfather`,
    });
  }
  if (parentsMother) {
    labels.push({
      id: parentsMother.id,
      relativeKey: `${parentalSide}-grandmother`,
    });
  }
  return labels;
}

function getNodesWithPiblingLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
): {
  id: string;
  relativeKey: PiblingRelation;
}[] {
  const father = nodes.find((n) => n.id === indexNode.fatherId);
  const mother = nodes.find((n) => n.id === indexNode.motherId);
  const paternalSiblings = father ? getAllSiblingsOf(father, nodes) : [];
  const maternalSiblings = mother ? getAllSiblingsOf(mother, nodes) : [];

  const paternalSiblingLabelKeys = paternalSiblings.map(
    (sibling): { id: string; relativeKey: PiblingRelation } => ({
      id: sibling.id,
      relativeKey: `paternal-${isMale(sibling) ? 'uncle' : 'aunt'}`,
    }),
  );
  const maternalSiblingLabelKeys = maternalSiblings.map(
    (sibling): { id: string; relativeKey: PiblingRelation } => ({
      id: sibling.id,
      relativeKey: `maternal-${isMale(sibling) ? 'uncle' : 'aunt'}`,
    }),
  );
  return [...paternalSiblingLabelKeys, ...maternalSiblingLabelKeys];
}

function getNodesWithCousinLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
): { id: string; relativeKey: 'cousin' }[] {
  const parents = nodes.filter(
    (n) => n.id === indexNode.fatherId || n.id === indexNode.motherId,
  );
  const parentsSiblings = parents
    .map((parent) => getAllSiblingsOf(parent, nodes))
    .flat();
  const cousins = parentsSiblings
    .map((sibling) => getAllChildrenOf(sibling, nodes))
    .flat();
  return cousins.map((cousin) => ({ id: cousin.id, relativeKey: 'cousin' }));
}

function getNodesWithRelativeLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
  existingLabelKeys: { id: string; relativeKey: RelativeKey }[],
): { id: string; relativeKey: 'relative' }[] {
  const allBloodRelatives = getAllBloodRelativesOf(indexNode, nodes);
  const bloodRelativesWithoutLabelKey = allBloodRelatives.filter(
    (relative) => !existingLabelKeys.some((label) => label.id === relative.id),
  );
  return bloodRelativesWithoutLabelKey.map((relative) => ({
    id: relative.id,
    relativeKey: 'relative',
  }));
}

function getNodesWithPartnerLabelKey(
  indexNode: PedigreeNode,
  nodes: PedigreeNode[],
  existingLabelKeys: { id: string; relativeKey: RelativeKey }[],
): { id: string; relativeKey: 'partner' }[] {
  const nodesWithoutLabelKey = nodes.filter(
    (node) =>
      node.id !== indexNode.id &&
      !existingLabelKeys.some((label) => label.id === node.id),
  );

  return nodesWithoutLabelKey
    .filter((node) =>
      node.partnerIds.some((id) =>
        existingLabelKeys.some((label) => label.id === id),
      ),
    )
    .map((node) => ({
      id: node.id,
      relativeKey: 'partner',
    }));
}

const isMale = (node: PedigreeNode) => node.sex === Sex.MALE;

type PaternalMaternalRelation = 'paternal' | 'maternal';
type ParentalRelation = 'father' | 'mother';
type GrandParentalRelation =
  `${PaternalMaternalRelation}-grand${ParentalRelation}`;
// Pibling is a term for a parent's sibling
type PiblingRelation = `${PaternalMaternalRelation}-${'uncle' | 'aunt'}`;

type SiblingRelation = 'brother' | 'sister';
type HalfSiblingRelation = `half-${SiblingRelation}`;
type ChildRelation = 'son' | 'daughter';

type RelativeKey = Expand<
  | 'index'
  | ParentalRelation
  | GrandParentalRelation
  | PiblingRelation
  | SiblingRelation
  | HalfSiblingRelation
  | ChildRelation
  | 'cousin'
  | 'relative'
  | 'partner'
>;
