import { faBell, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConsentActionButtonsViewProps {
  onAddRelativeClick: () => void;
  onSendReminderClick?: () => void;
  onCompleteClick?: () => void;
}

function ConsentActionButtonsView(props: ConsentActionButtonsViewProps) {
  const { t } = useTranslation(['all']);
  return (
    <Stack direction="row" spacing={3}>
      <Button
        color="primary"
        size="medium"
        onClick={props.onAddRelativeClick}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        {t('consent-action-button.add-relative')}
      </Button>

      {props.onSendReminderClick && (
        <Button
          color="primary"
          size="medium"
          onClick={props.onSendReminderClick}
          startIcon={<FontAwesomeIcon icon={faBell} />}
        >
          {t('consent-request.action-buttons.send-reminder')}
        </Button>
      )}

      {props.onCompleteClick && (
        <Button
          color="primary"
          size="medium"
          onClick={props.onCompleteClick}
          startIcon={<FontAwesomeIcon icon={faCheck} />}
        >
          {t('consent-request.action-buttons.complete')}
        </Button>
      )}
    </Stack>
  );
}

export default ConsentActionButtonsView;
