import {
  Box,
  Container,
  ContainerProps,
  Stack,
  Typography,
} from '@mui/material';
import ArkusLogo from '../../assets/images/arkus-logo.svg';
import { LanguageSelector } from '../../common/layouts/LanguageSelector';

interface FamilyTreePatientTopNavProps {
  containerMaxWidth?: ContainerProps['maxWidth'];
}

export function FamilyTreePatientTopNav({
  containerMaxWidth,
}: FamilyTreePatientTopNavProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'common.brand.lightPurple',
      }}
    >
      <Container maxWidth={containerMaxWidth}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img alt={'logo'} src={ArkusLogo} />
          <Typography variant="h2" sx={{ color: 'common.brand.gray' }}>
            myFamilyTree
          </Typography>
          <LanguageSelector />
        </Stack>
      </Container>
    </Box>
  );
}
