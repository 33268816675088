import {
  faCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentStatus } from 'types';

interface ConsentStatusCellProps {
  consentStatus: ConsentStatus;
}

const ConsentStatusCell = (props: ConsentStatusCellProps): React.ReactNode => {
  const { consentStatus } = props;
  const { t } = useTranslation(['all']);

  return (
    <Typography>
      <ConsentStatusIcon consentStatus={consentStatus} />
      {t(`consent-request-list-item.consent-status.${consentStatus}`)}
    </Typography>
  );
};
const ConsentStatusIcon = ({
  consentStatus,
}: {
  consentStatus: ConsentStatus;
}): React.ReactNode => {
  let icon, color;
  const theme = useTheme();
  switch (consentStatus) {
    case 'pending':
      color = theme.palette.warning.main;
      icon = faExclamationCircle;
      break;
    case 'accepted':
      color = theme.palette.success.main;
      icon = faCircleCheck;
      break;
    case 'declined':
      color = theme.palette.error.main;
      icon = faExclamationTriangle;
  }
  return (
    <FontAwesomeIcon
      icon={icon}
      color={color}
      style={{
        marginRight: '0.5rem',
      }}
    />
  );
};

export default ConsentStatusCell;
