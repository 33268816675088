import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Card,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { Sex } from 'pedigree/features/common/types';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { SexIcon } from '../../pedigreeRenderer/views/PedigreeNodeConsumerView';

interface PedigreeDrawerNodeCardProps {
  sex: Sex;
  relativeRelationLabel?: string;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    PopperComponent={Popper}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  '& .MuiTooltip-arrow': {
    color: theme.palette.info.light,
  },
  '& .MuiTooltip-tooltip': {
    color: theme.palette.common.brand.darkBlue,
    backgroundColor: theme.palette.info.light,
  },
}));

const PedigreeDrawerNodeCardView = ({
  sex,
  relativeRelationLabel,
}: PedigreeDrawerNodeCardProps) => {
  const { t } = useTranslation('myFamilyTree');
  return (
    <Stack justifyContent="center" flexDirection="row">
      <Box position="relative">
        <CustomTooltip
          arrow
          open
          PopperProps={{ disablePortal: true }}
          title={t('onBoarding-drawer.description.add-details-tooltip')}
          placement="left"
        >
          <Typography
            sx={{ position: 'absolute', top: '40%', left: '30%' }}
          ></Typography>
        </CustomTooltip>
        <Card
          elevation={2}
          sx={{
            p: 1,
            width: 165,
            height: 152,
            boxSizing: 'border-box',
            borderWidth: 0,
          }}
        >
          <Stack direction="row">
            <SexIcon sex={sex} iconColor={theme.palette.common.brand.gray} />
            <Typography variant="h6">{relativeRelationLabel}</Typography>
          </Stack>
        </Card>

        <CustomTooltip
          arrow
          open
          PopperProps={{ disablePortal: true }}
          title={t('onBoarding-drawer.description.add-relative-tooltip')}
          placement="right"
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              bottom: -18,
              left: 'calc(50% - 10px)',
              p: 1,
              width: 20,
              height: 20,
              border: 1,
              borderRadius: '100%',
              background: 'white',
              boxShadow: 2,
              borderColor: 'white',
              color: 'common.brand.blue',
            }}
          >
            <FontAwesomeIcon icon={faPlus} size="2x" />
          </Stack>
        </CustomTooltip>
      </Box>
    </Stack>
  );
};

export default PedigreeDrawerNodeCardView;
