import React from 'react';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from '../serviceProviderFactory';
import { ConsentSubmissionService } from './consent-submission-service';

const Context = React.createContext<{
  service: ConsentSubmissionService | null;
}>({ service: null });

export const ConsentSubmissionServiceProvider = serviceProviderFactory(
  ConsentSubmissionService,
  Context,
);

export const useConsentSubmissionService = serviceHookFactory(
  ConsentSubmissionService,
  Context,
);
