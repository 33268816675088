import { Box, Container, ContainerProps } from '@mui/material';

interface FooterProps {
  children?: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
}

function Footer(props: FooterProps) {
  return (
    <Box sx={{ backgroundColor: 'common.neutral.100' }}>
      <Container sx={{ py: 4 }} maxWidth={props.maxWidth}>
        {props.children}
      </Container>
    </Box>
  );
}

export default Footer;
