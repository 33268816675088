import { Box } from '@mui/material';
import { ConsentManagementServiceProvider } from 'api/consentManagement/ConsentManagementServiceProvider';
import PdfServiceProvider from 'api/pdf/PdfServiceProvider';
import Layout from 'components/layout';
import {
  useStaffAuth,
  withStaffAuthProvider,
} from 'features/auth/staff/StaffAuthProvider';
import AddRelatives from 'pages/consentManagement/AddRelatives';
import Dashboard from 'pages/consentManagement/Dashboard';
import NewPatient from 'pages/consentManagement/NewPatient';
import SendReminder from 'pages/consentManagement/SendReminder';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

function ConsentManagementRouter() {
  const { logout, accessToken } = useStaffAuth();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };
  const handleLogoutClick = () => {
    logout();
  };

  return (
    <ConsentManagementServiceProvider accessToken={accessToken} logout={logout}>
      <PdfServiceProvider accessToken={accessToken} logout={logout}>
        <Routes>
          <Route
            element={
              <Layout signOut={handleLogoutClick} onLogoClick={handleLogoClick}>
                <Box sx={{ pb: 2 }}>
                  <Outlet />
                </Box>
              </Layout>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/new-patient" element={<NewPatient />} />
            <Route
              path="/:consentRequestId/add-relative"
              element={<AddRelatives />}
            />
            <Route
              path="/:consentRequestId/send-reminder"
              element={<SendReminder />}
            />
          </Route>
        </Routes>
      </PdfServiceProvider>
    </ConsentManagementServiceProvider>
  );
}

export default withStaffAuthProvider(ConsentManagementRouter);
