import { PdfService } from 'api/pdf/pdf-service';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../serviceProviderFactory';
import PdfViewModal from './PdfViewModal';

const PdfServiceContext = React.createContext<{
  pdfApiService: PdfService | null;
  downloadPdf: (pdfLocationUrl: string, fileName: string) => Promise<void>;
  printPdf: (pdfLocationUrl: string) => void;
  viewPdf: (pdfLocationUrl: string, fileName: string) => void;
}>({
  pdfApiService: null,
  downloadPdf: async () => {},
  printPdf: () => {},
  viewPdf: () => {},
});

interface Props {
  children: React.ReactNode;
  accessToken: string | null;
  logout?: () => void;
}

const PdfServiceProvider: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const service = React.useMemo(() => {
    return new PdfService(
      API_BASE_URL,
      props.accessToken,
      null,
      i18n,
      props.logout,
    );
  }, [props.accessToken, props.logout, i18n]);

  const pdfPrintIframeRef = React.useRef<HTMLIFrameElement>(null);

  const handleDownloadPdf = async (
    pdfLocationUrl: string,
    fileName: string,
  ) => {
    try {
      const data = await service.getPdfData(pdfLocationUrl);
      const link = document.createElement('a');
      link.href = data;
      link.setAttribute('download', fileName);
      link.click();
    } catch (error) {
      console.error('Error download PDF URL:', error);
    }
  };
  const [openPdfLocationUrl, setOpenPdfLocationUrl] = React.useState<
    string | null
  >(null);
  const [openPdfFileName, setOpenPdfFileName] = React.useState<string | null>(
    null,
  );

  const handlePrintPdf = async (pdfLocationUrl: string) => {
    try {
      const data = await service.getPdfData(pdfLocationUrl);
      const iframe = pdfPrintIframeRef.current;

      if (iframe) {
        iframe.src = data;
      }
    } catch (error) {
      // Handle error appropriately
      console.error('Error download consent PDF URL:', error);
    }
  };

  const handleViewPdf = async (pdfLocationUrl: string, fileName: string) => {
    setOpenPdfFileName(fileName);
    setOpenPdfLocationUrl(pdfLocationUrl);
  };

  const onViewerClose = () => {
    setOpenPdfFileName(null);
    setOpenPdfLocationUrl(null);
  };

  const onDownloadClick = () => {
    if (openPdfLocationUrl && openPdfFileName) {
      handleDownloadPdf(openPdfLocationUrl, openPdfFileName);
    }
  };

  const onPrintClick = () => {
    if (openPdfLocationUrl) {
      handlePrintPdf(openPdfLocationUrl);
    }
  };

  const handleIframeLoad = () => {
    if (pdfPrintIframeRef.current && pdfPrintIframeRef.current.src) {
      pdfPrintIframeRef.current.contentWindow?.print();
    }
  };

  return (
    <PdfServiceContext.Provider
      value={{
        pdfApiService: service,
        downloadPdf: handleDownloadPdf,
        printPdf: handlePrintPdf,
        viewPdf: handleViewPdf,
      }}
    >
      {props.children}

      <iframe
        ref={pdfPrintIframeRef}
        style={{ display: 'none' }}
        title="PDF Document"
        onLoad={handleIframeLoad}
      />
      <PdfViewModal
        pdfLocationUrl={openPdfLocationUrl}
        title={openPdfFileName || ''}
        onDownloadClick={onDownloadClick}
        onPrintClick={onPrintClick}
        onCloseClick={onViewerClose}
      />
    </PdfServiceContext.Provider>
  );
};

export default PdfServiceProvider;

export const usePdfService = () => {
  const context = React.useContext(PdfServiceContext);
  if (!context) {
    throw new Error('usePdfService must be used within a PdfServiceProvider');
  }
  return context;
};
