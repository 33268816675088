import { useState } from 'react';

export type VisibleNodeProperties = {
  name: boolean;
  age: boolean;
  diagnosis: boolean;
};

export function usePedigreeFilterProps(
  diagnoseToColorMap: Record<string, string>,
) {
  const [showNames, setShowNames] = useState(true);
  const [showPatientAge, setShowPatientAge] = useState(true);
  const [showDiagnosis, setShowDiagnosis] = useState(false);

  const visibleNodeProperties: VisibleNodeProperties = {
    name: showNames,
    age: showPatientAge,
    diagnosis: showDiagnosis,
  };

  return {
    visibleNodeProperties,
    filterViewProps: {
      showNames,
      setShowNames,
      showPatientAge,
      setShowPatientAge,
      showDiagnosis,
      setShowDiagnosis,
      diagnoseToColorMap,
    },
  };
}
