import { LoadingButton } from '@mui/lab';
import { SubmitButtonProps, getSubmitButtonOptions } from '@rjsf/utils';
import { useTranslation } from 'react-i18next';

interface SubmitButtonTemplateProps extends SubmitButtonProps {
  loading: boolean;
}

export function SubmitButtonTemplate(props: SubmitButtonTemplateProps) {
  const { t } = useTranslation(['dynamicForm']);
  const { uiSchema } = props;
  const { norender } = getSubmitButtonOptions(uiSchema);

  if (norender) {
    return null;
  }

  return (
    <LoadingButton
      variant="contained"
      sx={{ marginTop: '10px', marginBottom: '10px' }}
      fullWidth
      type="submit"
      loading={props.loading}
    >
      {t('submit-button')}
    </LoadingButton>
  );
}
