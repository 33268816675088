import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { RadioInput, TextInput } from 'components/inputs';
import RelationshipInput from 'features/common/controllers/RelationshipInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RelativeDataErrors, RelativeFormData } from '../types';

interface RelativeFormRowViewProps {
  data: RelativeFormData;
  setData: (data: RelativeFormData) => void;
  errors: RelativeDataErrors;
  onDeleteClick: () => void;
  disabled?: boolean;
  handleTextInputChangeFactory: (
    key: keyof RelativeFormData,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAutocompleteInputChangeFactory: (
    key: keyof RelativeFormData,
  ) => (value: string | null) => void;
}
const GRID_COL_WIDTH = [{ md: 6 }, { md: 6 }, { md: 4 }, { md: 4 }];

function RelativeFormRowView(props: RelativeFormRowViewProps) {
  const { t } = useTranslation(['all']);

  const handleSignerTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value as 'first-degree' | 'specific-relative';
    props.setData({
      ...props.data,
      specificProxySigner: newValue === 'specific-relative',
    });
  };

  return (
    <Box>
      <Grid container spacing={3} columns={20}>
        <Grid {...GRID_COL_WIDTH[0]} sx={{ width: '100%' }}>
          <TextInput
            label={t('consent-management.new-patient.section3.relative-name')}
            id="name"
            value={props.data.name}
            required
            error={
              props?.errors?.name &&
              t(`input-validation.${props?.errors?.name}`)
            }
            onChange={props.handleTextInputChangeFactory('name')}
            textFieldProps={{ disabled: props.disabled }}
          />
        </Grid>
        <Grid {...GRID_COL_WIDTH[1]} sx={{ width: '100%' }}>
          <RelationshipInput
            label={t('consent-management.new-patient.section3.relationship')}
            value={props.data.relationshipToPatient || ''}
            onChange={props.handleAutocompleteInputChangeFactory(
              'relationshipToPatient',
            )}
            required
            error={
              props?.errors?.relationshipToPatient &&
              t(`input-validation.${props?.errors?.relationshipToPatient}`)
            }
            placeholder={t(
              'consent-management.new-patient.section3.relationship.placeholder',
            )}
            disabled={props.disabled}
          />
        </Grid>
        <Grid {...GRID_COL_WIDTH[2]} sx={{ width: '100%' }}>
          <TextInput
            label={t('consent-management.new-patient.section3.relative-pnr')}
            id="personalNumber"
            value={props.data.personalNumber}
            onChange={props.handleTextInputChangeFactory('personalNumber')}
            placeholder={t('personal-number.placeholder')}
            error={
              props?.errors?.personalNumber &&
              t(`input-validation.${props?.errors?.personalNumber}`)
            }
            textFieldProps={{
              disabled: props.disabled,
              inputProps: {
                maxLength: 12,
                minLength: 12,
              },
            }}
            formLabelProps={{
              sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </Grid>
        <Grid {...GRID_COL_WIDTH[3]}>
          <Stack direction="row" justifyContent="space-evenly" mt={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.data.deceased}
                  onChange={(event) =>
                    props.setData({
                      ...props.data,
                      deceased: event.target.checked,
                    })
                  }
                  disabled={props.disabled}
                />
              }
              label={t('consent-management.new-patient.section3.deceased')}
            />
            <IconButton
              onClick={props.onDeleteClick}
              disabled={props.disabled}
              sx={{
                color: 'theme.palette.common.neutral.600',
              }}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                style={{
                  aspectRatio: 1,
                }}
              />
            </IconButton>
          </Stack>
        </Grid>
        {props.data.deceased && (
          <>
            <Grid md={20}>
              <RadioInput
                label={t(
                  'consent-management.new-patient.section3.signer-option-label',
                )}
                id="relativeInfo"
                options={[
                  {
                    value: 'first-degree',
                    label: t(
                      'consent-management.new-patient.section3.signer-option.first-degree',
                    ),
                  },
                  {
                    value: 'specific-relative',
                    label: t(
                      'consent-management.new-patient.section3.signer-option.specific-relative',
                    ),
                  },
                ]}
                value={
                  props.data.specificProxySigner
                    ? 'specific-relative'
                    : 'first-degree'
                }
                onChange={handleSignerTypeChange}
                radioGroupProps={{
                  row: true,
                }}
                disabled={props.disabled}
              />
            </Grid>
            {props.data.specificProxySigner && (
              <>
                <Grid {...GRID_COL_WIDTH[0]}>
                  <TextInput
                    label={t(
                      'consent-management.new-patient.section3.relative-name',
                    )}
                    id="proxySignerName"
                    value={props.data.proxySignerName}
                    required
                    error={
                      props?.errors?.proxySignerName &&
                      t(`input-validation.${props?.errors?.proxySignerName}`)
                    }
                    onChange={props.handleTextInputChangeFactory(
                      'proxySignerName',
                    )}
                    textFieldProps={{ disabled: props.disabled }}
                  />
                </Grid>
                <Grid {...GRID_COL_WIDTH[1]}>
                  <RelationshipInput
                    label={t(
                      'consent-management.new-patient.section3.relationship-to-deceased',
                    )}
                    value={props.data.proxySignerToConsenteeRelationship || ''}
                    onChange={props.handleAutocompleteInputChangeFactory(
                      'proxySignerToConsenteeRelationship',
                    )}
                    required
                    error={
                      props?.errors?.proxySignerToConsenteeRelationship &&
                      t(
                        `input-validation.${props?.errors?.proxySignerToConsenteeRelationship}`,
                      )
                    }
                    placeholder={t(
                      'consent-management.new-patient.section3.relationship.placeholder',
                    )}
                    disabled={props.disabled}
                  />
                </Grid>
                <Grid {...GRID_COL_WIDTH[2]}>
                  <TextInput
                    label={t(
                      'consent-management.new-patient.section3.personal-number',
                    )}
                    id="proxySignerPersonalNumber"
                    value={props.data.proxySignerPersonalNumber}
                    onChange={props.handleTextInputChangeFactory(
                      'proxySignerPersonalNumber',
                    )}
                    placeholder={t('personal-number.placeholder')}
                    error={
                      props?.errors?.proxySignerPersonalNumber &&
                      t(
                        `input-validation.${props?.errors?.proxySignerPersonalNumber}`,
                      )
                    }
                    textFieldProps={{
                      disabled: props.disabled,
                      inputProps: {
                        maxLength: 12,
                        minLength: 12,
                      },
                    }}
                    formLabelProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default RelativeFormRowView;
