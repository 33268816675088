import { Stack } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import ListConsentEmptyState from 'components/common/ListConsentEmptyState';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CompleteConsentRequestController from './CompleteConsentRequestController';
import ConsentRequestCardController from './ConsentRequestCardController';
import SortAndSearchInputController, {
  SortAndSearchParameters,
} from './SortAndSearchInputController';

interface Props {
  showCompletedConsents?: boolean;
}

function ConsentRequestsListController(props: Props) {
  const service = useConsentManagementService();
  const { t } = useTranslation(['all']);

  const [sortAndSearchParameters, setSortAndSearchParameters] =
    React.useState<SortAndSearchParameters>({
      sort: {
        field: 'patientName',
        order: 'ASC',
      },
      search: '',
    });

  const sortQuery = `${sortAndSearchParameters.sort.field}:${sortAndSearchParameters.sort.order}`;
  const searchQuery = sortAndSearchParameters.search;

  const [consentRequestIdToComplete, setConsentRequestIdToComplete] =
    React.useState<string | null>(null);
  const { data, isLoading: isListConsentsLoading } = useQuery(
    ['listConsents', sortQuery, searchQuery, props.showCompletedConsents],
    () =>
      service.listConsentRequests({
        sortQuery,
        searchQuery,
        showCompleted: props.showCompletedConsents ? true : false,
      }),
  );

  let consentEmptyStateTitle = props.showCompletedConsents
    ? t('consent-completed-empty-state.title')
    : t('consent-ongoing-empty-state.title');
  let consentEmptyStateDescription = props.showCompletedConsents
    ? t('consent-completed-empty-state.description')
    : t('consent-ongoing-empty-state.description');

  if (searchQuery) {
    consentEmptyStateTitle = t('empty-states.no-results.title', {
      searchQuery,
    });
    consentEmptyStateDescription = t('empty-states.no-results.text');
  }

  const handleCompleteClick = (consentRequestId: string) => {
    setConsentRequestIdToComplete(consentRequestId);
  };

  return (
    <Stack spacing={4}>
      <SortAndSearchInputController
        {...{
          sortAndSearchParameters,
          setSortAndSearchParameters,
        }}
      />
      {isListConsentsLoading ? (
        <LoadingBackdrop />
      ) : data?.length ? (
        data.map((consentRequest) => (
          <ConsentRequestCardController
            key={consentRequest.consentRequestId}
            consentRequest={consentRequest}
            onCompleteClick={handleCompleteClick}
          />
        ))
      ) : (
        <ListConsentEmptyState
          title={consentEmptyStateTitle}
          description={consentEmptyStateDescription}
        />
      )}
      <CompleteConsentRequestController
        consentRequestIdToComplete={consentRequestIdToComplete}
        setConsentRequestIdToComplete={setConsentRequestIdToComplete}
      />
    </Stack>
  );
}

export default ConsentRequestsListController;
