import { Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { LoadingBackdrop } from 'components/common';
import {
  BankidAuthProvider,
  useBankidAuth,
} from 'features/auth/bankid/AuthProvider';
import {
  MyFamilyTreeSubmissionServiceProvider,
  useMyFamilyTreeSubmissionService,
} from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { FamilyTreePatientFooter } from 'myfamilytree/layouts/FamilyTreePatientFooter';
import { FamilyTreePatientTopNav } from 'myfamilytree/layouts/FamilyTreePatientTopNav';
import { PatientHome } from 'myfamilytree/pages/myFamilyTreePatient';
import { FourOFour } from 'myfamilytree/pages/myFamilyTreePatient/404';
import PedigreeCaseInactive from 'myfamilytree/pages/myFamilyTreePatient/PedigreeCaseInactive';
import PedigreeCaseSubmitted from 'myfamilytree/pages/myFamilyTreePatient/PedigreeCaseSubmitted';
import PedigreeEditor from 'myfamilytree/pages/myFamilyTreePatient/PedigreeEditor';
import { Questionnaire } from 'myfamilytree/pages/myFamilyTreePatient/Questionnaire';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export const PATIENT_PATHS = {
  QUESTIONNAIRE: 'questionnaire',
  PEDIGREE: 'pedigree',
};

function MyFamilyTreePatientRouter() {
  const [hideFooter, setHideFooter] = useState(false);
  const service = useMyFamilyTreeSubmissionService();
  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const is404Route = location.pathname.endsWith('/404');
  const isInactiveRoute = location.pathname.endsWith('/inactive');
  const isSubmittedRoute = location.pathname.endsWith('/submitted');

  const { data, isLoading } = useQuery(
    ['pedigreeCaseInfo', pedigreeCaseId],
    () => service.getPedigreeCaseInformation(pedigreeCaseId as string),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          if (is404Route) return;
          navigate('./404');
          return;
        }
      },
      onSuccess: (data) => {
        const caseIsInactive = data?.pedigreeCaseStatus === 'inactive';
        const caseIsSubmitted = data?.pedigreeCaseStatus === 'submitted';
        if (caseIsInactive && !isInactiveRoute) {
          navigate('./inactive');
          return;
        }
        if (caseIsSubmitted && !isSubmittedRoute) {
          navigate('./submitted');
          return;
        }
      },
      retry: false,
    },
  );

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <>
      <FamilyTreePatientTopNav />
      <Routes>
        <Route path="/404" element={<FourOFour />} />
        <Route path="/inactive" element={<PedigreeCaseInactive />} />
        <Route path="/submitted" element={<PedigreeCaseSubmitted />} />
      </Routes>
      {data && !isInactiveRoute && (
        <MyFamilyTreePrivatePatientRouterWithAuthProvider
          patientName={data.patientName}
          setHideFooter={setHideFooter}
        />
      )}
      {!hideFooter && data && (
        <FamilyTreePatientFooter
          phone={data.requestingEntity.phone}
          name={data.requestingEntity.name}
        />
      )}
    </>
  );
}

interface MyFamilyTreePrivatePatientRouterProps {
  setHideFooter: (hide: boolean) => void;
}
function MyFamilyTreePrivatePatientRouter({
  setHideFooter,
}: MyFamilyTreePrivatePatientRouterProps) {
  const { accessToken, logout } = useBankidAuth();
  return (
    <MyFamilyTreeSubmissionServiceProvider
      accessToken={accessToken}
      logout={logout}
    >
      <Routes>
        <Route path="/" element={<PatientHome />} />
        <Route
          path={`/${PATIENT_PATHS.QUESTIONNAIRE}`}
          element={<Questionnaire setHideFooter={setHideFooter} />}
        />
        <Route
          path={`/${PATIENT_PATHS.PEDIGREE}`}
          element={<PedigreeEditor setHideFooter={setHideFooter} />}
        />
      </Routes>
    </MyFamilyTreeSubmissionServiceProvider>
  );
}

interface MyFamilyTreePrivatePatientRouterWithAuthProviderProps {
  patientName: string;
  setHideFooter: (hide: boolean) => void;
}

function MyFamilyTreePrivatePatientRouterWithAuthProvider(
  props: MyFamilyTreePrivatePatientRouterWithAuthProviderProps,
) {
  return (
    <>
      <Stack sx={{ flex: 1 }} direction="column">
        <BankidAuthProvider
          containerMaxWidth="sm"
          beforeOnLaunchPicker={
            <BankidLaunchTitle patientName={props.patientName || ''} />
          }
        >
          <MyFamilyTreePrivatePatientRouter
            setHideFooter={props.setHideFooter}
          />
        </BankidAuthProvider>
      </Stack>
    </>
  );
}

interface BankidLaunchTitleProps {
  patientName: string;
}

function BankidLaunchTitle(props: BankidLaunchTitleProps) {
  const { t } = useTranslation(['myFamilyTree']);
  return (
    <>
      <Typography variant="h2">
        {t('my-family-tree-submission.welcome.title', {
          name: props.patientName,
        })}
      </Typography>
      <Typography>{t('my-family-tree-submission.welcome.content')}</Typography>
    </>
  );
}

export default MyFamilyTreePatientRouter;
