import { Stack } from '@mui/material';
import { AutoCompleteInput, TextInput } from 'components/inputs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useTheme, useMediaQuery } from '@mui/material';

interface SortAndSearchInputViewProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  sortValue: string;
  setSortValue: (value: string) => void;
}

function SortAndSearchInputView(props: SortAndSearchInputViewProps) {
  const { t } = useTranslation(['all']);
  const theme = useTheme();

  const sortOptions = [
    {
      label: t('sort.patients-surname.a-z-ascending'),
      value: 'patientName:ASC',
    },
    {
      label: t('sort.patients-surname.z-a-descending'),
      value: 'patientName:DESC',
    },
    { label: t('sort.date-created.newest'), value: 'createdAt:DESC' },
    { label: t('sort.date-created.oldest'), value: 'createdAt:ASC' },
  ];
  const { searchValue, setSearchValue, sortValue, setSortValue } = props;
  const [currentSearchValue, setCurrentSearchValue] =
    React.useState(searchValue);

  useDebounce(
    () => {
      if (currentSearchValue.length === 0 || currentSearchValue.length > 2)
        setSearchValue(currentSearchValue);
    },
    500,
    [currentSearchValue],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(event.target.value);
  };

  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Stack
      justifyContent="flex-start"
      gap={4}
      sx={{ flexDirection: { sm: 'row' } }}
    >
      <AutoCompleteInput
        label={t('sort.sort-menu-label')}
        value={sortValue}
        onChange={(val) => {
          if (!val) return;
          setSortValue(val);
        }}
        options={sortOptions}
        id="consent-management-sort"
        inlineLabel={isNotMobile}
        labelBefore
        autocompleteProps={{
          disableClearable: true,
          sx: { minWidth: 300 },
        }}
        fullWidth={false}
        readOnlyInput
      />
      <TextInput
        label={t('search.search')}
        value={currentSearchValue}
        onChange={handleSearchChange}
        id="consent-management-search"
        inlineLabel={isNotMobile}
        labelBefore
        fullWidth={false}
        placeholder={t('search.placeholder-text')}
        textFieldProps={{ sx: { minWidth: 350 } }}
      />
    </Stack>
  );
}

export default SortAndSearchInputView;
