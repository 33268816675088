import { faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack } from '@mui/material';
import { usePdfService } from 'api/pdf/PdfServiceProvider';

interface Props {
  pdfLocationUrl?: string;
  pdfFileName?: string;
  showViewPdfButton?: boolean;
  onPdfActionClick?: () => void;
}

function PdfActionsController(props: Props) {
  const { downloadPdf, printPdf, viewPdf } = usePdfService();
  const disabled = !props.pdfLocationUrl;
  const fileName = props.pdfFileName || 'consent.pdf';

  const handleViewPdfClick = () => {
    if (props.pdfLocationUrl) {
      viewPdf(props.pdfLocationUrl, fileName);
      props.onPdfActionClick?.();
    }
  };

  const handleDownloadPdfClick = () => {
    if (props.pdfLocationUrl) {
      downloadPdf(props.pdfLocationUrl, fileName);
      props.onPdfActionClick?.();
    }
  };

  const handlePrintPdfClick = () => {
    if (props.pdfLocationUrl) {
      printPdf(props.pdfLocationUrl);
      props.onPdfActionClick?.();
    }
  };

  return (
    <Stack direction="row" spacing={1} display="inline">
      {props.showViewPdfButton && (
        <IconButton
          color="primary"
          size="small"
          disabled={disabled}
          onClick={handleViewPdfClick}
        >
          <FontAwesomeIcon icon={faEye} />
        </IconButton>
      )}
      <IconButton
        color="primary"
        size="small"
        disabled={disabled}
        onClick={handleDownloadPdfClick}
      >
        <FontAwesomeIcon icon={faDownload} />
      </IconButton>
      <IconButton
        color="primary"
        size="small"
        disabled={disabled}
        onClick={handlePrintPdfClick}
      >
        <FontAwesomeIcon icon={faPrint} />
      </IconButton>
    </Stack>
  );
}

export default PdfActionsController;
