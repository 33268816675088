import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { useState } from 'react';

interface ExplanationSectionProps {
  explanationText: string[];
  buttonText: string;
}

function ExplanationSection(props: ExplanationSectionProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Stack spacing={0} alignItems="flex-start">
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        endIcon={
          <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
        }
        sx={{
          fontSize: '14px',
        }}
        variant="text"
      >
        {props.buttonText}
      </Button>
      <Collapse in={isExpanded}>
        <Stack spacing={2} p={1}>
          {props.explanationText.map((text, index) => (
            <Typography key={index} variant="body2">
              {text}
            </Typography>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

export default ExplanationSection;
