interface FemaleIconProps {
  color: string;
}

const FemaleIcon: React.FC<FemaleIconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.8669 7.21094C13.3058 7.21094 14.4724 6.04443 14.4724 4.60547C14.4724 3.16651 13.3058 2 11.8669 2C10.4279 2 9.26141 3.16651 9.26141 4.60547C9.26141 6.04443 10.4279 7.21094 11.8669 7.21094Z" />
      <path d="M17.691 15.4141L15.4254 9.58984C15.1597 8.55078 14.2261 7.82422 13.1558 7.82422H10.5777C9.50739 7.82422 8.56989 8.55078 8.30817 9.58984L6.04255 15.4141C5.8238 16.2773 6.47614 17.1172 7.36677 17.1172H9.25739V20.8281C9.25739 21.4766 9.78083 22 10.4293 22H13.3004C13.9488 22 14.4722 21.4766 14.4722 20.8281V17.1172H16.3668C17.2574 17.1172 17.9097 16.2773 17.691 15.4141Z" />
    </svg>
  );
};

FemaleIcon.defaultProps = {
  color: 'white',
};

export default FemaleIcon;
