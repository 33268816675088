import { Autocomplete, TextField } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React from 'react';
import { useRelationshipOptions } from '../hooks/useRelationshipOptions';

interface Props {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

const RelationshipInput = ({
  label,
  value,
  onChange,
  error,
  required,
  disabled,
  placeholder,
}: Props) => {
  const { relationshipOptions: options } = useRelationshipOptions();

  const randomId = React.useId();
  const id = `relationship-input-${randomId}`;
  return (
    <InputWrapper
      label={label}
      childId={id}
      required={required}
      error={error}
      fullWidth={true}
    >
      <Autocomplete
        id={id}
        value={value}
        onInputChange={(_, newValue) => {
          onChange(newValue);
        }}
        options={options}
        disabled={disabled}
        renderInput={(params) => (
          <TextField error={!!error} {...params} size="small" placeholder={placeholder} />
        )}
        freeSolo
      />
    </InputWrapper>
  );
};

export default RelationshipInput;
