import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { useTabNavigation } from 'components/tabNavigation/hooks/useTabNavigation';
import { TabSectionPanel } from 'components/tabNavigation/views/TabSectionPanel';
import { CaseTab } from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/CaseTab';
import {
  PedigreeCasePageNavBar,
  TabValue,
} from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/PedigreeCasePageNavBar';
import { PedigreeTab } from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/PedigreeTab';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const tabNames: TabValue[] = ['case', 'pedigree'];

export const PedigreeCasePage = () => {
  const { currentTab, handleChange } = useTabNavigation<TabValue>(
    'case',
    tabNames,
  );
  const navigate = useNavigate();

  const service = useStaffPedigreeCaseService();

  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();

  const { data, isLoading } = useQuery(['pedigreeCase', pedigreeCaseId], () =>
    service.getPedigreeCase(pedigreeCaseId || ''),
  );

  if (isLoading) return <LoadingBackdrop />;

  // FIXME: Handle error state
  if (!(data?.id === pedigreeCaseId)) return null;

  return (
    <>
      <PedigreeCasePageNavBar
        currentTab={currentTab}
        handleChange={handleChange}
        onBackButtonClick={() => {
          navigate('/?tab=all-patient');
        }}
        patientName={data?.patientDetails?.name as string}
      />
      <TabSectionPanel currentValue={currentTab} value="case">
        <CaseTab pedigreeCaseStatus={data?.status} />
      </TabSectionPanel>
      <TabSectionPanel currentValue={currentTab} value="pedigree">
        <PedigreeTab />
      </TabSectionPanel>
    </>
  );
};
