import { Stack, Typography, Button, useTheme, useMediaQuery  } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConsentManagementHeaderProps {
  navigate: (path: string) => void;
}

function ConsentManagementHeader({ navigate }: ConsentManagementHeaderProps) {
  const { t } = useTranslation(['all']);
  const theme = useTheme();

  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Stack direction={isNotMobile ? "row": "column"} justifyContent="space-between" alignItems="center">
      <Typography variant="h1">
        {t('consent-management.dashboard.title')}
      </Typography>
      <Button
        sx={{ marginTop: isNotMobile ? "0px" : "16px"}}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          navigate('/new-patient');
        }}
      >
        {t('consent-management.dashboard.new-consent')}
      </Button>
    </Stack>
  );
}

export default ConsentManagementHeader;
