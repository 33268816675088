import { Container, Link, Stack, Typography } from '@mui/material';
import completedImage from 'assets/images/consent-completed-check.png';
import { PedigreeSaveProgress } from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import { useTranslation } from 'react-i18next';

interface PedigreeCaseConfirmationViewProps {
  pedigreeSaveProgress?: PedigreeSaveProgress;
}

export const PedigreeCaseConfirmationView = (
  props: PedigreeCaseConfirmationViewProps,
) => {
  const { t } = useTranslation(['myFamilyTree']);

  const status = !!props.pedigreeSaveProgress?.pedigreeCaseUrl
    ? 'saved'
    : 'received';

  return (
    <Stack justifyContent={'space-between'}>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 594,
        }}
      >
        <Stack alignItems="center" spacing={4}>
          {status === 'received' && (
            <img src={completedImage} alt="completed checked" />
          )}
          <Typography variant="h1">
            {t(`my-family-tree-pedigree-confirm.${status}.title`)}
          </Typography>
          <>
            <Typography>
              {t(`my-family-tree-pedigree-confirm.${status}.description`)}
            </Typography>
            {props.pedigreeSaveProgress?.pedigreeCaseUrl && (
              <Link href={props.pedigreeSaveProgress.pedigreeCaseUrl}>
                {props.pedigreeSaveProgress.pedigreeCaseUrl}
              </Link>
            )}
          </>
          {props.pedigreeSaveProgress?.emailSent && (
            <Typography>
              {t(`my-family-tree-pedigree-confirm.${status}.info`)}
            </Typography>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
