import { Button, Stack } from '@mui/material';
import { Diagnose } from 'api/pedigreeCase/staff-pedigree-case-service';
import Dialog from 'components/common/Dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiagnosisFormFields, DiseaseOption } from './DiagnosisFormFields';
import { DiagnosisFormHeader } from './DiagnosisFormHeader';
import DiagnosisReadonly from './DiagnosisReadonly';
import {
  DiagnoseValue,
  DiagnosisFormData,
  DiagnosisFormTexts,
  DiagnosisItemErrors,
  FieldStyles,
} from './types';

interface DiagnosisFormItemProps {
  diseaseOptions: DiseaseOption[];
  diagnoses: DiagnosisFormData;
  errors: DiagnosisItemErrors;
  showDeleteButton?: boolean;
  readonly?: boolean;
  textsProvided?: DiagnosisFormTexts;
  requiredFields?: {
    [key in keyof Diagnose]?: boolean;
  };
  fieldStyles?: {
    [key in keyof Diagnose]?: FieldStyles;
  };
  titleItemNumber: number;
  deleteDiagnosis: () => void;
  onDiagnoseFieldChange: <K extends keyof Diagnose>(
    key: K,
    value: DiagnoseValue[K],
  ) => void;
}

export const DiagnosisFormItem = (props: DiagnosisFormItemProps) => {
  const {
    diagnoses,
    diseaseOptions,
    textsProvided,
    fieldStyles,
    showDeleteButton,
    readonly,
    errors,
    titleItemNumber,
    onDiagnoseFieldChange,
    deleteDiagnosis,
  } = props;

  const { t } = useTranslation(['diagnosisForm']);

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);

  const { title, ...fieldTexts } = textsProvided ?? {};

  const formTitle =
    title || t('diagnosis-form.title', { number: `${titleItemNumber}` });

  const handleDialogClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const deleteDialogProps = useMemo(() => {
    return {
      open: isOpenDeleteDialog,
      title: t('dialog-delete.title'),
      content: t('dialog-delete.content'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={() => {
            deleteDiagnosis();
            handleDialogClose();
          }}
        >
          {t('dialog-delete.button.delete')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={handleDialogClose}
        >
          {t('dialog-delete.button.cancel')}
        </Button>,
      ],
    };
  }, [deleteDiagnosis, isOpenDeleteDialog, t]);

  const isDirty = useMemo(() => {
    return (
      diagnoses.disease !== '' ||
      diagnoses.ageAtDiagnosis !== null ||
      diagnoses.treatedBy !== '' ||
      diagnoses.ageIsApproximate ||
      diagnoses.notes !== ''
    );
  }, [diagnoses]);

  const handleDeleteDiagnosis = () => {
    if (isDirty) {
      setIsOpenDeleteDialog(true);
      return;
    }
    deleteDiagnosis();
  };

  const isReadyOnly = diagnoses.readonly || readonly;

  return (
    <>
      <Stack gap={2}>
        <DiagnosisFormHeader
          onDeleteDiagnosisClick={handleDeleteDiagnosis}
          title={formTitle}
          subtitle={diagnoses.status}
          showDeleteButton={showDeleteButton && !isReadyOnly}
        />
        {isReadyOnly ? (
          <DiagnosisReadonly
            diagnosisData={diagnoses}
            fieldTexts={fieldTexts}
          />
        ) : (
          <DiagnosisFormFields
            item={diagnoses}
            diseaseOptions={diseaseOptions}
            handleOnchange={onDiagnoseFieldChange}
            errors={errors}
            fieldTexts={fieldTexts}
            fieldStyles={fieldStyles}
            requiredFields={props.requiredFields}
          />
        )}
      </Stack>
      <Dialog {...deleteDialogProps} />
    </>
  );
};
