import {
  faCircleQuestion,
  faDownload,
  faMinimize,
  faPrint,
  faSearchMinus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, IconButton, Stack, styled, useTheme } from '@mui/material';

interface PedigreeControlButtonsViewProps {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleZoomReset: () => void;
  handlePrint?: () => void;
  handleDownload?: () => void;
  onDrawerOpen?: () => void;
  direction?: 'row' | 'column';
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.primary.main,
}));

const PedigreeControlButtonsView = ({
  handleZoomIn,
  handleZoomOut,
  handlePrint,
  handleZoomReset,
  handleDownload,
  onDrawerOpen,
  direction = 'row',
}: PedigreeControlButtonsViewProps) => {
  const theme = useTheme();
  return (
    <Stack
      gap={1}
      direction={direction}
      sx={{
        position: 'absolute',
        zIndex: 100,
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      }}
    >
      <Card>
        <Stack direction={direction}>
          <StyledIconButton onClick={handleZoomIn}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </StyledIconButton>
          <StyledIconButton onClick={handleZoomOut}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </StyledIconButton>
          <StyledIconButton onClick={handleZoomReset}>
            <FontAwesomeIcon icon={faMinimize} />
          </StyledIconButton>
        </Stack>
      </Card>
      {(handlePrint || handleDownload) && (
        <Card>
          <Stack direction={direction}>
            {handlePrint && (
              <StyledIconButton onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} />
              </StyledIconButton>
            )}
            {handleDownload && (
              <StyledIconButton onClick={handleDownload}>
                <FontAwesomeIcon icon={faDownload} />
              </StyledIconButton>
            )}
          </Stack>
        </Card>
      )}
      {onDrawerOpen && (
        <Card>
          <Stack direction={direction}>
            <StyledIconButton onClick={() => onDrawerOpen()}>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </StyledIconButton>
          </Stack>
        </Card>
      )}
    </Stack>
  );
};

export default PedigreeControlButtonsView;
