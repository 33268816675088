import { Box, useTheme } from '@mui/material';
import { FormHelper } from 'components/forms';
import { InputWrapper } from 'components/inputs';
import { TextInputProps } from 'components/inputs/TextInput';
import React from 'react';

type TextAreaInputProps = Omit<
  TextInputProps,
  'textFieldProps' | 'onChange'
> & {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  helperText?: string;
};

function TextAreaInput({
  label,
  id,
  value,
  onChange,
  error,
  required = false,
  placeholder,
  labelInfo,
  disabled = false,
  helperText,
}: TextAreaInputProps) {
  const theme = useTheme();
  const randomId = React.useId();
  id = `${id}-${randomId}`;

  return (
    <InputWrapper
      label={label}
      labelInfo={labelInfo}
      childId={id}
      error={error}
      required={required}
      disabled={disabled}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textarea: {
            fontFamily: theme.typography.fontFamily,
            width: '100%',
            boxSizing: 'border-box',
            padding: `8.5px ${theme.spacing(1.75)}`,
            backgroundColor: 'white',
            borderRadius: theme.shape.borderRadius + 'px',
            resize: 'none',
            color: theme.palette.text.primary,
            borderColor: theme.palette.grey[400],
            ':hover:enabled': {
              borderColor: theme.palette.text.primary,
            },
            ':focus': {
              outlineColor: theme.palette.common.brand.blue,
            },
            ':disabled': {
              color: theme.palette.text.disabled,
            },
            fontWeight: 300,
          },
        }}
      >
        <textarea
          value={value as string}
          onChange={onChange}
          id={id}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          rows={5}
        />
        {helperText && <FormHelper>{helperText}</FormHelper>}
      </Box>
    </InputWrapper>
  );
}

export default TextAreaInput;
