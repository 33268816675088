import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactNode } from 'react';

type Cell = {
  cell: string | ReactNode;
  action?: () => void;
  cellProps?: TableCellProps;
};

export type Row = Cell[];
interface BasicTableProps {
  headings: string[];
  rows: Row[];
}

const BasicTable = (props: BasicTableProps) => {
  const { headings, rows } = props || {};

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headings?.map((row, index) => (
              <TableCell key={`heading-${index}`}>{row}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((cells, rowIdx) => {
            return (
              <TableRow key={`row-${rowIdx}`}>
                {cells.map(({ cell, action, cellProps }, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    {...cellProps}
                    onClick={action && action}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
