import { BankidLaunchMethod } from 'api/bankid/bankid-service';
import { useConsentSubmissionService } from 'api/consentSubmission/consentSubmissionServiceProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { useBankidAuth } from 'features/auth/bankid/AuthProvider';
import RelativeInformationFormController from 'features/consentSubmission/controllers/RelativeInformationFormController';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ConsentOverviewView from '../views/ConsentOverviewView';
import NotAllowedToSignConsentView from '../views/NotAllowedToSignConsentView';
import ConsentSubmissionSigningController from './ConsentSubmissionSigningController';
import ConsentSubmittedConfirmationController from './ConsentSubmittedConfirmationController';

interface ConsentSubmissionControllerProps {
  consentId: string;
}

function ConsentSubmissionController(props: ConsentSubmissionControllerProps) {
  const service = useConsentSubmissionService();
  const queryClient = useQueryClient();
  const { consentId } = props;
  const { preferredBankidLaunchMethod } = useBankidAuth();

  const [bankidLaunchMethod, setBankidLaunchMethod] = useState<
    BankidLaunchMethod | undefined
  >(preferredBankidLaunchMethod);

  const [submissionState, setSubmissionState] = useState<
    'form' | 'consent-overview' | 'sign' | 'confirmation'
  >('form');

  const {
    data: consent,
    isLoading,
    error,
  } = useQuery('consent', () => service.getConsent(consentId), {
    onSuccess: (data) => {
      if (data.status !== 'pending') {
        setSubmissionState('confirmation');
      }
    },
  });

  const declineMutation = useMutation(
    () => service.declineConsent({ consentId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('consent');
        setSubmissionState('confirmation');
      },
    },
  );

  if (error) return <NotAllowedToSignConsentView />;

  if (isLoading || consent === undefined) return <LoadingBackdrop />;

  const handleSignClick = (bankidLaunchMethod: BankidLaunchMethod) => {
    setBankidLaunchMethod(bankidLaunchMethod);
    setSubmissionState('sign');
  };

  const handleSignCompleted = () => {
    queryClient.invalidateQueries('consent');
  };

  switch (submissionState) {
    case 'form':
      return (
        <RelativeInformationFormController
          consentId={consentId}
          onSubmitSuccess={() => setSubmissionState('consent-overview')}
        />
      );
    case 'consent-overview':
      return (
        <ConsentOverviewView
          consent={consent}
          onBackClick={() => setSubmissionState('form')}
          onSignClick={handleSignClick}
          onConfirmDeclineClick={() => declineMutation.mutate()}
        />
      );
    case 'sign':
      return (
        <ConsentSubmissionSigningController
          consentId={consentId}
          onSignCompleted={handleSignCompleted}
          backToOverview={() => setSubmissionState('consent-overview')}
          bankidLaunchMethod={bankidLaunchMethod || 'autoStart'}
        />
      );
    case 'confirmation':
      return <ConsentSubmittedConfirmationController consentId={consentId} />;
  }
}

export default ConsentSubmissionController;
