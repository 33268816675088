import { Container, Stack, Toolbar, useTheme } from '@mui/material';
import { useTabNavigation } from 'components/tabNavigation/hooks/useTabNavigation';
import { TabNavBar } from 'components/tabNavigation/views/TabNavBar';
import { TabSectionPanel } from 'components/tabNavigation/views/TabSectionPanel';
import PatientListController from 'myfamilytree/features/myFamilyTreeManagement/patientList/controllers/PatientListController';
import PatientUpdatedListController from 'myfamilytree/features/myFamilyTreeManagement/patientList/controllers/PatientUpdatedListController';
import FamilyTreeHeader from 'myfamilytree/header/FamilyTreeHeader';
import { useTranslation } from 'react-i18next';

type TabValue = 'updates' | 'all-patient';
const listNameTabs: TabValue[] = ['updates', 'all-patient'];

function Dashboard() {
  const theme = useTheme();
  const { t } = useTranslation(['myFamilyTree']);

  const { currentTab, handleChange } = useTabNavigation<TabValue>(
    'updates',
    listNameTabs,
  );

  return (
    <Container>
      <FamilyTreeHeader tab={currentTab} />
      <Stack direction="column" spacing={3}>
        <Toolbar sx={{ [theme.breakpoints.up('sm')]: { paddingLeft: '0px' } }}>
          <TabNavBar<TabValue>
            value={currentTab}
            handleChange={handleChange}
            tabs={[
              { label: t('overview.tab.update'), value: 'updates' },
              { label: t('overview.tab.allPatient'), value: 'all-patient' },
            ]}
            tabsProps={{ variant: 'scrollable' }}
          />
        </Toolbar>

        <TabSectionPanel currentValue={currentTab} value="updates">
          <PatientUpdatedListController />
        </TabSectionPanel>
        <TabSectionPanel currentValue={currentTab} value="all-patient">
          <PatientListController />
        </TabSectionPanel>
      </Stack>
    </Container>
  );
}

export default Dashboard;
