import { Sex } from 'pedigree/features/common/types';
import { mockPedigree } from 'pedigree/features/common/utils/mock-pedigree';
import { createPedigreeLayout } from 'pedigree/features/common/utils/pedigree-layout';
import PedigreeEdges from 'pedigree/features/common/views/PedigreeEdges';
import { Circle, Group, Layer, Rect, Stage, Text } from 'react-konva';

const NODE_SIZE = 20;
const STAGE_OFFSET = 30;

function PedigreePlayground() {
  const layout = createPedigreeLayout(mockPedigree, {
    node: { width: 60, height: 60 },
    minGap: { horizontal: 92, vertical: 92 },
    sibshipGap: 18,
    sibshipGapWithPartnershipArcs: 30,
    overlappingSibshipGapOffset: 10,
    partnershipArc: { startOffset: 18, endOffset: 18 },
    nodeLabel: { marginBottom: 24 },
  });
  const pseudoNodes = Object.values(layout.nodes).filter((node) =>
    node.id.startsWith('p.'),
  );
  const links = layout.links;

  return (
    <Stage
      width={layout.width + STAGE_OFFSET * 2}
      height={layout.height + STAGE_OFFSET * 2}
      offset={{ x: -STAGE_OFFSET, y: -STAGE_OFFSET }}
    >
      <Layer>
        <PedigreeEdges
          links={links}
          strokeOptions={{
            width: 1,
            color: 'black',
          }}
        />
        {mockPedigree.map((node) => {
          const { pos } = layout.nodes[node.id];
          return (
            <Group
              key={node.id}
              x={pos.x}
              y={pos.y}
              offsetX={NODE_SIZE / 2}
              offsetY={NODE_SIZE / 2}
            >
              {node.sex === Sex.MALE ? (
                <Rect
                  width={NODE_SIZE}
                  height={NODE_SIZE}
                  fill="white"
                  stroke="black"
                  strokeWidth={1}
                />
              ) : (
                <Circle
                  width={NODE_SIZE}
                  height={NODE_SIZE}
                  offset={{ x: -NODE_SIZE / 2, y: -NODE_SIZE / 2 }}
                  fill="white"
                  stroke="black"
                  strokeWidth={1}
                />
              )}
              <Text text={node.id} />
            </Group>
          );
        })}
        {pseudoNodes.map((node) => {
          const { pos } = layout.nodes[node.id];
          return (
            <Group key={node.id} x={pos.x} y={pos.y}>
              <Circle
                width={2}
                height={2}
                fill="red"
                stroke="red"
                strokeWidth={1}
              />
              <Text text={node.id} />
            </Group>
          );
        })}
      </Layer>
    </Stage>
  );
}

export default PedigreePlayground;
