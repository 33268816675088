import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, Stack, TextFieldProps } from '@mui/material';
import { AutoCompleteInput, TextInput } from 'myfamilytree/common/index';

type SortOption = {
  label: string;
  value: string;
};

interface SortAndSearchInputViewProps {
  sortValue: string;
  setSortValue: (value: string) => void;
  sortOptions: SortOption[];
  sortLabel: string;
  sortFieldProps?: any;
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchPlaceholder: string;
  searchLabel: string;
  searchFieldProps?: TextFieldProps;
  inlineLabel?: boolean;
}

function SortAndSearchInputView(props: SortAndSearchInputViewProps) {
  return (
    <Stack
      justifyContent="flex-start"
      gap={4}
      sx={{ flexDirection: { sm: 'row' } }}
    >
      <AutoCompleteInput
        label={props.sortLabel}
        value={props.sortValue}
        onChange={(val) => {
          if (!val) return;
          props.setSortValue(val);
        }}
        options={props.sortOptions}
        id="sort-input"
        inlineLabel={props.inlineLabel}
        labelBefore
        autocompleteProps={{
          disableClearable: true,
          ...props.sortFieldProps,
        }}
        fullWidth={false}
        readOnlyInput
      />
      <TextInput
        label={props.searchLabel}
        value={props.searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setSearchValue(event.target.value);
        }}
        id="search-input"
        inlineLabel={props.inlineLabel}
        labelBefore
        fullWidth={false}
        placeholder={props.searchPlaceholder}
        textFieldProps={{
          ...props.searchFieldProps,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          },
        }}
      />
    </Stack>
  );
}

export default SortAndSearchInputView;
