import { Backdrop, CircularProgress } from '@mui/material';

interface LoadingBackdropProps {
  open?: boolean;
}

function LoadingBackdrop(props: LoadingBackdropProps) {
  const open = typeof props.open === 'boolean' ? props.open : true;
  return (
    <Backdrop open={open} sx={{ zIndex: 100 }}>
      <CircularProgress />
    </Backdrop>
  );
}

export default LoadingBackdrop;
