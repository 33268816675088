import { Circle, Ellipse, Group, Line } from 'react-konva';
import { PedigreeLayoutLink } from '../utils/pedigree-layout';

type StrokeOptions = {
  width: number;
  color: string;
};

interface RendererLineProps {
  links?: PedigreeLayoutLink[];
  strokeOptions: StrokeOptions;
}

const PedigreeEdges = (props: RendererLineProps) => {
  return (
    <>
      {props?.links?.map((link, idx) => {
        if (link.type === 'line') {
          return (
            <PedigreeLineEdge
              key={`link-${idx}`}
              points={link.points}
              strokeOptions={props.strokeOptions}
            />
          );
        }
        if (link.type === 'arc') {
          return (
            <PedigreeArcEdge
              key={`link-${idx}`}
              points={link.points}
              strokeOptions={props.strokeOptions}
            />
          );
        }
        if (link.type === 'crossing') {
          return (
            <PedigreeCrossings
              key={`link-${idx}`}
              points={link.points}
              strokeOptions={props.strokeOptions}
            />
          );
        }
        return null;
      })}
    </>
  );
};

interface PedigreeEdgeProps {
  points: number[];
  strokeOptions: StrokeOptions;
}

function PedigreeLineEdge({ points, strokeOptions }: PedigreeEdgeProps) {
  return (
    <Line
      points={points}
      stroke={strokeOptions.color}
      strokeWidth={strokeOptions.width}
    />
  );
}

function PedigreeArcEdge({ points, strokeOptions }: PedigreeEdgeProps) {
  const [
    startX,
    startY,
    ellipseEdge1x,
    ellipseEdge1y,
    ellipseEdge2x,
    ellipseEdge2y,
    endX,
    endY,
  ] = points;
  const xCenter = (ellipseEdge1x + ellipseEdge2x) / 2;
  const yCenter = (ellipseEdge1y + ellipseEdge2y) / 2;
  const radius = Math.abs(ellipseEdge1x - xCenter);
  const clipX = Math.min(ellipseEdge1x, ellipseEdge2x) - 4;
  const strokeProps = {
    stroke: strokeOptions.color,
    strokeWidth: strokeOptions.width,
  };

  return (
    <>
      <Line
        points={[startX, startY, ellipseEdge1x, ellipseEdge1y]}
        {...strokeProps}
      />
      <Group
        clipX={clipX}
        clipY={ellipseEdge1y - 52}
        clipHeight={52}
        clipWidth={2.1 * radius}
      >
        <Ellipse
          x={xCenter}
          y={yCenter}
          radiusX={radius}
          radiusY={50}
          {...strokeProps}
        />
      </Group>
      <Line
        points={[ellipseEdge2x, ellipseEdge2y, endX, endY]}
        {...strokeProps}
      />
    </>
  );
}

function PedigreeCrossings({ points, strokeOptions }: PedigreeEdgeProps) {
  const clipOffset = 7;
  const clipX = points[0] - clipOffset;
  const clipY = points[1] - clipOffset;
  return (
    <Group
      clipX={clipX}
      clipWidth={clipOffset}
      clipY={clipY}
      clipHeight={clipOffset * 2}
    >
      <Circle
        x={points[0]}
        y={points[1]}
        width={12}
        stroke={strokeOptions.color}
        strokeWidth={strokeOptions.width}
      />
    </Group>
  );
}

export default PedigreeEdges;
