import SendRequest from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendRequest';
import { useParams } from 'react-router-dom';

const SendRequestPage = () => {
  const { pedigreeCaseId } = useParams();
  if (!pedigreeCaseId) {
    throw new Error('Pedigree case id is undefined');
  }

  return <SendRequest pedigreeCaseId={pedigreeCaseId} />;
};

export default SendRequestPage;
