import { Box, Button, Container, Stack, Typography } from '@mui/material';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import SectionBox from 'components/common/SectionBox';
import { RelativeFormData } from 'features/consentManagement/common/types';
import RelativesFormSectionView from 'features/consentManagement/common/views/RelativesSectionFormView';
import { useTranslation } from 'react-i18next';
import ButtonRowView from './ButtonRowView';

interface AddRelativesFormViewProps {
  onBackButtonClick: () => void;
  onContinueClick: () => void;
  patientName: string;
  patientPersonalNumber: string;
  relatives: RelativeFormData[];
  errors: Array<{ [key: string]: string }>;
  handleAddRelative: () => void;
  handleDeleteRelative: (idx: number) => void;
  handleRelativeChange: (idx: number, data: RelativeFormData) => void;
}

const AddRelativesFormView = (props: AddRelativesFormViewProps) => {
  const { t } = useTranslation('all');
  return (
    <Container>
      <Stack direction="column" spacing={4}>
        <HeaderWithBackButton
          headerVariant="h1"
          onBackButtonClick={props.onBackButtonClick}
        >
          {t('consent.new-edit.add-relatives-title')}
        </HeaderWithBackButton>
        <SectionBox>
          <Stack direction="column" spacing={3}>
            <Typography variant="h2">
              1. {t('personal-information.patient-single')}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Typography variant="h4">{props.patientName}</Typography>
              <Typography variant="body1">
                {t('ui-generic.personal-number')}: {props.patientPersonalNumber}
              </Typography>
            </Stack>
          </Stack>
        </SectionBox>
        <RelativesFormSectionView
          title={'2. ' + t('consent.new-edit.add-relatives-title')}
          data={props.relatives}
          errors={props.errors}
          handleRelativeChange={props.handleRelativeChange}
          handleAddRelativeClick={props.handleAddRelative}
          handleRelativeDeleteClick={props.handleDeleteRelative}
          disabled={false}
        />

        <Box>
          <ButtonRowView
            ButtonOne={
              <Button
                variant="outlined"
                onClick={props.onBackButtonClick}
                fullWidth
              >
                {t('ui-generic.cancel')}
              </Button>
            }
            ButtonTwo={
              <Button
                variant="contained"
                onClick={props.onContinueClick}
                fullWidth
              >
                {t('ui-generic.continue')}
              </Button>
            }
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default AddRelativesFormView;
