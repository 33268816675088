import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { TextInput } from 'components/inputs';
import PasswordInput from 'components/inputs/PasswordInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from './AuthLayout';

interface SignInViewProps {
  loginFormRef: React.RefObject<HTMLFormElement>;
  isLoggingIn: boolean;
  handleLogin: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLFormElement>,
  ) => void;
  loginError?: string;
  clearError?: () => void;
}

function SigninView({
  loginFormRef,
  isLoggingIn,
  handleLogin,
  loginError,
  clearError,
}: SignInViewProps) {
  const { t } = useTranslation(['all']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleLogin(event);
    }
  };

  return (
    <AuthLayout>
      <form ref={loginFormRef} onKeyDown={handleKeyDown}>
        <Stack direction="column" spacing={2} sx={{ minWidth: 300 }}>
          <TextInput
            label={t('signin.email')}
            id="email"
            textFieldProps={{
              disabled: isLoggingIn,
              InputProps: { name: 'email' },
              onChange: clearError,
            }}
          />
          <PasswordInput
            label={t('signin.password')}
            disabled={isLoggingIn}
            error={loginError}
            clearError={clearError}
          />

          <LoadingButton
            variant="contained"
            color="primary"
            loading={isLoggingIn}
            onClick={handleLogin}
          >
            {t('signin.login')}
          </LoadingButton>
        </Stack>
      </form>
    </AuthLayout>
  );
}

export default SigninView;
