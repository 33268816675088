import { Box, Drawer } from '@mui/material';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { Pedigree } from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import { PedigreeNode } from 'pedigree/features/common/types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import RelativeDiagnosisFormController from '../../relativeDiagnosisForm/controller/RelativeDiagnosisFormController';
import { PedigreeCancerHistoryDrawerContentView } from '../views/PedigreeCancerHistoryDrawerContentView';
import { PedigreeCreatedDrawerContentView } from '../views/PedigreeCreatedDrawerContentView';
import { PedigreeEditingInfoDrawerContentView } from '../views/PedigreeEditingInfoDrawerContentView';
import { usePedigreeSubmissionRelativeDiagnosis } from './usePedigreeSubmissionRelativeDiagnosis';

interface usePedigreeDrawerProps {
  pedigree: Pedigree;
}

export const usePedigreeDrawer = (props: usePedigreeDrawerProps) => {
  const service = useMyFamilyTreeSubmissionService();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation('myFamilyTree');
  const pedigreeId = props.pedigree?.id;
  const nodes = props.pedigree?.nodes;

  const relativeDisease = localStorage.getItem('relativeDisease');
  const relativeDiseaseValue =
    relativeDisease === 'yes' ? 'cancerHistory' : 'created';
  const relativeDiseaseStatus = relativeDisease ? relativeDiseaseValue : null;

  const [pedigreeNodesWithCancerHistory, setPedigreeNodesWithCancerHistory] =
    useState<string[]>([]);
  const [drawerStatus, setDrawerStatus] = useState<
    'cancerHistory' | 'created' | 'infoEditing' | 'relativeDiagnosisForm' | null
  >(relativeDiseaseStatus);
  const [relativeLabel, setRelativeLabel] = useState<string>('');
  const [pedigreeNodeId, setPedigreeNodeId] = useState<string | undefined>(
    undefined,
  );
  const [relativeFormData, setRelativeFormData] = useState<any>({});
  const [previousNodeSelected, setPreviousNodeSelected] = useState<string>('');
  const [deselectAllNode, setDeselectAllNode] = useState(false);

  const drawerIsOpen = !!drawerStatus;

  const handleCloseDrawer = () => {
    setDrawerStatus(null);
  };

  const {
    submitNodeRelativeDiagnosis,
    submissionRelativeFailedDialog,
    isButtonSubmit,
  } = usePedigreeSubmissionRelativeDiagnosis({
    pedigreeIds: {
      pedigreeId,
      nodeId: pedigreeNodeId,
    },
    relativeFormData,
    relativeLabel,
    handleCloseDrawer,
    setRelativeFormData,
    setPreviousNodeSelected,
  });

  const cancerDiagnosisIsMissing = useMemo(() => {
    const isDiagnosisMissing = nodes?.filter(
      ({ hasDiagnoseHistory, diagnoses }) => {
        const validDiagnoses = diagnoses?.filter(
          ({ diseaseName }) =>
            !!diseaseName &&
            diseaseName !== 'null' &&
            diseaseName !== 'undefined',
        );
        return !!hasDiagnoseHistory && !validDiagnoses.length;
      },
    );

    return !!isDiagnosisMissing.length;
  }, [nodes]);

  const updateNodeRelativeDisease = (
    isSelected: boolean,
    pedigreeNode: PedigreeNode,
  ) => {
    if (isSelected) {
      return setPedigreeNodesWithCancerHistory([
        ...pedigreeNodesWithCancerHistory,
        pedigreeNode.id,
      ]);
    } else {
      return setPedigreeNodesWithCancerHistory(
        pedigreeNodesWithCancerHistory.filter((id) => id !== pedigreeNode.id),
      );
    }
  };

  const updateNodeRelativeDiagnosis = (
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    if (!pedigreeNodeId) return;

    if (pedigreeNodeId !== pedigreeNode.id) {
      submitNodeRelativeDiagnosis(false);
      setPreviousNodeSelected(pedigreeNodeId);
      openRelativeDiagnosisForm(pedigreeNode, relationshipLabel);
      return;
    }

    if (
      (isButtonSubmit || pedigreeNodeId === pedigreeNode.id) &&
      !drawerIsOpen
    ) {
      setPreviousNodeSelected('');
      openRelativeDiagnosisForm(pedigreeNode, relationshipLabel);
      return;
    }

    if (drawerIsOpen) {
      submitNodeRelativeDiagnosis(false);
      handleCloseDrawer();
      return;
    }
  };

  const removeRelativeFormQueries = useCallback(
    (nodeId: string) => {
      queryClient.removeQueries([
        'myFamilyTreeForm',
        pedigreeId,
        nodeId,
        i18n.language,
      ]);
    },
    [i18n.language, pedigreeId, queryClient],
  );

  const openRelativeDiagnosisForm = (
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    setPedigreeNodeId(pedigreeNode.id);
    setRelativeLabel(relationshipLabel);
    setDrawerStatus('relativeDiagnosisForm');
    removeRelativeFormQueries(pedigreeNode.id);
  };

  const handleSetPedigreeNode = (
    isSelected: boolean,
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    if (drawerStatus === 'cancerHistory') {
      updateNodeRelativeDisease(isSelected, pedigreeNode);
    }

    if (!relativeDisease) {
      if (drawerStatus === 'relativeDiagnosisForm') {
        updateNodeRelativeDiagnosis(pedigreeNode, relationshipLabel);
      } else {
        setDrawerStatus('relativeDiagnosisForm');
        openRelativeDiagnosisForm(pedigreeNode, relationshipLabel);
      }
    }
  };

  const submitNodesWithCancerHistoryMutation = useMutation(
    (nodeList: string[]) =>
      service?.sendRelativesWithCancerHistory(pedigreeId, nodeList),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pedigree');
      },
    },
  );

  const openOnboardingDrawer = useCallback(
    (isInfoEditing?: boolean) => {
      if (cancerDiagnosisIsMissing || !!isInfoEditing) {
        setDrawerStatus('infoEditing');
      } else {
        setDrawerStatus('created');
      }
    },
    [cancerDiagnosisIsMissing, setDrawerStatus],
  );

  const onConfirmCancerHistory = useCallback(() => {
    const hasCancerHistory = !!pedigreeNodesWithCancerHistory.length;
    if (hasCancerHistory) {
      submitNodesWithCancerHistoryMutation.mutate(
        pedigreeNodesWithCancerHistory,
      );
    }
    openOnboardingDrawer(hasCancerHistory);
  }, [
    pedigreeNodesWithCancerHistory,
    submitNodesWithCancerHistoryMutation,
    openOnboardingDrawer,
  ]);

  const isOnboardingDrawer =
    drawerStatus === 'created' || drawerStatus === 'infoEditing';

  const onDrawerConfirmClick = useCallback(() => {
    if (isOnboardingDrawer) {
      setDrawerStatus(null);
      setPreviousNodeSelected('');
      handleCloseDrawer();
    }
    if (drawerStatus === 'cancerHistory') {
      onConfirmCancerHistory();
    }
    if (relativeDisease) {
      setDeselectAllNode(true);
      localStorage.removeItem('relativeDisease');
    }
  }, [
    drawerStatus,
    isOnboardingDrawer,
    onConfirmCancerHistory,
    relativeDisease,
  ]);

  const drawerContent = useMemo(() => {
    let content;

    if (drawerStatus === 'cancerHistory') {
      content = (
        <PedigreeCancerHistoryDrawerContentView
          onConfirmClick={onDrawerConfirmClick}
        />
      );
    }

    if (drawerStatus === 'created') {
      content = (
        <PedigreeCreatedDrawerContentView
          onConfirmClick={onDrawerConfirmClick}
        />
      );
    }

    if (drawerStatus === 'infoEditing') {
      content = (
        <PedigreeEditingInfoDrawerContentView
          onConfirmClick={onDrawerConfirmClick}
        />
      );
    }

    if (drawerStatus === 'relativeDiagnosisForm') {
      content = (
        <RelativeDiagnosisFormController
          pedigreeIds={{
            pedigreeId,
            nodeId: pedigreeNodeId,
          }}
          relativeLabel={relativeLabel}
          onSubmit={submitNodeRelativeDiagnosis}
          setRelativeFormData={setRelativeFormData}
        />
      );
    }

    return content;
  }, [
    drawerStatus,
    pedigreeNodeId,
    pedigreeId,
    relativeLabel,
    onDrawerConfirmClick,
    submitNodeRelativeDiagnosis,
  ]);

  const drawer = (
    <Drawer
      open={drawerIsOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      hideBackdrop={true}
      sx={{ width: 0 }}
    >
      <Box sx={{ width: 465 }}>{drawerContent}</Box>
    </Drawer>
  );

  return {
    drawer,
    closeDrawer: handleCloseDrawer,
    openDrawer: openOnboardingDrawer,
    handleSetPedigreeNode,
    previousNodeSelected,
    deselectAllNode,
    submissionRelativeFailedDialog,
    showNodeAction: drawerStatus !== 'cancerHistory',
    allowNodeClick: !isOnboardingDrawer,
  };
};
