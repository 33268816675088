import {
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PedigreeStatusViewProps {
  pedigreeStatus: 'notCreated' | 'created' | 'received' | 'requested';
}

function PedigreeStatusView(props: PedigreeStatusViewProps) {
  const theme = useTheme();
  const { t } = useTranslation('iPedigree');

  const pedigreeStatusText = t(`pedigree-status.${props.pedigreeStatus}`);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {props.pedigreeStatus === 'notCreated' && (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color={theme.palette.error.main}
        />
      )}
      {props.pedigreeStatus === 'created' && (
        <FontAwesomeIcon
          icon={faCircleCheck}
          color={theme.palette.success.main}
        />
      )}
      {props.pedigreeStatus === 'received' && (
        <FontAwesomeIcon
          icon={faCircleCheck}
          color={theme.palette.success.main}
        />
      )}
      {props.pedigreeStatus === 'requested' && (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={theme.palette.warning.main}
        />
      )}

      <Typography>{pedigreeStatusText}</Typography>
    </Stack>
  );
}

export default PedigreeStatusView;
