import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, useTheme } from '@mui/material';

interface LabelInfoProps {
  labelInfo: string;
}

const LabelInfo = ({ labelInfo }: LabelInfoProps) => {
  const theme = useTheme();
  return (
    <Tooltip title={labelInfo} data-testid="tooltip">
      <FontAwesomeIcon icon={faCircleInfo} color={theme.palette.primary.main} />
    </Tooltip>
  );
};

export default LabelInfo;
