import { Stack } from '@mui/material';
import i18n from 'assets/localization/i18n/myFamilyTreeI18nConfig';
import { MyFamilyTreeSubmissionServiceProvider } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MyFamilyTreeManagementRouter from './MyFamilyTreeManagementRouter';
import MyFamilyTreePatientRouter from './MyFamilyTreePatientRouter';

const router = createBrowserRouter([
  {
    path: '/*',
    element: <MyFamilyTreeManagementRouter />,
  },
  {
    path: '/my-case/:pedigreeCaseId/*',
    element: (
      <MyFamilyTreeSubmissionServiceProvider accessToken={''}>
        <MyFamilyTreePatientRouter />
      </MyFamilyTreeSubmissionServiceProvider>
    ),
  },
]);

function MyFamilyTreeRouter() {
  return (
    <I18nextProvider i18n={i18n}>
      <Stack direction="column" minHeight="100vh">
        <RouterProvider router={router} />
      </Stack>
    </I18nextProvider>
  );
}

export default MyFamilyTreeRouter;
