import { Typography } from '@mui/material';
import ErrorViewLayout from 'features/common/views/ErrorViewLayout';
import { useTranslation } from 'react-i18next';

const NoPendingConsentsView = () => {
  const { t } = useTranslation('all');
  return (
    <ErrorViewLayout>
      <Typography variant="h1">{t('no-pending-consents.title')}</Typography>
    </ErrorViewLayout>
  );
};

export default NoPendingConsentsView;
