import { Box, Stack } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}
const ErrorViewLayout: React.FC<Props> = (props) => (
  <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
    <Box textAlign="center">
      <Stack direction="column" spacing={2}>
        {props.children}
      </Stack>
    </Box>
  </Box>
);

export default ErrorViewLayout;
