import { BaseService } from 'api/base-service';

export type BankidStatus = 'pending' | 'failed' | 'complete';

export type BankidHintCode =
  | 'outstandingTransaction'
  | 'noClient'
  | 'userCancel'
  | 'expiredTransaction'
  | 'userSign'
  | 'started'
  | 'certificateErr'
  | 'startFailed'
  | 'unknown';

export type BankidLaunchMethod = 'qrCode' | 'autoStart';

export type BankidClientAction =
  | 'showQR'
  | 'showLoading'
  | 'showError'
  | 'showSuccess';

type BankidBaseResponse = {
  sessionID: string;
  status: BankidStatus;
};

type BankidPendingResponse = BankidBaseResponse & {
  status: 'pending';
  hintCode: BankidHintCode;
  autoStartToken: string;
  qrCode: string;
};

export type BankidCompleteResponse = BankidBaseResponse & {
  status: 'complete';
};

export type BankidAuthCollectCompleteResponse = BankidCompleteResponse & {
  jwt: string;
};

export type BankidFailedResponse = BankidBaseResponse & {
  status: 'failed';
  hintCode: BankidHintCode;
};

export type BankidResponse =
  | BankidPendingResponse
  | BankidCompleteResponse
  | BankidAuthCollectCompleteResponse
  | BankidFailedResponse;

export type BankidInitResponse = BankidPendingResponse | BankidFailedResponse;

export type BankidAuthCollectResponse =
  | BankidPendingResponse
  | BankidAuthCollectCompleteResponse
  | BankidFailedResponse;

export class BankidService extends BaseService {
  initAuthBankid = async (): Promise<BankidInitResponse> =>
    this.axiosInstance
      .post('/bankid/auth', {})
      .then((response) => response.data);

  authCollectBankid = async (
    sessionId: string,
  ): Promise<BankidAuthCollectResponse> =>
    this.axiosInstance
      .post(`/bankid/bankid-sessions/${sessionId}/auth-collect`, {})
      .then((response) => response.data);

  cancelBankidSession = ({ sessionId }: { sessionId: string }) =>
    this.axiosInstance
      .post(`/bankid/bankid-sessions/${sessionId}/cancel`, {})
      .then((response) => response.data);
}
