import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { ButtonAction } from './types';

interface NotificationIsSentConfirmationViewProps {
  title: string;
  description: string;
  actions: ButtonAction[];
}

const NotificationIsSentConfirmationView = (
  props: NotificationIsSentConfirmationViewProps,
) => {
  return (
    <Container maxWidth="md">
      <Stack gap={3}>
        <Typography variant="h1">{props.title}</Typography>
        <Typography>{props.description}</Typography>
        <Grid container spacing={2}>
          {props.actions?.map((action, index) => (
            <Grid item key={index} sm={6}>
              <Button
                size="large"
                sx={{ width: '100%' }}
                variant={action.primary ? 'contained' : 'outlined'}
                color="primary"
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};

export default NotificationIsSentConfirmationView;
