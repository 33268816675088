import { SelectChangeEvent } from '@mui/material';
import Select from 'myfamilytree/components/common/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import enLogo from '../../assets/images/en-icon.svg';
import seLogo from '../../assets/images/se-icon.svg';

export function LanguageSelector() {
  const { t, i18n } = useTranslation(['common']);
  const [language, setLanguage] = React.useState(i18n.language);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value as string);
  };

  return (
    <Select
      options={[
        {
          label: t('language.choice.english'),
          value: 'en',
          icon: enLogo,
        },
        {
          label: t('language.choice.swedish'),
          value: 'se',
          icon: seLogo,
        },
      ]}
      currentValue={language}
      handleChange={handleLanguageChange}
    />
  );
}
