import { LoadingButton } from '@mui/lab';
import { Container, Stack } from '@mui/material';
import Breadcrumb from 'myfamilytree/components/common/Breadcrumbs';
import { PATIENT_PATHS } from 'myfamilytree/routes/MyFamilyTreePatientRouter';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
  handleSubmit?: () => void;
  handleContinue?: () => void;
  isSubmitting?: boolean;
  isSaving?: boolean;
}
export function Layout(props: LayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['myFamilyTree']);
  const currentPath = location.pathname;

  const activeItem = currentPath.includes(PATIENT_PATHS.QUESTIONNAIRE)
    ? PATIENT_PATHS.QUESTIONNAIRE
    : PATIENT_PATHS.PEDIGREE;

  const handleLinkClick = (breadcrumbKey: string) => {
    if (breadcrumbKey === PATIENT_PATHS.QUESTIONNAIRE) {
      navigate('../' + PATIENT_PATHS.QUESTIONNAIRE, { relative: 'path' });
    } else {
      navigate('../' + PATIENT_PATHS.PEDIGREE, { relative: 'path' });
    }
  };

  const breadcrumbs = [
    {
      key: PATIENT_PATHS.QUESTIONNAIRE,
      label: t('my-family-tree.personalInfo.title'),
    },
    { key: PATIENT_PATHS.PEDIGREE, label: t('my-family-tree.pedigree.title') },
  ];

  return (
    <>
      <Container>
        <Stack direction="row" justifyContent="space-between" my={2.5}>
          <Breadcrumb
            path={breadcrumbs}
            handleLinkClick={handleLinkClick}
            activeItem={activeItem}
          />
          <Stack direction="row" spacing={3}>
            {props.handleContinue && (
              <LoadingButton
                size="medium"
                variant="outlined"
                onClick={props.handleContinue}
                loading={props.isSaving}
                disabled={props.isSubmitting}
              >
                {t('common-button-continueLater')}
              </LoadingButton>
            )}
            {props.handleSubmit && (
              <LoadingButton
                loading={props.isSubmitting}
                disabled={props.isSaving}
                size="medium"
                variant="contained"
                onClick={props.handleSubmit}
              >
                {t('common-button-submit')}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </Container>
      {props.children}
    </>
  );
}
