import { useBankidService } from 'api/bankid/BankidServiceProvider';
import {
  BankidAuthCollectCompleteResponse,
  BankidClientAction,
  BankidCompleteResponse,
  BankidFailedResponse,
  BankidHintCode,
  BankidInitResponse,
  BankidLaunchMethod,
  BankidResponse,
} from 'api/bankid/bankid-service';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BankidLaunchError,
  getResponseCodeAndAction,
  launchBankIDOrFail,
} from '../utils/bankid-utils';
import BankidView from '../views/BankidView';

interface BankidControllerProps {
  onBankidInit: () => Promise<BankidInitResponse>;
  onBankidCollect: (bankidSessionId: string) => Promise<BankidResponse>;
  launchMethod: BankidLaunchMethod;
  onCompleted?: (
    response: BankidCompleteResponse | BankidAuthCollectCompleteResponse,
  ) => void;
  onError?: (response: BankidFailedResponse) => void;
  onCancel?: () => void;
  collectInterval?: number;
}

export const BankidController = ({
  onBankidInit,
  onBankidCollect,
  launchMethod,
  onCompleted,
  onError,
  onCancel,
  collectInterval = 1000,
}: BankidControllerProps) => {
  const service = useBankidService();
  const { t } = useTranslation(['all']);
  const queryClient = useQueryClient();

  const bankidInitWasCalled = React.useRef(false);
  const [qrCode, setQrCode] = React.useState<string | undefined>();
  const [autoStartToken, setAutoStartToken] = React.useState<
    string | undefined
  >();
  const [bankidSessionId, setBankidSessionId] = React.useState<string | null>(
    null,
  );
  const [msgAndAction, setMsgAndAction] = React.useState<{
    message: string;
    action: BankidClientAction;
  } | null>(null);

  const processBankidResponse = React.useCallback(
    (response: BankidResponse) => {
      const { status, sessionID } = response;
      setBankidSessionId(sessionID);

      if (status === 'pending') {
        const { qrCode, autoStartToken } = response;
        setQrCode(qrCode);
        setAutoStartToken(autoStartToken);
      }

      let hintCode: BankidHintCode = 'unknown';
      if (status !== 'complete') {
        hintCode = response.hintCode;
      }

      const [message, action] = getResponseCodeAndAction({
        status,
        hintCode,
        launchMethod,
      });
      setMsgAndAction({ message: t(`bankid.${message}`), action });

      if (status === 'failed') {
        onError?.(response);
      }

      if (status === 'complete') {
        onCompleted?.(response);
      }
    },
    [launchMethod, t, onCompleted, onError],
  );

  const initMutation = useMutation(onBankidInit, {
    onSuccess: (data) => {
      processBankidResponse(data);
    },
    onError: (error) => {
      console.error(error);
      setMsgAndAction({ message: t('bankid.RFA22'), action: 'showError' });
    },
  });

  const cancelMutation = useMutation(service.cancelBankidSession, {
    onSuccess: () => {
      if (!bankidSessionId) return;
      onCancel?.();
    },
  });

  useQuery(
    ['bankidCollect', bankidSessionId],
    () => {
      if (!bankidSessionId) return;
      return onBankidCollect(bankidSessionId);
    },
    {
      enabled:
        !!bankidSessionId &&
        msgAndAction?.action !== undefined &&
        ['showQR', 'showLoading'].includes(msgAndAction?.action),
      onSuccess: (data) => {
        if (!data) return;
        processBankidResponse(data);
      },
    },
  );

  React.useEffect(() => {
    if (!bankidSessionId) return;
    const interval = setInterval(() => {
      queryClient.invalidateQueries('bankidCollect');
    }, collectInterval);
    return () => clearInterval(interval);
  }, [bankidSessionId, queryClient, collectInterval]);

  React.useEffect(() => {
    if (bankidInitWasCalled.current) return;
    bankidInitWasCalled.current = true;
    initMutation.mutate();
  });

  React.useEffect(() => {
    if (!autoStartToken || launchMethod === 'qrCode') return;
    try {
      launchBankIDOrFail(autoStartToken);
    } catch (e) {
      if (e instanceof BankidLaunchError) {
        // FIXME: handle error
        setMsgAndAction({ message: '', action: 'showError' });
      }
    }
  }, [autoStartToken, launchMethod]);

  const { message, action } = msgAndAction || {
    message: '',
    action: 'showLoading',
  };

  const onCancelClick = React.useCallback(() => {
    if (bankidSessionId) {
      cancelMutation.mutate({ sessionId: bankidSessionId });
    } else {
      onCancel?.();
    }
  }, [bankidSessionId, cancelMutation, onCancel]);

  return (
    <BankidView
      action={action}
      message={message}
      qrCode={qrCode}
      onCancelClick={onCancelClick}
    />
  );
};
