import { useAuthService } from 'api/auth/AuthServicesProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import validator from 'validator';
import ChangePasswordView from '../views/ChangePasswordView';
import SigninView from '../views/SigninView';

interface Props {
  authenticate: (token: string) => void;
}

const StaffAuthSigninController: React.FC<Props> = ({ authenticate }) => {
  const signinFormRef = React.useRef<HTMLFormElement>(null);
  const changePasswordFormRef = React.useRef<HTMLFormElement>(null);
  const authService = useAuthService();
  const [error, setError] = React.useState<string>('');
  const [newPasswordError, setNewPasswordError] = React.useState<string>('');
  const { t } = useTranslation('all');
  const [authToken, setAuthToken] = React.useState<string>('');
  const [passwordChangeRequired, setPasswordChangeRequired] =
    React.useState<boolean>(false);

  const signinMutation = useMutation(
    (data: { email: string; password: string }) =>
      authService.signin(data.email, data.password),
    {
      onSuccess: (data) => {
        if (data.passwordChangeRequired) {
          setPasswordChangeRequired(true);
          setAuthToken(data.accessToken);
          return;
        }
        authenticate(data.accessToken);
      },
      onError: (error) => {
        setError(t('signin.error'));
      },
    },
  );

  const handleSigninClick = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLFormElement>,
    ) => {
      event.preventDefault();

      if (signinFormRef.current) {
        const formData = new FormData(signinFormRef.current);
        const email: string = formData.get('email')?.toString() || '';
        const password: string = formData.get('password')?.toString() || '';
        signinMutation.mutate({ email, password });
      }
    },
    [signinMutation],
  );

  const changePasswordMutation = useMutation(
    (password: string) => authService.updatePassword(password, authToken),
    {
      onSuccess: () => {
        authenticate(authToken);
      },
    },
  );

  const handleChangeClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (authToken === null) {
        setPasswordChangeRequired(false);
        return;
      }

      if (!changePasswordFormRef.current) return;

      const formData = new FormData(changePasswordFormRef.current);
      const password: string = formData.get('password')?.toString() || '';
      if (!validatePasswordStrength(password)) {
        setNewPasswordError(t('signin.new-password.invalid.error'));
        return;
      }
      changePasswordMutation.mutate(password);
    },
    [authToken, changePasswordMutation, t],
  );

  if (passwordChangeRequired) {
    return (
      <ChangePasswordView
        formRef={changePasswordFormRef}
        onChangeClick={handleChangeClick}
        isMutating={changePasswordMutation.isLoading}
        error={newPasswordError}
      />
    );
  }

  return (
    <SigninView
      loginFormRef={signinFormRef}
      isLoggingIn={signinMutation.isLoading}
      handleLogin={handleSigninClick}
      loginError={error}
      clearError={() => setError('')}
    />
  );
};

export default StaffAuthSigninController;

const validatePasswordStrength = (password: string) =>
  validator.isStrongPassword(password, {
    minLength: 12,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });
