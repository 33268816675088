import { RequestingEntity } from 'api/consentSubmission/consent-submission-service';

export function formatFooterText(
  requestingEntity: RequestingEntity | undefined,
) {
  if (!requestingEntity) {
    return '';
  }
  const { name, phone } = requestingEntity;
  return phone + ', ' + name;
}
