import { Stack } from '@mui/material';
import { StaffPedigreeCaseServiceProvider } from 'api/pedigreeCase/staff-pedigree-case-service';
import i18n from 'assets/localization/i18n/iPedigreeI18nConfig';
import TopNav from 'common/layouts/TopNav';
import { withStaffAuthProvider } from 'features/auth/staff/StaffAuthProvider';
import { IPedigreeManagementServiceProvider } from 'iPedigree/api/iPedigreeManagement/IPedigreeManagementServiceProvider';
import { useStaffAuth } from 'iPedigree/common';
import DashboardPage from 'iPedigree/pages/DashboardPage';
import NewPatientPage from 'iPedigree/pages/NewPatientPage';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PatientPageRouter from './PatientPageRouter';

export const ROUTES = {
  newPatient: '/new-patient',
  case: '/case',
};

const router = createBrowserRouter([
  {
    path: '/*',
    element: <DashboardPage />,
  },
  {
    path: ROUTES.newPatient,
    element: <NewPatientPage />,
  },
  {
    path: ROUTES.case + '/:pedigreeCaseId/*',
    element: <PatientPageRouter />,
  },
]);

const IPedigreeRouter = () => {
  const { accessToken, logout } = useStaffAuth();

  return (
    <I18nextProvider i18n={i18n}>
      <StaffPedigreeCaseServiceProvider
        accessToken={accessToken}
        logout={logout}
      >
        <IPedigreeManagementServiceProvider
          accessToken={accessToken}
          logout={logout}
        >
          <Stack direction="column" sx={{ minHeight: '100vh' }}>
            <TopNav containerMaxWidth="xl" />
            <RouterProvider router={router} />
          </Stack>
        </IPedigreeManagementServiceProvider>
      </StaffPedigreeCaseServiceProvider>
    </I18nextProvider>
  );
};

export default withStaffAuthProvider(IPedigreeRouter);
