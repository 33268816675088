import { useTranslation } from 'react-i18next';

export const useRelationshipOptions = () => {
  const { t } = useTranslation(['relationship']);

  const options = [
    { value: 'far', label: t('far') },
    { value: 'mor', label: t('mor') },
    { value: 'syster', label: t('syster') },
    { value: 'bror', label: t('bror') },
    { value: 'son', label: t('son') },
    { value: 'dotter', label: t('dotter') },
    { value: 'mormor', label: t('mormor') },
    { value: 'morfar', label: t('morfar') },
    { value: 'farmor', label: t('farmor') },
    { value: 'farfar', label: t('farfar') },
    { value: 'faster', label: t('faster') },
    { value: 'farbror', label: t('farbror') },
    { value: 'moster', label: t('moster') },
    { value: 'morbror', label: t('morbror') },
  ];

  const translateRelationship = (value: string) => t(`${value}`);

  return { relationshipOptions: options, translateRelationship };
};
