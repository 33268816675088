import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import PasswordInput from 'components/inputs/PasswordInput';
import { useTranslation } from 'react-i18next';
import AuthLayout from './AuthLayout';

interface Props {
  formRef: React.RefObject<HTMLFormElement>;
  isMutating: boolean;
  onChangeClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  error: string;
}

const ChangePasswordView: React.FC<Props> = (props) => {
  const { t } = useTranslation('all');

  return (
    <AuthLayout>
      <form ref={props.formRef}>
        <Stack direction="column" spacing={2} sx={{ minWidth: 300 }}>
          <Typography variant="h4">{t('change-password.title')}</Typography>
          <Typography>{t('change-password.content')}</Typography>
          <PasswordInput
            label={t('change-password.new-password-input.label')}
            disabled={props.isMutating}
            error={props.error}
          />
          <LoadingButton
            variant="contained"
            color="primary"
            loading={props.isMutating}
            onClick={props.onChangeClick}
          >
            {t('change-password.button.label')}
          </LoadingButton>
        </Stack>
      </form>
    </AuthLayout>
  );
};

export default ChangePasswordView;
