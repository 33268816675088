import { PedigreeNode } from 'pedigree/features/common/types';

export const CANCER_COLORS_MAP: Record<string, string> = {
  'Breast cancer': '#F89AC2',
  Bröstcancer: '#F89AC2',
  'Ovarian cancer': '#008080',
  Äggstockscancer: '#008080',
  'Prostate cancer': '#99AFE8',
  Prostatacancer: '#99AFE8',
  'Colon cancer': '#282E89',
  Tjocktarmscancer: '#282E89',
  'Colorectal/Bowel cancer': '#282E89',
  'Kolorektal/tarmcancer': '#282E89',
  Melanoma: '#000000',
  Melanom: '#000000',
  'Non-melanoma skin cancer': '#737679',
  'Icke-melanom hudcancer': '#737679',
  'Cowden syndrome': '#89CA28',
  'Cowdens syndrom': '#89CA28',
  'Hereditary Nonpolyposis Colorectal Cancer (HNPCC)': '#6E17A3',
  'Heriditär nonpolypos kolorektalcancer (HNPCC)': '#6E17A3',
  'Kidney/renal cancer': '#30C004',
  Njurcancer: '#30C004',
  'Leukemi/blodcancer': '#FF7518',
  'Leukemia/blood cancer': '#FF7518',
  'Li-Fraumeni syndrome (LFS)': '#71D7FF',
  'Li-Fraumeni syndrom (LFS)': '#71D7FF',
  'Lung cancer': '#F4EFE3',
  Lungcancer: '#F4EFE3',
  'Lynch syndrome': '#FF5964',
  'Lynch syndrom': '#FF5964',
  'Myelodysplastic syndromes (MDS)': '#5C2E0B',
  'Myelodysplastiskt syndrom (MDS)': '#5C2E0B',
  'Pancreatic cancer': '#440060',
  Bukspottskörtelcancer: '#440060',
  'Thyroid cancer': '#DE369D',
  Sköldkörtelcancer: '#DE369D',
  'Bladder cancer': '#FDCA40',
  Urinblåsecancer: '#FDCA40',
  'Endometrial cancer': '#F19D8A',
  Endometriecancer: '#F19D8A',
};

export const generateDiseaseToColorMap = (nodes: PedigreeNode[]) => {
  const allDiseases = Array.from(
    new Set(
      nodes.reduce((acc, node) => {
        const nodesDiseases = node.diagnoses.map(
          (diagnosis) => diagnosis.diseaseName,
        );
        acc.push(...nodesDiseases);
        return acc;
      }, [] as string[]),
    ),
  );

  return allDiseases.reduce(
    (acc, disease) => {
      acc[disease] = CANCER_COLORS_MAP[disease] || '#7162A1';
      return acc;
    },
    {} as Record<string, string>,
  );
};
