import {
  faDownload,
  faInfoCircle,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  PedigreeCase,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { PedigreeRequestReminderEventsView } from 'common/features/pedigreeCase/PedigreeRequestReminderEventsViewProps';
import { usePedigreePrintExport } from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import { isoToShortDate } from 'common/utils';
import { SectionBox } from 'components/common';
import { TitleWithIcon } from 'components/common/TitleWithIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface PedigreeCaseStatusPanelProps {
  pedigreeCase: PedigreeCase;
}

function PedigreeCaseStatusPanel({
  pedigreeCase,
}: PedigreeCaseStatusPanelProps) {
  const pedigreeCaseId = pedigreeCase.id;

  switch (pedigreeCase.status) {
    case 'caseCreated':
      return <CaseCreatedStatus />;
    case 'pedigreeRequested':
    case 'pedigreeCreatedByPatient':
      return <PedigreeRequestedStatus pedigreeCaseId={pedigreeCaseId} />;
    default:
      return <PedigreeReceivedStatus pedigreeCaseId={pedigreeCaseId} />;
  }
}

function CaseCreatedStatus() {
  const { t } = useTranslation('myFamilyTree');
  const navigate = useNavigate();

  return (
    <SectionBox>
      <Stack gap={2} direction="column" alignItems="flex-start">
        <TitleWithIcon
          icon={faInfoCircle}
          title={t(
            'my-family-tree-management.pedigree-case-status.request.title',
          )}
        />
        <Typography variant="body1">
          {t(
            'my-family-tree-management.pedigree-case-status.request.description',
          )}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate('./send-request');
          }}
        >
          {t('my-family-tree-management.pedigree-case-status.request.button')}
        </Button>
      </Stack>
    </SectionBox>
  );
}

interface PedigreeRequestedStatusProps {
  pedigreeCaseId: string;
}

function PedigreeRequestedStatus(props: PedigreeRequestedStatusProps) {
  const { t } = useTranslation('myFamilyTree');
  const navigate = useNavigate();
  const service = useStaffPedigreeCaseService();

  const { data: pedigreeCaseEvents } = useQuery(
    ['pedigreeCaseEvents', props.pedigreeCaseId],
    () => service.getPedigreeCaseEvents(props.pedigreeCaseId),
  );

  return (
    <SectionBox>
      <Stack gap={2} direction="column">
        <TitleWithIcon
          title={t(
            'my-family-tree-management.pedigree-case-status.requested.title',
          )}
          icon={faInfoCircle}
        />
        <PedigreeRequestReminderEventsView
          pedigreeCaseEvents={pedigreeCaseEvents?.items || []}
        />
        <Box>
          <Button
            onClick={() => navigate('./send-reminder')}
            variant="outlined"
          >
            {t(
              'my-family-tree-management.pedigree-case-status.reminder.button',
            )}
          </Button>
        </Box>
      </Stack>
    </SectionBox>
  );
}

interface PedigreeReceivedStatusProps {
  pedigreeCaseId: string;
}

function PedigreeReceivedStatus(props: PedigreeReceivedStatusProps) {
  const { t, i18n } = useTranslation('myFamilyTree');
  const service = useStaffPedigreeCaseService();

  const { data: pedigreeCaseEvents } = useQuery(
    ['pedigreeCaseEvents', props.pedigreeCaseId],
    () => service.getPedigreeCaseEvents(props.pedigreeCaseId),
  );

  const receivedAt = useMemo(() => {
    return pedigreeCaseEvents?.items.find(
      (event) => event.name === 'pedigreeSubmittedByPatient',
    )?.createdAt;
  }, [pedigreeCaseEvents]);

  const { onPedigreePrintClick, onPedigreeDownloadClick, dialog } =
    usePedigreePrintExport();

  const handlePrintClick = () => {
    onPedigreePrintClick(props.pedigreeCaseId);
  };
  const handleDownloadClick = () => {
    onPedigreeDownloadClick(props.pedigreeCaseId);
  };

  if (!receivedAt) {
    return null;
  }

  return (
    <SectionBox>
      <Stack gap={2} direction="column">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TitleWithIcon
            title={t(
              'my-family-tree-management.pedigree-case-status.received.title',
            )}
            icon={faInfoCircle}
          />
          <Stack gap={1} direction="row" alignContent="center">
            <Button
              variant="outlined"
              onClick={handlePrintClick}
              startIcon={<FontAwesomeIcon icon={faPrint} />}
            >
              {t('my-family-tree-management.page-overview.print-pedigree')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleDownloadClick}
              startIcon={<FontAwesomeIcon icon={faDownload} />}
            >
              {t('my-family-tree-management.page-overview.export-pedigree')}
            </Button>
          </Stack>
        </Stack>
        <Typography variant="body1">
          {t(
            'my-family-tree-management.pedigree-case-status.received.description',
            {
              date: isoToShortDate(receivedAt, i18n),
            },
          )}
        </Typography>
      </Stack>
      {dialog}
    </SectionBox>
  );
}

export default PedigreeCaseStatusPanel;
