import { Box, Container } from '@mui/material';
import { ReactComponent as FourOFourSvg } from 'assets/images/404.svg';

// NOTE this need improvement
// There should be some text explaining the user that the page is not found
export function FourOFour() {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Box>
        <FourOFourSvg />
      </Box>
    </Container>
  );
}
