import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, IconButton, IconButtonProps } from '@mui/material';

interface ClickAwayListenerIconButtonProps {
  onBtnClickAway: (event: MouseEvent | TouchEvent) => void;
  icon: IconProp;
  iconButton?: IconButtonProps;
}

const ClickAwayListenerIconButton = (
  props: ClickAwayListenerIconButtonProps,
) => {
  return (
    <ClickAwayListener onClickAway={(e) => props.onBtnClickAway(e)}>
      <IconButton data-testid="basic-icon-button" {...props.iconButton}>
        <FontAwesomeIcon icon={props.icon} />
      </IconButton>
    </ClickAwayListener>
  );
};

export default ClickAwayListenerIconButton;
