import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PrintDialogContentViewProps {
  onPrintClick: (anonymized: boolean) => void;
  isLoading: boolean;
}

export function PrintDialogContentView({
  onPrintClick,
  isLoading,
}: PrintDialogContentViewProps) {
  const { t } = useTranslation(['pedigreePrintExport']);

  return (
    <>
      <DialogTitle>{t('print-pedigree.dialog.title')}</DialogTitle>
      <DialogContent>{t('print-pedigree.dialog.content')}</DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => onPrintClick(false)}
          loading={isLoading}
          color="primary"
          variant="outlined"
        >
          {t('print-pedigree.dialog.button.not-anonymise')}
        </LoadingButton>
        <LoadingButton
          onClick={() => onPrintClick(true)}
          loading={isLoading}
          color="primary"
          variant="contained"
        >
          {t('print-pedigree.dialog.button.anonymise')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
