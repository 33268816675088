import { Autocomplete, TextField } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React from 'react';

type AutoCompleteOption = {
  label: string;
  value: string;
};

type NewValue = AutoCompleteOption | AutoCompleteOption[] | null | string;

interface AutoCompleteInputProps {
  label: string;
  value: string | string[] | null;
  id: string;
  onChange?: (value: string | null) => void;
  onMultipleChange?: (value: string[]) => void;
  options: AutoCompleteOption[];
  autocompleteProps?: any;
  labelInfo?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  inlineLabel?: boolean;
  labelBefore?: boolean;
  fullWidth?: boolean;
  readOnlyInput?: boolean;
  autoSelect?: boolean;
}

const AutoCompleteInput = ({
  label,
  value,
  id,
  onChange,
  onMultipleChange,
  options,
  autocompleteProps,
  labelInfo,
  placeholder,
  error,
  disabled = false,
  required = false,
  inlineLabel = false,
  labelBefore = false,
  fullWidth = true,
  readOnlyInput = false,
  autoSelect = false,
}: AutoCompleteInputProps) => {
  if (!onChange && !onMultipleChange)
    throw new Error('onChange or onMultipleChange must be defined');

  const randomId = React.useId();
  id = `${id}-${randomId}`;
  return (
    <InputWrapper
      label={label}
      childId={id}
      labelInfo={labelInfo}
      required={required}
      error={error}
      inlineLabel={inlineLabel}
      labelBefore={labelBefore}
      fullWidth={fullWidth}
    >
      <Autocomplete
        id={id}
        value={value}
        autoSelect={autoSelect}
        freeSolo={autoSelect}
        forcePopupIcon={autoSelect ? true : 'auto'}
        isOptionEqualToValue={(option: AutoCompleteOption, value: string) => {
          if (typeof option === 'string') {
            return option === value;
          } else {
            return option.value === value;
          }
        }}
        getOptionLabel={(option: AutoCompleteOption | string) => {
          if (typeof option === 'string') {
            if (autoSelect) {
              return option;
            }
            return options.find((el) => el.value === option)?.label;
          }
          return option.label;
        }}
        onChange={(_, newValue: NewValue) => {
          if (
            autocompleteProps?.multiple &&
            onMultipleChange &&
            newValue instanceof Array
          ) {
            const values = newValue.map((el) => (el?.value || el) as string);
            onMultipleChange(values || []);
          } else if (
            onChange &&
            !(newValue instanceof Array) &&
            typeof newValue !== 'string'
          ) {
            onChange(newValue?.value || null);
          } else if (onChange && typeof newValue === 'string' && autoSelect) {
            onChange(newValue);
          }
        }}
        options={options}
        disabled={disabled}
        {...autocompleteProps}
        renderInput={(params) => (
          <TextField
            error={!!error}
            {...params}
            size="small"
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              readOnly: readOnlyInput,
            }}
          />
        )}
      />
    </InputWrapper>
  );
};

export default AutoCompleteInput;
