import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Pedigree } from '../types';
import { findIndexNode, isBloodRelative } from '../utils/helpers';
import { DialogContentType } from '../views/Dialog';

interface UseAllowedToAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
}

export const useAllowedToAddNode = (props: UseAllowedToAddNodeProps) => {
  const { pedigree, setDialogContent } = props;

  const { t } = useTranslation('pedigree');

  const closeDialog = () => {
    setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const isAllowedToAddNode = (
    action: string,
    pedigreeNodeId: string,
  ): boolean => {
    if (!pedigree) return false;

    const indexNode = findIndexNode(pedigree.nodes);
    if (!indexNode) return false;

    const nodeIsBloodRelative = isBloodRelative(
      indexNode,
      pedigree.nodes,
      pedigreeNodeId,
    );

    const allowedActionsForNonBloodRelative = ['addSon', 'addDaughter'];

    if (
      !nodeIsBloodRelative &&
      !allowedActionsForNonBloodRelative.includes(action)
    ) {
      openDialogCannotAdded();
      return false;
    }

    return true;
  };

  const openDialogCannotAdded = () => {
    props.setDialogContent({
      open: true,
      title: t('node-member-dialog.not-allowed-add.title'),
      content: t('node-member-dialog.not-allowed-add.content'),
      actions: [
        <Button
          key="close-not-allowed-add-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.not-allowed-add.button.confirm')}
        </Button>,
      ],
    });
  };

  return {
    isAllowedToAddNode,
  };
};
