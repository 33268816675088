import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en/all.json';
import commonEn from '../translations/en/common.json';
import diagnosisFormEn from '../translations/en/diagnosisForm.json';
import dynamicFormEn from '../translations/en/dynamicForm.json';
import iPedigreeEn from '../translations/en/iPedigree.json';
import pedigreeEn from '../translations/en/pedigree.json';
import pedigreeCaseNewPatientEn from '../translations/en/pedigreeCaseNewPatient.json';
import pedigreeCasePatientOverviewEn from '../translations/en/pedigreeCasePatientOverview.json';
import pedigreeCaseRequestReminderEventsViewEn from '../translations/en/pedigreeCaseRequestReminderEventsView.json';
import pedigreeCaseSendRequestReminderEn from '../translations/en/pedigreeCaseSendRequestReminder.json';
import pedigreePrintExportEn from '../translations/en/pedigreePrintExport.json';
import relationshipEn from '../translations/en/relationship.json';
import se from '../translations/se/all.json';
import commonSe from '../translations/se/common.json';
import diagnosisFormSe from '../translations/se/diagnosisForm.json';
import dynamicFormSe from '../translations/se/dynamicForm.json';
import iPedigreeSe from '../translations/se/iPedigree.json';
import pedigreeSe from '../translations/se/pedigree.json';
import pedigreeCaseNewPatientSe from '../translations/se/pedigreeCaseNewPatient.json';
import pedigreeCasePatientOverviewSe from '../translations/se/pedigreeCasePatientOverview.json';
import pedigreeCaseRequestReminderEventsViewSe from '../translations/se/pedigreeCaseRequestReminderEventsView.json';
import pedigreeCaseSendRequestReminderSe from '../translations/se/pedigreeCaseSendRequestReminder.json';
import pedigreePrintExportSe from '../translations/se/pedigreePrintExport.json';
import relationshipSe from '../translations/se/relationship.json';

const iPedigreeI18nInstance = i18n.createInstance();

iPedigreeI18nInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        all: en,
        pedigreeCaseNewPatient: pedigreeCaseNewPatientEn,
        pedigreeCasePatientOverview: pedigreeCasePatientOverviewEn,
        pedigreeCaseRequestReminderEventsView:
          pedigreeCaseRequestReminderEventsViewEn,
        pedigreeCaseSendRequestReminder: pedigreeCaseSendRequestReminderEn,
        pedigreePrintExport: pedigreePrintExportEn,
        iPedigree: iPedigreeEn,
        relationship: relationshipEn,
        dynamicForm: dynamicFormEn,
        pedigree: pedigreeEn,
        common: commonEn,
        diagnosisForm: diagnosisFormEn,
      },
      se: {
        all: se,
        pedigreeCaseNewPatient: pedigreeCaseNewPatientSe,
        pedigreeCasePatientOverview: pedigreeCasePatientOverviewSe,
        pedigreeCaseRequestReminderEventsView:
          pedigreeCaseRequestReminderEventsViewSe,
        pedigreeCaseSendRequestReminder: pedigreeCaseSendRequestReminderSe,
        pedigreePrintExport: pedigreePrintExportSe,
        iPedigree: iPedigreeSe,
        relationship: relationshipSe,
        dynamicForm: dynamicFormSe,
        pedigree: pedigreeSe,
        common: commonSe,
        diagnosisForm: diagnosisFormSe,
      },
    },
    fallbackLng: 'se',
    detection: {
      order: ['cookie', 'localStorage'],
      caches: ['cookie', 'localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

iPedigreeI18nInstance.services.formatter?.add(
  'capitalize',
  (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
);

iPedigreeI18nInstance.services.formatter?.add(
  'capitalizeWords',
  (value: string) =>
    value
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
);

export default iPedigreeI18nInstance;
