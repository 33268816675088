import { serviceHookFactory, serviceProviderFactory } from 'iPedigree/common';
import React from 'react';
import { IPedigreeManagementService } from './iPedigree-management-service';

const IPedigreeManagementServiceContext = React.createContext<{
  service: IPedigreeManagementService | null;
}>({ service: null });

export const IPedigreeManagementServiceProvider = serviceProviderFactory(
  IPedigreeManagementService,
  IPedigreeManagementServiceContext,
);

export const useIPedigreeManagementService = serviceHookFactory(
  IPedigreeManagementService,
  IPedigreeManagementServiceContext,
);
