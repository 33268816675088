import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Typography } from '@mui/material';
import { LoadingBackdrop } from 'components/common';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import Personnummer from 'personnummer';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import RelativeDiagnosisFormView from '../views/RelativeDiagnosisFormView';

interface RelativeDiagnosisFormControllerProps {
  pedigreeIds: { pedigreeId: string | undefined; nodeId: string | undefined };
  relativeLabel: string;
  onSubmit: (isButtonSubmit: boolean) => void;
  setRelativeFormData: (data: any) => void;
}

const RelativeDiagnosisFormController = (
  props: RelativeDiagnosisFormControllerProps,
) => {
  const service = useMyFamilyTreeSubmissionService();
  const { i18n } = useTranslation('myFamilyTree');

  const { data, isLoading } = useQuery(
    [
      'myFamilyTreeForm',
      props.pedigreeIds.pedigreeId,
      props.pedigreeIds.nodeId,
      i18n.language,
    ],
    () =>
      service?.getRelativeForm(
        props.pedigreeIds.pedigreeId || '',
        props.pedigreeIds.nodeId || '',
      ),
  );

  const customValidation = (formData: any, errors: any) => {
    if (
      formData.personalInfo.personalNumber &&
      !Personnummer.valid(formData.personalInfo.personalNumber)
    ) {
      errors.personalInfo?.personalNumber.addError(
        'Personal Number is invalid',
      );
    }

    return errors;
  };

  return (
    <Stack direction="column" gap={3} my={2}>
      <Typography
        variant="h3"
        style={{ display: 'flex', alignItems: 'center' }}
        mx={2}
      >
        {props.relativeLabel}
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={() => props.onSubmit(true)} sx={{ width: 40 }}>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </div>
      </Typography>
      {data && (
        <RelativeDiagnosisFormView
          key={props.pedigreeIds.nodeId}
          onSubmit={props.onSubmit}
          customValidate={customValidation}
          schema={data.jsonSchema}
          uiSchema={{ ...data.uiSchema, 'ui:accordionsGap': 0.5 }}
          formData={data.formData}
          onChange={({ formData }) => props.setRelativeFormData(formData)}
        />
      )}
      {!data && !isLoading && <div>Form not found</div>}
      {<LoadingBackdrop open={isLoading} />}
    </Stack>
  );
};
export default RelativeDiagnosisFormController;
