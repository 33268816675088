import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CancelAndContinueButtonsProps {
  onCancelClick: () => void;
  onContinueClick: () => void;
  isLoading: boolean;
}

function CancelAndContinueButtons(props: CancelAndContinueButtonsProps) {
  const { t } = useTranslation('common');
  return (
    <Stack direction="row" spacing={4}>
      <Button
        variant="outlined"
        onClick={props.onCancelClick}
        disabled={props.isLoading}
        fullWidth
      >
        {t('common:button.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={props.onContinueClick}
        loading={props.isLoading}
        fullWidth
      >
        {t('common:button.continue')}
      </LoadingButton>
    </Stack>
  );
}

export default CancelAndContinueButtons;
