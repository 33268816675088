import { Stack } from '@mui/material';
import { FieldErrorProps } from '@rjsf/utils';
import { FormError } from 'components/forms';

export const FieldErrorTemplate = (props: FieldErrorProps) => {
  const { errors } = props;

  return (
    <>
      {errors ? (
        <Stack>
          {errors?.length > 0 &&
            props.idSchema.$id !== 'root_children_hasChildren' &&
            errors.map((error: any, i: number) => {
              return (
                <Stack key={i}>{error && <FormError error={error} />}</Stack>
              );
            })}
        </Stack>
      ) : (
        ''
      )}
    </>
  );
};
