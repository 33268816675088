import { Button, Stack, Typography } from '@mui/material';
import { PedigreeCaseEvent } from 'api/pedigreeCase/staff-pedigree-case-service';
import { isoToShortDate } from 'common/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PedigreeRequestReminderEventsViewProps {
  pedigreeCaseEvents: PedigreeCaseEvent[];
}
export function PedigreeRequestReminderEventsView({
  pedigreeCaseEvents,
}: PedigreeRequestReminderEventsViewProps) {
  const { t, i18n } = useTranslation('pedigreeCaseRequestReminderEventsView');

  const events = useMemo(() => {
    if (!pedigreeCaseEvents) {
      return [];
    }
    const filteredEvents = pedigreeCaseEvents.filter(
      (event) =>
        event.name === 'pedigreeRequested' ||
        event.name === 'pedigreeRequestReminded',
    );

    const sortedEvents = filteredEvents.sort((a, b) => {
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    });

    return sortedEvents;
  }, [pedigreeCaseEvents]);

  const latestRequestedEvent = useMemo(
    () =>
      events
        .filter((event) => event.name === 'pedigreeRequested')
        .sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
        )[0],
    [events],
  );

  return (
    <Stack>
      <ReminderEvents pedigreeCaseEvents={pedigreeCaseEvents} />

      {latestRequestedEvent && (
        <Typography variant="body2">
          {t('requested-event-text', {
            date: isoToShortDate(latestRequestedEvent.createdAt, i18n),
            initiator: latestRequestedEvent.createdBy,
          })}
        </Typography>
      )}
    </Stack>
  );
}
function ReminderEvents({
  pedigreeCaseEvents,
}: PedigreeRequestReminderEventsViewProps) {
  const [showAll, setShowAll] = useState(false);

  const { t, i18n } = useTranslation('pedigreeCaseRequestReminderEventsView');

  const getReminderEventText = useCallback(
    (event: PedigreeCaseEvent) =>
      t('reminded-event-text', {
        date: isoToShortDate(event.createdAt, i18n),
        initiator: event.createdBy,
      }),
    [t, i18n],
  );

  const reminderEvents = useMemo(
    () =>
      pedigreeCaseEvents.filter(
        (event) => event.name === 'pedigreeRequestReminded',
      ),
    [pedigreeCaseEvents],
  );
  if (reminderEvents.length === 0) {
    return null;
  }
  if (reminderEvents.length === 1) {
    return (
      <Typography variant="body2">
        {getReminderEventText(reminderEvents[0])}
      </Typography>
    );
  }
  return (
    <Stack>
      {reminderEvents.map((event, index) =>
        index === 0 ? (
          <Stack
            direction="row"
            spacing={2}
            key={index}
            justifyContent="space-between"
            alignItems="end"
          >
            <Typography variant="body2">
              {getReminderEventText(event)}
            </Typography>
            <Button
              onClick={() => setShowAll(!showAll)}
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
              }}
            >
              {showAll ? t('button.show-less') : t('button.show-more')}
            </Button>
          </Stack>
        ) : (
          showAll && (
            <Typography key={index} variant="body2">
              {getReminderEventText(event)}
            </Typography>
          )
        ),
      )}
    </Stack>
  );
}
