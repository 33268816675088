import { Stack, Typography } from '@mui/material';
import SpinnerIcon from '../icons/SpinnerIcon';
import './CircularLoading.css';

interface CircularLoadingProps {
  width?: number;
  height?: number;
  label?: string;
  spinnerColor?: string;
}

export const CircularLoading = ({
  width = 20,
  height = 21,
  label,
}: CircularLoadingProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <SpinnerIcon
        width={width}
        height={height}
        className="loader"
      ></SpinnerIcon>
      {label && (
        <Typography
          sx={{
            opacity: 0.7,
          }}
        >
          {label}
        </Typography>
      )}
    </Stack>
  );
};
