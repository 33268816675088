import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { Diagnose, Sex } from 'pedigree/features/common/types';
import { Arrow, Circle, Group, Line, Rect, Text, Wedge } from 'react-konva';
import { VisibleNodeProperties } from '../hooks/usePedigreeFilrerView';

interface PedigreeNodeViewProps {
  isIndex: boolean;
  nodeWidth: number;
  sex: Sex;
  name?: string;
  diagnoses?: Diagnose[];
  yearOfBirth?: number;
  ageAtDeath?: number;
  deceased?: boolean;
  diagnoseToColorMap: Record<string, string>;
  visibleNodeProperties: VisibleNodeProperties;
  isSelected?: boolean;
}

export function PedigreeNodeView(props: PedigreeNodeViewProps) {
  const {
    isIndex,
    name,
    diagnoses,
    yearOfBirth,
    ageAtDeath,
    deceased,
    sex,
    nodeWidth,
    visibleNodeProperties,
  } = props;

  const age = getAge(deceased || false, yearOfBirth, ageAtDeath);
  let text = '';
  let nameAgeRow = '';
  if (name && visibleNodeProperties.name) nameAgeRow += name;
  if (age && visibleNodeProperties.age) nameAgeRow += ', ' + age;
  if (nameAgeRow) text += nameAgeRow + '\n';
  if (diagnoses && visibleNodeProperties.diagnosis)
    text += createDiagnosesText(diagnoses);

  return (
    <>
      <NodeBorder sex={sex} width={nodeWidth} isSelected={props.isSelected} />
      <Group {...getClip(nodeWidth, sex)}>
        <DiagnosesColoringGroup
          colors={
            props.diagnoses?.map(
              (diagnose) => props.diagnoseToColorMap[diagnose.diseaseName],
            ) || []
          }
        />
      </Group>
      <Text
        text={text}
        x={-nodeWidth / 2}
        y={nodeWidth / 2}
        width={2 * nodeWidth}
        fontSize={10}
        fontFamily="Arial"
        fill="black"
        lineHeight={1.4}
      />
      {isIndex && <IndexArrow nodeWidth={nodeWidth} />}
      {deceased && <DeceasedIndicator nodeWidth={nodeWidth} />}
    </>
  );
}

const getClip = (width: number, sex: Sex) => {
  width = width - 2;
  if (sex === Sex.FEMALE)
    return {
      clipFunc: (ctx: any) => {
        ctx.arc(0, 0, width / 2, 0, Math.PI * 2, false);
      },
    };
  return {
    clip: {
      x: -width / 2,
      y: -width / 2,
      width: width,
      height: width,
    },
  };
};

interface DiagnosesColoringGroupProps {
  colors: string[];
}

function DiagnosesColoringGroup(props: DiagnosesColoringGroupProps) {
  const { colors } = props;
  const wedgeRadius = 100;

  if (colors.length === 0) {
    return (
      <Rect
        x={-wedgeRadius / 2}
        y={-wedgeRadius / 2}
        width={wedgeRadius}
        height={wedgeRadius}
        fill="white"
      />
    );
  }

  const wedgeAngle = Math.ceil(360 / colors.length);

  return (
    <Group>
      {colors.map((color, index) => (
        <Wedge
          key={index}
          radius={wedgeRadius}
          angle={wedgeAngle}
          fill={color}
          rotation={wedgeAngle * index + 90}
        />
      ))}
    </Group>
  );
}

interface NodeBorderProps {
  sex: Sex;
  width: number;
  isSelected?: boolean;
}

function NodeBorder(props: NodeBorderProps) {
  const theme = useTheme();
  const { width, sex } = props;
  const strokeProps = {
    stroke: props.isSelected
      ? theme.palette.common.brand.blue
      : theme.palette.common.black,
    strokeWidth: 2,
  };
  const shadowProps = props.isSelected
    ? {
        shadowColor: theme.palette.common.black,
        shadowBlur: 3,
        shadowOffset: { x: 1, y: 1 },
        shadowOpacity: 0.9,
      }
    : {};

  if (sex === Sex.FEMALE) {
    return <Circle radius={width / 2} {...strokeProps} {...shadowProps} />;
  }
  return (
    <Rect
      x={-width / 2}
      y={-width / 2}
      width={width}
      height={width}
      {...strokeProps}
      {...shadowProps}
    />
  );
}

interface IndexArrowProps {
  nodeWidth: number;
}

function IndexArrow({ nodeWidth }: IndexArrowProps) {
  const arrowLength = nodeWidth;
  const dx = arrowLength / 2;
  const dy = arrowLength / 3;

  return (
    <Arrow
      x={-nodeWidth - 5}
      y={nodeWidth - dy}
      points={[0, 0, dx, -dy]}
      fill="black"
      stroke="black"
      strokeWidth={2}
    />
  );
}

interface DeceasedIndicatorProps {
  nodeWidth: number;
}

function DeceasedIndicator({ nodeWidth }: DeceasedIndicatorProps) {
  const offset = 5;
  return (
    <Line
      points={[
        -nodeWidth / 2 - offset,
        nodeWidth / 2 - offset,
        nodeWidth / 2 + offset,
        -nodeWidth / 2 + offset,
      ]}
      stroke="black"
      strokeWidth={2}
    />
  );
}

function createDiagnosesText(diagnoses: Diagnose[]) {
  return diagnoses
    .map((diagnose) => {
      const ageOfOnsetString = diagnose.ageOfOnset
        ? `(${diagnose.ageOfOnset})`
        : '';
      return `${diagnose.diseaseName} ${ageOfOnsetString}`;
    })
    .join('\n');
}

function getAge(deceased: boolean, yearOfBirth?: number, ageAtDeath?: number) {
  if (!deceased && yearOfBirth) {
    return (DateTime.now().year - yearOfBirth).toString();
  }
  if (ageAtDeath) {
    return `d.${ageAtDeath}`;
  }
}
