import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack } from '@mui/material';
import CardWithBorder from 'components/cards/CardWithBorder';
import { CollapsibleRowType } from 'components/tables/CollapsibleRow';
import TableWithCollapsibleRows from 'components/tables/TableWithCollapsibleRows';
import { useTranslation } from 'react-i18next';
import ConsentRequestListItemHeaderView from './ConsentRequestListItemHeaderView';

interface ConsentRequestCardViewProps {
  patientName: string;
  patientPersonalNumber: string;
  createdAt: string;
  completedAt?: string;
  relativeTableHeadings: string[];
  relativeTableRows: CollapsibleRowType[];
  onCompleteClick?: () => void;
  onSendReminderClick?: () => void;
  onAddRelativeClick?: () => void;
}

function ConsentRequestCardView(props: ConsentRequestCardViewProps) {
  const { t } = useTranslation('all');

  return (
    <CardWithBorder variant="outlined" sx={{ pb: 2 }}>
      <Stack direction="column" spacing={1}>
        <ConsentRequestListItemHeaderView
          patientName={props.patientName}
          patientPersonalNumber={props.patientPersonalNumber}
          createdAt={props.createdAt}
          completedAt={props.completedAt}
          onCompleteConsentClick={props.onCompleteClick}
          onSendReminderClick={props.onSendReminderClick}
        />
        <TableWithCollapsibleRows
          headings={props.relativeTableHeadings}
          rows={props.relativeTableRows}
        />
        {props.onAddRelativeClick && (
          <Box sx={{ justifyContent: 'flex-start' }} pl={2}>
            <Button
              color="primary"
              size="medium"
              onClick={props.onAddRelativeClick}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t('consent-action-button.add-relative')}
            </Button>
          </Box>
        )}
      </Stack>
    </CardWithBorder>
  );
}

export default ConsentRequestCardView;
