import { Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useTablePagination from '../../common/hooks/useTablePagination';
import { useCaseInfoTable } from '../hooks/useCaseInfoTable';
import { PatientListView } from '../views/PatientListView';

const PatientUpdatedListController = () => {
  const { t } = useTranslation(['myFamilyTree']);
  const service = useStaffPedigreeCaseService();

  const [totalCount, setTotalCount] = useState<number>(0);

  const { paginationComponent, limit, offset } = useTablePagination({
    count: totalCount,
  });

  const submittedAfter = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const newDate = date.toISOString();
    return newDate;
  }, []);

  const { data, isLoading } = useQuery(
    ['pedigreeCasesUpdate', submittedAfter, limit, offset],
    () =>
      service.getPedigreeCasesUpdates({
        submittedAfter,
        limit: `${limit}`,
        offset: `${offset}`,
      }),
    {
      onSuccess: (data) => {
        const { count } = data || {};
        setTotalCount(count);
      },
    },
  );

  const { rows, headings } = useCaseInfoTable({
    items: data?.items || [],
    tabSection: 'updates',
  });

  const casesEmptyStateTitle = t(
    'my-family-tree-management.page-dashboard.table.updates-empty-state.title',
  );

  const casesEmptyStateDescription = t(
    'my-family-tree-management.page-dashboard.table.updates-empty-state.description',
  );

  const casesInfo = t(
    'my-family-tree-management.page-dashboard.table.updates.info',
  );

  const showPagination = useMemo(() => {
    return data?.items && data?.items?.length >= limit;
  }, [data?.items, limit]);

  return (
    <>
      {isLoading ? (
        <LoadingBackdrop />
      ) : (
        <Stack mb={4}>
          <PatientListView
            rows={rows}
            headings={headings}
            casesEmptyStateTitle={casesEmptyStateTitle}
            casesEmptyStateDescription={casesEmptyStateDescription}
            casesInfo={casesInfo}
            {...(showPagination
              ? {
                  paginationComponent,
                }
              : undefined)}
          ></PatientListView>
        </Stack>
      )}
    </>
  );
};

export default PatientUpdatedListController;
