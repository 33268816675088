import { Typography } from '@mui/material';
import ErrorViewLayout from 'features/common/views/ErrorViewLayout';
import { useTranslation } from 'react-i18next';

const NotAllowedToSignConsentView = () => {
  const { t } = useTranslation(['all']);
  return (
    <ErrorViewLayout>
      <Typography variant="h1">{t('consent-not-allowed.title')}</Typography>
      <Typography variant="body1">
        {t('consent-not-allowed.content')}
      </Typography>
    </ErrorViewLayout>
  );
};

export default NotAllowedToSignConsentView;
