import { useState } from 'react';
import { useDebounce } from 'react-use';

interface Sort {
  field: string;
  order: string;
}

type SortAndSearchParameters<T> = T & {
  sort: Sort;
  search: string;
};

interface useSortAndSearchInputProps<T> {
  initialSortAndSearchParameters: T;
}

const useSortAndSearchInput = <T,>(
  props: useSortAndSearchInputProps<SortAndSearchParameters<T>>,
) => {
  const [sortAndSearchParameters, setSortAndSearchParameters] = useState<
    SortAndSearchParameters<T>
  >(props.initialSortAndSearchParameters);

  const searchValue = sortAndSearchParameters.search;

  const [currentSearchValue, setCurrentSearchValue] =
    useState<string>(searchValue);

  const handleSetSortValue = (value: string) => {
    const [field, order] = value.split(':') as [string, string];
    setSortAndSearchParameters((prev) => ({
      ...prev,
      sort: {
        field,
        order,
      },
    }));
  };

  const handleSetSearchValue = (value: string) => {
    setSortAndSearchParameters((prev) => ({
      ...prev,
      search: value,
    }));
  };

  const sortValue = `${sortAndSearchParameters.sort.field}:${sortAndSearchParameters.sort.order}`;

  useDebounce(
    () => {
      if (currentSearchValue.length === 0 || currentSearchValue.length > 2)
        handleSetSearchValue(currentSearchValue);
    },
    500,
    [currentSearchValue],
  );

  return {
    searchValue: currentSearchValue,
    setSearchValue: setCurrentSearchValue,
    sortValue,
    setSortValue: handleSetSortValue,
    searchQuery: searchValue,
    sortQuery: sortValue,
  };
};

export default useSortAndSearchInput;
