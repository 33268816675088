import { Box, Snackbar, Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { NumberOfRelativesFormData } from 'common/features/newPedigreeForm/types';
import { usePedigreePrintExport } from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import NewPedigreeForm from 'iPedigree/features/patientPage/NewPedigreeForm';
import PedigreeOverviewPanel from 'iPedigree/features/patientPage/PedigreeOverviewPanel';
import PedigreeReviewPanel from 'iPedigree/features/patientPage/PedigreeReviewPanel';
import Pedigree from 'pedigree/features';
import { CustomizableFormData } from 'pedigree/features/common/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { ToastMessage } from '../../components/common/ToastMessage';

export const PatientPedigreePage = () => {
  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();
  const { t } = useTranslation('iPedigree');
  const service = useStaffPedigreeCaseService();
  const [showCreatePedigreeForm, setShowCreatePedigreeForm] =
    useState<boolean>(false);
  const location = useLocation();
  const [reviewToastType, setReviewToastType] = useState<
    'accept' | 'reject' | null
  >(null);

  const {
    onPedigreePrintClick,
    onPedigreeDownloadClick,
    dialog: printExportDialog,
  } = usePedigreePrintExport();

  const queryClient = useQueryClient();
  const reviewMutation = useMutation(
    (review: 'accept' | 'reject') =>
      service.reviewPedigreeCase(pedigreeCaseId || '', review),
    {
      onSuccess: (_, review) => {
        setReviewToastType(review);
        queryClient.invalidateQueries([
          'pedigreeCase',
          pedigreeCaseId,
          'pedigreePage',
        ]);
      },
    },
  );

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', pedigreeCaseId, 'pedigreePage'],
    () => service.getPedigreeCase(pedigreeCaseId || ''),
  );

  useEffect(() => {
    const navigationState = location.state as {
      showCreatePedigreeForm: boolean;
    };
    if (
      navigationState?.showCreatePedigreeForm &&
      pedigreeCase?.status === 'caseCreated'
    ) {
      setShowCreatePedigreeForm(true);
    }
  }, [location.state, pedigreeCase]);

  if (!pedigreeCaseId) return null;

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  const showPedigreeOverviewPanel =
    !showCreatePedigreeForm &&
    (pedigreeCase?.status === 'pedigreeRequested' ||
      pedigreeCase?.status === 'caseCreated');

  if (showPedigreeOverviewPanel) {
    return (
      <Box mt={4} display="flex" justifyContent="center">
        <Snackbar
          open={reviewToastType !== null}
          onClose={() => setReviewToastType(null)}
          message={
            reviewToastType === 'reject' ? (
              <ToastMessage message={t('review.rejected')} />
            ) : null
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={1800}
        />
        <Stack display="flex" width="100%" maxWidth={705}>
          <PedigreeOverviewPanel pedigreeCaseId={pedigreeCaseId} />
        </Stack>
      </Box>
    );
  }

  const askForConcerningCancer =
    (pedigreeCase && pedigreeCase?.purpose.length === 0) || false;

  const handleSubmitNewPedigreeForm = async ({
    numberOfRelatives,
    purpose,
  }: {
    numberOfRelatives: NumberOfRelativesFormData;
    purpose?: { id: number };
  }) => {
    if (purpose) {
      await service.updatePedigreeCasePurpose(pedigreeCaseId, purpose);
    }
    await service.createNewPedigree(pedigreeCaseId, numberOfRelatives);
    queryClient.invalidateQueries([
      'pedigreeCase',
      pedigreeCaseId,
      'pedigreePage',
    ]);
    setShowCreatePedigreeForm(false);
  };

  if (!!showCreatePedigreeForm) {
    return (
      <NewPedigreeForm
        submitFormCallback={handleSubmitNewPedigreeForm}
        onCancelClick={() => setShowCreatePedigreeForm(false)}
        askForConcerningCancer={askForConcerningCancer}
      />
    );
  }

  const isProfessionalReadonly =
    pedigreeCase?.status === 'pedigreeCreatedByPatient' ||
    pedigreeCase?.status === 'caseViewed';

  const pedigreeType = isProfessionalReadonly
    ? 'professionalReadonly'
    : 'professionalEditable';

  return (
    <>
      <Snackbar
        open={reviewToastType !== null}
        onClose={() => setReviewToastType(null)}
        message={
          reviewToastType === 'accept' ? (
            <ToastMessage message={t('review.accepted')} />
          ) : null
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1800}
      />
      {printExportDialog}
      {isProfessionalReadonly && (
        <PedigreeReviewPanel
          onAcceptClick={() => reviewMutation.mutate('accept')}
          onRejectClick={() => reviewMutation.mutate('reject')}
        />
      )}
      <Pedigree
        pedigreeType={pedigreeType}
        service={{
          getPedigree: () =>
            service?.getPedigreeByPedigreeCaseId(pedigreeCaseId),
          addNewPedigreeNode: service.addNewPedigreeNode,
          updatePedigreeNodeParents: service.updatePedigreeNodeParents,
          deletePedigreeNode: service.deletePedigreeNode,
          deletablePedigreeNode: service.deletablePedigreeNode,
          getPatientForm: () => service.getPatientForm(pedigreeCaseId),
          getRelativeForm: service.getRelativeForm,
          putRelativeForm: service.submitRelativeForm,
          putPatientForm: (formData: CustomizableFormData) =>
            service.submitPatientForm(pedigreeCaseId, formData),
          onPedigreePrintClick: isProfessionalReadonly
            ? undefined
            : () => onPedigreePrintClick(pedigreeCaseId),
          onPedigreeDownloadClick: isProfessionalReadonly
            ? undefined
            : () => onPedigreeDownloadClick(pedigreeCaseId),
        }}
      />
    </>
  );
};
