import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CollapsibleRow, {
  CollapsibleRowType,
} from 'components/tables/CollapsibleRow';
import React from 'react';

interface TableWithCollapsibleRowsProps {
  headings: React.ReactNode[];
  rows: CollapsibleRowType[];
}

function TableWithCollapsibleRows(props: TableWithCollapsibleRowsProps) {
  return (
    <TableContainer>
      <Table
        sx={{
          'th, td': {
            border: 'none',
            '&:last-child': {
              textAlign: 'center',
            },
          },
          td: {
            pt: 0.7,
            pb: 0.7,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {props.headings.map((heading, headingIdx) => (
              <TableCell key={`heading-${headingIdx}`}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, rowIdx) => {
            const isEvenRow = rowIdx % 2 === 0;
            return (
              <CollapsibleRow
                key={`row-${rowIdx}`}
                cells={row.cells}
                hiddenRows={row.hiddenRows}
                actionButtons={row.actionButtons}
                isEvenRow={isEvenRow}
                isOpen={row.isOpen}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableWithCollapsibleRows;
