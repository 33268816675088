import { i18n } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ServiceConstructor {
  new (
    baseUrl: string | undefined,
    token: string | null,
    queryClient: QueryClient,
    i18n: i18n,
    logout?: () => void,
  ): any;
}

export function serviceProviderFactory<
  S extends ServiceConstructor,
  C extends React.Context<{ service: InstanceType<S> | null }>,
>(Service: S, Context: C) {
  interface Props {
    children: React.ReactNode;
    accessToken: string | null;
    logout?: () => void;
  }

  return function ServiceProvider({ children, accessToken, logout }: Props) {
    const queryClient = useQueryClient();
    const { i18n } = useTranslation();
    const service = React.useMemo(() => {
      return new Service(API_BASE_URL, accessToken, queryClient, i18n, logout);
    }, [accessToken, queryClient, logout, i18n]);

    return <Context.Provider value={{ service }}>{children}</Context.Provider>;
  };
}

export function serviceHookFactory<
  S extends ServiceConstructor,
  C extends React.Context<{ service: InstanceType<S> | null }>,
>(Service: S, Context: C) {
  return function useService() {
    const context = React.useContext(Context);
    const serviceName = Service.name;

    if (context === undefined) {
      throw new Error(
        `use${serviceName} must be used within an ${serviceName}Provider`,
      );
    }

    if (!context.service) {
      throw new Error('Service is not initialized');
    }

    return context.service;
  };
}
