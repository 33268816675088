import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { CSSProperties } from 'react';

type Option<V> = {
  label: string;
  value: V;
};

interface OptionButtonProps<V> {
  option: Option<V>;
  icon?: IconProp;
  buttonStyle?: SxProps<Theme>;
  iconStyle?: CSSProperties;
  listItemStyle?: SxProps<Theme>;
  onOptionClick: (optionValue: V) => void;
  useDivider?: boolean;
}

export function OptionButton<V>(props: OptionButtonProps<V>) {
  const divider = props.useDivider ? <Divider /> : null;

  return (
    <>
      {divider}
      <ListItemButton
        onClick={() => props.onOptionClick(props.option.value)}
        sx={props.buttonStyle}
      >
        {props.icon && (
          <ListItemIcon sx={props.listItemStyle}>
            <FontAwesomeIcon icon={props.icon} style={props.iconStyle} />
          </ListItemIcon>
        )}
        <Typography>{props.option.label}</Typography>
      </ListItemButton>
    </>
  );
}
