import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export type Element = {
  name: string;
  content: ReactNode;
};

export type Subsection = {
  title: string;
  items: Item[];
  hiddenSubsection: boolean;
};

export type Item = {
  title: string;
  elements: Element[];
  hiddenItem: boolean;
};

interface ContentsFieldsWithSubsectionProps {
  subsection: Subsection;
  gap: number;
}

export const ContentsFieldsWithSubsection = (
  props: ContentsFieldsWithSubsectionProps,
) => {
  const { subsection, gap } = props;

  return (
    <Grid container gap={gap}>
      <Grid item xs={12}>
        <Typography variant="h4">{subsection.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack gap={gap}>
          {subsection.items.map((item, itemIndex) => {
            if (item.hiddenItem) {
              return null;
            }
            return <ContentsItems key={itemIndex} item={item} />;
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};

interface ContentsItemsProps {
  item: Item;
}

const ContentsItems = (props: ContentsItemsProps) => {
  const theme = useTheme();
  const { item } = props;

  const subsectionStyle = item.title
    ? {
        borderLeft: `1px solid ${theme.palette.common.opacity.darkBlue[25]}`,
        marginLef: '1px',
        pl: 2,
      }
    : {};

  return (
    <Grid container gap={1}>
      <Grid item xs={12}>
        <Typography variant="h5">{item.title}</Typography>
      </Grid>
      <Grid item sx={subsectionStyle}>
        <Stack gap={3}>
          {item.elements.map((element, elementIndex) => {
            return <Stack key={elementIndex}>{element.content}</Stack>;
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};
