import { Box, debounce } from '@mui/material';
import Konva from 'konva';
import {
  Children,
  ReactElement,
  ReactNode,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Layer, Rect, Stage } from 'react-konva';

interface ResponsiveRendererProps {
  children: ReactNode;
  highlightedMode?: boolean;
  stageRef: any;
  layoutSize: {
    width: number;
    height: number;
  };
}

export const ResponsiveRenderer = ({
  children,
  highlightedMode,
  layoutSize,
  stageRef,
}: ResponsiveRendererProps) => {
  const [stageDimensions, setStageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [stagePosition, setStagePosition] = useState({
    x: 0,
    y: 0,
  });

  const pedigreeLayerRef = useRef<Konva.Layer>(null);
  const overlayLayerRef = useRef<Konva.Layer>(null);

  useEffect(() => {
    const stage = document.getElementById('stage');
    // on window resize, recalculate and resize the stage
    const resizeStage = () => {
      setStageDimensions({
        width: stage?.clientWidth || 0,
        height: stage?.clientHeight || 0,
      });
    };
    const debouncedResizeStage = debounce(resizeStage, 500);

    window.addEventListener('resize', debouncedResizeStage);
    resizeStage();
    return () => {
      window.removeEventListener('resize', debouncedResizeStage);
    };
  }, []);

  const stageWrapper = document.getElementById('stage');

  useEffect(() => {
    if (stageWrapper) {
      setStageDimensions({
        width: stageWrapper.clientWidth,
        height: stageWrapper.clientHeight,
      });
    }
  }, [stageWrapper]);

  useEffect(() => {
    if (
      highlightedMode &&
      pedigreeLayerRef.current &&
      overlayLayerRef.current
    ) {
      pedigreeLayerRef.current.setZIndex(0);
      overlayLayerRef.current.setZIndex(1);
    }
  }, [highlightedMode]);

  const highlightedChildren = filterChildren(children, (child) => {
    return !!child.props.highlightedNode;
  });

  const notHighlightedChildren = filterChildren(children, (child) => {
    return !child.props.highlightedNode;
  });

  const handleDragMove = () => {
    if (stageRef.current) {
      const { x, y } = stageRef.current.getPosition();
      setStagePosition({
        x,
        y,
      });
    }
  };

  const overlayLayerOffset = {
    x: stageDimensions.width + stagePosition.x,
    y: stageDimensions.height + stagePosition.y,
  };

  return (
    <Box id="stage" sx={{ flexGrow: 1 }} data-testid="pedigree-stage">
      <Stage
        width={stageDimensions.width}
        height={stageDimensions.height}
        style={{
          overflow: 'hidden',
          height: '100%',
        }}
        offsetX={-(stageDimensions.width / 2)}
        offsetY={-(stageDimensions.height / 2)}
        draggable={true}
        ref={stageRef}
        onDragMove={handleDragMove}
      >
        <Layer
          ref={pedigreeLayerRef}
          offsetX={layoutSize.width / 2}
          offsetY={layoutSize.height / 2}
        >
          {notHighlightedChildren}
        </Layer>
        {highlightedMode && (
          <>
            <Layer
              offsetX={layoutSize.width / 2}
              offsetY={layoutSize.height / 2}
            >
              {highlightedChildren}
            </Layer>
            <Layer
              ref={overlayLayerRef}
              offsetX={overlayLayerOffset.x}
              offsetY={overlayLayerOffset.y}
            >
              <Rect
                width={stageDimensions.width * 2}
                height={stageDimensions.height * 2}
                fill="rgba(0, 0, 0, 0.5)"
              />
            </Layer>
          </>
        )}
      </Stage>
    </Box>
  );
};

const filterChildren = (
  children: ReactNode,
  predicate: (child: ReactElement) => boolean,
) => {
  if (children) {
    const childrenArray = Children.toArray(children);
    return childrenArray.filter((child) => {
      return isValidElement(child) && predicate(child);
    });
  }
};
