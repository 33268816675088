import { formatPersonalNumberInput } from 'features/common/utils';
import React from 'react';
import { RelativeDataErrors, RelativeFormData } from '../types';
import RelativeFormRowView from '../views/RelativeFormRowView';

interface RelativeFormRowControllerProps {
  data: RelativeFormData;
  setData: (data: RelativeFormData) => void;
  errors: RelativeDataErrors;
  onDeleteClick: () => void;
  disabled?: boolean;
}

function RelativeFormRowController(props: RelativeFormRowControllerProps) {
  const handleTextInputChangeFactory =
    (key: keyof RelativeFormData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (['personalNumber', 'proxySignerPersonalNumber'].includes(key)) {
        value = formatPersonalNumberInput(value);
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
      }
      props.setData({ ...props.data, [key]: value });
      if (props.errors && props.errors[key as keyof RelativeDataErrors])
        props.errors[key as keyof RelativeDataErrors] = '';
    };

  const handleAutocompleteInputChangeFactory =
    (key: keyof RelativeFormData) => (value: string | null) => {
      props.setData({ ...props.data, [key]: value });
      if (props.errors && props.errors[key as keyof RelativeDataErrors])
        props.errors[key as keyof RelativeDataErrors] = '';
    };

  return (
    <RelativeFormRowView
      data={props.data}
      errors={props.errors}
      setData={props.setData}
      onDeleteClick={props.onDeleteClick}
      disabled={props.disabled}
      handleTextInputChangeFactory={handleTextInputChangeFactory}
      handleAutocompleteInputChangeFactory={
        handleAutocompleteInputChangeFactory
      }
    />
  );
}

export default RelativeFormRowController;
