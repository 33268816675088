import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Popper as MuiPopper,
  Paper,
  PopperPlacementType,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { NodeActionMenuArrow, arrowStyle } from './NodeActionMenuArrow';
import { Action, Option } from './NodeActionsMenu';

interface NodeActionsMenuPopperProps {
  id: string;
  anchorElement: HTMLElement | null;
  options: Option[];
  offset?: number;
  placement?: PopperPlacementType;
  showArrow?: boolean;
  onMenuOptionClick: (action: Action) => void;
  onMenuOptionMouseOver?: (
    event: React.MouseEvent<HTMLElement>,
    action: Action,
  ) => void;
}

const Popper = styled(MuiPopper)(() => ({
  ...arrowStyle,
}));

const NodeActionsMenuPopper = ({
  id,
  anchorElement,
  placement = 'right',
  offset = 22,
  showArrow = false,
  options,
  onMenuOptionClick,
  onMenuOptionMouseOver,
}: NodeActionsMenuPopperProps) => {
  const theme = useTheme();

  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const popperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, offset],
        },
      },
      {
        name: 'arrow',
        enabled: showArrow,
        options: {
          element: arrowRef,
        },
      },
    ],
    [offset, showArrow, arrowRef],
  );

  return (
    <Popper
      id={id}
      open={!!anchorElement}
      anchorEl={anchorElement}
      placement={placement || 'right'}
      style={{ maxWidth: 204, minWidth: 112, zIndex: 10 }}
      modifiers={popperModifiers}
    >
      <Paper elevation={8} data-testid="paper">
        <List
          sx={{
            padding: `${theme.spacing(0.5)} 0`,
            zIndex: 1,
          }}
        >
          {showArrow && <NodeActionMenuArrow ref={setArrowRef} />}
          {options?.map((option, index: number) => {
            const hasSubMenu =
              option.subMenuOptions && option.subMenuOptions?.length > 0;
            return (
              <Stack key={`${index}-${option.value}`}>
                <ListItemButton
                  onClick={() => onMenuOptionClick(option.value)}
                  key={index}
                  sx={{
                    padding: 0,
                    backgroundColor: 'white',
                    ':hover': {
                      backgroundColor: theme.palette.common.brand.lightPurple,
                    },
                  }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 'unset',
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    <FontAwesomeIcon icon={option.icon} size="xs" />
                  </ListItemIcon>
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    p={1}
                    id={`option-menu-${option.value}`}
                    onMouseOver={(event) =>
                      onMenuOptionMouseOver &&
                      onMenuOptionMouseOver(event, option.value)
                    }
                  >
                    <Typography sx={{ lineHeight: 1.4 }}>
                      {option.label}
                    </Typography>
                    {hasSubMenu && (
                      <>
                        <IconButton
                          size="small"
                          sx={{
                            color: theme.palette.common.brand.darkBlue,
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                </ListItemButton>
                {!!option.divider && <Divider />}
              </Stack>
            );
          })}
        </List>
      </Paper>
    </Popper>
  );
};

export default NodeActionsMenuPopper;
