import Joi from 'joi';
import validator from 'validator';

export function constructDataValidator<T>(joiSchema: Joi.Schema): (data: T) => {
  valid: boolean;
  errorMessages:
    | {
        [key in keyof Partial<T>]: string;
      }
    | undefined;
} {
  return (data: T) => {
    const validationResult = joiSchema.validate(data, {
      abortEarly: false,
    });
    const valid = !validationResult.error;
    const errorMessages = validationResult.error?.details.reduce(
      (acc, error) => {
        const key = error.context?.key as keyof typeof acc;
        const type = error.type;
        return { ...acc, [key]: type };
      },
      {} as {
        [key in keyof Partial<T>]: string;
      },
    );

    return { valid, errorMessages };
  };
}

export function validatePhoneNumber(
  value: string,
  helpers: Joi.CustomHelpers,
): string | Joi.ErrorReport {
  if (validator.isMobilePhone(value, ['sv-SE'], { strictMode: false })) {
    return value;
  }
  return helpers.error('phoneNumber.invalid');
}
