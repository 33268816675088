import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConsentDeclinedConfirmationView: React.FC = () => {
  const { t } = useTranslation(['all']);

  return (
    <Box sx={{ textAlign: 'center', px: 2, my: 4 }}>
      <Typography variant="body1">
        {t('consent-submission-success.declined-message')}
      </Typography>
      <Typography variant="body1">
        {t('consent-submission-success.thank-you')}
      </Typography>
    </Box>
  );
};

export default ConsentDeclinedConfirmationView;
