import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import CompleteConsentRequestModal from '../views/CompleteConsentRequestModal';

interface Props {
  consentRequestIdToComplete: string | null;
  setConsentRequestIdToComplete: (consentRequestId: string | null) => void;
}

const CompleteConsentRequestController: React.FC<Props> = (props) => {
  const service = useConsentManagementService();
  const queryClient = useQueryClient();

  const completeMutation = useMutation(service.completeConsentRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'getUpdateListConsents' });
      queryClient.invalidateQueries({ queryKey: 'listConsents' });
      handleCloseClick();
    },
  });

  const showCompleteConfirmation = props.consentRequestIdToComplete !== null;

  const handleCloseClick = () => {
    props.setConsentRequestIdToComplete(null);
  };

  const handleCompleteClick = () => {
    if (!props.consentRequestIdToComplete) return;
    completeMutation.mutate(props.consentRequestIdToComplete);
  };

  return (
    <CompleteConsentRequestModal
      showCompleteConfirmation={showCompleteConfirmation}
      isSubmitting={completeMutation.isLoading}
      onCloseClick={handleCloseClick}
      onCompleteClick={handleCompleteClick}
    />
  );
};

export default CompleteConsentRequestController;
