import { useState } from 'react';

export type VisibleNodeProperties = {
  name: boolean;
  age: boolean;
  diagnosis: boolean;
  variants: boolean;
};

export type Filter = {
  [key in keyof VisibleNodeProperties]?: boolean | undefined;
};

export const usePedigreeFilter = (
  diagnoseToColorMap: Record<string, string>,
) => {
  const [filters, setFilters] = useState<VisibleNodeProperties>({
    name: true,
    age: true,
    diagnosis: true,
    variants: true,
  });

  const setFilterValue = (filter: Filter) => {
    setFilters({ ...filters, ...filter });
  };

  return {
    visibleNodeProperties: filters,
    filterViewProps: {
      filters,
      setFilterValue,
      diagnoseToColorMap,
    },
  };
};
