import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import useDialog from 'common/hooks/useDialog';
import { LoadingBackdrop } from 'components/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ErrorMessageView from './ErrorMessageView';
import NotificationIsSentConfirmationView from './NotificationIsSentConfirmationView';
import NotificationView from './NotificationView';

interface SendReminderProps {
  pedigreeCaseId: string;
}

const SendReminder = (props: SendReminderProps) => {
  const service = useStaffPedigreeCaseService();
  const { pedigreeCaseId } = props;
  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation(['pedigreeCaseSendRequestReminder', 'common']);

  const { data: reminderPreview, isLoading: reminderPreviewLoading } = useQuery(
    ['reminderPreview', pedigreeCaseId],
    () => service.getPedigreeReminderPreview(pedigreeCaseId),
  );

  const {
    data: pedigreeCase,
    isLoading: pedigreeCaseLoading,
    error: pedigreeCaseError,
    refetch: pedigreeCaseRefetch,
  } = useQuery('pedigreeCase', () => service.getPedigreeCase(pedigreeCaseId));

  const createReminderMutation = useMutation(service.createReminder, {
    onSuccess: () => {
      setShowConfirmation(true);
    },
    onError: () => openDialog(),
  });

  const { dialog, openDialog, closeDialog } = useDialog({
    dialogContent: {
      title: t('send-reminder.failed-to-send.title'),
      content: t('send-reminder.failed-to-send.message'),
      textConfirm: t('common:button.try-again'),
      textCancel: t('common:button.cancel'),
    },
    onDialogConfirmClick: () => handleSendReminder(),
  });

  const handleSendReminder = () => {
    closeDialog();
    createReminderMutation.mutate(pedigreeCaseId);
  };

  if (reminderPreviewLoading || pedigreeCaseLoading) {
    return <LoadingBackdrop />;
  }

  if (!pedigreeCase || pedigreeCaseError) {
    return (
      <ErrorMessageView
        message={t('common:error-message')}
        actions={[
          { label: t('common:button.back'), onClick: () => navigate(-1) },
          {
            label: t('common:button.try-again'),
            onClick: () => pedigreeCaseRefetch(),
            primary: true,
          },
        ]}
      />
    );
  }

  if (showConfirmation) {
    return (
      <NotificationIsSentConfirmationView
        title={t('send-reminder.confirmation.title')}
        description={t('send-reminder.confirmation.message', {
          patientName: pedigreeCase.patientDetails.name,
        })}
        actions={[
          {
            label: t('button.to-homepage'),
            onClick: () => navigate('/'),
            primary: false,
          },
          {
            label: t('button.to-patient'),
            onClick: () => navigate('./..'),
            primary: false,
          },
        ]}
      />
    );
  }

  return (
    <>
      {dialog}

      <NotificationView
        title={t('send-reminder.header')}
        onBackButtonClick={() => navigate(-1)}
        actions={[
          {
            label: t('common:button.back'),
            onClick: () => navigate(-1),
            primary: false,
            isLoading: createReminderMutation.isLoading,
          },
          {
            label: t('button.send'),
            onClick: () => handleSendReminder(),
            primary: true,
            isLoading: createReminderMutation.isLoading,
          },
        ]}
      >
        {reminderPreview}
      </NotificationView>
    </>
  );
};

export default SendReminder;
