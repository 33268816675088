import { debounce } from '@mui/material';
import React from 'react';
import { Layer, Stage } from 'react-konva';

interface ResponsiveStageProps {
  children: React.ReactNode;
  stageRef: any;
  layoutSize: {
    width: number;
    height: number;
  };
}

const ResponsiveStage = ({
  children,
  layoutSize,
  stageRef,
}: ResponsiveStageProps) => {
  const [stageDimensions, setStageDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    const stage = document.getElementById('stage');
    // on window resize, recalculate and resize the stage
    const resizeStage = () => {
      setStageDimensions({
        width: stage?.clientWidth || 0,
        height: stage?.clientHeight || 0,
      });
    };
    const debouncedResizeStage = debounce(resizeStage, 500);

    window.addEventListener('resize', debouncedResizeStage);
    resizeStage();
    return () => {
      window.removeEventListener('resize', debouncedResizeStage);
    };
  }, []);

  const stageWrapper = document.getElementById('stage');
  React.useEffect(() => {
    if (stageWrapper) {
      setStageDimensions({
        width: stageWrapper.clientWidth,
        height: stageWrapper.clientHeight,
      });
    }
  }, [stageWrapper]);

  return (
    <div
      id="stage"
      style={{ width: '100%', flexGrow: 1 }}
      data-testid="pedigree-stage"
    >
      <Stage
        width={stageDimensions.width}
        height={stageDimensions.height}
        style={{
          height: '100%',
          overflow: 'hidden',
        }}
        offsetX={-(stageDimensions.width / 2)}
        offsetY={-(stageDimensions.height / 2)}
        draggable={true}
        ref={stageRef}
      >
        <Layer offsetX={layoutSize.width / 2} offsetY={layoutSize.height / 2}>
          {children}
        </Layer>
      </Stage>
    </div>
  );
};

export default ResponsiveStage;
