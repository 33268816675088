import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { TabNavBar } from 'components/tabNavigation/views/TabNavBar';
import { useTranslation } from 'react-i18next';

export type TabValue = 'case' | 'pedigree';

interface PedigreeCasePageNavBarProps {
  currentTab: TabValue;
  handleChange: (newValue: TabValue) => void;
  onBackButtonClick: () => void;
  patientName: string | '';
  navBarHeight?: string;
}

export function PedigreeCasePageNavBar(props: PedigreeCasePageNavBarProps) {
  const {
    currentTab,
    handleChange,
    onBackButtonClick,
    patientName,
    navBarHeight,
  } = props;
  const { t } = useTranslation(['myFamilyTree']);

  return (
    <Box sx={{ backgroundColor: 'common.neutral.100' }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: navBarHeight ? navBarHeight : '50px', // using height as prop or defaults to 50px
          }}
        >
          <Typography variant="body1">
            <IconButton
              aria-label="back"
              onClick={onBackButtonClick}
              sx={{ mr: 1, mb: 0.5, color: 'primary.main' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="xs" />
            </IconButton>
            {t('overview.tab.allPatient')}
          </Typography>

          <TabNavBar<TabValue>
            value={currentTab}
            handleChange={handleChange}
            tabs={[
              {
                label: patientName
                  ? patientName
                  : t('patient.overview.caseOverview'),
                value: 'case',
              },
              { label: t('patient.overview.pedigree'), value: 'pedigree' },
            ]}
            tabsProps={{
              sx: {
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
