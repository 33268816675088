import {
  BankidClientAction,
  BankidHintCode,
  BankidLaunchMethod,
  BankidStatus,
} from 'api/bankid/bankid-service';
import { isIOS } from 'react-device-detect';

export class BankidLaunchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BankidLaunchError';
  }
}

export function launchBankIDOrFail(autoStartToken: string) {
  try {
    if (isIOS) {
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    } else {
      window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
    }
  } catch (error) {
    if (error instanceof Error) throw new BankidLaunchError(error.message);
  }
}

export interface BankidState {
  status: BankidStatus;
  hintCode: BankidHintCode;
  launchMethod: BankidLaunchMethod;
}

const responseCodeAndActionMap: {
  [status in BankidStatus]: {
    [hintCode in BankidHintCode]?:
      | null
      | [string, BankidClientAction]
      | {
          [launchMethod in BankidLaunchMethod]: [string, BankidClientAction];
        };
  };
} = {
  pending: {
    outstandingTransaction: {
      autoStart: ['RFA13', 'showLoading'],
      qrCode: ['RFA1', 'showQR'],
    },
    noClient: ['RFA1', 'showQR'],
    userSign: ['RFA9', 'showLoading'],
    started: ['RFA9', 'showLoading'],
  },
  failed: {
    userCancel: ['RFA6', 'showError'],
    expiredTransaction: ['RFA8', 'showError'],
    certificateErr: ['RFA16', 'showError'],
    startFailed: {
      autoStart: ['RFA17A', 'showError'],
      qrCode: ['RFA17B', 'showError'],
    },
  },
  complete: {},
};

export function getResponseCodeAndAction(
  state: BankidState,
): [string, BankidClientAction] {
  const { status, hintCode, launchMethod } = state;
  const responseCodeAndAction = responseCodeAndActionMap[status][hintCode];
  if (Array.isArray(responseCodeAndAction)) return responseCodeAndAction;
  if (!responseCodeAndAction && status === 'pending')
    return ['RFA21', 'showLoading'];
  if (!responseCodeAndAction && status === 'failed')
    return ['RFA22', 'showError'];
  if (!responseCodeAndAction && status === 'complete')
    return ['', 'showSuccess'];
  if (responseCodeAndAction) return responseCodeAndAction[launchMethod];
  throw new Error('BankID status and hintCode combination not handled');
}
