import { FormLabelProps } from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React from 'react';
import InputWrapper from './InputWrapper';

export type DatePickerProps = {
  label: string;
  id: string;
  value?: DateTime<true> | DateTime<false>;
  onChange?: (event: any) => void;
  error?: string;
  required?: boolean;
  placeholder?: string;
  formLabelProps?: FormLabelProps;
  labelBefore?: boolean;
  fullWidth?: boolean;
  labelInfo?: string;
  width?: string;
  format?: string;
};

const DatePicker = ({
  label,
  id,
  value,
  onChange,
  error,
  required = false,
  formLabelProps,
  labelInfo,
  labelBefore = false,
  fullWidth = true,
  width,
  format,
}: DatePickerProps) => {
  const randomId = React.useId();
  id = `${id}-${randomId}`;

  return (
    <InputWrapper
      label={label}
      childId={id}
      error={error}
      required={required}
      formLabelProps={formLabelProps}
      labelBefore={labelBefore}
      labelInfo={labelInfo}
      fullWidth={fullWidth}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MuiDatePicker
          value={value}
          onChange={onChange}
          format={format}
          sx={{
            width: width,
          }}
          slotProps={{
            textField: {
              error: false,
            },
          }}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
};

export default DatePicker;
