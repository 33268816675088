import { Stack } from '@mui/material';
import { BankidLaunchMethod } from 'api/bankid/bankid-service';
import { BankidSignButton } from 'features/bankid/views/BankidSignButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BankidLaunchMethodPickerViewProps {
  before?: React.ReactNode;
  setLaunchMethod: (method: BankidLaunchMethod) => void;
}

const BankidLaunchMethodPickerView: React.FC<
  BankidLaunchMethodPickerViewProps
> = (props) => {
  const { t } = useTranslation(['all']);
  return (
    <Stack spacing={2}>
      {props.before ? props.before : null}
      <BankidSignButton
        onClick={() => props.setLaunchMethod('autoStart')}
        fullWidth
      >
        {t('bankid.login-on-same-device')}
      </BankidSignButton>
      <BankidSignButton
        onClick={() => props.setLaunchMethod('qrCode')}
        variant="outlined"
        fullWidth
      >
        {t('bankid.login-on-another-device')}
      </BankidSignButton>
    </Stack>
  );
};

export default BankidLaunchMethodPickerView;
