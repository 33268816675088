import useDialog, { DialogContent } from 'common/hooks/useDialog';
import { TFunction } from 'i18next';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

interface usePedigreeDeleteNodeActionProps {
  pedigreeId: string;
}

export const usePedigreeDeleteNodeAction = (
  props: usePedigreeDeleteNodeActionProps,
) => {
  const [dialogContent, setDialogContent] = useState<
    DialogContent | undefined
  >();
  const [pedigreeNodeId, setPedigreeNodeId] = useState<string | null>(null);
  const { t } = useTranslation('myFamilyTree');

  const service = useMyFamilyTreeSubmissionService();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    (pedigreeNodeId: string) =>
      service?.deletePedigreeNode(props.pedigreeId, parseInt(pedigreeNodeId)),
    {
      onSuccess: () => queryClient.invalidateQueries('pedigree'),
      onError: ({ response }) => {
        const { data } = response || {};
        if (data.statusCode === 400) {
          setDialogContent(
            getDeleteDialogContent({
              errorMessage: data,
              pedigreeNodeId: pedigreeNodeId || '',
              t,
            }),
          );
          openDialog();
          setPedigreeNodeId(null);
        }
      },
    },
  );

  const { dialog, openDialog } = useDialog({
    dialogContent,
    onDialogConfirmClick: () => {
      if (pedigreeNodeId) deleteMutation.mutate(pedigreeNodeId);
    },
  });

  const handleDeleteNodeAction = (pedigreeNodeId: string) => {
    setPedigreeNodeId(pedigreeNodeId);
    setDialogContent(getDeleteDialogContent({ pedigreeNodeId, t }));
    openDialog();
  };

  return {
    deleteNodeDialog: dialog,
    handleDeleteNodeAction,
    isLoading: deleteMutation.isLoading,
  };
};

const getDeleteDialogContent = (props: {
  errorMessage?: { message: string };
  pedigreeNodeId: string;
  t: TFunction;
}): DialogContent => {
  const { t } = props;
  const { message } = props.errorMessage || {};
  const isDeletingIndexError = message === 'Index node cannot be deleted';
  const isDeletingParentError =
    message === 'Parents of index node cannot be deleted';
  const isDeletingConnectingNodeError =
    message ===
    `Pedigree is not connected after deleting node with id ${props.pedigreeNodeId}`;

  let label;
  let content;

  if (
    isDeletingIndexError ||
    isDeletingParentError ||
    isDeletingConnectingNodeError
  ) {
    label = 'isNotAllow';
  } else {
    label = 'confirm';
  }

  if (isDeletingIndexError || isDeletingParentError) {
    content = 'isNotAllow';
  } else if (isDeletingConnectingNodeError) {
    content = 'relative';
  } else {
    content = 'confirm';
  }

  const dialogContent = {
    title: t(`pedigree.node.member.dialog.title.${label}.delete`),
    content: t(`pedigree.node.member.dialog.content.${content}.delete`),
    textConfirm: t(`pedigree.node.member.dialog.button.${label}.delete`),
    textCancel:
      label === 'confirm'
        ? t('pedigree.node.member.dialog.button.cancel.delete')
        : undefined,
  };

  return dialogContent;
};
