import { defaultRelativeData } from 'features/consentManagement/common/consts';
import useCancelConfirmationDialog from 'features/consentManagement/common/hooks/useCancelConfirmationDialog';
import { RelativeFormData } from 'features/consentManagement/common/types';
import { validateRelativeData } from 'features/consentManagement/common/utils/form-validation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddRelativeFormView from '../views/AddRelativesFormView';

interface AddRelativeFormProps {
  patientName: string;
  patientPersonalNumber: string;
  relatives: RelativeFormData[];
  setRelatives: React.Dispatch<React.SetStateAction<RelativeFormData[]>>;
  onContinueClick: () => void;
}

function AddRelativeForm(props: AddRelativeFormProps) {
  const navigate = useNavigate();

  const { relatives, setRelatives } = props;
  const [errors, setErrors] = React.useState<Array<{ [key: string]: string }>>(
    [],
  );

  const handleAddRelative = () => {
    setRelatives([...relatives, defaultRelativeData]);
  };

  const handleDeleteRelative = (idx: number) => {
    const newRelatives = [...relatives];
    newRelatives.splice(idx, 1);
    setRelatives(newRelatives);
    const newErrors = [...errors];
    newErrors.splice(idx, 1);
    setErrors(newErrors);
  };

  const handleRelativeChange = (idx: number, data: RelativeFormData) => {
    const newRelatives = [...relatives];
    newRelatives[idx] = data;
    setRelatives(newRelatives);
  };

  const handleContinueClick = () => {
    const { valid, errorMessages: errors } = validateRelativeData(relatives);

    if (valid) {
      props.onContinueClick();
      return;
    }
    setErrors(errors);
  };

  const { dialog: cancelConfirmationDialog, openDialog: onCancelClick } =
    useCancelConfirmationDialog(() => navigate(-1));

  const handleBackButtonClick = () => {
    const hasChanged = relatives.some((relative, idx) => {
      return (
        relative.name !== defaultRelativeData.name ||
        relative.personalNumber !== defaultRelativeData.personalNumber ||
        relative.relationshipToPatient !==
          defaultRelativeData.relationshipToPatient ||
        relative.proxySignerName !== defaultRelativeData.proxySignerName
      );
    });
    onCancelClick(hasChanged);
  };

  return (
    <>
      <AddRelativeFormView
        onBackButtonClick={handleBackButtonClick}
        onContinueClick={handleContinueClick}
        patientName={props.patientName}
        patientPersonalNumber={props.patientPersonalNumber}
        relatives={relatives}
        errors={errors}
        handleAddRelative={handleAddRelative}
        handleDeleteRelative={handleDeleteRelative}
        handleRelativeChange={handleRelativeChange}
      />
      {cancelConfirmationDialog}
    </>
  );
}

export default AddRelativeForm;
