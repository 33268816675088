import { defaultRelativeData } from 'features/consentManagement/common/consts';
import {
  RelativeDataErrors,
  RelativeFormData,
} from 'features/consentManagement/common/types';
import { useTranslation } from 'react-i18next';
import RelativesFormSectionView from '../../common/views/RelativesSectionFormView';

interface RelativesFormControllerProps {
  data: RelativeFormData[];
  errors: RelativeDataErrors[];
  setData: (data: RelativeFormData[]) => void;
  disabled?: boolean;
  setRelativesErrorMessages: (errors: RelativeDataErrors[]) => void;
}

function RelativesFormController(props: RelativesFormControllerProps) {
  const { t } = useTranslation(['all']);
  const { data, setData } = props;

  const handleAddRelativeClick = () => {
    const newData = [...props.data];
    newData.push(defaultRelativeData);
    setData(newData);
  };

  const deleteRelative = (idx: number) => {
    const newData = [...props.data];
    newData.splice(idx, 1);
    setData(newData);
    const newErrors = [...props.errors];

    newErrors.splice(idx, 1);
    props.setRelativesErrorMessages(newErrors);
  };

  const handleRelativeChange = (idx: number, data: RelativeFormData) => {
    const newData = [...props.data];
    newData[idx] = data;
    setData(newData);
  };

  return (
    <RelativesFormSectionView
      title={t('consent-management.new-patient.section3.title')}
      data={data}
      errors={props.errors}
      handleRelativeChange={handleRelativeChange}
      disabled={props.disabled}
      handleAddRelativeClick={handleAddRelativeClick}
      handleRelativeDeleteClick={deleteRelative}
    />
  );
}

export default RelativesFormController;
