import { Autocomplete, TextField } from '@mui/material';
import { useId, useMemo } from 'react';
import InputWrapper from './InputWrapper';

interface FreeSoloAutoCompleteInputProps {
  value: string | null;
  options: { label: string; value: string }[];
  onChange: (value: string | null) => void;
  label: string;
  placeholder: string;
  required?: boolean;
  showPopupIcon?: boolean;
  error?: string;
  helperText?: string;
}

export function FreeSoloAutoCompleteInput(
  props: FreeSoloAutoCompleteInputProps,
) {
  const id = useId();
  return (
    <InputWrapper
      label={props.label}
      childId={id}
      error={props.error}
      fullWidth
      required={props.required}
    >
      <FreeSoloAutocomplete
        id={id}
        value={props.value}
        options={props.options}
        onChange={props.onChange}
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        showPopupIcon={props.showPopupIcon}
      />
    </InputWrapper>
  );
}

interface FreeSoloAutoCompleteProps {
  value: string | null;
  options: { label: string; value: string }[];
  onChange: (value: string | null) => void;
  placeholder: string;
  error?: string;
  helperText?: string;
  id: string;
  showPopupIcon?: boolean;
}

function FreeSoloAutocomplete(props: FreeSoloAutoCompleteProps) {
  const value = useMemo(() => {
    return props.options.find((option) => option.value === props.value) || null;
  }, [props.options, props.value]);

  const onChange = (_: any, value: any) => {
    props.onChange(value?.value || null);
  };

  return (
    <Autocomplete
      id={props.id}
      options={props.options}
      onChange={onChange}
      value={value}
      inputValue={props.value || ''}
      onInputChange={(_, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={props.placeholder}
          error={!!props.error}
          helperText={props.helperText}
        />
      )}
      freeSolo
      forcePopupIcon={props.showPopupIcon ? true : 'auto'}
    />
  );
}
