import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface PedigreeFiltersViewProps {
  showNames: boolean;
  showPatientAge: boolean;
  showDiagnosis: boolean;
  setShowNames: (showNames: boolean) => void;
  setShowPatientAge: (showPatientAge: boolean) => void;
  setShowDiagnosis: (showDiagnosis: boolean) => void;
  diagnoseToColorMap: Record<string, string>;
  receivedAt: string;
}

const PedigreeFiltersView = ({
  setShowNames,
  setShowPatientAge,
  setShowDiagnosis,
  showNames,
  showPatientAge,
  showDiagnosis,
  diagnoseToColorMap,
  receivedAt,
}: PedigreeFiltersViewProps) => {
  const { t } = useTranslation(['myFamilyTree']);

  return (
    <Card
      sx={{
        minWidth: 191,
        maxWidth: 191,
        position: 'absolute',
        zIndex: 100,
        top: 200,
        backgroundColor: 'white',
        marginLeft: 2,
        padding: 2,
      }}
    >
      <Stack gap={2}>
        <Stack>
          <Typography>{t('pedigree-filters.show-label')}:</Typography>
          <FormGroup sx={{ paddingLeft: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNames}
                  onChange={() => setShowNames(!showNames)}
                  size="small"
                />
              }
              label={t('pedigree-filters.names-label')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPatientAge}
                  onChange={() => setShowPatientAge(!showPatientAge)}
                  size="small"
                />
              }
              label={t('pedigree-filters.age-label')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDiagnosis}
                  onChange={() => setShowDiagnosis(!showDiagnosis)}
                  size="small"
                />
              }
              label={t('pedigree-filters.diagnosis-label')}
            />
          </FormGroup>
        </Stack>

        <Stack gap={2}>
          <Typography>Legend:</Typography>
          {Object.keys(diagnoseToColorMap).map(
            (disease: string, index: number) => (
              <Stack
                ml={2}
                gap={1}
                key={index}
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  width={24}
                  height={24}
                  sx={{ background: diagnoseToColorMap[disease] }}
                />
                <Typography>{disease}</Typography>
              </Stack>
            ),
          )}
        </Stack>

        <Typography sx={{ fontSize: 12 }} variant="body1">
          {t(
            'my-family-tree-management.pedigree-filter.received-from-patient',
            { date: DateTime.fromISO(receivedAt).toFormat('dd.MM.yyyy') },
          )}
        </Typography>
      </Stack>
    </Card>
  );
};

export default PedigreeFiltersView;
