import iPedigreeI18nInstance from 'assets/localization/i18n/iPedigreeI18nConfig';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from '.';
import { DynamicFormPlayground } from './DynamicForm';
import PedigreePlayground from './Pedigree';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '/pedigree', element: <PedigreePlayground /> },
      { path: '/dynamic-form', element: <DynamicFormPlayground /> },
    ],
  },
]);

const PlaygroundRouter = () => {
  return (
    <I18nextProvider i18n={iPedigreeI18nInstance}>
      <RouterProvider router={router} />
    </I18nextProvider>
  );
};

export default PlaygroundRouter;
