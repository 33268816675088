import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorViewLayout from './ErrorViewLayout';

const NotFoundView: React.FC = () => {
  const { t } = useTranslation(['all']);
  return (
    <ErrorViewLayout>
      <Typography variant="h1">{t('page-not-found.title')}</Typography>
      <Typography variant="body1">{t('page-not-found.content')}</Typography>
    </ErrorViewLayout>
  );
};

export default NotFoundView;
