import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import { useMemo } from 'react';

interface ReadonlyViewProps {
  title: string;
  value: string;
  padding?: number | string;
  direction?: 'row' | 'column' | 'row-reverse';
  gap?: number;
  format?: 'date' | 'personalNumber';
}

export const ReadonlyView = (props: ReadonlyViewProps) => {
  const theme = useTheme();

  const { title, value, padding, direction, gap, format } = props;

  // This should be removed when the backend returns the correct format
  const newValue = useMemo(() => {
    if (format === 'date' && value) {
      const date = value.replaceAll('-', '.');
      return `${date}.`;
    }

    if (format === 'personalNumber' && value) {
      return formatPersonalNumber(value);
    }

    return value;
  }, [format, value]);

  return (
    <Grid container gap={3} p={padding}>
      <Grid item>
        <Stack direction={direction} gap={gap}>
          <Typography
            variant="body1"
            color={theme.palette.common.opacity.darkBlue[70]}
          >
            {title}
          </Typography>
          <Typography variant="body1">{newValue}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
