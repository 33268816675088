import SendReminder from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendReminder';
import { useParams } from 'react-router-dom';

const SendRequestPage = () => {
  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();
  if (!pedigreeCaseId) {
    throw new Error('Pedigree Case ID is required');
  }

  return <SendReminder pedigreeCaseId={pedigreeCaseId} />;
};

export default SendRequestPage;
