import i18n from 'assets/localization/i18n/eConsentI18nConfig';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ConsentManagementRouter from './ConsentManagementRouter';
import ConsentSubmissionRouter from './ConsentSubmissionRouter';

export const SUBMISSION_PATH = 'my-case';

const router = createBrowserRouter([
  {
    path: '/*',
    element: <ConsentManagementRouter />,
  },
  {
    path: `/${SUBMISSION_PATH}/*`,
    element: <ConsentSubmissionRouter />,
  },
]);

export function EConsentRouter() {
  return (
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  );
}
