import { isoToShortDate } from 'common/utils';
import { useTranslation } from 'react-i18next';

interface DateViewProps {
  isoDate: string; // 1999-12-31
}

function DateView(props: DateViewProps) {
  const { i18n } = useTranslation();
  const formattedDate = isoToShortDate(props.isoDate, i18n);
  return <>{formattedDate}</>;
}

export default DateView;
