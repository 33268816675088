import { BaseService } from '../base-service';

export abstract class AuthServiceAbstract {
  abstract signin: (
    email: string,
    password: string,
  ) => Promise<{ accessToken: string; passwordChangeRequired: boolean }>;
}

export class AuthService extends BaseService implements AuthServiceAbstract {
  name = 'AuthService';

  signin = async (email: string, password: string) =>
    this.axiosInstance
      .post(`/auth/logins`, { email, password })
      .then((response) => response.data);

  updatePassword = async (password: string, token: string) =>
    this.axiosInstance.put(
      `/auth/password`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
}
