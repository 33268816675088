import { Tab, Tabs, TabsProps } from '@mui/material';

interface TabNavBarProps<T> {
  value: T;
  handleChange: (newValue: T) => void;
  tabs: { label: string; value: T }[];
  tabsProps?: TabsProps;
}

export function TabNavBar<T>({
  value,
  handleChange,
  tabs,
  tabsProps,
}: TabNavBarProps<T>) {
  return (
    <Tabs
      {...tabsProps}
      value={value}
      onChange={(_, value) => handleChange(value)}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          value={tab.value}
          {...tabAriaAttributes<T>(tab.value)}
        />
      ))}
    </Tabs>
  );
}

function tabAriaAttributes<T>(value: T) {
  return {
    id: `tab-${value}`,
    'aria-controls': `tabpanel-${value}`,
  };
}
