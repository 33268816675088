import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { TabNavBar } from 'components/tabNavigation/views/TabNavBar';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

type IPedigreePatientPageTabValue = 'patient-overview' | 'pedigree';

function IPedigreeCasePagesTabNavBar() {
  const { pedigreeCaseId } = useParams<{ pedigreeCaseId: string }>();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation(['iPedigree']);

  const service = useStaffPedigreeCaseService();
  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', pedigreeCaseId],
    () => service.getPedigreeCase(pedigreeCaseId || ''),
  );

  const patientName = useMemo(
    () => pedigreeCase?.patientDetails?.name as string,
    [pedigreeCase],
  );

  const handleBackButtonClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleTabChange = useCallback(
    (newValue: IPedigreePatientPageTabValue) => {
      if (newValue === 'patient-overview') {
        navigate('./');
      }
      if (newValue === 'pedigree') {
        navigate('./pedigree');
      }
    },
    [navigate],
  );

  const currentTab = useMemo<IPedigreePatientPageTabValue>(() => {
    if (pathname?.includes('pedigree')) {
      return 'pedigree';
    }
    return 'patient-overview';
  }, [pathname]);

  if (isLoading) return <LoadingBackdrop />;

  return (
    <Box bgcolor="common.neutral.100">
      <Container
        maxWidth="xl"
        sx={{ display: 'flex', alignItems: 'center', height: 48 }}
      >
        <Typography variant="body1">
          <IconButton
            aria-label="home"
            onClick={handleBackButtonClick}
            sx={{ mr: 1, mb: 0.5, color: 'primary.main' }}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="xs" />
          </IconButton>
          {t('case-overview.tab-navbar.home-btn.label')}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <TabNavBar<IPedigreePatientPageTabValue>
            value={currentTab}
            handleChange={handleTabChange}
            tabs={[
              { label: patientName, value: 'patient-overview' },
              {
                label: t('case-overview.tab-navbar.pedigree-tab.label'),
                value: 'pedigree',
              },
            ]}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default IPedigreeCasePagesTabNavBar;
