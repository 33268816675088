import { PedigreeNode } from 'pedigree/features/common/types';
import { useEffect, useMemo } from 'react';
import { Group } from 'react-konva';
import { Html } from 'react-konva-utils';
import { usePedigreeContext } from '../../common/pedigreeContext';
import { VisibleNodeProperties } from '../hooks/usePedigreeFilter';
import { GeneticTestMarker } from '../views/GeneticTestMarker';
import { NodeProfessionalView } from '../views/NodeProfessionalView';

interface NodeProfessionalControllerProps {
  position?: {
    x: number;
    y: number;
  };
  pedigreeNode: PedigreeNode;
  diagnoseToColorMap: Record<string, string>;
  isSelected: boolean;
  highlightedNode: boolean;
  highlightedMode: boolean;
  visibleNodeProperties: VisibleNodeProperties;
}

const NodeProfessionalController = (props: NodeProfessionalControllerProps) => {
  const {
    position,
    pedigreeNode,
    diagnoseToColorMap,
    isSelected,
    highlightedNode,
    highlightedMode,
    visibleNodeProperties,
  } = props;

  const { nodeSize, editMode, openNodeMenu, closeNodeMenu, handleNodeAction } =
    usePedigreeContext();

  const validDiagnoses = pedigreeNode.diagnoses.filter(
    ({ diseaseName }) =>
      !!diseaseName && diseaseName !== 'null' && diseaseName !== 'undefined',
  );

  const isAllowClick = highlightedMode && !highlightedNode ? false : true;

  const handleNodeClick = ({
    anchorElement,
    pedigreeNodeId,
  }: {
    anchorElement: HTMLElement;
    pedigreeNodeId: string;
  }) => {
    if (isAllowClick) {
      if (editMode && !highlightedMode) {
        openNodeMenu(anchorElement, pedigreeNodeId);
      } else {
        handleNodeAction({ action: 'select', pedigreeNodeId });
      }
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.id !== 'node-professional-controller') {
        closeNodeMenu();
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [closeNodeMenu]);

  const { x, y } = position || {
    x: 0,
    y: 0,
  };

  const colors: string[] = useMemo(() => {
    const uniqueFilteredDiagnoses = pedigreeNode.diagnoses.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.diseaseName === item.diseaseName),
    );
    return (
      uniqueFilteredDiagnoses?.map(
        (diagnose) => diagnoseToColorMap[diagnose.diseaseName],
      ) || []
    );
  }, [diagnoseToColorMap, pedigreeNode.diagnoses]);

  return (
    <>
      <Html
        groupProps={{
          x: x,
          y: y,
          offsetX: nodeSize.width / 2,
          offsetY: nodeSize.height / 2,
        }}
        divProps={{ style: { zIndex: 1 } }}
      >
        <div
          id="node-professional-controller"
          style={{
            width: nodeSize.width,
            height: nodeSize.height,
            cursor: isAllowClick ? 'pointer' : 'default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={(e) =>
            handleNodeClick({
              anchorElement: e.currentTarget,
              pedigreeNodeId: pedigreeNode.id,
            })
          }
        >
          {pedigreeNode.geneticTest?.result && (
            <div
              style={{
                position: 'absolute',
                zIndex: -1,
              }}
            >
              <GeneticTestMarker result={pedigreeNode.geneticTest?.result} />
            </div>
          )}
        </div>
      </Html>
      <Group x={x} y={y}>
        <NodeProfessionalView
          isIndex={pedigreeNode.isIndex}
          nodeWidth={nodeSize.width}
          sex={pedigreeNode.sex}
          diagnoses={pedigreeNode.diagnoses}
          name={pedigreeNode.name}
          yearOfBirth={pedigreeNode.yearOfBirth}
          ageAtDeath={pedigreeNode.ageAtDeath}
          deceased={pedigreeNode.deceased}
          geneticTest={pedigreeNode.geneticTest}
          hasError={!!pedigreeNode.hasDiagnoseHistory && !validDiagnoses.length}
          colors={colors}
          visibleNodeProperties={visibleNodeProperties}
          isSelected={isSelected}
          isCreated={pedigreeNode.created}
          isUpdated={pedigreeNode.updated}
        ></NodeProfessionalView>
      </Group>
    </>
  );
};

export default NodeProfessionalController;
