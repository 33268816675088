import { ArrayFieldTemplateProps } from '@rjsf/utils';
import {
  DiagnosisForm,
  emptyDiagnosis,
} from 'common/features/diagnosisForm/DiagnosisForm';
import {
  DiagnosisFormData,
  DiagnosisItemErrors,
  DiagnosisMedicalFormdata,
  FieldAttributes,
  FieldStyles,
} from 'common/features/diagnosisForm/types';
import { Diagnose } from 'pedigree/features/common/types';
import { useEffect, useMemo, useState } from 'react';

export const DiagnosisFormTemplate = (props: ArrayFieldTemplateProps) => {
  const [diagnoses, setDiagnoses] = useState<DiagnosisFormData[]>([]);
  const [isInitialFormState, setIsInitialFormState] = useState<boolean>(true);

  const items = useMemo(() => props.uiSchema?.items || {}, [props.uiSchema]);

  const diseaseOptions = items.disease?.['ui:enumOptions'] || [
    { label: '', value: '' },
  ];

  const { textsProvided, fieldStyles } = useMemo(() => {
    return Object.keys(items).reduce(
      (
        acc: {
          textsProvided: Record<string, FieldAttributes>;
          fieldStyles: Record<string, FieldStyles>;
        },
        key,
      ) => {
        const value = items?.[key];

        const fieldkey =
          key === 'ageAtDiagnosisIsApproximate' ? 'ageIsApproximate' : key;

        if (value) {
          acc.textsProvided[fieldkey] = {
            label: value['ui:title'] || '',
            placeholder: value['ui:placeholder'] || '',
            helperText: value['ui:description'] || '',
          };
          acc.fieldStyles[fieldkey] = {
            gridColumnWidth: value['ui:gridColumnWidth'] || '',
          };
        }

        return acc;
      },
      { textsProvided: {}, fieldStyles: {} },
    );
  }, [items]);

  const requiredFields = useMemo(() => {
    const items = props.schema?.items as any;
    return (
      items?.required?.reduce(
        (acc: { [key in keyof Diagnose]?: boolean }, field: string) => {
          acc[field as keyof Diagnose] = true;
          return acc;
        },
        {},
      ) || {}
    );
  }, [props.schema?.items]);

  const diagnosesErrors: DiagnosisItemErrors[] = useMemo(() => {
    return props.items.map((item) => {
      const error = item.children.props.errorSchema;

      if (error) {
        const field = Object.keys(error)[0];
        let errorMessage = error[field].__errors[0];

        if (errorMessage === 'Required') {
          errorMessage = 'string.empty';
        }

        return {
          [field]: errorMessage,
        };
      }
      return {};
    });
  }, [props.items]);

  const onDiagnoseUpdate = (diagnose: DiagnosisFormData, index: number) => {
    let newData = [...diagnoses];
    newData[index] = diagnose;
    setDiagnoses(newData);

    const filteredValidDiagnoseData = Object.fromEntries(
      Object.entries(diagnose).filter(
        ([_, value]) => value !== null && value !== '',
      ),
    );

    const isAnExistingDiagnosis =
      props.formData[index].id === filteredValidDiagnoseData.id;

    const formatDiagnosesData = {
      ...filteredValidDiagnoseData,
      id: isAnExistingDiagnosis ? filteredValidDiagnoseData.id : null,
      ageAtDiagnosisIsApproximate: filteredValidDiagnoseData.ageIsApproximate,
    };

    props.items[index].children.props.onChange(formatDiagnosesData);
  };

  const onDiagnoseDelete = (diagnoses: DiagnosisFormData[], index: number) => {
    setDiagnoses(diagnoses);
    return props.items[index].onDropIndexClick(index)();
  };

  const onDiagnoseAdd = (diagnoses: DiagnosisFormData[]) => {
    setDiagnoses(diagnoses);
    return props.onAddClick();
  };

  const setInitialDiagnoses = (diagnoses: DiagnosisMedicalFormdata[]) => {
    const diagnosesData = diagnoses
      .filter(({ disease }) => Boolean(disease))
      .map((diagnose, index) => ({
        ...diagnose,
        ageIsApproximate: Boolean(diagnose.ageAtDiagnosisIsApproximate),
        id: diagnose.id || index,
      }));

    return setDiagnoses(diagnosesData);
  };

  useEffect(() => {
    const diagnoses: DiagnosisMedicalFormdata[] = props.formData;
    const isEmpty = !diagnoses.some((obj) => Object.keys(obj).length > 0);

    if (isInitialFormState) {
      setIsInitialFormState(false);

      if (isEmpty) {
        if (diagnoses.length === 0) props.onAddClick();
        return setDiagnoses([emptyDiagnosis]);
      }
      return setInitialDiagnoses(diagnoses);
    }
  }, [props.formData, isInitialFormState, props]);

  const isAllowDeleteRemainingItem = diagnoses.length > 1;

  return (
    <DiagnosisForm
      isAllowDeleteRemainingItem={isAllowDeleteRemainingItem}
      diseaseOptions={diseaseOptions}
      textsProvided={textsProvided}
      fieldStyles={fieldStyles}
      diagnoses={diagnoses}
      errors={diagnosesErrors}
      onDiagnoseDelete={onDiagnoseDelete}
      onDiagnoseAdd={onDiagnoseAdd}
      onDiagnoseUpdate={onDiagnoseUpdate}
      addButtonWidth="100%"
      readonly={props.readonly}
      requiredFields={requiredFields}
    />
  );
};
