import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Divider, Stack, Typography } from '@mui/material';
import SectionBox from 'components/common/SectionBox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RelativeFormRowController from '../controllers/RelativeFormRowController';
import useDeleteRelativeConfirmationDialog from '../hooks/useDeleteRelativeConfirmationDialog';
import { RelativeDataErrors, RelativeFormData } from '../types';

interface RelativesFormSectionViewProps {
  title: string;
  data: RelativeFormData[];
  errors: RelativeDataErrors[];
  disabled?: boolean;
  handleAddRelativeClick: () => void;
  handleRelativeDeleteClick: (idx: number) => void;
  handleRelativeChange: (idx: number, data: RelativeFormData) => void;
}

function RelativesFormSectionView(props: RelativesFormSectionViewProps) {
  const { t } = useTranslation(['all']);

  const { dialog: deleteRelativeDialog, openDialog } =
    useDeleteRelativeConfirmationDialog(props.handleRelativeDeleteClick);

  const handleRelativeDeleteClick = (idx: number) => {
    const hasChanged =
      props.data[idx].personalNumber !== '' ||
      props.data[idx].name !== '' ||
      props.data[idx].relationshipToPatient !== '' ||
      props.data[idx].proxySignerName !== '';
    openDialog(idx, hasChanged);
  };

  return (
    <>
      <SectionBox>
        <Stack direction="column" spacing={3}>
          <Typography variant="h2">{props.title}</Typography>
          {props.data.map((relativeData, index) => (
            <React.Fragment key={index}>
              <RelativeFormRowController
                data={relativeData}
                setData={(data: RelativeFormData) => {
                  props.handleRelativeChange(index, data);
                }}
                errors={props.errors[index]}
                onDeleteClick={() => handleRelativeDeleteClick(index)}
                disabled={props.disabled}
              />
              <Divider />
            </React.Fragment>
          ))}
          {props.data.length === 0 && (
            <Alert severity="error">
              {t('consent-management.new-patient.section3.no-relatives')}
            </Alert>
          )}
          <Button
            onClick={props.handleAddRelativeClick}
            endIcon={
              <FontAwesomeIcon icon={faPlus} style={{ aspectRatio: 1 }} />
            }
            disabled={props.disabled}
            sx={{ justifyContent: 'flex-start' }}
          >
            {t('consent-management.new-patient.section3.add-relative')}
          </Button>
        </Stack>
      </SectionBox>
      {deleteRelativeDialog}
    </>
  );
}

export default RelativesFormSectionView;
